import { connect } from 'react-redux';
import {
  preloadScript
} from 'opentok-react';
import VoipChat from './voip_chat';
import withPGTranslation from '../../config/withPGTranslation';
import {
  subscribed, receivedSignal,
  hangupVoipChat, noAnswer,
  timerEndConversation, pullConversation,
  setReconnecting, pauseVoipChat, forceHangupVoipChat
} from '../../actions/voip_chat';
import { trackEvent } from '../../actions/mixpanel';

const mapStateToProps = ({ voipChat }) => ({ ...voipChat, ...voipChat.conversationOption });

export default preloadScript(connect(
  mapStateToProps,
  {
    subscribed,
    receivedSignal,
    hangupVoipChat,
    noAnswer,
    timerEndConversation,
    pullConversation,
    trackEvent,
    setReconnecting,
    pauseVoipChat,
    forceHangupVoipChat
  }
)(withPGTranslation(VoipChat)));
