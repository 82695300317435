import { connect } from 'react-redux';
import CreatedVoipChat from './created_voip_chat';
import Const from '../../config/const';
import withPGTranslation from '../../config/withPGTranslation';
import { voipPurchaseOnBack } from '../../actions/voip_chat';
import { trackEventSpentCredit } from '../../actions/analytics';

const mapStateToProps = ({ voipChat, user: { user } }) => ({
  ...voipChat,
  ...voipChat.order,
  userId: user && user.id,
  isNewUser: voipChat && voipChat.selectDurationKind === Const.selectDurationKind.newUser
});

export default connect(mapStateToProps, {
  voipPurchaseOnBack,
  trackEventSpentCredit
})(withPGTranslation(CreatedVoipChat));
