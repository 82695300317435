import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import AdvisorVideoPlayer from './advisor_video_player';
import { trackPlayingAdvisorProfileVideoEvent } from '../../actions/analytics';

export default connect(
  () => ({}),
  {
    trackPlayingAdvisorProfileVideoEvent
  }
)(withPGTranslation(AdvisorVideoPlayer));
