import React from 'react';
import classes from '../classes.module.scss';
import { getModeColor } from '../../../../../config/util';

export const videoOnline = (
  <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.modeIcon }>
    <path d="M12.9 1H2.7C1.76112 1 1 1.72754 1 2.625V12.375C1 13.2725 1.76112 14 2.7 14H12.9C13.8389 14 14.6 13.2725 14.6 12.375V2.625C14.6 1.72754 13.8389 1 12.9 1Z" stroke={ getModeColor('video') } strokeWidth="1.5" />
    <path d="M14.6 5.0625L17.5055 2.97955C17.7092 2.83346 18 2.97244 18 3.21591V11.7841C18 12.0276 17.7092 12.1665 17.5055 12.0205L14.6 9.9375V5.0625Z" stroke={ getModeColor('video') } strokeWidth="1.5" />
  </svg>
);
