import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

function ProfileInfoPopover(props) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {
    children, content, title
  } = props;

  const handleClick = e => {
    if (document.triggerNode && document.triggerNode.contains(e.target) && e.target.className !== 'close') {
      setIsPopoverVisible(!isPopoverVisible);
      return;
    }
    if (e.target.className === 'close') {
      setIsPopoverVisible(false);
    } else {
      const showPopover = document.contentNode
      && document.titleNode
      && (document.contentNode.parentNode.contains(e.target)
        || document.titleNode.parentNode.contains(e.target));
      setIsPopoverVisible(showPopover);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const renderContent = () => (
    <div
      className={ classes.profileInfoPopoverInnerContent }
      ref={ node => { document.contentNode = node; } }
    >
      {content}
    </div>
  );

  const renderTitle = () => (
    <div
      className={ classes.profileInfoPopoverTitle }
      ref={ node => { document.titleNode = node; } }
      suppressHydrationWarning
    >
      {title}
    </div>
  );

  return (
    <div suppressHydrationWarning>
      <div ref={ node => { document.triggerNode = node; } } suppressHydrationWarning>{children}</div>
      <div
        className={ classes.profileInfoPopoverContainer }
        style={ { '--display':  isPopoverVisible ? 'flex' : 'none' } }
        suppressHydrationWarning
      >
        <div className={ classes.profileInfoPopoverInnerContainer }>
          <div className={ classes.profileInfoPopoverTopArrow } />
          <div
            className={ classes.profileInfoPopoverInner }
            suppressHydrationWarning
          >
            { renderTitle() }
            { renderContent() }
          </div>
        </div>
      </div>
    </div>
  );
}

ProfileInfoPopover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired
};

export default ProfileInfoPopover;
