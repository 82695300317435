import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateNavigationStack, updateSmartBannerVisibility, getSEOData } from '../../actions/application';
import { LANGUAGE } from '../../config/i18n';

const updateProps = (props, index) => {
  const { path } = props;
  if (path instanceof Array) {
    return {
      ...props,
      path,
      key: index
    };
  }
  return { ...props, path, key: index };
};
function LocalizedSwitch({
  children, navigationStackUpdate,
  smartBannerVisibilityUpdate, loading, getSEODataAction
}) {
  const location = useLocation();
  const cloneElement = (el, index) => {
    if (React.isValidElement(el)) {
      const { props, props: { path } } = el;
      if (path === '/') return <Route { ...{ ...props } } key={ index } />;
      const updatedProps = updateProps(props, index);
      return (
        <Route { ...updatedProps } />
      );
    }
    return el;
  };

  useEffect(() => {
    navigationStackUpdate();
    getSEODataAction(window.location.pathname);
    if (loading && (window.location.pathname === '/' || window.location.pathname === `/${ LANGUAGE.es }`)) return;
    smartBannerVisibilityUpdate({ url: location.pathname });
  }, [location.pathname, loading]);
  return (
    <Routes>
      { children.map((child, index) => cloneElement(child, index)) }
    </Routes>
  );
}

LocalizedSwitch.propTypes = {
  children: PropTypes.node,
  navigationStackUpdate: PropTypes.func.isRequired,
  smartBannerVisibilityUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getSEODataAction: PropTypes.func.isRequired
};

LocalizedSwitch.defaultProps = {
  children: null
};

const mapStateToProps = ({ homePage }) => ({
  loading: (homePage || {}).loading
});

const mapDispatchToProps = (dispatch) => ({
  navigationStackUpdate: () => dispatch(updateNavigationStack({})),
  smartBannerVisibilityUpdate: (url) => dispatch(updateSmartBannerVisibility({ url })),
  getSEODataAction: (url) => dispatch(getSEOData(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizedSwitch);
