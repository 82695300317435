import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import PersonalSection from './personal_section';
import withPGTranslation from '../../../../config/withPGTranslation';
import { parseAdvisorsFromAdvisorsState } from '../../../../config/util';

const parseSections = (sections) => {
  const updatedSections = sections;
  Object.keys(sections).forEach((s) => {
    if (sections[s]?.content?.advisors === undefined) {
      updatedSections[s] = { ...sections[s] };
      return;
    }
    updatedSections[s] = {
      ...sections[s],
      content: {
        advisors: parseAdvisorsFromAdvisorsState(updatedSections[s]?.content?.advisors)
      }
    };
  });
  return updatedSections;
};

const mapStateToProps = ({ homePage: { personalSections } }) => (
  {
    personalSections: parseSections(personalSections)
  }
);
export default connect(
  mapStateToProps,
  {
  }
)(withLastLocation(withPGTranslation(PersonalSection)));
