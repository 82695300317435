/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import classes from './classes.module.scss';

interface AvatarStyleProps {
  width?: string,
  height?: string,
  borderRadius?: string,
}

interface AvatarProps {
  imgSrc?: string | null,
  topOverlayContainer?: ReactNode,
  bottomOverlayContainer?: ReactNode,
  transformContainer?: ReactNode
  styleProps?: AvatarStyleProps
}

const defaultAvatarUrl = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100% 100%' fill='none'%3e%3crect width='100%' height='100%' fill='%23D0D0D0'/%3e%3cg clip-path='url(%23clip0_1143_48193)'%3e%3ccircle cx='49.9996' cy='35.5552' r='12.2222' fill='%239C9C9C'/%3e%3cellipse cx='49.9998' cy='65.5552' rx='21.1111' ry='12.2222' fill='%239C9C9C'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_1143_48193'%3e%3cpath d='M0 15C0 6.71573 6.71573 0 15 0H85C93.2843 0 100 6.71573 100 15V85C100 93.2843 93.2843 100 85 100H15C6.71573 100 0 93.2843 0 85V15Z' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e";

const Avatar: React.FC<AvatarProps> = ({
  imgSrc = null, topOverlayContainer, bottomOverlayContainer, transformContainer, styleProps = {}
}) => {
  const { width, height, borderRadius } = styleProps;

  const renderTransformContainer = () => {
    if (!transformContainer) return null;
    return (
      <div className={ classes.avatarTransformContainerHolder }>
        { transformContainer }
      </div>
    );
  };

  const renderTopOverlayContainer = () => {
    if (!topOverlayContainer) return <div />;
    return (
      <div className={ classes.avatarTopOverlayContainer }>
        { topOverlayContainer }
      </div>
    );
  };

  const renderBottomOverlayContainer = () => {
    if (!renderBottomOverlayContainer) return <div />;
    return (
      <div className={ classes.avatarBottomOverlayContainer }>
        { bottomOverlayContainer }
      </div>
    );
  };

  const renderOverlaysContainer = () => {
    if (!topOverlayContainer && !bottomOverlayContainer) return null;
    return (
      <div className={ classes.avatarOverlaysContainer }>
        { renderTopOverlayContainer() }
        { renderBottomOverlayContainer() }
      </div>
    );
  };

  const getAvatarUrl = () => {
    if (!imgSrc) return defaultAvatarUrl;
    return imgSrc || defaultAvatarUrl;
  };

  const renderAvatar = () => (
    <div
      className={ classes.avatarContainer }
      style={ { width, height } }
    >
      <div className={ classes.avatarImgContainerHolder } style={ { borderRadius } }>
        <div className={ classes.avatarImgContainer }>
          <img
            src={ getAvatarUrl() }
            className={ classes.avatarImage }
            alt=""
          />
        </div>
        { renderOverlaysContainer() }
      </div>
      { renderTransformContainer() }
    </div>
  );

  return renderAvatar();
};

export default Avatar;
