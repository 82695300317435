import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  progress: false,
  error: null,
  success: false,
  visible: false,
  advisorId: null
};

const defaultHandler = {
  [Const.notifyMe.progress]: state => ({
    ...state,
    progress: true
  }),
  [Const.notifyMe.error]: (state, { payload: { error } }) => ({
    ...state,
    progress: false,
    error
  }),
  [Const.notifyMe.success]: state => ({
    ...state,
    progress: false,
    success: true
  }),
  [Const.notifyMe.clear]: () => ({
    ...defaultState
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  }),
  [Const.notifyMe.displayNotifyMe]: (state, { shouldDisplay, advisorId, clickSource }) => ({
    ...state,
    visible: shouldDisplay,
    advisorId,
    clickSource,
    success: false,
    error: null
  }),
  [Const.aplicationError.clearError]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, defaultHandler);
