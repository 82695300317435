import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import Const from '../../../config/const';

function NetworkStateComponent({ state, t }) {
  const renderNetworkState = () => <div>{ t('chat.network_seems_to_be_offline') }</div>;

  return state !== Const.pubnub.states.down ? null : (
    <div className={ classes.container }>
      <div className={ classes.holder }>
        {renderNetworkState()}
      </div>
    </div>
  );
}

NetworkStateComponent.propTypes = {
  state: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default NetworkStateComponent;
