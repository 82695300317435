import { connect } from 'react-redux';
import AdvisorEnded from './advisor_ended';
import withPGTranslation from '../../../config/withPGTranslation';
import { hangupVoipChat } from '../../../actions/voip_chat';
import { hangupChat } from '../../../actions/chat';
import { showGeneralPopup } from '../../../actions/general_popup';
import { clearPN } from '../../../actions/firebase_messaging';

const mapStateToProps = ({ voipChat, chat, pushNotification }) => ({
  voipChatState: voipChat && voipChat.voipState,
  textChatstate: chat && chat.state,
  ...pushNotification
});

export default connect(mapStateToProps, {
  hangupVoipChat,
  hangupChat,
  showGeneralPopup,
  clearPN
})(withPGTranslation(AdvisorEnded));
