import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Const from '../../../config/const';

function AdvisorEnded({
  notification, voipChatState, clearPN
}) {
  if (voipChatState === Const.voipChatStates.endedConversation) return <div />;
  useEffect(() => {
    if (notification && notification.ntype === 'advisor_ended') {
      // chatData[chatType].hangupAction();
      // showGeneralPopup(t('advisor_ended.was_ended', { callType: chatData[chatType].type }), null);
      clearPN();
    }
    return () => {};
  }, [notification]);
  return <div />;
}

AdvisorEnded.propTypes = {
  notification: PropTypes.object,
  voipChatState: PropTypes.string,
  clearPN: PropTypes.func.isRequired
};

AdvisorEnded.defaultProps = {
  notification: null,
  voipChatState: null
};

export default AdvisorEnded;
