import { connect } from 'react-redux';
import Const from '../../../config/const';
import ChatEnded from './chat_ended';
import { cancelChat } from '../../../actions/chat';
import { cancelVoipChat } from '../../../actions/voip_chat';
import { requestPermission } from '../../../actions/firebase_messaging';
import withPGTranslation from '../../../config/withPGTranslation';
import { clearAffiliatePromoPopup } from '../../../actions/affiliate_promo_popup';

const getFeedback = (order) => order && order.feedbackComment;
const getFeedbackIsPoste = (orderIdsSame, feedback) => orderIdsSame && !!feedback;

const mapStateToProps = ({ orderDetails, chat, voipChat }, { chatType }) => {
  const { orderId } = chat;
  const order = (orderDetails && orderDetails.orderDetails) || {};
  const orderIdsSame = ((order && order.id) || -1) === orderId;
  const feedback = getFeedback(order);
  const feedbackIsPosted = getFeedbackIsPoste(orderIdsSame, feedback);
  switch (chatType) {
    case Const.chatType.text:
      return {
        feedbackIsPosted,
        textChatReason: chat && chat.reason,
        ...chat
      };
    case Const.chatType.voice:
    case Const.chatType.video:
      return {
        feedbackIsPosted,
        ...voipChat,
        ...voipChat.order,
        orderState: voipChat.order.state
      };
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(cancelChat()),
        requestPermission: () => dispatch(requestPermission()),
        clearAffiliatePromoPopup: () => dispatch(clearAffiliatePromoPopup())
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(cancelVoipChat()),
        requestPermission: () => dispatch(requestPermission()),
        clearAffiliatePromoPopup: () => dispatch(clearAffiliatePromoPopup())
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPGTranslation(ChatEnded));
