import Const from '../config/const';
import { parseAndTrackAnalyticEvents } from './analytics';
import { createNotifyMeAPI, deleteNotifyMeAPI } from '../config/api';
import { loadAdvisor } from './advisors';
import { toCamelCase } from '../../common/config/utils';
import { getAdvisorSlug } from '../project_items/helper_functions';

const createProgressAction = () => ({
  type: Const.notifyMe.progress
});

const createErrorAction = error => ({
  type: Const.notifyMe.error,
  payload: {
    error
  }
});

const createSuccessAction = advisor => ({
  type: Const.notifyMe.success,
  payload: {
    advisor
  }
});

const trackNotifyMeEvents = ({ notifyMeParams, analyticEvents }) => {
  const { clickSource } = notifyMeParams;
  parseAndTrackAnalyticEvents(analyticEvents, { 'click source': clickSource });
};

const responseHandler = (promise, notifyMeParams, dispatch) => {
  promise
    .then((res) => {
      const { advisor, analyticEvents } = res;
      trackNotifyMeEvents({ notifyMeParams, analyticEvents });
      dispatch(createSuccessAction(advisor));
    })
    .catch(error => {
      error
        .json()
        .then(jsonError => {
          dispatch(createErrorAction(toCamelCase(jsonError)));
        })
        .catch(e => {
          dispatch(createErrorAction(e));
        });
    });
};

export const notifyMeAction = ({ notifyMeParams }) => (dispatch) => {
  const { notifyMeSwitchValue, advisorId, additionParams } = notifyMeParams;
  dispatch(createProgressAction());
  if (notifyMeSwitchValue) {
    responseHandler(createNotifyMeAPI(advisorId, additionParams), notifyMeParams, dispatch);
  } else {
    responseHandler(deleteNotifyMeAPI(advisorId), notifyMeParams, dispatch);
  }
};

const getNotifyMeData = advisorId => (dispatch) => {
  if (advisorId) {
    dispatch(loadAdvisor(getAdvisorSlug({ advisorId })));
  }
};

export const clearLocalState = () => ({
  type: Const.notifyMe.clear
});

const displayNotifyMeAction = (shouldDisplay, advisorId, clickSource) => ({
  type: Const.notifyMe.displayNotifyMe,
  shouldDisplay,
  advisorId,
  clickSource
});

export const displayNotifyMe = (shouldDisplay, advisorId, clickSource, needToLoadAdv = true) => (dispatch) => {
  if (advisorId && needToLoadAdv) {
    dispatch(getNotifyMeData(advisorId));
  }
  dispatch(displayNotifyMeAction(shouldDisplay, advisorId, clickSource));
};
