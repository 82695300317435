import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  price: null,
  duration: null,
  purchaseType: null,
  changingSource: false,
  clickSource: null,
  bonus: 0,
  advisorId: null,
  expiresAt: null,
  diffPrice: null,
  isMinPurchase: null,
  requiredCredit: null,
  fees: {}
});

const purchaseDetailsReducer = createReducer(getDefaultState(), {
  [Const.purchaseDetails.updateDetails]: (state, {
    clickSource,
    details: {
      price, duration, url, bonus, advisorId, expiresAt, diffPrice, isMinPurchase, requiredCredit,
      fees, isPayg, flow
    },
    purchaseType
  }) => ({
    ...state,
    price,
    duration,
    url,
    purchaseType,
    clickSource,
    bonus,
    advisorId,
    expiresAt,
    diffPrice,
    isMinPurchase,
    requiredCredit,
    fees,
    isPayg,
    flow
  }),
  [Const.purchaseDetails.changingPaymentSource]: (state, { changing }) => ({
    ...state,
    changingSource: changing
  }),
  [Const.purchaseDetails.clearDetails]: (state) => ({
    ...getDefaultState(),
    ...state
  }),
  [Const.purchaseDetails.updateApplePayEligibility]: (state, { canMakeApplePayPayments }) => ({
    ...state,
    canMakeApplePayPayments
  })
});

export default purchaseDetailsReducer;
