import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { OTPublisher, OTSubscriber, createSession } from 'opentok-react';
import VoipAlert from './voip_alert';
import Const from '../../config/const';
import classesModule from './classes.module.scss';
import classes from './classes.css'; // eslint-disable-line
import SquareAnimation from '../square_animation/square_animation';
import { log } from '../../config/app_logger';
import VoiceOverlay from './voice_overlay';
import VoipControls from './voip_controls';
import AutoHangupTimer from './auto_hangup_timer';
import DisconnectSessionTimer from './disconnect_session_timer';
import CallDurationTimer from './call_duration_timer';
import BlinkTimer from './blink_timer';

export default class VoipChat extends PureComponent {
  static propTypes = {
    tokboxSessionId: PropTypes.string,
    tokboxApiKey: PropTypes.number,
    tokboxBuyerToken: PropTypes.string,
    subscribed: PropTypes.func.isRequired,
    receivedSignal: PropTypes.func.isRequired,
    conversationOption: PropTypes.object,
    voipType: PropTypes.string.isRequired,
    profilePictureUrl: PropTypes.string.isRequired,
    enableMic: PropTypes.bool.isRequired,
    enableVideo: PropTypes.bool.isRequired,
    setReconnecting: PropTypes.func.isRequired,
    pauseVoipChat: PropTypes.func.isRequired,
    voipState: PropTypes.string.isRequired,
    forceHangupVoipChat: PropTypes.func.isRequired
  };

  static defaultProps = {
    tokboxSessionId: null,
    tokboxApiKey: null,
    tokboxBuyerToken: null,
    conversationOption: null
  };

  state = {
    stream: null,
    blinkSetup: true,
    callDurationNow: null,
    showHUD: true,
    error: null,
    chatReady: false,
    xfmProgramActive: false
  };

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('onpopstate', this.disableBackButton);
    this.sessionHelper = null;
    this.autoHangupTimer = createRef();
    this.disconnectSessiontimer = createRef();
    const { setReconnecting } = this.props;
    this.sessionHandlers = {
      sessionDisconnected: () => {
        log('VOIP CHAT', 'Session disconnected');
        this.setState({ showHUD: true });
      },
      sessionReconnected: () => {
        log('VOIP CHAT', 'Session reconnected');
        setReconnecting(false);
        this.setState({ showHUD: false });
      },
      sessionReconnecting: () => {
        log('VOIP CHAT', 'Session reconnecting');
        setReconnecting(true);
        this.setState({ showHUD: true });
      }
    };
    this.createOpentokSession();
  }

  componentDidUpdate(prevProps) {
    const { conversationOption, tokboxApiKey } = this.props;
    if (conversationOption && !prevProps.conversationOption) this.onReceivedConversationOptions();
    if (tokboxApiKey && !prevProps.tokboxApiKey) this.createOpentokSession();
  }

  componentWillUnmount() {
    window.removeEventListener('onpopstate', this.disableBackButton);
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  }

  disableBackButton = () => {
    window.history.go(1);
  };

  onPublisherError = (error) => {
    if (error.code === 1500) {
      this.setState({
        error: 'Access to camera or microphone is blocked, please check your browser settings'
      });
    }
    log('VOIP CHAT', 'Publisher error', error);
  };

  onStreamsUpdated = (streams) => {
    const { pauseVoipChat, voipState, forceHangupVoipChat } = this.props;
    this.setState({ stream: streams[0] });
    if (streams.length === 0) {
      if (voipState === Const.voipChatStates.finishing) {
        clearTimeout(this.disconnectSessiontimer.current);
        log('VOIP CHAT', 'Disconnect timer was cleared from streams update');
        pauseVoipChat();
      } else {
        log('VOIP CHAT', 'Forced voip chat hang up');
        forceHangupVoipChat();
      }
    }
    log('VOIP CHAT', 'Streams updated');
  };

  onSubscriberSubscribed = () => {
    const { subscribed } = this.props;
    log('VOIP CHAT', 'On Subcribe');
    subscribed();
    clearTimeout(this.autoHangupTimer.current);
    this.setState({ showHUD: false });
  };

  onReceivedConversationOptions = () => {
    const { conversationOption } = this.props;
    this.setState({ callDurationNow: conversationOption.callDurationTimer });
  };

  blinkOnTimer = () => {
    const { blinkSetup } = this.state;
    this.setState({ blinkSetup: !blinkSetup });
  };

  updateCallDuration = (newCallDurationNow) => this.setState({
    callDurationNow: newCallDurationNow
  });

  updateXfmProgramActive = (newXfmProgramActive) => this.setState({
    xfmProgramActive: newXfmProgramActive
  });

  clearError = () => this.setState({ error: null });

  createOpentokSession = () => {
    const {
      tokboxApiKey, tokboxSessionId,
      tokboxBuyerToken, receivedSignal
    } = this.props;
    if (tokboxApiKey && tokboxSessionId && tokboxBuyerToken) {
      log('VOIP', 'Creating OPENTOK SESSION');
      this.sessionHelper = createSession({
        apiKey: tokboxApiKey,
        sessionId: tokboxSessionId,
        token: tokboxBuyerToken,
        onStreamsUpdated: this.onStreamsUpdated,
        onConnect: () => log('VOIP CHAT', 'ON SESSION CONNECT')
      });
      this.setState({ chatReady: true });
      this.sessionHelper.session.on('signal', (event) => {
        const { callDurationNow } = this.state;
        receivedSignal(event.data, callDurationNow);
      });
      this.sessionHelper.session.on(this.sessionHandlers);
    }
  };

  renderSubscriber() {
    const { session } = this.sessionHelper;
    const { stream } = this.state;
    if (!stream) return null;
    return (
      <OTSubscriber
        key={ stream.id }
        session={ session }
        stream={ stream }
        properties={ {
          showControls: false
        } }
        onSubscribe={ this.onSubscriberSubscribed }
      />
    );
  }

  renderPublisher() {
    const { session } = this.sessionHelper;
    const { stream } = this.state;
    if (!stream) return null;
    const { voipType, enableMic, enableVideo } = this.props;
    const showVideo = voipType === Const.chatType.video && enableVideo;
    return (
      <OTPublisher
        session={ session }
        onError={ this.onPublisherError }
        properties={ {
          publishAudio: enableMic,
          publishVideo: showVideo,
          showControls: false,
          height: '150',
          width: '100%',
          videoSource: voipType === Const.chatType.video ? true : null
        } }
      />
    );
  }

  renderTimers() {
    const { stream } = this.state;
    const { pauseVoipChat } = this.props;
    return [
      <AutoHangupTimer timerRef={ this.autoHangupTimer } key="hangup_timer" />,
      <DisconnectSessionTimer
        timerRef={ this.disconnectSessiontimer }
        stream={ stream }
        onTimeOut={ pauseVoipChat }
        key="disconnect_session_timer"
      />,
      <CallDurationTimer onCallDurationUpdate={ this.updateCallDuration } updateXfmProgramActive={ this.updateXfmProgramActive } key="call_duration_timer" />,
      <BlinkTimer onTick={ this.blinkOnTimer } key="blink_timer" />
    ];
  }

  renderVoiceChatOverlay() {
    const { profilePictureUrl, voipType } = this.props;
    const { blinkSetup, callDurationNow } = this.state;
    const voiceChat = voipType === Const.chatType.voice;
    if (!voiceChat) return null;
    return (
      <VoiceOverlay
        profilePictureUrl={ profilePictureUrl }
        blinkSetup={ blinkSetup }
        callDurationNow={ callDurationNow }
      />
    );
  }

  renderControls() {
    const { voipType } = this.props;
    const voiceChat = voipType === Const.chatType.voice;
    const { blinkSetup, callDurationNow, xfmProgramActive } = this.state;
    if (voiceChat) return null;
    return <VoipControls blinkSetup={ blinkSetup } callDurationNow={ callDurationNow } xfmProgramActive={ xfmProgramActive } />;
  }

  render() {
    const {
      showHUD, error, chatReady
    } = this.state;
    if (!chatReady) return null;
    const HUD = showHUD ? <SquareAnimation /> : null;
    return (
      <div className={ classesModule.container }>
        { this.renderTimers() }
        <div>
          { this.renderSubscriber() }
          <div className={ classesModule.publisherContainer }>
            { this.renderPublisher() }
          </div>
          { this.renderControls() }
        </div>
        { this.renderVoiceChatOverlay() }
        <VoipAlert error={ error } clearError={ this.clearError } />
        { HUD }
      </div>
    );
  }
}
