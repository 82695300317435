import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import ChangeLanguage from './change_language';
import { showLanguageCreator } from '../../actions/change_language';

const mapStateToProps = ({ language }) => language;

export default connect(
  mapStateToProps,
  { showLanguage: showLanguageCreator }
)(withPGTranslation(ChangeLanguage));
