import PropTypes from 'prop-types';

export const sectionIds = {
  salesBanner: 'sales_banner',
  filters: 'filters',
  staffPick: 'staff_pick',
  specialities: 'specialities',
  recentlyViewed: 'recently_viewed',
  favorites: 'favorite_advisors',
  contactAgain: 'contact_again',
  tryout: 'tryout_advisors',
  votedMostAccurate: 'voted_most_accurate',
  highestRated: 'highest_rated',
  trending:'pg_trending',
  recomended: 'recommended',
  all_advisors:'all_advisors',
  redeemCahsback: 'redeem_cashback',
  allAdvisorsList: 'all_advisors_list',
  topAdvisorsTarotReadings: 'top_advisors_tarot_readings',
  pathHeadingAdvisors: 'path_heading_advisors',
  tensionRelationshipAdvisors: 'tension_relationship_advisors',
  soulmateAdvisors: 'soulmate_advisors'
};

export const sectionTypes = {
  salesBanner: 'sales_banner',
  staffPick: 'staff_pick',
  specialities: 'specialities',
  personal: 'personal',
  filters: 'filters',
  badge: 'badge',
  button: 'button',
  list: 'list'
};

export const sectionSubTypes = {
  endToEnd: 'end_to_end'
};

export const advisorsSectionTypes = {
  staffPick: 'staff_pick',
  personal: 'personal',
  badge: 'badge'
};

export const advisorsSectionThemes = {
  staff_pick: 'staff_pick',
  personal: 'personal',
  badge: 'badge',
  badge_end_to_end: `${ advisorsSectionTypes.badge }_${ sectionSubTypes.endToEnd }`,
  all_advisors_section: 'all_advisors_section'
};

export const advisorsSectionTypesArray = Object.keys(advisorsSectionTypes)
  .map((advisorsSectionType) => sectionTypes[advisorsSectionType]);

export const typesToArray = (object) => Object.keys(object)
  .map((item) => object[item]);

const idsArray = Object.keys(sectionIds).map((sectionId) => sectionIds[sectionId]);
const typesArray = Object.keys(sectionTypes).map((sectionType) => sectionTypes[sectionType]);

export const SectionProps = {
  type: PropTypes.oneOf(typesArray),
  id: PropTypes.oneOf(idsArray),
  analytics: PropTypes.object,
  content: PropTypes.object,
  title: PropTypes.string,
  params: PropTypes.object,
  refresh: PropTypes.bool,
  showMore: PropTypes.object,
  iconUrl: PropTypes.string,
  slug: PropTypes.string,
  subtype: PropTypes.string
};

export const SectionDefaultProps = {
  id:null,
  type: null,
  analytics: null,
  content: null,
  title: null,
  params: null,
  refresh: false,
  showMore: null,
  iconUrl: null,
  slug:null,
  subtype: null
};

export const HomePageAdvisorLiveModes = {
  mode: PropTypes.string,
  modeState: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  salesPrice: PropTypes.string
};

export const HomePageAdvisorLiveModesDefaultProps = {
  mode: '',
  modeState: '',
  name: '',
  price: ''
};

// badges
export const AdvisorBadgesProps = {
  ig: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string
};

export const AdvisorBadgesDefaultProps = {
  ig: '',
  name: '',
  color: ''
};

export const HomePageAdvisorProps = {
  id: PropTypes.number,
  liveModeMinPrice: PropTypes.string,
  liveModes: PropTypes.arrayOf(HomePageAdvisorLiveModes),
  nickname: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  rating: PropTypes.number,
  readingsCount: PropTypes.number,
  serviceTitle: PropTypes.string,
  status: PropTypes.string,
  yearJoined: PropTypes.number
};

export const HomePageAdvisorDefaultProps = {
  id: null,
  liveModeMinPrice: '',
  liveModes: [],
  nickname: '',
  profilePictureUrl: '',
  rating: 0,
  readingsCount: 0,
  serviceTitle: '',
  status: '',
  yearJoined: null
};

export const FullAdvisorDetails = {
  id: PropTypes.number,
  busy_status: PropTypes.string,
  is_available: PropTypes.string,
  status: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  nickname: PropTypes.string,
  serviceTitle: PropTypes.string,
  serviceDescription: PropTypes.string,
  instructions: PropTypes.string,
  aboutMe: PropTypes.string,
  profile_video_thumb_url: PropTypes.string,
  profile_video_url: PropTypes.string,
  advisor_share_link: PropTypes.string,
  isFavorite: PropTypes.bool,
  readingsCount: PropTypes.number,
  yearJoined: PropTypes.number,
  rating: PropTypes.number,
  isNewAdvisor: PropTypes.bool
};

export const FullAdvisorDefaultDetails = {
  id: null,
  busy_status: '',
  is_available: '',
  status: '',
  profilePictureUrl: '',
  nickname: '',
  serviceTitle: '',
  serviceDescription: '',
  instructions: '',
  about_me: '',
  profile_video_thumb_url: '',
  profile_video_url: '',
  advisor_share_link: '',
  badges: [],
  isFavorite: false,
  readingsCount: 0,
  yearJoined: 0,
  rating: 0,
  isNewAdvisor: false
};

export const PromotionContent = {
  countdownBeforeEndInSeconds: PropTypes.number,
  countdownBeforeEventInSeconds: PropTypes.number,
  promotionInviteFriendsUrl: PropTypes.string,
  promotionSaleCampaign: PropTypes.string,
  promotionSaleTitle: PropTypes.string,
  promotionShortDescription: PropTypes.string,
  shareBanner: PropTypes.bool,
  webBannerImgSrc: PropTypes.string
};

export const PromotionDefaultProps = {
  countdownBeforeEndInSeconds: null,
  countdownBeforeEventInSeconds: null,
  promotionInviteFriendsUrl: null,
  promotionSaleCampaign: null,
  promotionSaleTitle: null,
  promotionShortDescription: null,
  shareBanner: false,
  webBannerImgSrc: null
};
