import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { appGeneralPopupTheme } from '../../config/const';
import AppGeneralPopup from '../../app_general_popup';

const errorX = require('./img/error_x.svg');
const closeIcon = require('./img/close_icon.svg');

function Alert({
  errorString, message, onClose, banner
}) {
  const renderCloseBtn = () => {
    if (onClose) {
      return (
        <div className={ classes.closeBtnContainer } onClick={ onClose } onKeyDown={ onClose }>
          <img src={ closeIcon } alt="" className={ classes.closeBtnImg } />
        </div>
      );
    }
    return null;
  };

  const renderAlertContent = () => (
    <div className={ classes.alertContentContainer }>
      { renderCloseBtn() }
      <div className={ classes.errorXImgContainer }>
        <img src={ errorX } alt="" className={ classes.errorXImg } />
      </div>
      <div className={ classes.textContainer }>
        <div className={ classes.messageContainer }>
          { message }
        </div>
        <div className={ classes.errorStringContainer }>
          { errorString }
        </div>
      </div>
    </div>
  );

  const renderAlert = () => {
    if (banner) return renderAlertContent();
    return (
      <AppGeneralPopup
        visible={ !!errorString && errorString !== '' }
        closable={ onClose }
        theme={ appGeneralPopupTheme.CENTERED }
      >
        { renderAlertContent() }
      </AppGeneralPopup>
    );
  };

  return renderAlert();
}

Alert.propTypes = {
  errorString: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  banner: PropTypes.bool
};

Alert.defaultProps = {
  errorString: null,
  message: 'Error',
  onClose: null,
  banner: false
};

export default Alert;
