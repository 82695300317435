import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutgoingScreen from '../outgoin_call';

export default class Starting extends PureComponent {
  static propTypes = {
    createChat: PropTypes.func.isRequired,
    profilePictureUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    chatType: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { createChat } = this.props;
    createChat();
  }

  render() {
    const { chatType } = this.props;
    return <OutgoingScreen chatType={ chatType } />;
  }
}
