import { connect } from 'react-redux';
import PromotionLabel from './promotion_label';
import withPGTranslation from '../../../../config/withPGTranslation';
import { loadHomePage } from '../../../../actions/home_page';
import { getUserFirstActivePromotion } from '../../../../config/util';

const mapStateToProps = ({ user, application }) => ({
  userActivePromotion: getUserFirstActivePromotion({ user, application })
});

export default connect(
  mapStateToProps,
  {
    loadHomePage
  }
)(withPGTranslation(PromotionLabel));
