import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../common/helpers/alert';

function GeneralError({
  errorCode, clearAplicationError, errorText
}) {
  if (errorCode === 401 && errorText) {
    return (
      <Alert
        errorString={ errorText }
        onClose={ clearAplicationError }
      />
    );
  }
  return null;
}

GeneralError.propTypes = {
  errorCode: PropTypes.number,
  errorText: PropTypes.string,
  t: PropTypes.func.isRequired,
  clearAplicationError: PropTypes.func.isRequired
};

GeneralError.defaultProps = {
  errorCode: null,
  errorText: null
};

export default GeneralError;
