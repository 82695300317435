/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import GDPRConsernPopup from './gdpr_consern_popup';
import {
  gdprSwitcherValueChanged,
  shouldShowGDPRConsent,
  authSocial,
  clearError
} from '../../../actions/auth';

const mapStateToProps = ({
  auth: {
    consentedToGDPR, GDPRConsernPopupVisible, socialParams
  }
}) => ({
  consentedToGDPR,
  GDPRConsernPopupVisible,
  socialParams
});

export default connect(
  mapStateToProps,
  {
    gdprSwitcherValueChanged,
    shouldShowGDPRConsent,
    authSocial,
    clearError
  }
)(GDPRConsernPopup);
