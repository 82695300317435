import { connect } from 'react-redux';
import SeoData from './seo_data';

import { setNewToPGAction } from '../../../actions/application';

const mapStateProps = ({ user: { user }, application }) => ({
  newToPG: application.newToPG,
  userId: user && user.id,
  seoData: application.seoData
});

export default connect(
  mapStateProps,
  {
    setNewToPGAction
  }
)(SeoData);
