import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {};

const defaultHandler = {
  [Const.transcript.loading]: (state, { payload: { orderId } }) => ({
    ...state,
    [orderId]: {
      ...(state[orderId] || {}),
      loading: true
    }
  }),
  [Const.transcript.error]: (state, { payload: { error, orderId } }) => ({
    ...state,
    [orderId]: {
      ...(state[orderId] || {}),
      loading: false,
      error
    }
  }),
  [Const.transcript.loaded]: (state, { payload: { messages, orderId } }) => ({
    ...state,
    [orderId]: {
      ...(state[orderId] || {}),
      loading: false,
      messages
    }
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, defaultHandler);
