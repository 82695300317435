import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SignOut from '../settings/sign_out';
import { MODAL } from '../settings/sign_out/sign_out';
import classes from './classes.module.scss';
import AppLink from '../routes/app_link';
import UserAvatarPicker from '../user_avatar_picker';
import ProfileInfoPopover from './profile_info_popover';
import HeaderSearch from './header_search';
import { getComponentStyle, componentStyles } from './component_styles';
import HeaderLogo from './header_logo';
import Const from '../../config/const';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/marketing-banner.css';
import TopBanner from './top_banner';

const settings = require('./img/popover_settings.svg');
const defaultAvatar = require('./img/home_placeholder.png');

const handleScroll = ({
  withTranparentTransition, transparent, setTransparent, xfmProgram
}) => {
  if (!withTranparentTransition || xfmProgram) return;
  if (!transparent && window.scrollY === 0) setTransparent(true);
  if (transparent && window.scrollY > 0) setTransparent(false);
};

const getAvatar = ({ avatarUrlResize, avatarUrl }) => avatarUrlResize || avatarUrl || defaultAvatar;
function AppHeader({
  badgeCount, t, avatarUrl, avatarUrlResize, toggleDrawer, displayRegister,
  nickname, withTranparentTransition, itemProp, logOutSuccess, logOutClear, xfmProgram
}) {
  const [transparent, setTransparent] = useState(
    window.scrollY === 0 && withTranparentTransition && !xfmProgram
  );
  const onScroll = () => {
    handleScroll({
      withTranparentTransition, transparent, setTransparent, xfmProgram
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [transparent]);

  useEffect(() => {
    if (logOutSuccess) logOutClear();
  }, [logOutSuccess]);

  function renderProfilePopoverHeader() {
    return (
      <div suppressHydrationWarning>
        <div className={ classes.profilePopoverHeaderAvatarContainer }>
          <UserAvatarPicker>
            <div
              suppressHydrationWarning
              className={ classes.avatarContainer }
              style={ { '--size': '64px', '--radius':'24px' } }
            >
              <img
                suppressHydrationWarning
                alt=""
                src={ getAvatar({ avatarUrlResize, avatarUrl }) }
                style={ { '--radius': '20px' } }
                className={ classes.popoverAvatar }
              />
            </div>
          </UserAvatarPicker>
        </div>
        <div className={ classes.headerPopoverTitle } suppressHydrationWarning>
          { nickname || ''}
        </div>
      </div>
    );
  }

  function renderProfilePopoverContent() {
    return (
      <div className={ classes.popoverContainer }>
        <div>
          <AppLink to="/settings" className={ classes.popoverItem }>
            <img src={ settings } alt="" />
            <div className={ classes.popoverItemText }>{ t('sideMenu.settings') }</div>
          </AppLink>
        </div>
        <SignOut theme={ MODAL } />
      </div>
    );
  }

  const showSignUp = () => {
    displayRegister({ shouldDisplay: true, signUpFlow: 'menu', screen: Const.authScreens.signUp });
  };

  const showSignIn = () => {
    displayRegister({ shouldDisplay: true, signUpFlow: 'menu', screen: Const.authScreens.signIn });
  };

  const renderJoinButton = () => (
    <button
      onClick={ showSignUp }
      type="button"
      className={ classes.loginButtonContainer }
      style={ getComponentStyle('transparent', componentStyles.joinButton) }
      suppressHydrationWarning
    >
      {t('join_now')}
    </button>
  );

  function renderProfileInfo() {
    if (window.currentUserId) {
      return (
        <div className={ classes.popoverContainer } suppressHydrationWarning>
          <ProfileInfoPopover
            title={ renderProfilePopoverHeader() }
            content={ renderProfilePopoverContent() }
          >
            <div
              className={ classes.profileInfoContainer }
              type="button"
              style={ getComponentStyle(transparent, componentStyles.profileInfoContainer) }
              suppressHydrationWarning
            >
              <div suppressHydrationWarning>
                { nickname || '' }
              </div>
              <div className={ classes.profileInfoContainerDivider } suppressHydrationWarning />
              <img className={ classes.avatar } src={ getAvatar({ avatarUrlResize, avatarUrl }) } alt="" />
            </div>
          </ProfileInfoPopover>
        </div>
      );
    }
    return (
      <div className={ classes.signInButtonsContainer }>
        <button onClick={ showSignIn } type="button" className={ classes.signIn } style={ getComponentStyle(transparent, componentStyles.signIn) } suppressHydrationWarning>
          { t('sign_in') }
        </button>
        {renderJoinButton()}
      </div>
    );
  }

  return (
    <div id="mainHeaderID">
      <TopBanner />
      <div
        suppressHydrationWarning
        className={ classes.headerContainer }
        style={ getComponentStyle(transparent, componentStyles.headerContainer) }
      >
        <div className={ classes.headerContent } suppressHydrationWarning>
          <div className={ classes.contentHolder } suppressHydrationWarning>
            <HeaderLogo
              toggleDrawer={ toggleDrawer }
              sideMenuIcon={ getComponentStyle(transparent, componentStyles.sideMenuIcon) }
              badgeCount={ badgeCount }
              transparent={ transparent }
              itemProp={ itemProp }
            />
            <div className={ classes.headerSearchAndProfileContainer }>
              <HeaderSearch />
              { renderProfileInfo() }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AppHeader.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
  badgeCount: PropTypes.number,
  avatarUrl: PropTypes.string,
  avatarUrlResize: PropTypes.string,
  nickname: PropTypes.string,
  toggleDrawer: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  withTranparentTransition: PropTypes.bool,
  itemProp: PropTypes.object,
  logOutSuccess: PropTypes.bool.isRequired,
  logOutClear: PropTypes.func.isRequired,
  xfmProgram: PropTypes.object
};

AppHeader.defaultProps = {
  children: null,
  badgeCount: 0,
  avatarUrl: null,
  avatarUrlResize: null,
  nickname: null,
  withTranparentTransition: false,
  itemProp: null,
  xfmProgram: null
};

export default AppHeader;
