import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import parse, { attributesToProps } from 'html-react-parser';
import { toCamelCase } from '../../../../common/config/utils';
import classes from './classes.module.scss';
import ExpandedText from '../../../../common/helpers/expanded_text';
import { seoDataCategoriesRoutes } from '../../../config/app_routes';

const anyArticleDataExcist = (title, content) => title && content;

const metaElements = (value) => ({
  htmlDescription: <meta name="description" content={ value } key="htmlDescription" />,
  htmlTitle: <title key="htmlTitle">{value}</title>,
  ogTitle: <meta property="og:title" content={ value } key="ogTitle" />,
  ogType: <meta property="og:type" content={ value } key="ogType" />,
  ogDescription: <meta property="og:description" content={ value } key="ogDescription" />,
  ogImage: <meta property="og:image" content={ value } key="ogImage" />
});

const renderArticleTitle = (articleTitle) => {
  if (!articleTitle) return null;
  return (
    <h2 className={ classes.articleTitle }>
      { parse(articleTitle) }
    </h2>
  );
};

const renderArticleSubtitle = (articleSubtitle) => {
  if (!articleSubtitle) return null;
  return (
    <h2 className={ classes.articleSubtitle }>
      { parse(articleSubtitle) }
    </h2>
  );
};

const renderArticleText = (text, alwaysExpanded) => (alwaysExpanded ? (
  <div className={ classes.articleTextBox }>
    <span className={ classes.articleText } style={ { whiteSpace: 'pre-wrap', display: 'block' } } suppressHydrationWarning>
      {parse(text, {
        transform(reactNode, domNode, index) {
          if (!reactNode.props) return reactNode;
          const props = attributesToProps({
            ...reactNode.props, custom: 'qux', suppressHydrationWarning: true, key: `parse element index: ${ index }`
          });
          return React.createElement(`${ reactNode.type }`, { ...props });
        }
      })}
    </span>
  </div>
) : (
  <ExpandedText
    limit={ seoDataCategoriesRoutes() ? null : 4 }
    ellipsis
    className={ classes.articleTextBox }
    customClasses={ {
      text: classes.articleText,
      readMoreBtn: classes.showMoreOrLessBtn
    } }
    readMoreKind="limitTextByRows"
  >
    <span suppressHydrationWarning>
      {parse(text)}
    </span>
  </ExpandedText>
));

const needRenderNewToPGSection = ({ setNewToPGAction, newToPG }) => {
  const { utmCampaign, utmSource } = toCamelCase(queryString.parse(window.location.search));
  const isNewToPG = !!(((utmCampaign || utmSource) || newToPG));
  if (isNewToPG !== newToPG) setNewToPGAction(isNewToPG);
  return isNewToPG;
};

function SeoData({
  setNewToPGAction, newToPG, userId, seoData
}) {
  const {
    articleTitle, seoScript,
    articleSubtitle, articleContent, alwaysExpanded,
    ...rest
  } = seoData[window.location.pathname] || {};

  useEffect(() => {
    needRenderNewToPGSection({ setNewToPGAction, newToPG });
  }, [window.location.search, window.location.pathname, userId]);

  const replaceScript = () => {
    let currentElement = document.getElementById('seo_pages_script');
    if (!currentElement) {
      currentElement = React.createElement('div', { id: 'seo_pages_script' });
      document.body.appendChild(currentElement);
    }
    currentElement.innerHTML = seoScript || null;
  };

  useEffect(() => {
    replaceScript();
  }, [seoData]);

  const metaData = () => (
    <Helmet>{Object.keys(rest).map((key) => metaElements(rest[key])[key])}</Helmet>
  );

  const contentArea = () => {
    if (!anyArticleDataExcist(articleTitle, articleContent)) return null;
    return (
      <div className= { classes.specialtyContentAreaContainer }>
        <div className= { classes.specialtyContentArea } suppressHydrationWarning>
          { renderArticleTitle(articleTitle) }
          { renderArticleSubtitle(articleSubtitle) }
          { renderArticleText(articleContent, alwaysExpanded) }
        </div>
      </div>
    );
  };

  return (
    <>
      {metaData()}
      {contentArea()}
      {replaceScript()}
    </>
  );
}

SeoData.propTypes = {
  setNewToPGAction: PropTypes.func.isRequired,
  newToPG: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  seoData: PropTypes.object
};

SeoData.defaultProps = {
  userId: null,
  seoData: null
};

export default (SeoData);
