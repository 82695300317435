/* eslint-disable no-console */
import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import { buildStore } from './config/store';
import RoutesComponent from './components/routes';

import 'react-loading-skeleton/dist/skeleton.css';

import './main.css';
import shared from './main.module.scss';

import './fonts/poppins/poppins-v19-latin-regular.eot';
import './fonts/poppins/poppins-v19-latin-regular.woff';
import './fonts/poppins/poppins-v19-latin-regular.ttf';
import './fonts/poppins/poppins-v19-latin-500.eot';
import './fonts/poppins/poppins-v19-latin-500.woff';
import './fonts/poppins/poppins-v19-latin-500.ttf';
import './fonts/poppins/poppins-v19-latin-700.eot';
import './fonts/poppins/poppins-v19-latin-700.woff';
import './fonts/poppins/poppins-v19-latin-700.ttf';
import ContextRenderer from './project_items/context_renderer';

window.shared = shared;

const store = buildStore();

function App() {
  return (
    <I18nextProvider i18n={ i18n }>
      <ContextRenderer componentName="mbw_colors" />
      <Provider store={ store }>
        <RoutesComponent />
      </Provider>
    </I18nextProvider>
  );
}
export default App;
