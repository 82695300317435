/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { isKA } from '../../../common/config/utils';

interface ContextRendererProps {
  componentName: string,
  params: object
}

const ContextRenderer: React.FC<ContextRendererProps> = ({ componentName, params }) => {
  const getContextRenderer =  () => {
    const componentNameToLowerCase = componentName.toLowerCase();
    if (isKA()) {
      const ComponentKAmodule = require(`../${ componentNameToLowerCase }/ka/${ componentNameToLowerCase }.tsx`);
      const ComponentKA = ComponentKAmodule.default;
      return <ComponentKA props={ params } />;
    }
    const ComponentPGmodule = require(`../${ componentNameToLowerCase }/pg/${ componentNameToLowerCase }.tsx`);
    const ComponentPG = ComponentPGmodule.default;
    return <ComponentPG { ...params } />;
  };

  return getContextRenderer();
};

export default ContextRenderer;
