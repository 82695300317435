import { differenceInYears } from 'date-fns';

export const validateDate = ({ t, date }) => {
  let error;
  if (!date) error = t('date_picker.please_enter_your_date_of_birth');
  if (Number.isNaN(Number(date)) || differenceInYears(new Date(), new Date(date)) > 100) error = t('date_picker.please_enter_valid_date');
  if (differenceInYears(new Date(), new Date(date)) < 18) error = t('app_date_picker.you_must_be_18');
  return error;
};

export const generateDate = ({ datePickerValue }) => {
  let day;
  let month;
  let year;
  if (datePickerValue) {
    const datePickerValueSplit = datePickerValue.split('/');
    [month, day, year] = datePickerValueSplit;
  }
  const updatedDay = day || 0;
  const updatedMonth = month;
  const updatedYear = (year && year.length === 4 && year[0] !== '0') ? year : null;
  return new Date(`${ updatedMonth }/${ updatedDay }/${ updatedYear }`);
};

const getIndices = (s, t) => [...s].flatMap((char, i) => (char === t ? i + 1 : []));

export const addDividerToDateString = ({ dataString, divider }) => {
  const indexsWhenNeedSetDivider = [2, 5];
  let dataStringWithDivider = dataString;
  indexsWhenNeedSetDivider.forEach((i, index) => {
    if (dataString.length - 1 >= i && getIndices(dataString, divider).length < 2) {
      const prevDataStringPart = dataString.slice(indexsWhenNeedSetDivider[index - 1] + 1, i);
      if (dataString[i] !== divider && !prevDataStringPart.includes(divider)) {
        dataStringWithDivider = `${ dataString.slice(0, i)  }${ divider }${  dataString.slice(i) }`;
      }
    }
  });
  return dataStringWithDivider;
};

const isLeapYear = (year) => (
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
);

const getFebruaryDaysCount = (year) => {
  if (year && year.length === 4) {
    if (isLeapYear(parseInt(year, 10))) {
      return 29;
    }
    return 28;
  }
  return 29;
};

const getMonthList = (year) => (
  {
    January: 31,
    February: getFebruaryDaysCount(year),
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31
  }
);

const emptyDatePartValues = ['', '0', '00'];

export const validateDateString = ({ dateString, divider }) => {
  const dateStringSplit = dateString.split(divider);
  if (dateStringSplit[0] && dateStringSplit[0] > 12) {
    dateStringSplit[1] = `${ dateStringSplit[0][1] }`;
    dateStringSplit[0] = `0${ dateStringSplit[0][0] }`;
  }
  if (dateStringSplit[1]) {
    const monthList = getMonthList(dateStringSplit[2]);
    const monthKey = Object.keys(monthList)[parseInt(dateStringSplit[0], 10) - 1];
    if (dateStringSplit[1] > monthList[monthKey]) dateStringSplit[1] = monthList[monthKey];
  }
  if (dateStringSplit[2]?.length > 4) {
    dateStringSplit[2] = dateStringSplit[2].slice(0, 4);
  }
  dateStringSplit.forEach((item, index) => {
    const isEmptyDatePart = emptyDatePartValues.includes(item);
    if (isEmptyDatePart && dateStringSplit[index + 1]) {
      dateStringSplit[index] = '01';
    }
  });
  const updatedDateString = dateStringSplit.join(divider);
  return addDividerToDateString({ dataString: updatedDateString, divider });
};

export const cleanupDateValue = (stringValue) => stringValue.replace(/[^0-9/]/g, '');

export const getDatePickerPlaceholder = ({ datePickerValue, divider }) => {
  let placeholder = 'MM/DD/YYYY';
  const placeholderSplit = placeholder.split(divider);
  const datePickerValueSplit = datePickerValue.split(divider);
  datePickerValueSplit.forEach((item, index) => {
    if (item && datePickerValueSplit[index + 1] !== undefined) {
      placeholderSplit[index] = item;
    } else {
      for (let i = 0; i < item.length; i++) {
        placeholderSplit[index] = placeholderSplit[index]
          .replace(placeholderSplit[index][i], item[i]);
      }
    }
  });
  placeholder = placeholderSplit.join(divider);
  return placeholder;
};
