import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  kind: null,
  payload: null
});

const promoCodeReducer = createReducer(getDefaultState(), {
  [Const.popover.show]: (state, { kind, payload }) => ({
    ...state,
    kind,
    payload
  }),
  [Const.popover.hide]: (state) => ({
    ...state,
    kind: null,
    payload: null
  })
});

export default promoCodeReducer;
