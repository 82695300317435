import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  error: null,
  credits: [],
  showAffiliatePromoPopup: false,
  showAffiliatePromoCode: false,
  arg: null,
  purchaseType: null,
  noThanksLinkClicked: false,
  onCloseIconClick: null
});

const affiliatePromoPopupReducer = createReducer(getDefaultState(), {
  [Const.affiliatePromoPopup.loading]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [Const.funds.error]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error
  }),
  [Const.affiliatePromoPopup.displayDetails]: (state, { display, additionParams }) => ({
    ...state,
    showAffiliatePromoPopup: display,
    ...additionParams
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.affiliatePromoPopup.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.affiliatePromoPopup.noThanksLinkClicked]: (state) => ({
    ...state,
    noThanksLinkClicked: true
  })
});

export default affiliatePromoPopupReducer;
