import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../../common/app_general_popup';

function NotInLiveMode({ cancelChat, t }) {
  return (
    <AppGeneralPopup closeButtonAction={ cancelChat }>
      <div className={ classes.notInLiveModeContainer }>
        { t('advisor_cannot_accept_the_chat_now') }
      </div>
    </AppGeneralPopup>
  );
}

NotInLiveMode.propTypes = {
  cancelChat: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default NotInLiveMode;
