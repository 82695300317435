import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

function Switch({
  id, checked, onChange, offColor, onColor, offSliderImg, onSliderImg, offSliderColor,
  onSliderColor, width, height, handleDiameter
}) {
  const onCheckBoxChange = (e) => {
    onChange(e.target.checked);
  };

  const renderSwitch = () => (
    <label id={ id } htmlFor={ `checkbox${ id }` } className={ classes.switch } style={ { '--width': `${ width }px`, '--height': `${ height }px` } }>
      <input id={ `checkbox${ id }` } type="checkbox" checked={ checked } onChange={ onCheckBoxChange } />
      <span
        className={ classes.slider }
        style={ {
          '--offColor': offColor,
          '--onColor': onColor,
          '--offSliderColor': offSliderColor,
          '--onSliderColor': onSliderColor,
          '--offSliderImg': `url(${ offSliderImg })`,
          '--onSliderImg': `url(${ onSliderImg })`,
          '--handleDiameter': `${ handleDiameter }px`
        } }
      />
    </label>
  );

  return renderSwitch();
}

Switch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  offSliderImg: PropTypes.string,
  onSliderImg: PropTypes.string,
  offSliderColor: PropTypes.string,
  onSliderColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  handleDiameter: PropTypes.number
};

Switch.defaultProps = {
  className: null,
  checked: null,
  onChange: null,
  offColor: '#ccc',
  onColor: 'var(--app-color)',
  offSliderImg: '',
  onSliderImg: '',
  offSliderColor: '#FFFFFF',
  onSliderColor: '#FFFFFF',
  width: 44,
  height: 22,
  handleDiameter: 18,
  id: null
};

export default Switch;
