import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import Const from '../../../config/const';
import { purchaseDetailsTypes } from '../../../../common/config/const';
import classes from './classes.module.scss';
import ContinueCallFooter from './continue_call';
import StartCallFooter from './start_call';
import withPGTranslation from '../../../config/withPGTranslation';
import { log } from '../../../config/app_logger';
import env from '../../../config/variables';
import DurationSelector from './duration_selector/duration_selector';
import {
  setDefaultTransform, setDefaultSelected, renderTitle, renderThisChargeTakesIntoAccountLabel, renderInfoLabel, renderAdditionalOption, renderSaveUpLabel, renderZenModeLabel, renderNewUserPaygAvailableBalanceLabel
} from './select_chat_credit_utils';
import Spiner from '../../../../common/helpers/spiner';
import { isWebView } from '../../../../common/config/utils';

const { shared } = window;

const closeImg = require('./img/close_img.svg');

const durationChanged = (selected, option, setDefaultDurationChanged) => {
  if (selected.duration && !(option.duration === selected.duration) && !option.default) setDefaultDurationChanged(true);
  if (option.default)  setDefaultDurationChanged(false);
};

const renderClientAvailableCredit = ({ loading, clientAvailableCredit }) => {
  if (loading) return <span className={ classes.clientAvailableCreditSkeleton } />;
  return `$${ clientAvailableCredit }`;
};

function SelectChatCredit({
  pricingOptions, cancelChat, selectCredit, advisorId, name, selectDurationOption,
  isContinue, clientAvailableCredit, t, loadPricingOptions, loading, selectedCarouselIndex,
  disabledStartChatButton, chatType, displayAffiliatePromoPopupAction, userMatchUpBonusInfo,
  userShowMatchUpBonusInfo, clearAffiliatePromoPopup, isNewUser, trackDurationPickExit,
  selectorOpenedTime, selectDurationKind, advisorXfmProgram, userXfmProgram, newUserPaygPricingOption, userAdvisorModeSalePrice
}) {
  const [selected, setSelected] = useState({});
  const [defaultDurationChanged, setDefaultDurationChanged] = useState(false);
  const [selectedCarouselDefaultItem, setSelectedCarouselDefaultItem] = useState(0);
  const carouselRef = useRef(null);
  const additionalOption = pricingOptions.find(({ additional }) => additional);

  const onBackButtonEvent = () => {
    if (!isContinue) {
      cancelChat();
    }
  };

  const onSelect = (option) => {
    log('Chat', `selected option ${ JSON.stringify(option) } isContinue: ${ isContinue }`);
    durationChanged(selected, option, setDefaultDurationChanged);
    setSelected(option);
  };

  useEffect(() => {
    setDefaultTransform(selected.selectedCarouselIndex, carouselRef, pricingOptions);
  }, [selected]);

  useEffect(() => {
    if (((!pricingOptions || pricingOptions.length === 0) && !isContinue)) {
      loadPricingOptions(advisorId);
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    setDefaultSelected(onSelect, pricingOptions, selectedCarouselIndex, setSelectedCarouselDefaultItem, setDefaultDurationChanged, newUserPaygPricingOption, isNewUser);
  }, [pricingOptions, newUserPaygPricingOption]);

  const startChat = () => {
    log('Chat', `on start chat isContinue: ${ isContinue }`);
    const exitTime = new Date();
    const time = exitTime - selectorOpenedTime;
    selectCredit(selected, time, defaultDurationChanged,  isContinue);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') { startChat(); }
  };

  const onClickCurrentBalance = () => {
    log('Chat', `on use current balance, isContinue: ${ isContinue }`);
    const exitTime = new Date();
    const time = exitTime - selectorOpenedTime;
    onSelect(additionalOption);
    selectCredit(additionalOption, time, defaultDurationChanged, isContinue);
  };

  const renderClientCreditBalance = () => (
    clientAvailableCredit > 0 && selectDurationKind !== Const.selectDurationKind.newUserPayg ? (
      <div
        className={ classes.clientCreditBalanceContainer }
      >
        { t('chat.credit_balance') }
        :
        {' '}
        {renderClientAvailableCredit({ loading, clientAvailableCredit: parseFloat(clientAvailableCredit).toFixed(2) })}
      </div>
    ) : null
  );

  const renderFooter = () => {
    const { duration, intermediateChargeAmount } = selected;
    const content = isContinue ? (
      <ContinueCallFooter
        chatType={ chatType }
        option={ selected }
        selectedDuration={ duration }
        defaultDurationChanged={ defaultDurationChanged }
        selectorOpenedTime={ selectorOpenedTime }
      />
    ) : (
      <StartCallFooter
        option={ selected }
        chatType={ chatType }
        defaultDurationChanged={ defaultDurationChanged }
        selectorOpenedTime={ selectorOpenedTime }
        selectDurationKind={ selectDurationKind }
      />
    );

    return (
      <div className={ classes.infoBottomContainer }>
        { renderThisChargeTakesIntoAccountLabel({ duration, clientAvailableCredit, t }) }
        { renderInfoLabel({
          duration, intermediateChargeAmount, t, selectDurationKind
        }) }
        {content}
        { renderNewUserPaygAvailableBalanceLabel({ selectDurationKind, clientAvailableCredit, t }) }
        { renderZenModeLabel({ selectDurationKind, intermediateChargeAmount, t }) }
      </div>
    );
  };

  const onCloseIconClick = () => {
    const exitTime = new Date();
    const time = exitTime - selectorOpenedTime;
    const arg = chatType === Const.chatType.text ? selected : selected.duration;
    clearAffiliatePromoPopup();
    trackDurationPickExit(arg, time, defaultDurationChanged);
    cancelChat();
  };

  const renderCloseIcon = () => (isContinue ? null : (
    <button type="button" className={ classes.closeBtn } onClick={ onCloseIconClick }>
      <img src={ closeImg } alt="" className={ classes.closeIcon } />
    </button>
  ));

  useEffect(() => {
    const { MATCH_UP_BONUS_INFO } = env;
    const creditMatchingInfo =  userMatchUpBonusInfo || MATCH_UP_BONUS_INFO;
    if (creditMatchingInfo
        && Object.keys(creditMatchingInfo).length !== 0
        && (userShowMatchUpBonusInfo === null || userShowMatchUpBonusInfo)
        && Object.keys(selected).length !== 0) {
      const additionParams = {
        noNeedCloseIcon: true,
        chatType,
        needUpdatePricingOptions: true,
        onSelectDuration: true,
        onCloseIconClick
      };
      if (!isWebView()) {
        displayAffiliatePromoPopupAction(
          creditMatchingInfo,
          purchaseDetailsTypes.AFFILIATE_PROMO_POPUP,
          additionParams
        );
      }
    }
  }, [userMatchUpBonusInfo, userShowMatchUpBonusInfo, selected]);

  const onSelectDurationOption = (option, index) => {
    selectDurationOption(option, isContinue, isNewUser, index);
    onSelect({ ...option, selectedCarouselIndex: index });
  };

  const onDurationClick = (option, index) => (!isNewUser ? startChat()
    : onSelectDurationOption(option, index));

  const durationsSelectorProps = {
    selected,
    isContinue,
    pricingOptions,
    onDurationClick,
    selectedCarouselDefaultItem,
    disabledStartChatButton,
    carouselRef,
    isNewUser,
    setDefaultTransform,
    onKeyDown,
    loading,
    onSelect,
    selectedCarouselIndex,
    selectDurationKind,
    advisorXfmProgram,
    userXfmProgram,
    clientAvailableCredit,
    userAdvisorModeSalePrice
  };

  return (
    <div className={ classes.container }>
      { loading ? <Spiner loaderColor={ shared.appColor } className={ classes.loader } /> : null }
      {renderCloseIcon()}
      {renderSaveUpLabel({ newUserPaygPricingOption })}
      <div className={ selectDurationKind === Const.selectDurationKind.newUser ? classes.newUserContainerWithIcon : classes.containerWithIcon }>
        <div className={ classes.dialogContainer } style={ selectDurationKind === Const.selectDurationKind.newUserPayg ? { background: 'var(--app-white)' } : {}  }>
          <div className={ classes.selectTitleContainer } style={ { marginTop: isContinue && selectDurationKind === Const.selectDurationKind.newUser ? '80px' : '40px' } }>
            <b>{renderTitle(chatType, isContinue, name, t, selectDurationKind)}</b>
          </div>
          <DurationSelector { ...durationsSelectorProps } />
          {renderAdditionalOption(additionalOption, onClickCurrentBalance, onKeyDown, t, selectDurationKind) || renderClientCreditBalance()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
}

SelectChatCredit.propTypes = {
  cancelChat: PropTypes.func.isRequired,
  selectCredit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pricingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      default: PropTypes.bool.isRequired,
      additional: PropTypes.bool.isRequired
    })
  ),
  clientAvailableCredit: PropTypes.string,
  isContinue: PropTypes.bool,
  loadPricingOptions: PropTypes.func.isRequired,
  advisorId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  selectedCarouselIndex: PropTypes.number,
  disabledStartChatButton: PropTypes.bool.isRequired,
  chatType: PropTypes.string.isRequired,
  displayAffiliatePromoPopupAction: PropTypes.func.isRequired,
  userMatchUpBonusInfo: PropTypes.object,
  userShowMatchUpBonusInfo: PropTypes.bool,
  clearAffiliatePromoPopup: PropTypes.func.isRequired,
  requestParams: PropTypes.object,
  isNewUser: PropTypes.bool,
  name: PropTypes.string.isRequired,
  trackDurationPickExit: PropTypes.func.isRequired,
  selectorOpenedTime: PropTypes.instanceOf(Date).isRequired,
  selectDurationOption: PropTypes.func.isRequired,
  selectDurationKind: PropTypes.string,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  newUserPaygPricingOption: PropTypes.object,
  userAdvisorModeSalePrice: PropTypes.string
};

SelectChatCredit.defaultProps = {
  isContinue: false,
  clientAvailableCredit: null,
  pricingOptions: [],
  loading: false,
  selectedCarouselIndex: null,
  userMatchUpBonusInfo: null,
  userShowMatchUpBonusInfo: null,
  requestParams: null,
  isNewUser: false,
  selectDurationKind: null,
  advisorXfmProgram: {},
  userXfmProgram: {},
  newUserPaygPricingOption: {},
  userAdvisorModeSalePrice: null
};

export default withPGTranslation(SelectChatCredit);
