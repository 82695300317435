import { connect } from 'react-redux';
import { showGeneralPopup } from '../../actions/general_popup';
import { favoriteAdvisor } from '../../actions/favorite_advisors';
import AdvisorProfileAvatar from './advisor_profile_avatar';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateToProps = ({
  advisors
}, { advisorId }) => ({
  ...(advisors || {})[parseInt(advisorId, 10)] || {}
});

export default connect(
  mapStateToProps,
  {
    showGeneralPopup,
    favoriteAdvisor
  }
)(withPGTranslation(AdvisorProfileAvatar));
