import React from 'react';
import queryString from 'query-string';
import Const from '../config/const';
import { getSEODataAPI, welcomePromoCodesAPI } from '../config/api';
import { isPrerenderMode, pathsNameWithLocalization } from '../config/util';
import {
  navigationStackBarRoutesData,
  shouldShowNavigationStackBarRoutes,
  shouldSetNavigationStackBarRoutes,
  shouldClearNavigationStackBarRoutes,
  showSmartBannerRoutes,
  shouldGetSEODataRoutes
} from '../config/app_routes';
import { matchPaths, toCamelCase } from '../../common/config/utils';

export const navigationStackItemProp = {
  itemScope: 'itemscope',
  itemType: 'https://schema.org/BreadcrumbList',
  navigationStackItem: {
    itemProp:'itemListElement',
    itemScope: 'itemscope',
    itemType:'https://schema.org/ListItem',
    link: {
      appLink: { itemProp: 'item' },
      span: { itemProp: 'name' }
    },
    meta: {
      position: (itemPropPosition) => <meta itemProp="position" content={ itemPropPosition } />
    }
  }
};

export const routMatch = ({ pathName, paths }) => {
  const match = matchPaths({ pathname: [...pathsNameWithLocalization(paths)], path: pathName });
  return match || {};
};

const createPathData = ({
  pathName, getState, itemData, dataKey
}) => {
  const key = dataKey || pathName;
  const match = routMatch({ pathName, paths: shouldSetNavigationStackBarRoutes });
  const matchPath = match.pattern && match.pattern.path;
  let pathData = navigationStackBarRoutesData[dataKey || matchPath];
  const { application } = getState();
  if (itemData) {
    pathData = { ...pathData, ...itemData };
  }
  if (Object.keys(application.screenStack).length === 0) {
    return { ...pathData.directScreenStack, [key]: pathData };
  }

  return { [key]: pathData };
};

export const updateNavigationStackAction = ({
  showNavigationStackBar, pathData, navigationStackLoading
}) => ({
  type: Const.application.updateNavigationStack,
  pathData,
  showNavigationStackBar,
  navigationStackLoading
});

export const navigationStackLoadingAction = () => ({
  type: Const.application.navigationStackLoading
});

const clearNavigationStackAction = () => ({
  type: Const.application.clearNavigationStack
});

const clearNavigationStackIfNeed = ({ pathName, dispatch, getState }) => {
  const matchShouldClear = routMatch({ pathName, paths: shouldClearNavigationStackBarRoutes });
  if (Object.keys(matchShouldClear).length > 0) {
    const { application: { screenStack } } = getState();
    if (Object.keys(screenStack).length > 1) { // if not only Home
      dispatch(clearNavigationStackAction());
    }
  }
};

export const updateNavigationStack = ({
  dataKey, itemData, navigationStackLoading
}) => (dispatch, getState) => {
  const pathName = window.location.pathname;
  clearNavigationStackIfNeed({ pathName, dispatch, getState });
  const matchShouldSet = routMatch({ pathName, paths: shouldSetNavigationStackBarRoutes });
  if (!Object.keys(matchShouldSet).length > 0) {
    const { application: { showNavigationStackBar } } = getState();
    if (showNavigationStackBar) {
      dispatch(updateNavigationStackAction({ showNavigationStackBar: false,  pathData: {} }));
    }
    return;
  }
  const pathData = createPathData({
    pathName, getState, itemData, dataKey
  });
  const matchShouldShow = routMatch({
    pathName, paths: shouldShowNavigationStackBarRoutes
  });

  const showNavigationStackBar = Object.keys(matchShouldShow).length > 0;
  dispatch(updateNavigationStackAction({
    showNavigationStackBar, pathData, navigationStackLoading
  }));
};

const updateSmartBannerVisibilityAction = ({ visible, needPresent }) => ({
  type: Const.application.updateSmartBannerVisibility,
  visible,
  needPresent
});

export const updateSmartBannerVisibility = ({ url }) => (dispatch, getState) => {
  const { application: { needPresent } } = getState();
  if (!needPresent) return;
  const match = routMatch({ pathName: (url.url || {}), paths: showSmartBannerRoutes });
  let aditionParams = { needPresent };
  if (needPresent && !Object.keys(match).length > 0) {
    aditionParams = { needPresent: false };
  }
  dispatch(updateSmartBannerVisibilityAction({ visible: Object.keys(match).length > 0, ...aditionParams }));
};

const setNewToPG = (value) => ({
  type: Const.application.setNewToPG,
  value
});

export const setNewToPGAction = (value) => (dispatch) => {
  dispatch(setNewToPG(value));
};

export const updateSEOData = (route, { seoData }) => ({
  type: Const.application.udpateSEOData,
  route,
  seoData
});

export const getSEOData = (route) => (dispatch, getState) => {
  if (isPrerenderMode()) return;
  const { application: { seoData } } = getState();
  const match = routMatch({ pathName: route, paths: shouldGetSEODataRoutes });
  if (!Object.keys(match).length > 0 || (seoData[route] !== null && typeof seoData[route] !== 'undefined')) {
    dispatch(updateSEOData(route, { seoData: seoData[route] }));
    return;
  }
  getSEODataAPI(route).then((res) => {
    dispatch(updateSEOData(route, res));
  });
};

export const appLoading = () => ({
  type: Const.application.appLoading
});

const setAnonymousUserAction = (anonymousUser) => ({
  type: Const.application.setAnonymousUser,
  anonymousUser
});

export const getAnonymousUser = () => (dispatch) => {
  const { promo } = toCamelCase(queryString.parse(window.location.search));
  let deeplink = null;
  if (promo) deeplink = `promo/${ promo }`;
  welcomePromoCodesAPI(deeplink).then(res => {
    const { anonymousUser } = res;
    dispatch(setAnonymousUserAction(anonymousUser));
  });
};
