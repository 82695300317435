import { connect } from 'react-redux';
import StartCallFooter from './start_call';
import { selectCredit } from '../../../../actions/chat';
import { selectVoipCredit } from '../../../../actions/voip_chat';
import Const from '../../../../config/const';
import withPGTranslation from '../../../../config/withPGTranslation';

const mapStateToProps = ({ chat, voipChat, user: { user } }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({
        clientAvailableCredit: user && user.clientAvailableCredit,
        disabledStartChatButton:
          chat.state !== Const.chatStates.appInitial
          && chat.state !== Const.chatStates.paused
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        clientAvailableCredit: user && user.clientAvailableCredit,
        disabledStartChatButton:
          voipChat.voipState !== Const.voipChatStates.initial
          && voipChat.voipState !== Const.voipChatStates.paused
      });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        selectCredit: (option, time, defaultDurationChanged) => dispatch(
          selectCredit(option, time, defaultDurationChanged, false)
        )
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        selectCredit: (option, time, defaultDurationChanged) => dispatch(
          selectVoipCredit(option, time, defaultDurationChanged, false)
        )
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPGTranslation(StartCallFooter));
