import { connect } from 'react-redux';
import TimerComponent from './timer_component';
import withPGTranslation from '../../../config/withPGTranslation';
import { loadAdvisor } from '../../../actions/advisors';
import { setNeedTrackSessionHireEventAvtion } from '../../../actions/chat';

const mapeStateToProps = ({
  chat: {
    paidDuration, manualAnalyticEvents,
    freeDuration, state, advisorId, durationCounterDisplay, needTrackSessionHireEvent, name, orderId, buyerDurationCounterDirection
  },
  user: { user },
  advisors
}) => {
  const advisor = (advisors || {})[parseInt(advisorId, 10)];
  return ({
    manualAnalyticEvents,
    paidDuration,
    freeDuration,
    state,
    advisorXfmProgram: advisor && advisor.xfmProgram,
    userXfmProgram: user && user.xfmProgram,
    advisorId,
    buyerDurationCounterDirection,
    durationCounterDisplay,
    advisorName: name,
    needTrackSessionHireEvent,
    orderId
  });
};

export default connect(
  mapeStateToProps,
  {
    loadAdvisor,
    setNeedTrackSessionHireEventAvtion
  }
)(withPGTranslation(TimerComponent));
