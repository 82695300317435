import React from 'react';
import PropTypes from 'prop-types';
import { browserName } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { downloadAppLink } from '../../../../common/config/utils';
import Const, { appGeneralPopupTheme } from '../../../config/const';
import AppGeneralPopup from '../../../../common/app_general_popup';

const extractErrors = (error) => {
  if (error.errors) {
    return error.errors.map((e, i) => <div key={ i }>{e}</div>);
  }
  if (error.voipErrors) {
    return error.voipErrors.map((e, i) => <div key={ i }>{e}</div>);
  }
  return null;
};

const getLearnMoreLink = () => {
  switch (browserName) {
    case 'Chrome':
      return 'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en';
    case 'Firefox':
      return 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
    case 'Safar':
      return 'https://support.apple.com/en-il/guide/safari/ibrwe2159f50/mac';
    case 'Opera':
      return 'https://help.opera.com/en/opera36/set-web-preferences/';
    default:
      return 'https://www.online-tech-tips.com/computer-tips/how-to-block-camera-and-mic-access-in-every-major-browser/';
  }
};

const renderTitle = (permissionDeniedError) => {
  if (permissionDeniedError) {
    return (
      <div className={ classes.dialogTitle }>
        Microphone and/or camera blocked
      </div>
    );
  }
  return null;
};

const renderText = (permissionDeniedError, error, onCancelClicked) => {
  if (permissionDeniedError) {
    return (
      <>
        To order a live voice/video call,
        you&apos;ll need to allow access to your microphone and/or camera.&nbsp;
        <a href={ getLearnMoreLink() } target="_blank" rel="noopener noreferrer" className={ classes.link } onClick={ onCancelClicked }>
          Learn more
        </a>
        .
      </>
    );
  }
  return (extractErrors(error));
};

const appLinkAFChanel = (chatType) => {
  switch (chatType) {
    case Const.chatType.video:
      return 'video_button';
    case Const.chatType.voice:
      return 'voice_button';
    case Const.chatType.text:
      return 'chat_button';
    default:
      return null;
  }
};

function Error({
  error, onCancelClicked, chatType, permissionDeniedError
}) {
  const { t } = useTranslation();
  return (
    <AppGeneralPopup theme={ appGeneralPopupTheme.CENTERED }>
      { renderTitle(permissionDeniedError) }
      <div className={ classes.dialogText }>
        {' '}
        { renderText(permissionDeniedError, error, onCancelClicked) }
        <a href={ downloadAppLink(appLinkAFChanel(chatType)) } target="_blank" rel="noopener noreferrer" className={ classes.link } onClick={ onCancelClicked }>
          <Trans
            i18nKey="app_general.download_link"
            components={ {
              downloadLink: <a href={ downloadAppLink(appLinkAFChanel(chatType)) } target="_blank" rel="noopener noreferrer" className={ classes.link } onClick={ onCancelClicked } />
            } }
          />
        </a>
      </div>
      <div
        className={ classes.cancelButton }
        onClick={ onCancelClicked }
        onKeyDown={ onCancelClicked }
      >
        <div className={ classes.okBtn }>{t('general.ok')}</div>
      </div>
    </AppGeneralPopup>
  );
}

Error.propTypes = {
  error: PropTypes.object.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  chatType: PropTypes.string,
  permissionDeniedError: PropTypes.bool
};

Error.defaultProps = {
  permissionDeniedError: null,
  chatType: null
};

export default Error;
