import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import { clearAplicationError } from '../../actions/aplication_error';
import GeneralError from './general_error';

const mapStateToProps = ({ aplicationError }) => ({
  ...aplicationError
});

export default  connect(mapStateToProps, {
  clearAplicationError
})(withPGTranslation(GeneralError));
