import { connect } from 'react-redux';
import FiltersContainer from './filters_container';
import { showPopover, dissmissPopover } from '../../../../actions/popover';
import { loadAdvisors } from '../../../../actions/search_results';

const mapStateProps = ({ searchResults, application, user }) => ({
  ...searchResults,
  newToPG: application.newToPG,
  lastPurchaseDate: ((user || {}).user || {}).lastPurchaseDate
});

export default connect(
  mapStateProps,
  {
    showPopover,
    dissmissPopover,
    loadAdvisors
  }
)(FiltersContainer);
