import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  page: 0,
  myOrders: {},
  unreadMessagesCount: 0
});

const myOrdersReducer = createReducer(getDefaultState(), {
  [Const.myOrders.loading]: (state, { page }) => ({
    ...state,
    loading: true,
    page
  }),
  [Const.myOrders.loaded]: (state, { orders, initialLoad }) => ({
    ...state,
    myOrders: {
      ...state.myOrders,
      ...orders,
      paginationParams: orders.paginationParams,
      complete: initialLoad
        ? orders.complete : [...state.myOrders.complete, ...orders.complete]
    },
    loading: false
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.orderDetails.messagesRead]: (state, { unreadMessagesCount }) => ({
    ...state,
    unreadMessagesCount
  })
});

export default myOrdersReducer;
