import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  state: null,
  lastTimeToken: null
});

const psychicsReducer = createReducer(getDefaultState(), {
  [Const.pubnub.actions.shutdown]: () => getDefaultState(),
  [Const.pubnub.actions.connect]: (state, { ownUuid }) => ({
    ...state,
    state: Const.pubnub.states.connecting,
    ownUuid
  }),
  [Const.pubnub.actions.connected]: (state) => ({
    ...state,
    state: Const.pubnub.states.connected
  }),
  [Const.pubnub.actions.down]: (state) => ({
    ...state,
    state: Const.pubnub.states.down
  }),
  [Const.pubnub.actions.up]: (state) => ({
    ...state,
    state: Const.pubnub.states.up
  }),
  [Const.pubnub.actions.timetoken]: (state, { timetoken }) => ({
    ...state,
    lastTimeToken: timetoken
  })

});

export default psychicsReducer;
