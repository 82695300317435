import { connect } from 'react-redux';
import Const from '../../../config/const';
import NotInLiveMode from './not_in_live_mode';
import { cancelChat } from '../../../actions/chat';
import { cancelVoipChat } from '../../../actions/voip_chat';
import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = ({ chat, voipChat }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({ ...chat });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({ ...voipChat });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(cancelChat())
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(cancelVoipChat())
      });
    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withPGTranslation(NotInLiveMode));
