import createReducer from '../config/create_reducer';
import Const from '../config/const';
import { getXfmFreeSetupMin } from '../config/util';

const getDefaultState = () => ({
  advisorId: null,
  state: null,
  messages: [],
  pricingOptions: [],
  profilePictureUrl: null,
  name: null,
  error: null,
  loading: false,
  selectedCarouselIndex: null,
  continuedSession: null,
  startChatClickSource: null,
  advisorConnectionLost: null,
  analytics: null,
  requestParams: null,
  systemMessages: [],
  initialDuration: null,
  initialCharged: null,
  needTrackSessionHireEvent: true
});

const shouldCleanCarouselIndex = (nextState, paymentRequired, onContinue) => nextState === Const.chatStates.appStarting
  || (!paymentRequired && onContinue);

const getNextStateForDurationSelected = ({
  introduce, paymentRequired, onContinue, isNewUser, isPayg
}) => {
  let nextState = Const.chatStates.appStarting;
  if (introduce) {
    nextState = Const.chatStates.introduce;
  }
  if (paymentRequired) {
    nextState = onContinue
      ? Const.chatStates.paymentRequiredOnContinue : Const.chatStates.paymentRequired;
  }
  if (isNewUser) {
    nextState = onContinue
      ? Const.chatStates.paused : Const.chatStates.appInitial;
  }
  if (isPayg) {
    nextState = Const.chatStates.paymentRequired;
  }
  return nextState;
};

const updatePricingOptionsIfXfmProgram = ({ pricingOptions, xfmProgram }) => {
  if (pricingOptions && xfmProgram) {
    const updatedPricingOptions = pricingOptions.map((o) => ({ ...o, freeMin: getXfmFreeSetupMin() }));
    return updatedPricingOptions;
  }
  return pricingOptions;
};

const chatReducer = createReducer(getDefaultState(), {
  [Const.chat.cancel]: () => getDefaultState(),
  [Const.chat.cancelOnPP]: (state, { isContinue }) => ({
    ...state,
    state: isContinue ? Const.chatStates.paused : Const.chatStates.appInitial
  }),
  [Const.chat.hangingUp]: state => ({
    ...state,
    hangingUp: true,
    continuedSession: null
  }),
  [Const.chat.pricingLoading] : (state) => ({
    ...state,
    loading: true
  }),
  [Const.chat.init]: (state, {
    advisorId,
    name,
    profilePictureUrl,
    pricePerMinute,
    startChatClickSource
  }) => ({
    ...state,
    state: Const.chatStates.appInitial,
    advisorId,
    name,
    profilePictureUrl,
    pricePerMinute,
    continuedSession: null,
    startChatClickSource
  }),
  [Const.chat.start]: (
    state,
    {
      advisorId,
      pricePerMinute,
      pricingOptions,
      profilePictureUrl,
      name,
      startChatClickSource,
      homepageSection,
      requestParams,
      selectDurationKind,
      xfmProgram,
      newUserPaygPricingOption
    }
  ) => ({
    ...state,
    advisorId,
    pricePerMinute: pricePerMinute || state.pricePerMinute,
    pricingOptions: updatePricingOptionsIfXfmProgram({ pricingOptions, xfmProgram }),
    profilePictureUrl: profilePictureUrl || state.profilePictureUrl,
    name: name || state.name,
    state: Const.chatStates.appInitial,
    loading: false,
    startChatClickSource: startChatClickSource || state.startChatClickSource,
    homepageSection: homepageSection || state.homepageSection,
    requestParams,
    selectDurationKind,
    newUserPaygPricingOption
  }),
  [Const.chat.durationSelected]: (state, {
    duration, price, introduce, paymentRequired, selectedCarouselIndex,
    onContinue, isNewUser, isPayg, flow
  }) => {
    const nextState = getNextStateForDurationSelected({
      introduce, paymentRequired, onContinue, isNewUser, isPayg
    });
    return ({
      ...state,
      state: nextState,
      duration,
      price,
      initialDuration: onContinue ? state.initialDuration : duration,
      initialCharged: onContinue ? state.initialCharged : price,
      selectedCarouselIndex: shouldCleanCarouselIndex(nextState, paymentRequired, onContinue)
        ? null : selectedCarouselIndex,
      isPayg,
      flow
    });
  },
  [Const.chat.paymentComplete]: (state, { introduce }) => ({
    ...state,
    state: introduce ? Const.chatStates.introduce : Const.chatStates.appStarting,
    selectedCarouselIndex: null
  }),
  [Const.chat.purchaseBack]: (state, { payload: { continueFlow } }) => ({
    ...state,
    state: continueFlow ? Const.chatStates.paused : Const.chatStates.appInitial
  }),
  [Const.introduce.user]: state => ({
    ...state,
    state: state.state ? Const.chatStates.appStarting : null,
    selectedCarouselIndex: null
  }),
  [Const.chat.connect]: state => ({
    ...state,
    state: Const.chatStates.appConnecting,
    selectedCarouselIndex: null
  }),
  [Const.chat.notInLiveMode]: state => ({
    ...state,
    state: Const.chatStates.appNotInLiveMode
  }),
  [Const.chat.cantCreate]: (state, { errors }) => ({
    ...state,
    errors,
    state: Const.chatStates.appCantCreate
  }),
  [Const.chat.generalError]: (state, { errors }) => ({
    ...state,
    errors,
    state: Const.chatStates.generalError
  }),
  [Const.chat.noAnswer]: state => ({
    ...state,
    state: Const.chatStates.noAnswer
  }),
  [Const.chat.connectProvider]: (state, { config }) => ({
    ...state,
    state: Const.chatStates.appConnectProvider,
    ...config
  }),
  [Const.chat.adminUpdate]: (innerState, { state, details, reason }) => {
    let  updatedContinueSession  = innerState.continuedSession;
    let newPricingOptions = state.pricingOptions;
    if (state === Const.chatStates.paused) {
      updatedContinueSession = innerState.continuedSession ? innerState.continuedSession + 1 : 1;
    }
    if (state === Const.chatStates.started) {
      newPricingOptions = [];
    }
    return ({
      ...innerState,
      state,
      ...details,
      hangingUp: false,
      continuedSession: updatedContinueSession,
      pricingOptions: newPricingOptions,
      reason
    });
  },
  [Const.chat.systemMessage]: (state, { systemMessage }) => ({
    ...state,
    systemMessages: [...state.systemMessages, systemMessage]
  }),
  [Const.chat.message]: (state, { message }) => ({
    ...state,
    messages: [...state.messages, message]
  }),
  [Const.chat.messageUpdate]: (state, { message }) => {
    const messagesArray = [...state.messages];
    Object.assign(
      messagesArray[message.arrayIndex],
      { offline: false, timetoken: message.timetoken }
    );
    return ({
      ...state,
      messages: messagesArray
    });
  },
  [Const.chat.startPeerTyping]: state => ({
    ...state,
    peerTyping: true
  }),
  [Const.chat.stopPeerTyping]: state => ({
    ...state,
    peerTyping: false
  }),
  [Const.chat.continueChat]: (state, { payload: { duration, price } }) => ({
    ...state,
    state: Const.chatStates.continuingChat,
    duration,
    price,
    selectedCarouselIndex: null
  }),
  [Const.chat.updatePricingOptions]: (state, {
    payload: { pricingOptions, selectDurationKind, newUserPaygPricingOption }
  }) => ({
    ...state,
    pricingOptions,
    selectDurationKind,
    loading: false,
    newUserPaygPricingOption
  }),
  [Const.chat.advisorConnectionLost]: state => ({
    ...state,
    advisorConnectionLost: true
  }),
  [Const.chat.setNeedTrackSessionHireEvent]: (state, { value }) => ({
    ...state,
    needTrackSessionHireEvent: value
  }),
  [Const.chat.clearAdvisorConnectionLost]: (state) => ({
    ...state,
    advisorConnectionLost: null
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.aplicationError.clearError]: () => ({
    ...getDefaultState()
  })
});

export default chatReducer;
