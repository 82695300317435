/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import { isSafari } from 'react-device-detect';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classes from './classes.module.scss';
import { log } from '../../config/app_logger';
import {
  isMobile, getSectionForEvent, getClickSourceForEvent
} from '../../config/util';
import AppGeneralPopup from '../../../common/app_general_popup';
import VideoPlayer from '../video_player';
import { trackPageVisit } from '../../actions/analytics';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { isKA } from '../../../common/config/utils';

export const SCREEN_THEME = {
  HOME: 'HOME',
  PROFILE: 'PROFILE'
};

const profilePlay = require('./img/profile_play.svg');
const xIcon = require('./img/x_icon.png');

const isInFullScreenForBrowserCheck = () => (
  (document.webkitFullscreenElement && document.webkitFullscreenElement !== null)
  || (document.mozFullScreenElement && document.mozFullScreenElement !== null)
  || (document.msFullscreenElement && document.msFullscreenElement !== null)
);

const isInFullScreen = () => (
  (document.fullscreenElement && document.fullscreenElement !== null)
  || isInFullScreenForBrowserCheck()
);

const requestFullScreenHandler = ({ video }) => {
  if (video.requestFullScreen) {
    // W3C API
    video.requestFullScreen();
  } else if (video.mozRequestFullScreen) {
    // Mozilla current API
    video.mozRequestFullScreen();
  } else if (video.webkitRequestFullScreen) {
    // Webkit current API
    video.webkitRequestFullScreen();
  } else if (video.webkitEnterFullscreen) {
    // This is the iOS Mobile edge case
    video.webkitEnterFullscreen();
  }
};

const onPlayingHandler = ({ videoRef }) => {
  const video = videoRef.current;
  if (!isMobile()) return;
  if (!isInFullScreen() && !video.paused) {
    requestFullScreenHandler({ video });
  }
};

const getReactPlayerContainerStyle = () => {
  if (isMobile()) return { '--reactPlayerContainerWidth': '100%', '--reactPlayerContainerHeight': '100%' };
  return { '--reactPlayerContainerWidth': '50%', '--reactPlayerContainerHeight': '50%' };
};

const getCloseButtonTopStyle = () => {
  if (isMobile()) return { '--closeButtonTop': '10px', '--closeButtonRight': '10px' };
  return { '--closeButtonTop': '-18px', '--closeButtonRight': '-25px' };
};

const getPlayBtn = ({ playImg }) => {
  if (playImg) return playImg;
  return <img src={ profilePlay } alt="" className={ classes.playButtonimg } />;
};

const setMutedForSafari = ({ playerVisible, setMuted }) => {
  if (playerVisible && isSafari && !isMobile()) {
    setMuted(true);
  }
};

const trackPlayVideoEvent = ({
  videoRef, needTrackProfileVideoEvent, advisorId, location, analytics,
  trackPlayingAdvisorProfileVideoEvent
}) => {
  const videoPlayingDuration = videoRef.current.currentTime;
  if (needTrackProfileVideoEvent && videoPlayingDuration >= 4) {
    const params = {
      id: advisorId,
      ...getClickSourceForEvent({ location }),
      ...getSectionForEvent({ location }),
      duration: parseFloat(videoPlayingDuration).toFixed(),
      ...analytics
    };
    trackPlayingAdvisorProfileVideoEvent(params);
  }
};

const onFullScreenChangeHandler = ({ e, onClose }) => {
  if (isMobile() && !isInFullScreen()) onClose(e);
};

function AdvisorVideoPlayer({
  profileVideoUrl, trackPlayingAdvisorProfileVideoEvent, advisorId, needTrackProfileVideoEvent,
  playImg, analytics
}) {
  const [playerVisible, setPlayerVisible] = useState(false);
  const [muted, setMuted] = useState(false);
  const videoRef = useRef();
  const closeBtnRef = useRef();
  const playerContentRef = useRef();
  const location = useLocation();

  const onPlayerClick = (e) => {
    e.stopPropagation();
  };

  const onClose = (e) => {
    log('Player', 'on close');
    e.stopPropagation();
    setMuted(false);
    trackPlayVideoEvent({
      videoRef,
      needTrackProfileVideoEvent,
      advisorId,
      location,
      analytics,
      trackPlayingAdvisorProfileVideoEvent
    });
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    setPlayerVisible(false);
  };

  const onFullScreenChange = (e) => {
    onFullScreenChangeHandler({ e, onClose });
  };

  useEffect(() => {
    setMutedForSafari({ playerVisible, setMuted });
    const video = videoRef.current;
    if (playerVisible && video) {
      video.play();
      if (isMobile()) {
        // Webkit
        video.onfullscreenchange = onFullScreenChange;
        video.onwebkitfullscreenchange = onFullScreenChange;
        // iOS Mobile
        video.addEventListener('webkitendfullscreen', onFullScreenChange, false);
        return () => video.removeEventListener('webkitendfullscreen', onFullScreenChange, false);
      }
      const playerContent = playerContentRef.current;
      const closeBtn = closeBtnRef.current;
      if (playerContent) {
        playerContent.addEventListener('click', onClose, false);
        closeBtn.addEventListener('click', onClose, false);
        video.addEventListener('click', onPlayerClick, false);
        return () => {
          playerContent.removeEventListener('click', onClose, false);
          closeBtn.removeEventListener('click', onClose, false);
          video.removeEventListener('click', onPlayerClick, false);
        };
      }
    }
    return () => {};
  }, [playerVisible]);

  const showPlayerVisible = () => {
    log('Player', 'on set Player Visible');
    trackPageVisit('media player');
    if (!profileVideoUrl) return;
    setPlayerVisible(true);
  };

  const onPlaying = () => (
    onPlayingHandler({ videoRef })
  );

  const player = useMemo(() => (
    <div className={ classes.playerContent } ref={ playerContentRef }>
      <div
        className={ classes.reactPlayerContainer }
        style={ getReactPlayerContainerStyle() }
      >
        <button
          ref={ closeBtnRef }
          className={ classes.closeButton }
          type="button"
          style={ getCloseButtonTopStyle() }
        >
          <img src={ xIcon } alt="" className={ classes.closeImg } suppressHydrationWarning />
        </button>
        <VideoPlayer
          videoUrl={ profileVideoUrl }
          videoRef={ videoRef }
          onPlayerClick={ onPlayerClick }
          onPlaying={ onPlaying }
          muted={ muted }
        />
      </div>
    </div>
  ), [playerVisible, profileVideoUrl]);

  const renderPlayBtn = () => {
    if (isKA()) return null;
    const playBtn = getPlayBtn({ playImg });
    return (
      <div
        className={ classes.playBtnContainer }
        onClick={ showPlayerVisible }
      >
        { playBtn }
      </div>
    );
  };

  const renderAdvisorVideoPlayer = () => {
    if (!profileVideoUrl) return null;
    return (
      <>
        { renderPlayBtn() }
        <AppGeneralPopup
          visible={ playerVisible }
          needOpenOnAppRoot
          theme={ appGeneralPopupTheme.VIDEO_POPOVER }
        >
          { player }
        </AppGeneralPopup>
      </>
    );
  };

  return renderAdvisorVideoPlayer();
}

AdvisorVideoPlayer.propTypes = {
  profileVideoUrl: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  ratingCount: PropTypes.string,
  children: PropTypes.node,
  trackPlayingAdvisorProfileVideoEvent: PropTypes.func.isRequired,
  advisorId: PropTypes.number,
  needTrackProfileVideoEvent: PropTypes.bool,
  theme: PropTypes.oneOf([
    SCREEN_THEME.HOME,
    SCREEN_THEME.PROFILE]),
  t: PropTypes.func.isRequired,
  playImg: PropTypes.node,
  analytics: PropTypes.object
};

AdvisorVideoPlayer.defaultProps = {
  profileVideoUrl: null,
  profilePictureUrl: null,
  ratingCount: null,
  children: null,
  needTrackProfileVideoEvent: false,
  theme: SCREEN_THEME.PROFILE,
  playImg: null,
  advisorId: null,
  analytics: null
};

export default AdvisorVideoPlayer;
