import React from 'react';
import classes from './classes.module.scss';

function SquareAnimation() {
  return (
    <div className={ classes.container }>
      <div className={ classes.squareGif } />
    </div>
  );
}

export default SquareAnimation;
