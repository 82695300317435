import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DurationItem } from '@barges/react';
import '@barges/scss/lib/duration-item.css';
import '@barges/scss/lib/title.css';
import classes from './classes.module.scss';
import withPGTranslation from '../../../../config/withPGTranslation';
import { roundCredits, isXfmProgram, isPayg } from '../../../../config/util';

const heartInCircleIcon = require('./img/heart_in_circle.svg');

const renderDurationItemPriceTextContainer = (price, ppm, duration, additional) => {
  if (additional) return null;
  let priceText = `$${ roundCredits(price) }`;
  if (isPayg({ duration })) priceText = `$${ roundCredits(ppm) }/min`;
  return priceText;
};

const renderAdditionalOptionText = (additional, duration, selected, t) => (additional && duration === selected.duration) && t('chat.tap_hero_to_use_your_balance');

const renderMostPopularHeartInCircle = (mostPopular) => {
  if (!mostPopular) return null;
  return (
    <div className={ classes.mostPopularHeartInCircleContainer }>
      <img src={ heartInCircleIcon } alt="" className={ classes.mostPopularHeartInCircleIcon } />
    </div>
  );
};

const renderFreeMinText = (freeMin, advisorXfmProgram, userXfmProgram, t) => {
  if (!freeMin || !isXfmProgram({ advisorXfmProgram, userXfmProgram  })) return null;
  return (
    <div className={ classes.freeMinTextContainer }>
      { t('free_minutes.not_including_free_minutes') }
    </div>
  );
};

const renderDurationItemMinutesCount = (duration) => {
  let minutesCount = parseInt(duration / 60, 10);
  if (isPayg({ duration })) minutesCount = '∞'; // &#8734;
  return minutesCount;
};

const renderDurationItemSecondsCount = (duration, t) => {
  if (isPayg({ duration })) return null;
  const minutes = parseInt(duration / 60, 10);
  const letfSeconds = (duration - minutes * 60);
  return letfSeconds && `${ letfSeconds } ${ t('chat.seconds') }`;
};

function DurationItemComponent({
  duration, price, mostPopular, t, onClickAction, disabledStartChatButton, freeMin, isNewUser, advisorXfmProgram, userXfmProgram, ppm, selected, additional
}) {
  const onClick = () => {
    if (disabledStartChatButton) return;
    onClickAction();
  };

  const onKeyDown = () => {};

  const renderDurationItemMinutesText = () => {
    let text = t('chat.minutes');
    if (isPayg({ duration })) {
      text = (
        <div className={ classes.durationItemZenModePayContainer }>
          <div className={ classes.durationItemZenModeText }>
            Zen mode
          </div>
          <div className={ classes.durationItemPayAsYouGoText }>
            Pay as you go
          </div>
        </div>
      );
    }
    return (
      <div className={ classes.durationItemMinutesTextContainer }>
        { text }
      </div>
    );
  };

  const renderDurationItem = () => (
    <div
      className={ classNames(classes.durationItem) }
      onClick={ onClick }
      onKeyDown={ onKeyDown }
    >
      <DurationItem
        selected={ duration === selected.duration }
        duration={ renderDurationItemMinutesCount(duration) }
        durationText={ renderDurationItemMinutesText() }
        durationSubText={ renderFreeMinText(freeMin, advisorXfmProgram, userXfmProgram, t) }
        priceText={ renderDurationItemPriceTextContainer(price, ppm, duration, additional) }
        mostPopular={ renderMostPopularHeartInCircle(mostPopular) }
        isNewUser={ isNewUser }
        useYourBalanceText={ renderAdditionalOptionText(additional, duration, selected, t) }
        secondsText= { renderDurationItemSecondsCount(duration, t) }
      />
    </div>
  );

  return renderDurationItem();
}

DurationItemComponent.propTypes = {
  duration: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  disabledStartChatButton: PropTypes.bool.isRequired,
  mostPopular: PropTypes.bool,
  freeMin: PropTypes.number,
  isNewUser: PropTypes.bool.isRequired,
  advisorXfmProgram: PropTypes.object,
  userXfmProgram: PropTypes.object,
  ppm: PropTypes.string,
  selected: PropTypes.object,
  additional: PropTypes.bool
};

DurationItemComponent.defaultProps = {
  mostPopular: null,
  freeMin: null,
  advisorXfmProgram: {},
  userXfmProgram: {},
  ppm: null,
  selected: {},
  additional: false
};

export default withPGTranslation(DurationItemComponent);
