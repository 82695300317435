import { connect } from 'react-redux';
import CreditCardValidateProcessing from './credit_card_validate_processing';
import {
  validateCreditCardAction,
  getValidateCreditCardStatus,
  validateCreditCardClearAction,
  creditCardValidateProcessingValidating
} from '../../../actions/credit_card_validate_processing';

const mapStateToProps = ({ creditCardValidateProcessing }, ownProp) => ({
  ...creditCardValidateProcessing,
  loading: ownProp.loading || creditCardValidateProcessing.validating
});

export default connect(
  mapStateToProps,
  {
    validateCreditCardAction,
    getValidateCreditCardStatus,
    validateCreditCardClearAction,
    creditCardValidateProcessingValidating
  }
)(CreditCardValidateProcessing);
