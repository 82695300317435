import React from 'react';
import PropTypes from 'prop-types';
import classesModule from './classes.module.scss';
import Const from '../../config/const';
import Stopwatch from '../stopwatch';

const kFreeTimeIc = require('./img/ring_animation_icon.png');

const formattedTime = (time) => (`0${ time }`).slice(-2);

const textLabel = (t, voipState) => (
  <div className={ classesModule.setupLabel }>
    { voipState === Const.voipChatStates.finishing
      ? t('chat.end_call_time_label') : t('chat.free_setup_time') }
  </div>
);

const timer = (callDurationNow) => {
  const hours = Math.floor(callDurationNow / 3600);
  const minutes = Math.floor((callDurationNow % 3600) / 60);
  const seconds = Math.floor(callDurationNow % 3600 % 60);
  const hoursDisplay = hours > 0 ? formattedTime(hours) : '00';
  const minutesDisplay = minutes > 0 ? formattedTime(minutes) : '00';
  const secondsDispaly = seconds > 0 ? formattedTime(seconds) : '00';
  const fulltime = `${ hoursDisplay }:${ minutesDisplay }:${ secondsDispaly }`;
  return (
    <div className={ classesModule.setupLabel }>
      { fulltime }
    </div>
  );
};

function TimerLabel({
  callDurationNow, paidDuration, blinkSetup, t, voipState, buyerDurationCounterDirection
}) {
  const propsExists = callDurationNow && paidDuration;
  const showTimer = propsExists && voipState !== Const.voipChatStates.finishing && (callDurationNow <= paidDuration);

  const renderTimer = () => (
    <>
      <img
        src={ kFreeTimeIc }
        alt=""
        className={ blinkSetup ? classesModule.freeSetupFadeOut : classesModule.freeSetupFadeIn }
      />
      {!showTimer ? textLabel(t, voipState) : timer(callDurationNow)}
    </>
  );

  const renderStopwatchTimer = () => (
    <Stopwatch
      secondsCount={ callDurationNow }
      callType="voip_chat"
      paidDuration={ paidDuration }
    />
  );

  const renderTimerContent = () => {
    if (buyerDurationCounterDirection === 'stopwatch') {
      return renderStopwatchTimer();
    }
    return renderTimer();
  };

  return (
    <div className={ classesModule.timer }>
      <div className={ classesModule.timerContainer }>
        {renderTimerContent()}
      </div>
    </div>
  );
}

TimerLabel.propTypes = {
  callDurationNow: PropTypes.number,
  paidDuration: PropTypes.number,
  blinkSetup: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  voipState: PropTypes.string.isRequired,
  buyerDurationCounterDirection: PropTypes.string.isRequired
};

TimerLabel.defaultProps = {
  callDurationNow: null,
  paidDuration: null
};

export default TimerLabel;
