import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@barges/react';
import '@barges/scss/lib/button.css';
import '@barges/scss/lib/title.css';
import classes from './classes.module.scss';
import withPGTranslation from '../../../../config/withPGTranslation';
import { log } from '../../../../config/app_logger';
import Const from '../../../../config/const';
import { isPayg } from '../../../../config/util';

const renderStartCallFooterBtn = (btnClass, title, disabledStartChatButton, startChat) => (
  <div className={ btnClass }>
    <Button
      title={ title }
      disabled={ disabledStartChatButton }
      onClick={ startChat }
    />
  </div>
);

const renderStartCallFooterContent = ({
  clientAvailableCredit, option, startChat, disabledStartChatButton, t, selectDurationKind
}) => {
  if (selectDurationKind === Const.selectDurationKind.newUserPayg) {
    return renderStartCallFooterBtn(classes.startBtnContainer, t('chat.start_your_session'), disabledStartChatButton, startChat);
  }
  if ((clientAvailableCredit >= option.price && selectDurationKind === Const.selectDurationKind.oldUser)) {
    const title = isPayg({ duration: option.duration }) ? t('chat.continue') : t('chat.start');
    return renderStartCallFooterBtn(classes.startBtnContainer, title, disabledStartChatButton, startChat);
  }
  return renderStartCallFooterBtn(classes.nextBtnContainer, t('chat.next'), disabledStartChatButton, startChat);
};

function StartCallFooter({
  selectCredit, option, t, clientAvailableCredit, disabledStartChatButton,
  selectorOpenedTime, defaultDurationChanged, chatType, selectDurationKind
}) {
  const startChat = () => {
    log('Chat', `on start chat isContinue: ${ false }`);
    const exitTime = new Date();
    const time = exitTime - selectorOpenedTime;
    selectCredit(option, time, defaultDurationChanged);
  };

  const renderStartCallFooter = () => {
    if ((!clientAvailableCredit || !option.price) && !isPayg({ duration: option.duration })) return null;
    return renderStartCallFooterContent({
      clientAvailableCredit, option, startChat, disabledStartChatButton, chatType, t, selectDurationKind
    });
  };

  return (
    <>
      { renderStartCallFooter() }
    </>
  );
}

StartCallFooter.propTypes = {
  option: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectCredit: PropTypes.func.isRequired,
  clientAvailableCredit: PropTypes.string.isRequired,
  disabledStartChatButton: PropTypes.bool.isRequired,
  selectorOpenedTime: PropTypes.instanceOf(Date).isRequired,
  defaultDurationChanged: PropTypes.bool.isRequired,
  chatType: PropTypes.string.isRequired,
  selectDurationKind: PropTypes.string
};

StartCallFooter.defaultProps = {
  option: {},
  selectDurationKind: null
};

export default withPGTranslation(StartCallFooter);
