import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import classes from '../classes.module.scss';
import withPGTranslation from '../../../config/withPGTranslation';
import Const from '../../../config/const';

export const LOGIN_EMAIL = 0;
export const LOGIN_PASSWORD = 1;
export const SIGNUP_EMAIL = 2;
export const SIGNUP_RETYPE_EMAIL = 3;
export const SIGNUP_PASSWORD = 4;

function Input({
  onChange, onKeyDown, inputType, value, disabled, error, t, id, theme
}) {
  const placeholder = typer => {
    if (theme === Const.authScreens.signUpAffiliatePromo) return null;
    switch (typer) {
      case SIGNUP_EMAIL:
      case LOGIN_EMAIL:
        return t('email');
      case SIGNUP_PASSWORD:
      case LOGIN_PASSWORD:
        return t('password');
      case SIGNUP_RETYPE_EMAIL:
        return t('retype_email');
      default:
        return '';
    }
  };

  const isPasswordField = inputType === LOGIN_PASSWORD || inputType === SIGNUP_PASSWORD;
  return (
    <div>
      <div>
        <input
          id={ id }
          placeholder={ placeholder(inputType) }
          className={ classnames(classes.input, error ? [classes.inputError, classes[`input${ theme }`]] : classes[`input${ theme }`]) }
          onChange={ onChange }
          onKeyDown={ onKeyDown }
          value={ value }
          disabled={ disabled }
          type={ isPasswordField ? 'password' : 'text' }
        />
      </div>
      {error ? <div className={ classes.error }>{error}</div> : null}
    </div>
  );
}

Input.propTypes = {
  inputType: PropTypes.oneOf([
    LOGIN_EMAIL,
    LOGIN_PASSWORD,
    SIGNUP_EMAIL,
    SIGNUP_RETYPE_EMAIL,
    SIGNUP_PASSWORD
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  id: PropTypes.string,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string
};

Input.defaultProps = {
  error: null,
  id: null,
  onKeyDown: null,
  theme: null
};

export default withPGTranslation(Input);
