import createReducer from '../config/create_reducer';
import Const from '../config/const';
import { compareObjects } from '../../common/config/utils';

const getDefaultState = () => ({
  messageToAppData: {
    sentToAppMessage: []
  }
});

const getMessageToAppData = (state, data) => {
  if (data.sentToAppMessage) {
    const sentToAppMessages = [...state.messageToAppData.sentToAppMessage, data.sentToAppMessage];
    return { ...state.messageToAppData, sentToAppMessage: sentToAppMessages };
  }
  if (data.delete) {
    const sentToAppMessages = state.messageToAppData.sentToAppMessage
      .filter((m) => !compareObjects(m, data.delete));
    return { ...state.messageToAppData, sentToAppMessage: sentToAppMessages };
  }
  return { ...state.messageToAppData, ...data };
};

const handler = {
  [Const.messageToApp.setData]: (state, { data }) => ({
    messageToAppData: getMessageToAppData(state, data)
  })

};

export default createReducer(getDefaultState(), handler);
