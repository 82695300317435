/* eslint-disable object-property-newline */
import React, { useState, useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';

const getlimitedText = (readMoreKind, text, limit) => (readMoreKind === 'limitTextByChars' ? `${ text.slice(0, limit) }` : `${ text.slice(0, limit) }...`);

const limitCharactersText = ({ text, limit, readMoreKind }) => {
  if (typeof text !== 'string') {
    const textString = text.toString();
    if (textString.length > limit) return parse(getlimitedText(readMoreKind, textString, limit));
    return text;
  }
  if (text.length > limit) return getlimitedText(readMoreKind, text, limit);
  return text;
};

const readMoreBtnText = ({ extraInfo, t, readMoreKind }) => {
  if (extraInfo) return readMoreKind === 'limitTextByChars' ? t('seo_content_area.show_less') : t('advisor_profile.less');
  return readMoreKind === 'limitTextByChars' ? t('seo_content_area.read_more') : t('advisor_profile.read_more');
};

const getText = ({
  extraInfo, text, limit, ellipsis, children, readMoreKind
}) => {
  if (children) return children;
  if (ellipsis) return text;
  if (extraInfo) return text;
  return limitCharactersText({ text, limit, readMoreKind });
};

const getTextLength = (textType, text) => (textType === 'string' ? text.length : text.toString().length);

const renderReadMoreBtn = ({
  readMoreBtnClick, customClasses, extraInfo, t, ellipsis,
  isTextClamped, limit, readMoreKind, text
}) => {
  if (ellipsis && !readMoreKind) return null;
  if (!isTextClamped && readMoreKind !== 'limitTextByChars') return null;
  const textType = typeof text;
  if (readMoreKind === 'limitTextByChars' && getTextLength(textType, text) <= limit) return null;
  return <button type="button" onClick={ readMoreBtnClick } className={ customClasses.readMoreBtn }>{ readMoreBtnText({ extraInfo, t, readMoreKind }) }</button>;
};

const getEllipsisTextStyle = ({ extraInfo, limit, readMoreKind }) => {
  if (extraInfo && readMoreKind !== 'limitTextByChars') return { WebkitLineClamp: 'initial' };
  if (extraInfo && readMoreKind === 'limitTextByChars') return { display: 'inline' };
  if (readMoreKind === 'limitTextByChars') return { display: 'inline' };
  return { '--rows': limit };
};

function ExpandedText({
  text, customClasses, skeleton, loading, limit, itemProp, ellipsis, children, className,
  textStyle, readMoreClick, readMoreKind, ellipsisTextStyle
}) {
  const { t } = useTranslation();
  const [extraInfo, setExtraInfo] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(null);
  const ellipsisTextRef = useRef();

  useEffect(() => {
    if (readMoreKind && ellipsisTextRef.current) {
      const elm = ellipsisTextRef.current;
      setIsTextClamped(elm.scrollHeight > elm.clientHeight);
    }
  }, [ellipsisTextRef, loading]);

  const readMoreBtnClick = (e) => {
    if (readMoreClick) readMoreClick(e);
    setExtraInfo(!extraInfo);
  };

  const renderExpandedText = () => {
    if (loading) return skeleton;
    return (
      <div className={ className }>
        <div
          className={ classes.ellipsisText }
          itemProp={ itemProp }
          style={
            { ...getEllipsisTextStyle({ extraInfo, limit, readMoreKind }), ...ellipsisTextStyle }
          }
          ref={ ellipsisTextRef }
          suppressHydrationWarning
        >
          <span className={ customClasses.text } style={ { ...textStyle, whiteSpace: 'pre-wrap' } } suppressHydrationWarning>
            {getText({
              extraInfo, text, limit, ellipsis, children, readMoreKind
            })}
          </span>
        </div>
        { renderReadMoreBtn({
          readMoreBtnClick, customClasses, extraInfo, t, ellipsis, isTextClamped,
          limit, readMoreKind, text
        }) }
      </div>

    );
  };

  return renderExpandedText();
}

ExpandedText.propTypes = {
  text: PropTypes.string,
  customClasses: PropTypes.object,
  skeleton: PropTypes.node,
  loading: PropTypes.bool,
  limit: PropTypes.number,
  t: PropTypes.func,
  itemProp: PropTypes.string,
  ellipsis: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  textStyle: PropTypes.object,
  readMoreKind: PropTypes.oneOf(['limitTextByRows', 'limitTextByChars']),
  readMoreClick: PropTypes.func,
  ellipsisTextStyle: PropTypes.object
};

ExpandedText.defaultProps = {
  text: null,
  customClasses: { text: classes.text, readMoreBtn: classes.readMoreBtn },
  skeleton: <Skeleton className={ classes.ctaSkeleton } />,
  loading: null,
  limit: 1,
  itemProp: null,
  ellipsis: null,
  children: null,
  className: null,
  textStyle: null,
  readMoreKind: null,
  t: null,
  ellipsisTextStyle: null
};

export default ExpandedText;
