import { connect } from 'react-redux';
import AdvisorSection from './advisor_section';
import withPGTranslation from '../../../../config/withPGTranslation';

const mapStateToProps = ({ homePage, user: { user } }, { id }) => (
  {
    ...homePage,
    sectionLoading: ((homePage.sections || []).find(s => (s || {}).id === id) || {}).loading,
    userXfmProgram: user && user.xfmProgram
  }
);

export default connect(
  mapStateToProps,
  {
  }
)(withPGTranslation(AdvisorSection));
