import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  show: false
});

const handler = {
  [Const.language.show]: (state, { payload: { show } }) => ({
    ...state,
    show
  })
};

export default createReducer(getDefaultState(), handler);
