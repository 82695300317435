import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import QRCode from 'react-qr-code';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../../config/const';
import { downloadAppLink } from '../../../../../common/config/utils';
import env from '../../../../config/variables';
import { appLogo } from '../../../../logo/logo';

const { PG_CASHBACK_PERCENT } = env;
const closeIcon = require('./img/close_icon.svg');
const backgroundImg = require('./img/bonus_promo_img.png');
const qrCodeBackgroundImg = require('./img/qrcode_background.svg');
const qrCodeIcon = require('./img/qrcode_icon.svg');

const renderDesktopContent = (downloadLink) => (downloadLink ? (
  <div className={ classes.desktopContentContainer }>
    <div className={ classes.qrCodeContainer }>
      <img src={ qrCodeBackgroundImg } className={ classes.backgroundImg } alt="" />
      <QRCode
        size={ 170 }
        bgColor="white"
        fgColor="black"
        style={ { position: 'absolute', width: '170px' } }
        value={ downloadLink }
        viewBox="0 0 170 170"
      />
    </div>
    <div className={ classes.qrCodeTextContainer }>
      <img src={ qrCodeIcon } className={ classes.qrCodeIcon } alt="" />
      <span className={ classes.qrCodeText }>
        <Trans
          i18nKey="app_download_app_popup.scan_to_download"
          components={ {
            bold:<b className={ classes.boldText } />,
            normal:<span className={ classes.normalText } />
          } }
        />
      </span>
    </div>
  </div>
) : null);

const cashBackPopupContent = (renderDownloadBtn, popupDownloadAppLink) => (
  isMobile ? renderDownloadBtn(classes.downloadAppPopupLink, popupDownloadAppLink)
    : renderDesktopContent(popupDownloadAppLink));

const bonusPopupContent = (renderMobileContent, showDownloadAppPopupLink) => (
  isMobile ? renderMobileContent() : renderDesktopContent(showDownloadAppPopupLink)
);

function DownloadAppPopup({
  showDownloadAppPopupKind, showDownloadAppPopupLink, matchingBonus
}) {
  const [downloadAppPopupVisible, setDownloadAppPopupVisible] = useState(
    !!showDownloadAppPopupKind
  );
  const { t } = useTranslation();
  const popupDownloadAppLink = downloadAppLink('web_session_ended_download_popup');

  const closeCashbackPoppup = () => {
    setDownloadAppPopupVisible(false);
  };

  const renderCloseBtn = (popupCloseBtnStyle) => (
    <div className={ classes.downloadAppPopupCloseContainer }>
      <button type="button" className={ popupCloseBtnStyle } onClick={ closeCashbackPoppup }>
        <img alt="" src={ closeIcon } className={ classes.downloadAppPopupCloseImg } />
      </button>
    </div>
  );
  const renderLogo = () => (
    <div className={ classes.logoContainer }>
      <img src={ appLogo() } alt="" className={ classes.logoImg } />
    </div>
  );

  const onDownloadBtnClick = () => {
    setDownloadAppPopupVisible(false);
  };

  const renderDownloadBtn = (style, link) => (
    <a
      href={ link }
      onClick={ onDownloadBtnClick }
      target="_blank"
      rel="noopener noreferrer"
      className={ style }
    >
      { t('app_download_app_popup.download_app') }
    </a>
  );

  const renderMobileContent = () => (
    <>
      <div className={ classes.mobileBonusPopupBackgroundImgContainer }>
        <img src={ backgroundImg } alt="" className={ classes.mobileBonusPopupBackgroundImg } />
      </div>
      { renderDownloadBtn(classes.bonusDownloadAppPopupLink, showDownloadAppPopupLink) }
    </>
  );

  const renderCashBackPopupContent = () => cashBackPopupContent(renderDownloadBtn, popupDownloadAppLink);
  const renderBonusPopupContent = () => bonusPopupContent(renderMobileContent, showDownloadAppPopupLink);

  const renderDownloadPoppup = (title, text, renderContent) => (
    <AppGeneralPopup
      visible={ downloadAppPopupVisible }
      theme={ appGeneralPopupTheme.CENTERED }
      customTopHeader={ renderCloseBtn(classes.downloadAppPopupCloseBtn) }
    >
      <div className={ classes.downloadAppPopupContentContainer }>
        { renderLogo() }
        <div className={ classes.downloadAppPopupTitle }>
          { title }
        </div>

        <div className={ classes.downloadAppPopupText }>
          <Trans
            i18nKey={ text }
            components={ {
              bold:<b className={ classes.boldText } />,
              normal:<span className={ classes.normalText } />,
              purpleText:<span className={ classes.purpleText } />
            } }
            values={ {
              matchingBonus: parseInt(matchingBonus, 10), cashbackPercent: PG_CASHBACK_PERCENT
            } }
          />
        </div>
        {renderContent()}
      </div>
    </AppGeneralPopup>
  );

  const renderPopup = () => {
    if (showDownloadAppPopupKind === 1) {
      return renderDownloadPoppup(
        t('download_app_popup.did_you_know'),
        'app_download_app_popup.by_downloading_the_app',
        renderCashBackPopupContent
      );
    }
    if (showDownloadAppPopupKind === 2) {
      return renderDownloadPoppup(
        t('download_app_popup.its_a_match'),
        'app_download_app_popup.download_the_app_and_get_a_bonus',
        renderBonusPopupContent
      );
    }
    return null;
  };

  return renderPopup();
}

DownloadAppPopup.propTypes = {
  showDownloadAppPopupKind: PropTypes.number,
  showDownloadAppPopupLink: PropTypes.string,
  matchingBonus: PropTypes.string
};

DownloadAppPopup.defaultProps = {
  showDownloadAppPopupKind: null,
  showDownloadAppPopupLink: null,
  matchingBonus: null
};

export default DownloadAppPopup;
