/* eslint-disable object-property-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ProcessingStatus from '../processing_status/processing_status';
import { purchaseDetailsTypes, purchaseErrorText } from '../../../../common/config/const';
import { PaymentOptionSlimType } from '../../../../common/payment_methods_list/payment_sources_prop_types';
import ThreeDSecure from '../../../../common/blue_snap/threeDSecure';
import {
  getFollowupParam, payingWithCreditCard, renderButtonTitle, selectCloseButtonActionIfNeed,
  isAffiliatePromoPopup, isSideMenu, sign, onRetryButtonClick, onConfirmButton,
  mpTrackPurchaseStartedEvent, mpTrackPurchaseEvent, mpTrackPurchaseFailedEvent
} from '../purchases_utils';
import { browserContext } from '../../../../common/config/utils';
import CreditCardPayment from '../../../../common/credit_card_payment/credit_card_payment';
import { log } from '../../../config/app_logger';

const shouldRenderProcessingStatus = ({
  errorMessage, okMessage, userLoading
}) => errorMessage || okMessage || userLoading;

const onPurchaseClickedHandler = ({
  e, disabled, startCreditCardProcessingPurchase, purchaseURL
}) => {
  e.preventDefault();
  if (disabled) return;
  startCreditCardProcessingPurchase(purchaseURL);
};

const closePurchaseDetailsOnSideMenu = (onPurchaseComplete, type) => {
  if (onPurchaseComplete && isSideMenu({ type })) onPurchaseComplete();
};

function CreditCardProcessing({
  purchaseURL, onPurchaseComplete, getUser, hasPaypalBillingAgreement, loadAdvisor, type, children,
  purchaseAmount, t, bonus, cancelChat, userLoading, currentPaymentOption, isContinue, disabled,
  hidePaymentDetails, advisorId, cancelChatOnPP, chatType, hideAffiliatePromoPopup, error,
  getCreditCardProcessingData, purchaseData, purchaseResponce, createCreditCardProcessingPurchase,
  getCreditCardProcessingPurchaseResponce, clearCreditCardProcessing, purchaseParams, dispatch,
  loadPaymentOptions, purchase3DSRequiredEvent, purchase3DSFailedEvent, fees
}) {
  const history = useNavigate();
  const [retryCount, setRetryCount] = useState(0);
  const [creditCardPaymentStatus, setCreditCardPaymentStatus] = useState({});
  const { purchaseFees } = fees;

  const setCreditCardPaymentStatusHandler = (value) => {
    setCreditCardPaymentStatus(pendingCreditCardPaymentStatus => ({
      ...pendingCreditCardPaymentStatus,
      ...value
    }));
  };

  const clearCreditCardPaymentStatus = () => {
    setCreditCardPaymentStatus({});
  };

  const startCreditCardProcessingPurchase = (URL) => {
    if (!URL) return;
    const params = {
      use_reference_transaction: true,
      with3ds: true,
      ...getFollowupParam({ isContinue, type }),
      ...browserContext
    };
    const url = `${ URL }${ sign({ URL }) }`;
    getCreditCardProcessingData({ url, params });
  };

  useEffect(() => {
    if (creditCardPaymentStatus.purchaseStarted) {
      mpTrackPurchaseStartedEvent({
        retryCount, conversionEventId: purchaseParams.conversionEventId, type, purchaseAmount,
        bonus, hasPaypalBillingAgreement, threeDAuthResult: purchaseResponce.threedAuthResult,
        currentPaymentOption, chatType, dispatch, purchaseFees
      });
      setCreditCardPaymentStatusHandler({ purchaseStarted: false });
    }
    if (creditCardPaymentStatus.okMessage) {
      const {
        conversionEventId, pathname, threedAuthResult, extraEventParams
      } = creditCardPaymentStatus.okMessage;
      mpTrackPurchaseEvent({
        retryCount, conversionEventId, type, purchaseAmount, bonus, hasPaypalBillingAgreement,
        threeDAuthResult: threedAuthResult, currentPaymentOption, chatType, dispatch,
        pathname, getUser, extraEventParams
      });
    }
    if (creditCardPaymentStatus.errorMessage) {
      const {
        errorType, errorPathname, errorMessage, errorName, errorCode, gatewayName,
        threedAuthResult, extraEventParams, spendingLimits
      } = creditCardPaymentStatus.errorMessage;
      mpTrackPurchaseFailedEvent({
        errorType, errorMessage, errorName, errorCode, gatewayName, errorPathname,
        threeDAuthResult: threedAuthResult, retryCount, type, purchaseAmount, bonus,
        hasPaypalBillingAgreement, currentPaymentOption, chatType, dispatch, extraEventParams, spendingLimits
      });
    }
  }, [creditCardPaymentStatus]);

  const renderCreditCardPayment = () => {
    const creditCardPaymentProps = {
      paymentsParams: purchaseParams,
      createCreditCardProcessingPurchase,
      getCreditCardProcessingPurchaseResponce,
      paymentsData: purchaseData,
      paymentsResponce: purchaseResponce,
      setCreditCardPaymentStatusHandler
    };
    return <CreditCardPayment { ...creditCardPaymentProps } />;
  };

  const purchase3DSRequiredEventHandler = (eventName, params) => {
    purchase3DSRequiredEvent(params);
  };

  const purchase3DSFailedEventHandler = (eventName, params) => {
    purchase3DSFailedEvent(params);
  };

  const renderThreeDSecure = () => {
    if (!creditCardPaymentStatus.showThreeDSecure) return null;
    const threeDSecureProps = {
      createCreditCardProcessingPurchase, paymentsParams: purchaseParams,
      purchase3DSRequiredEventAction: purchase3DSRequiredEventHandler,
      purchase3DSFailedEventAction: purchase3DSFailedEventHandler, log
    };
    return <ThreeDSecure { ...threeDSecureProps } />;
  };

  useEffect(() => {
    if (error && error.error === purchaseErrorText.paymentMethodCannotBeUsed) {
      loadPaymentOptions();
    }
  }, [error]);

  const onConfirmButtonClick = () => {
    onConfirmButton({
      clearPurchaseAction: clearCreditCardProcessing, setRetryCount, onPurchaseComplete
    });
  };

  const onCloseButtonClick = () => {
    clearCreditCardProcessing();
    clearCreditCardPaymentStatus();
    selectCloseButtonActionIfNeed({ type, cancelChatOnPP, hideAffiliatePromoPopup });
    closePurchaseDetailsOnSideMenu(onPurchaseComplete, type);
  };

  const onRetry = (e) => {
    e.preventDefault();
    setRetryCount(retryCount + 1);
    onRetryButtonClick({
      purchaseResponce, hidePaymentDetails, cancelChat, history, loadAdvisor, advisorId,
      purchaseURL, startPurchaseAction: startCreditCardProcessingPurchase,
      clearPurchaseAction: clearCreditCardProcessing,
      clearAdditionalAction: clearCreditCardPaymentStatus
    });
  };

  const renderProcessingStatus = () => {
    const { errorMessage, okMessage } = purchaseResponce;
    return (
      <ProcessingStatus
        purchaseResponce={ purchaseResponce }
        t={ t }
        isSideMenu={ isSideMenu({ type }) }
        isAffiliatePromoPopup={ isAffiliatePromoPopup({ type }) }
        onClose={ onCloseButtonClick }
        userLoading={ userLoading }
        onRetry={ onRetry }
        onConfirm={ onConfirmButtonClick }
        isCreditCard={ payingWithCreditCard({ currentPaymentOption }) }
        purchaseURL={ purchaseURL }
        buttonTitle={ renderButtonTitle({ isContinue, type, t }) }
        chatType={ chatType }
        purchaseAmount={ purchaseAmount }
        bonus={ bonus }
        error={ error }
        visible= { !!shouldRenderProcessingStatus({
          errorMessage, okMessage, userLoading
        }) }
      />
    );
  };

  const onPurchaseClicked = (e) => {
    onPurchaseClickedHandler({
      e, disabled, startCreditCardProcessingPurchase, purchaseURL
    });
  };

  return (
    <>
      <a href={ purchaseURL } target="blank" onClick={ onPurchaseClicked } disabled={ disabled }>
        { children }
      </a>
      { renderCreditCardPayment() }
      { renderThreeDSecure() }
      { renderProcessingStatus() }
    </>
  );
}

CreditCardProcessing.propTypes = {
  purchaseURL: PropTypes.string,
  purchaseAmount: PropTypes.number.isRequired,
  getUser: PropTypes.func.isRequired,
  hasPaypalBillingAgreement: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(purchaseDetailsTypes).map((key) => purchaseDetailsTypes[key])),
  children: PropTypes.node,
  onPurchaseComplete: PropTypes.func,
  t: PropTypes.func.isRequired,
  bonus: PropTypes.number,
  cancelChat: PropTypes.func.isRequired,
  userLoading: PropTypes.bool.isRequired,
  currentPaymentOption: PropTypes.shape(PaymentOptionSlimType),
  isContinue: PropTypes.bool,
  disabled: PropTypes.bool,
  hidePaymentDetails: PropTypes.func.isRequired,
  advisorId: PropTypes.number,
  loadAdvisor: PropTypes.func.isRequired,
  cancelChatOnPP: PropTypes.func.isRequired,
  chatType: PropTypes.string.isRequired,
  hideAffiliatePromoPopup: PropTypes.func,
  getCreditCardProcessingData: PropTypes.func.isRequired,
  createCreditCardProcessingPurchase: PropTypes.func.isRequired,
  getCreditCardProcessingPurchaseResponce: PropTypes.func.isRequired,
  clearCreditCardProcessing: PropTypes.func.isRequired,
  purchaseParams: PropTypes.object,
  purchaseData: PropTypes.object,
  purchaseResponce: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.object,
  loadPaymentOptions: PropTypes.func.isRequired,
  purchase3DSRequiredEvent: PropTypes.func.isRequired,
  purchase3DSFailedEvent: PropTypes.func.isRequired,
  fees:PropTypes.object
};

CreditCardProcessing.defaultProps = {
  purchaseURL: null,
  hasPaypalBillingAgreement: false,
  type: purchaseDetailsTypes.SIDE_MENU,
  children: null,
  onPurchaseComplete: null,
  currentPaymentOption: null,
  hideAffiliatePromoPopup: null,
  bonus: 0,
  isContinue: false,
  disabled: false,
  advisorId: null,
  purchaseParams: null,
  purchaseData: null,
  purchaseResponce: {},
  error: null,
  fees: {}
};

export default CreditCardProcessing;
