import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'node_modules/react-smartbanner/dist/main.css';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import SideMenu from '../side_menu';
import AppHeader from '../header';
import { renderItemPropMeta, getHeaderHeight } from '../../config/util';
import Footer from './footer';
import SeoData from './seo_data';
import NavigationStackBar from '../navigation_stack_bar';
import { hideBodyScroll, unlockBodyScroll } from '../../../common/config/utils';

function AppLayout({
  children, removeHeader, topGradientColor,
  needsHeaderTransparentTransition, fullHeight, itemProp, noNeedFooter
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const appLayoutItemProp = (itemProp || {});

  const calculateScrollbarWidth = () => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${ scrollbarWidth }px`);
    document.documentElement.style.setProperty('--window-width', `${ window.innerWidth }`);
    document.documentElement.style.setProperty('--window-height', `${ window.innerHeight }px`);
    document.documentElement.style.setProperty('--header-height', `${ getHeaderHeight() || '0px' }`);
  };

  const toggleDrawer = () => {
    setVisible(!visible);
    if (!visible) {
      hideBodyScroll();
    } else {
      unlockBodyScroll();
    }
  };

  useEffect(() => {
    if (visible) {
      setVisible(false);
      unlockBodyScroll();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    // recalculate on resize
    window.addEventListener('resize', calculateScrollbarWidth, false);
    // recalculate on dom load
    document.addEventListener('DOMContentLoaded', calculateScrollbarWidth, false);
    // recalculate on load (assets loaded as well)
    window.addEventListener('load', calculateScrollbarWidth, false);
    return () => {
      window.removeEventListener('resize', calculateScrollbarWidth, false);
      document.removeEventListener('DOMContentLoaded', calculateScrollbarWidth, false);
      window.removeEventListener('load', calculateScrollbarWidth);
      unlockBodyScroll();
    };
  }, []);

  useEffect(() => {
    calculateScrollbarWidth();
  }, [document.documentElement.clientWidth]);

  const renderHeader = () => {
    if (removeHeader) return null;
    return (
      <div className={ classes.rootHeader }>
        <AppHeader
          toggleDrawer={ toggleDrawer }
          withTranparentTransition = { needsHeaderTransparentTransition }
          topGradientColor={ topGradientColor }
          itemProp={ itemProp }
        />
      </div>
    );
  };

  const renderFooter = () => {
    if (noNeedFooter) return null;
    return (
      <div className={ classes.footer }>
        <Footer />
      </div>
    );
  };

  return (
    <div suppressHydrationWarning style={ { display: 'flex' } }>
      <div
        id="appRoot"
        className={ classes.appRoot }
        itemScope={ appLayoutItemProp.itemScope }
        itemType={ appLayoutItemProp.itemType }
        suppressHydrationWarning
      >
        <Helmet>
          <title>{ t('app_name_title') }</title>
          <meta property="og:url" content={ window.location.href } suppressHydrationWarning />
          <meta property="og:title" content={ `${ t('app_name_title') } 1` } />
          <meta name="description" content="Live Psychic readings on love, romance, money, and more! Unlock life secrets by connecting with some of the highest rated advisors in the world!" />
          <meta property="og:description" content="Personalized psychic video readings on love, romance, money, and more! Unlock life secrets by connecting with some of the highest rated advisors in the world!" />
        </Helmet>
        { renderItemPropMeta(appLayoutItemProp) }
        { renderHeader() }
        <SideMenu
          visible={ visible }
          toggleDrawer={ toggleDrawer }
        />
        <div
          className={ classes.rootContent }
          suppressHydrationWarning
          style={ { '--topGradientColor':topGradientColor } }
        >
          <div
            suppressHydrationWarning
            className={ fullHeight
              ? classNames(classes.container, classes.fullHeightContainer)
              : classNames(classes.container) }
          >
            <NavigationStackBar location={ useLocation() } />
            <div className={ classes.childrenContainer }>
              { children }
            </div>
            <SeoData />
            { renderFooter() }
          </div>
        </div>
      </div>
      <div className={ classes.invisibleScrollBar } />
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node,
  noNeedMarginTop: PropTypes.bool,
  removeHeader: PropTypes.bool,
  needsHeaderTransparentTransition: PropTypes.bool,
  topGradientColor: PropTypes.string,
  fullHeight: PropTypes.bool,
  showNavigationStackBar: PropTypes.bool,
  itemProp: PropTypes.object,
  noNeedFooter: PropTypes.bool
};

AppLayout.defaultProps = {
  children: null,
  noNeedMarginTop: null,
  removeHeader: null,
  needsHeaderTransparentTransition: false,
  topGradientColor:null,
  fullHeight: false,
  showNavigationStackBar: null,
  itemProp: null,
  noNeedFooter: null
};

export default AppLayout;
