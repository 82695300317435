/* eslint-disable import/no-cycle */
import React from 'react';
import  queryString  from 'query-string';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';
import classes from './classes.module.scss';
import AppLink from '../../routes/app_link';
import { isKA, toCamelCase } from '../../../../common/config/utils';
import { seeMoreBtnThemes } from './index';
import PaginationPages from '../pagination_pages';

const seeMoreBtn = require('../img/see_more_btn.svg');

const getPage = (page) => {
  const search = toCamelCase(queryString.parse(window.location.search));
  const searchPage = typeof search.page !== 'undefined' && search.page;
  const usedPage = searchPage ? parseFloat(searchPage) : page;
  return { usedPage, searchPage };
};
const needRenderSeeMoreBtnDefault = ({ loading, lastPageNum }) => !loading && lastPageNum;

const renderSeeMoreBtnDefault = ({
  loading, lastPageNum, seeMoreBtnClick, t, loadAdvisors, theme, params, path, seenPages, page
}) => {
  if (!needRenderSeeMoreBtnDefault({ loading, lastPageNum })) return null;
  const { usedPage, searchPage } = getPage(page);
  if (!searchPage || usedPage === 1) {
    if (lastPageNum <= usedPage) return null;
    return (
      <div className={ classes.bottomButtonContainer }>
        <button type="button" className={ classes.bottomButton } onClick={ seeMoreBtnClick }>
          { t('home_page.see_more') }
        </button>
      </div>
    );
  }
  return (
    <PaginationPages
      loadAdvisors={ loadAdvisors }
      theme={ theme }
      params={ params }
      path={ path }
      lastPageNum={ lastPageNum }
      seenPages={ seenPages }
    />
  );
};

function SeeMoreBtn({
  theme, loadAdvisors, loading, params, t, slug, path, seenPages, lastPageNum, page
}) {
  const history = useNavigate();
  const location = useLocation();

  const seeMoreBtnClick = () => {
    const { usedPage, searchPage } = getPage(page);
    const nextPage = usedPage + 1;
    loadAdvisors({ ...params, page: nextPage }, path, searchPage);
    if (searchPage) {
      const srarch = queryString.parse(location.search);
      const searchWithPage = queryString.stringify({ ...srarch, page: nextPage });
      history(`${ location.pathname }?${ searchWithPage }`, {
        state: { ...location.state },
        replace: true
      });
    }
  };

  const renderSeeMoreBtnDefaultHomePageAllAdvisors = () => (
    <div className={ classes.bottomButtonContainer }>
      <AppLink
        to={ {
          pathname: slug,
          state: {
            needRefresh: true,
            params
          }
        } }
        style={ isKA() ? { padding: '10px 50px' } : {} }
        className={ classes.bottomButton }
      >

        {!isKA() && <img src={ seeMoreBtn } className={ classes.seeMoreBtnImg } alt="" />}
        <span className={ classes.seeMoreBtnText }>
          { t('home_page.see_more_advisors') }
        </span>
      </AppLink>
    </div>
  );

  const renderSeeMoreBtn = () => {
    if (theme === seeMoreBtnThemes.homePageAllAdvisors) {
      return renderSeeMoreBtnDefaultHomePageAllAdvisors();
    }
    return renderSeeMoreBtnDefault({
      loading, lastPageNum, seeMoreBtnClick, t, loadAdvisors, theme, params, path, seenPages, page
    });
  };

  return (
    <div className={ classes[`${ theme }`] }>
      { renderSeeMoreBtn() }
    </div>
  );
}

SeeMoreBtn.propTypes = {
  theme: PropTypes.string,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadAdvisors: PropTypes.func,
  isLastPage: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string,
  slug: PropTypes.string,
  lastPageNum: PropTypes.number,
  seenPages: PropTypes.array,
  page: PropTypes.number
};

SeeMoreBtn.defaultProps = {
  theme: 'searchResult',
  isLastPage: true,
  loadAdvisors: null,
  path: null,
  loading: null,
  params: null,
  slug: null,
  lastPageNum: null,
  seenPages: [],
  page: 1
};

export default SeeMoreBtn;
