import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  advisors: []
};

const handler = {
  [Const.favoriteAdvisors.loading]: (state, { loading }) => ({
    ...state,
    loading,
    error: null
  }),
  [Const.favoriteAdvisors.advisors]: (state, { payload: { advisors } }) => ({
    ...state,
    loading: false,
    error: null,
    advisors
  }),
  [Const.favoriteAdvisors.error]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error
  }),
  [Const.favoriteAdvisors.markUnFavorite]: (state, { payload }) => ({
    ...state,
    advisors: [...state.advisors.filter(({ id }) => id !== payload.id)]
  }),
  [Const.favoriteAdvisors.markFavorite]: (state, { payload: { advisor } }) => ({
    ...state,
    advisors: [...state.advisors, advisor]
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, handler);
