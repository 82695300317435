import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

const positiveIcon = require('../img/rate_positive.svg');
const negativeIcon = require('../img/rate_negative.svg');

export const reviewItemThemes = {
  advisorHeader: 'reviewItemAdvisorHeader',
  reviewsSection: 'reviewItemReviewsSection'
};

function ReviewItem({
  isPositive, value, ignoreLeftMargin, theme
}) {
  return (
    <div className={ classes[theme] } style={ ignoreLeftMargin ? { marginLeft: 0 } : {} } suppressHydrationWarning>
      <div className={ classes.reviewItem } suppressHydrationWarning>
        <img className={ classes.reviewImg } src={ isPositive ? positiveIcon : negativeIcon } alt="" />
        { value }
      </div>
    </div>
  );
}

ReviewItem.propTypes = {
  value: PropTypes.number,
  isPositive: PropTypes.bool,
  ignoreLeftMargin: PropTypes.bool,
  theme: PropTypes.string
};

ReviewItem.defaultProps = {
  value: 0,
  isPositive: false,
  ignoreLeftMargin: false,
  theme: reviewItemThemes.reviewsSection
};

export default ReviewItem;
