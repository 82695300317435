import { connect } from 'react-redux';
import { showLanguageCreator as showLanguage } from '../../actions/change_language';
import withPGTranslation from '../../config/withPGTranslation';
import SideMenu from './side_menu';

const mapStateToProps = ({
  user, language: { show }, homePage
}) => ({
  isSufficientForCashback: user.user ? user.user.isSufficientForCashback : false,
  cashbackAmount: user.user ? user.user.cashbackAmount : '0.0',
  userInviteCode: user.user ? user.user.inviteCode : null,
  languageShow: show,
  shouldShowPayoutMethodMenuItem:
    user.user && user.user.settings
      ? !!user.user.settings.showPaymentMethodMenuItem : true,
  specialities: homePage.specialities
});

export default connect(
  mapStateToProps,
  {
    showLanguageSwitcher: showLanguage
  }
)(withPGTranslation(SideMenu));
