import { connect } from 'react-redux';
import PriceText from './price_text';
import { getUserFirstActivePromotion } from '../../../../config/util';

const mapStateToProps = ({
  user, application
}) => (
  {
    userAdvisorModeMinSalesPrice: getUserFirstActivePromotion({ user, application })?.advisorModeMinSalesPrice
  }
);

export default connect(
  mapStateToProps,
  {}
)((PriceText));
