import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  user: null
};

const handler = {
  [Const.introduce.inProgress]: state => ({
    ...state,
    loading: true
  }),
  [Const.introduce.error]: (state, { payload: { error } }) => ({
    ...state,
    error,
    loading: false
  }),
  [Const.introduce.clearError]: (state) => ({
    ...state,
    error: null
  }),
  [Const.introduce.user]: (state, { payload: { user } }) => ({
    ...state,
    loading: false,
    error: null,
    user
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, handler);
