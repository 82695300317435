import { connect } from 'react-redux';
import ForgotPassword from './forgot_password';
import withPGTranslation from '../../config/withPGTranslation';
import {
  forgot, passwordForgotClear, clearError
} from '../../actions/forgot_password';

const mapStateToProps = ({ passwordForgot }) => passwordForgot;

export default connect(
  mapStateToProps,
  {
    forgot,
    passwordForgotClear,
    clearError
  }
)(withPGTranslation(ForgotPassword));
