import React from 'react';
import classes from './classes.module.scss';
import { isNotificationSupported } from '../../../actions/firebase_messaging';
import { linkType } from '../../../actions/general_popup';
import { subscribedToAdvisor, isPrerenderMode } from '../../../config/util';
import { trackPageVisit } from '../../../actions/analytics';
import { isWebView } from '../../../../common/config/utils';

const blackFav = require('../img/black_favorite_empty.svg'); // bclak
const blackFavActive = require('../img/black_favorite_active.svg');// bllack
const blackNotify = require('../img/black_notify.svg');
const blackNotifyActive = require('../img/black_notify_active.svg');

const whiteFav = require('../img/white_favorite_empty.svg');
const whiteFavActive = require('../img/white_favorite_active.svg');
const whiteNotify = require('../img/white_notify.svg');
const whiteNotifyActive = require('../img/white_notify_active.svg');

const navigateToRegisterScreen = (displayRegister) => {
  displayRegister({ shouldDisplay: true });
};

const callCallbackOrSetItPending = (authorizedCallback, needToAuthCallback) => (
  window.currentUserId ? authorizedCallback() : needToAuthCallback()
);

export const renderFav = (onFavoriteClick, favorite) => (
  <button
    type="button"
    onClick={ onFavoriteClick }
    className={ classes.iconButton }
    suppressHydrationWarning
  >
    <img
      className={ classes.topIconImgWhite }
      src={ favorite && window.currentUserId ? whiteFavActive : whiteFav }
      alt="Favorites"
      suppressHydrationWarning
    />
    <img
      className={ classes.topIconImgBlack }
      src={ favorite && window.currentUserId ? blackFavActive : blackFav }
      alt="Favorites"
      suppressHydrationWarning
    />
  </button>
);

export const renderNotify = ({ onNotifyClick, availabilityNotification, advisorStatus }) => {
  if (advisorStatus === 'online' || !advisorStatus || isPrerenderMode()) return null;
  return (
    <button
      type="button"
      onClick={ onNotifyClick }
      className={ classes.iconButton }
      suppressHydrationWarning
    >
      <img
        className={ classes.topIconImgWhite }
        src={ subscribedToAdvisor(availabilityNotification) ? whiteNotifyActive : whiteNotify }
        alt="Notifications"
        suppressHydrationWarning
      />
      <img
        className={ classes.topIconImgBlack }
        src={ subscribedToAdvisor(availabilityNotification) ? blackNotifyActive : blackNotify }
        alt="Notifications"
        suppressHydrationWarning
      />
      {!isWebView() && (<div className={ classes.notifyMeTooltipContainer } data-tooltip="Notify me" suppressHydrationWarning />) }
    </button>
  );
};

const getClickSource = (location) => {
  if (location.state && location.state.clickSource) return location.state.clickSource;
  return 'advisor profile';
};

export const handleFavoriteClick = (
  e,
  favoriteAdvisor,
  favorite,
  id,
  displayRegister,
  authPendingAction,
  location,
  favoriteLoading
) => {
  if (favoriteLoading) return;
  e.preventDefault();
  const favoriteAction = () => {
    favoriteAdvisor(favorite, id, getClickSource(location), true);
  };

  const authNeedAction = () => {
    authPendingAction(() => {
      if (!favorite) favoriteAction(favorite, id, favoriteAdvisor);
    });
    navigateToRegisterScreen(displayRegister);
  };

  callCallbackOrSetItPending(favoriteAction, authNeedAction);
};

export const handleNotifyClick = (
  e,
  displayNotifyMe,
  id,
  showGeneralPopup,
  authPendingAction,
  displayRegister,
  location,
  needToLoadAdv
) => {
  e.preventDefault();
  const notifyClickAction = () => {
    if (isNotificationSupported()) {
      displayNotifyMe(true, parseInt(id, 10), getClickSource(location), needToLoadAdv);
    } else {
      showGeneralPopup(null, linkType.notificationNotSupported);
      trackPageVisit('service unavailable popup');
    }
  };
  const authNeedAction = () => {
    authPendingAction(() => {
      notifyClickAction(id, displayNotifyMe, showGeneralPopup);
    });
    navigateToRegisterScreen(displayRegister);
  };
  callCallbackOrSetItPending(notifyClickAction, authNeedAction);
};
