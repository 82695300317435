import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  error: null,
  success: false
});

const handler = {
  [Const.aws.uploading]: state => ({
    ...state,
    loading: true,
    error: null,
    succcess: null
  }),
  [Const.aws.failed]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload
  }),
  [Const.aws.success]: (state) => ({
    ...state,
    loading: false,
    error: null,
    success: true
  }),
  [Const.aws.reset]: () => ({
    ...getDefaultState()
  })
};

export default createReducer(getDefaultState(), handler);
