import { connect } from 'react-redux';
import AllAdvisorsSection from './all_advisors_section';
import { loadHomePage } from '../../../../actions/home_page';
import { sectionIds } from '../../prop_types';

export const AdvisorPreviewThemes = {
  staff_pick: 'staff_pick',
  personal: 'personal',
  badge: 'badge',
  badge_end_to_end: 'badge_end_to_end',
  searchResult: 'searchResult',
  someOtherAdvisor: 'someOtherAdvisor'
};

const mapStateToProps = ({ homePage: { sections } }) => ({
  allAdvisorsListSection: sections.find((s) => s.id === sectionIds.allAdvisorsList)
});

export default connect(
  mapStateToProps,
  {
    loadHomePage
  }
)(AllAdvisorsSection);
