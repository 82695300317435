class Debouncer {
  constructor(config) {
    this.debounceTime = (config && config.debounceTime) || 500;
    this.timer = null;
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  debounceLoad = callback => {
    this.clearTimer();
    this.timer = setTimeout(callback, this.debounceTime);
  };

  loadWithDebounce = (callback, delayed) => {
    if (delayed) {
      this.debounceLoad(callback);
    } else {
      this.clearTimer();
      callback();
    }
  };
}

export default Debouncer;
