import React from 'react';
import PropTypes from 'prop-types';
import Error from '../error';

function NoAnswer({ t, onCancelClicked }) {
  return <Error error={ { errors: [t('chat.no_answer')] } } onCancelClicked={ onCancelClicked } />;
}

NoAnswer.propTypes = {
  t: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired
};

export default NoAnswer;
