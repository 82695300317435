import React from 'react';
import PropTypes from 'prop-types';
import Error from '../error';
import { getAdvisorSlug } from '../../../project_items/helper_functions';
// import classes from './classes.module.scss';

function NotInLiveMode({
  cancelChat, errors, loadAdvisor, advisorId
}) {
  const onCancel = () => {
    cancelChat();
    if (advisorId) {
      loadAdvisor(getAdvisorSlug({ advisorId }));
    }
  };

  return (
    <Error onCancelClicked={ onCancel } error={ { errors } } />
  );
}

NotInLiveMode.propTypes = {
  cancelChat: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadAdvisor: PropTypes.func.isRequired,
  advisorId: PropTypes.number
};

NotInLiveMode.defaultProps = {
  advisorId: null
};

export default NotInLiveMode;
