import { connect } from 'react-redux';
import DownloadAppPopup  from './download_app_popup';

const mapStateToProps = ({ chat, voipChat: { order } }) => ({
  ...chat,
  ...order
});

export default connect(
  mapStateToProps,
  {}
)(DownloadAppPopup);
