import { formatISO } from 'date-fns';
import Const from '../config/const';
import { toCamelCase } from '../../common/config/utils';
import {
  trackChatStartedEvent, StatusSuccess, StatusNoAnswer, trackPageVisit
} from './analytics';
import { getUser } from './user';
import { getPricingOptions } from '../config/api';
import { log } from '../config/app_logger';
import { loadAdvisor } from './advisors';
import { cancelChat, pricingLoading, cancelChatOnPPActions } from './chat';
import { getAdvisorSlug } from '../project_items/helper_functions';

const NO_ANSWER = 'no_answer';
const ADVISOR_CONNECTION_LOST = 'advisor_connection_lost';

let pubNub = null;
let listener = null;

const CATGEORY_ACTIONS = {
  PNConnectedCategory: Const.pubnub.actions.connected,
  PNNetworkDownCategory: Const.pubnub.actions.down,
  PNNetworkUpCategory: Const.pubnub.actions.up
};

const updatePricingOptionsActionCreator = (pricingOptions, selectDurationKind, newUserPaygPricingOption) => ({
  type: Const.chat.updatePricingOptions,
  payload: { pricingOptions, selectDurationKind, newUserPaygPricingOption }
});

const trackMPChatStartedEventIfNeeded = (chatState, dispatch, getState) => {
  if (chatState !== Const.chatStates.started) return;

  const { chat, user: { user } } = getState();
  trackChatStartedEvent(Const.chatType.text, StatusSuccess, chat, user, dispatch);
  trackPageVisit('live session page');
};

const trackNoAnswerEventIfNeeded = (reason, dispatch, getState) => {
  if (reason !== NO_ANSWER) return;

  const { chat, user: { user } } = getState();
  trackChatStartedEvent(Const.chatType.text, StatusNoAnswer, chat, user, dispatch);
};

const updateUserIfNeeded = (state, dispatch) => {
  if (!(state === Const.chatStates.ended || state === Const.chatStates.paused)) return;

  dispatch(getUser());
};

const updateAdvisorIfEnded = (state, dispatch, getState) => {
  if (state !== Const.chatStates.ended) return;

  const { chat: { advisorId } } = getState();
  if (advisorId === null) return;
  dispatch(loadAdvisor(getAdvisorSlug({ advisorId })));
};

export const updatePricingOptions = (onContinue, dispatch, getState) => {
  dispatch(pricingLoading());
  const { chat: { advisorId } } = getState();
  const additionalParams = onContinue ? { context: 'followup' } : {};
  getPricingOptions(advisorId, { liveModes:'chat', ...additionalParams }).then((res) => {
    dispatch(updatePricingOptionsActionCreator(res.chatPricingOptions || [], res.selectDurationKind, res.newUserPaygPricingOption));
  });
};

const updatePricingOptionsOnContinueIfNeed = (state, dispatch, getState) => {
  if (state !== Const.chatStates.paused) return;
  updatePricingOptions(true, dispatch, getState);
};

export const cancelChatOnPP = (isContinue) => (dispatch, getState) => {
  updatePricingOptions(isContinue, dispatch, getState);
  dispatch(cancelChatOnPPActions(isContinue));
};

const updateActions = (state, dispatch, getState) => {
  updateAdvisorIfEnded(state, dispatch, getState);
  updateUserIfNeeded(state, dispatch);
  updatePricingOptionsOnContinueIfNeed(state, dispatch, getState);
};

const dispatchAdminMessage = ({ state, reason, ...details }, dispatch, getState) => {
  trackMPChatStartedEventIfNeeded(state, dispatch, getState);
  trackNoAnswerEventIfNeeded(reason, dispatch, getState);

  updateActions(state, dispatch, getState);

  log('State', `state: ${ state }, reason: ${ reason }`);
  if (reason === NO_ANSWER) {
    dispatch({ type: Const.chat.noAnswer });
    return;
  }

  const chatState = getState().chat.state;
  // const id = getState().chat.chatId;
  if (chatState === null || (state === chatState && state !== Const.chatStates.ended)) return;
  // Close chat without complete screen if user hangup before the advisor answered
  if (
    (chatState === Const.chatStates.appConnectProvider
      || chatState === Const.chatStates.ringing
    ) && state === Const.chatStates.ended) {
    dispatch(cancelChat());
    return;
  }
  dispatch({
    type: Const.chat.adminUpdate, state, details: toCamelCase(details), reason
  });
};

const handleTextUserMessage = (body, reason, uuid, arrayIndex, timetoken, dispatch) => {
  if (reason === Const.message.status.offlineMessage) {
    dispatch({ type: Const.chat.messageUpdate, message: { timetoken, arrayIndex } });
    return;
  }
  dispatch({ type: Const.chat.message, message: { body, uuid, timetoken } });
};

const dispatchUserMessage = ({
  body, kind, reason, uuid, arrayIndex
}, timetoken, dispatch, getState) => {
  if (kind === Const.message.kinds.text) {
    handleTextUserMessage(body, reason, parseInt(uuid, 10), arrayIndex, timetoken, dispatch);
    return;
  }

  if (kind === Const.message.kinds.textSystemMessage) {
    dispatch({ type: Const.chat.systemMessage, systemMessage: { body, uuid, timetoken } });
    return;
  }

  if (kind !== Const.message.kinds.notification) {
    log('PubNub', `unknown kind: ${ kind }, reason: ${ reason }`);
  }

  const {
    pubnub: { ownUuid }
  } = getState();
  if (parseInt(ownUuid, 10) === parseInt(uuid, 10)) return;

  if (reason === Const.message.typing.stop) {
    dispatch({ type: Const.chat.stopPeerTyping, uuid });
    return;
  }

  if (reason === Const.message.typing.start) dispatch({ type: Const.chat.startPeerTyping, uuid });
};

const connectPubNub = getState => {
  const {
    chat: { channelId }
  } = getState();

  pubNub.subscribe({ channels: [channelId], withPresence: true });
};

const saveStorePubNubMessage = (message) => (dispatch) => {
  dispatch({ type: Const.chat.message, message });
};

const doSendMessage = ({
  kind, reason, body, arrayIndex
}) => (dispatch, getState) => {
  const {
    chat: { channelId },
    pubnub: { ownUuid, state }
  } = getState();
  const config = {
    channel: channelId,
    sendByPost: true,
    storeInHistory: kind === Const.message.kinds.text,
    message: {
      user: {
        kind, reason, body: (body && body.trim()) || '', uuid: parseInt(ownUuid, 10)
      }
    }
  };
  if (state === Const.pubnub.states.down) {
    if (kind === 'text') {
      const message = {
        body,
        uuid: parseInt(ownUuid, 10),
        offline: true,
        arrayIndex: getState().chat.messages.length
      };
      log('PubNub', `saveStorePubNubMessage kind: ${ kind }, reason: ${ reason }, uuid: ${ message.uuid }`);
      dispatch(saveStorePubNubMessage(message));
    }
    return;
  }

  if (reason === Const.message.status.offlineMessage) {
    if (kind === 'text') { config.message.user.arrayIndex = arrayIndex; }
  }
  log('PubNub', `pubnub sending channel: ${ config.channel }, kind: ${ kind }, reason: ${ reason }, uuid: ${ config.message.user.uuid }`);
  pubNub.publish(config);
};

const sendStorePubNubMessage = (dispatch, getState) => {
  const {
    chat: { messages }
  } = getState();
  messages.forEach((m) => {
    if (m.offline) {
      log('PubNub', `sendStorePubNubMessage kind: ${ Const.message.kinds.textreason }, reason: ${ Const.message.status.offlineMessage }`);
      dispatch(doSendMessage({
        kind: Const.message.kinds.text,
        body: m.body,
        reason: Const.message.status.offlineMessage,
        arrayIndex: m.arrayIndex
      }));
    }
  });
};

export const setAadvisorConnectionLost = (dispatch) => {
  dispatch({ type: Const.chat.advisorConnectionLost });
};

export const clearAdvisorConnectionLost = () => dispatch => {
  dispatch({ type: Const.chat.clearAdvisorConnectionLost });
};

const createListener = (dispatch, getState) => ({
  message: (props) => {
    const { message, publisher, timetoken } = props;
    const { admin, user, sm } = message;
    const {
      pubnub: { ownUuid }
    } = getState();
    log('PubNub', 'message');
    log('message', { ...message });
    dispatch({ type: Const.pubnub.actions.timetoken, timetoken });

    if (admin) {
      const { peers } = getState().chat;
      if (!peers || peers.findIndex(p => p.uuid === publisher) === -1) {
        dispatchAdminMessage(admin, dispatch, getState);
      }
      if (admin.reason === ADVISOR_CONNECTION_LOST) {
        setAadvisorConnectionLost(dispatch);
      }
      return;
    }

    if (sm && sm.receiver_ids.includes(parseInt(ownUuid, 10))) {
      dispatchUserMessage(sm, formatISO(new Date(timetoken / 10000)), dispatch, getState);
    }
    if (user) {
      dispatchUserMessage(user, formatISO(new Date(timetoken / 10000)), dispatch, getState);
    }
  },
  status: (props) => {
    const { category, ...rest } = props;
    const type = CATGEORY_ACTIONS[category];
    if (type === Const.pubnub.actions.up) {
      connectPubNub(getState);
    }

    if (type === Const.pubnub.actions.connected) {
      sendStorePubNubMessage(dispatch, getState);
    }

    if (type) {
      dispatch({ type });
      return;
    }
    log('PubNub', `status unknown category: ${ category }`);
    log('status props', { ...rest });
  },
  presence: presence => {
    log('PubNub', 'presence');
    log('presence', { ...presence });
  }
});

export const connectChatProvider = () => (dispatch, getState) => {
  const {
    chat: { pubnub }
  } = getState();
  import('pubnub').then((module) => {
    pubNub = new module.default({ ...pubnub, ssl: true, restore: true });
    listener = createListener(dispatch, getState);
    pubNub.addListener(listener);
    const { uuid } = pubnub;
    dispatch({ type: Const.pubnub.actions.connect, ownUuid: uuid });
    connectPubNub(getState);
  });
};

export const disconnectChatProvider = () => dispatch => {
  if (!pubNub) {
    return;
  }
  if (listener) {
    pubNub.removeListener(listener);
  }
  pubNub.stop();
  dispatch({ type: Const.pubnub.actions.shutdown });
};

export const sendMessage = body => doSendMessage({ kind: Const.message.kinds.text, body });

export const sendTyping = () => doSendMessage({
  kind: Const.message.kinds.notification,
  reason: Const.message.typing.start
});

export const sendStopTyping = () => doSendMessage({
  kind: Const.message.kinds.notification,
  reason: Const.message.typing.stop
});
