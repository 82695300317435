import { combineReducers } from 'redux';

import advisors from './advisors';
import chat from './chat';
import pubnub from './pubnub';
import myOrders from './my_orders';
import advisorOrders from './advisor_orders';
import favoriteAdvisors from './favorite_advisors';
import orderDetails from './order_details';
import promoCode from './promo_code';
import auth from './auth';
import user from './user';
import intro from './intro';
import funds from './funds';
import notifyMe from './notifyMe';
import transcript from './transcript';
import logOut from './log_out';
import settings from './settings';
import cashback from './cashback';
import passwordForgot from './password_forgot';
import pushNotification from './push_notification';
import language from './change_language';
import generalPopup from './general_popup';
import paymentSources from './payment_sources';
import paymentOptions from './payment_options';
import aplicationError from './aplication_error';
import purchaseDetails from './purchase_details';
import affiliatePromoPopup from './affiliate_promo_popup';
import aws from './aws';
import spendingLimits from './spending_limits';
import voipChat from './voip_chat';
import homePage from './home_page';
import searchResults from './search_results';
import popover from './popover';
import application from './application';
import creditCardProcessing from './credit_card_processing';
import creditCardValidateProcessing from './credit_card_validate_processing';
import messageToApp from './message_to_app';
import categories from './categories';

export default combineReducers({
  advisors,
  chat,
  pubnub,
  myOrders,
  advisorOrders,
  favoriteAdvisors,
  orderDetails,
  auth,
  user,
  intro,
  funds,
  notifyMe,
  promoCode,
  transcript,
  logOut,
  settings,
  cashback,
  passwordForgot,
  pushNotification,
  language,
  generalPopup,
  paymentSources,
  aplicationError,
  aws,
  voipChat,
  paymentOptions,
  purchaseDetails,
  spendingLimits,
  affiliatePromoPopup,
  homePage,
  searchResults,
  popover,
  application,
  creditCardProcessing,
  creditCardValidateProcessing,
  messageToApp,
  categories
});
