import createReducer from '../config/create_reducer';
import Const from '../config/const';
import { sectionIds, sectionTypes } from '../components/home_page/prop_types';
import { updateSlugIfNeed } from '../../common/config/utils';

const defaultState = {
  loading: true,
  error: null,
  filters: null,
  salesBanner: null,
  sections: null,
  specialities: null
};

const formatPersonalSections = (personalSectionsArray) => {
  let personalSections = {};
  personalSectionsArray.forEach((section) => {
    personalSections = {
      ...personalSections,
      [section.id]: section
    };
  });
  return personalSections;
};

const updateSections = (newSections, stateSections) => {
  if (stateSections.length === 0) return newSections;
  const updatedArray = stateSections.map((currentSection) => {
    const newSection = newSections.find((s) => (s || {}).id === (currentSection || {}).id);
    return newSection || currentSection;
  });
  return updatedArray;
};

const updateLoadingOnSections = (sectionsIds, stateSections) => {
  if (sectionsIds.length === 0) return stateSections;
  const updatedArray = stateSections.map((currentSection) => {
    if (sectionsIds.includes((currentSection || {}).id)) {
      currentSection.loading = true; //eslint-disable-line
    }
    return currentSection;
  });
  return updatedArray;
};

const handler = {
  [Const.homePage.loading]: state => ({
    ...state,
    loading: true
  }),
  [Const.homePage.sectionsloading]: (state, { sectionsIds }) => ({
    ...state,
    sections: updateLoadingOnSections(sectionsIds, state.sections || [])
  }),
  [Const.homePage.error]: (state, { error }) => ({
    ...state,
    error
  }),
  [Const.homePage.specialities]: (state, { payload: { specialities } }) => ({
    ...state,
    // loading: false,
    error: {},
    specialities: [...updateSlugIfNeed({ item: specialities })]
  }),
  [Const.homePage.salesBanner]: (state, { payload: { salesBanner } }) => ({
    ...state,
    // loading: false,
    error: {},
    salesBanner
  }),
  [Const.homePage.loaded]: (state, { sections, fullReload }) => {
    const filterSection = sections.find((section) => section && section.id === sectionIds.filters);
    const personalSectionsArray = sections.filter(
      (section) => section && section.type === sectionTypes.personal && section.content
    );
    return ({
      ...state,
      loading: false,
      error: null,
      filters: filterSection,
      sections: fullReload ? updateSlugIfNeed({ item: sections }) : updateSlugIfNeed({ item: updateSections(sections, state.sections || []) }),
      personalSections: formatPersonalSections(personalSectionsArray)
    });
  }
};

export default createReducer(defaultState, handler);
