/* eslint-disable max-len */
import React from 'react';

export const freeMinIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.49199 1.23047C4.63645 1.23325 3.15899 1.28434 2.22166 2.22166C1.28434 3.15899 1.23325 4.63645 1.23047 7.492H4.55662C4.31903 7.19523 4.14151 6.84518 4.04471 6.45798C3.68035 5.00052 5.00052 3.68035 6.45798 4.04471C6.84518 4.14151 7.19523 4.31903 7.49199 4.55662V1.23047Z" fill={ color } />
    <path d="M1.23047 8.5074C1.23325 11.3629 1.28434 12.8404 2.22166 13.7777C3.15899 14.7151 4.63645 14.7661 7.49199 14.7689V9.43728C6.96423 10.4914 5.87413 11.2152 4.615 11.2152C4.33461 11.2152 4.1073 10.9879 4.1073 10.7075C4.1073 10.4271 4.33461 10.1998 4.615 10.1998C5.65532 10.1998 6.52699 9.47769 6.75619 8.5074H1.23047Z" fill={ color } />
    <path d="M8.5074 14.7689C11.3629 14.7661 12.8404 14.7151 13.7777 13.7777C14.7151 12.8404 14.7661 11.3629 14.7689 8.5074H9.24321C9.47241 9.47769 10.3441 10.1998 11.3844 10.1998C11.6648 10.1998 11.8921 10.4271 11.8921 10.7075C11.8921 10.9879 11.6648 11.2152 11.3844 11.2152C10.1253 11.2152 9.03517 10.4914 8.5074 9.43728V14.7689Z" fill={ color } />
    <path d="M14.7689 7.492C14.7661 4.63645 14.7151 3.15899 13.7777 2.22166C12.8404 1.28434 11.3629 1.23325 8.5074 1.23047V4.55662C8.80417 4.31903 9.15422 4.14151 9.54142 4.04471C10.9989 3.68035 12.319 5.00052 11.9547 6.45798C11.8579 6.84518 11.6804 7.19523 11.4428 7.492H14.7689Z" fill={ color } />
    <path d="M6.21171 5.0298C6.96414 5.21791 7.49199 5.89398 7.49199 6.66957V7.492H6.66957C5.89398 7.492 5.21791 6.96414 5.0298 6.21171C4.85136 5.49791 5.49791 4.85136 6.21171 5.0298Z" fill={ color } />
    <path d="M8.5074 6.66957V7.492H9.32983C10.1054 7.492 10.7815 6.96414 10.9696 6.21171C11.148 5.49791 10.5015 4.85136 9.78769 5.0298C9.03526 5.21791 8.5074 5.89398 8.5074 6.66957Z" fill={ color } />
  </svg>
);
