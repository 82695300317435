import React from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';

const leftImage = require('../../../img/slider_arrow_left.svg');
const rightImage = require('../../../img/slider_arrow_right.svg');

function Handle({ handle:{ id, percent }, getHandleProps, isLeft }) {
  return (
    <div
      className={ isLeft ? classes.sliderLeftHandle : classes.sliderRigthHandle }
      style={ {
        left: `${ percent }%`
      } }
    >
      <img
        alt=""
        src={ isLeft ? leftImage : rightImage }
        { ...getHandleProps(id) }
        className={ classes.sliderArrowImg }
      />

    </div>
  );
}
Handle.propTypes = {
  handle: PropTypes.object.isRequired,
  getHandleProps: PropTypes.func.isRequired,
  isLeft: PropTypes.bool.isRequired
};

export default Handle;
