import { promoCodeAPI } from '../config/api';
import { toCamelCase } from '../../common/config/utils';
import { log } from '../config/app_logger';
import { trackRedeemPromoFailedEvent, trackRedeemPromoEvent } from './analytics';
import { getUser } from './user';
import Const from '../config/const';

const promoCodeLoading = () => ({
  type: Const.promoCode.loading
});

const promoError = (error) => ({
  type: Const.promoCode.error,
  error
});

const promoSuccess = (creditText) => ({
  type: Const.promoCode.success,
  creditText
});

export const clearModalText = () => ({
  type: Const.promoCode.clearModalText
});

export const applyCode = (code, clickSource) => (dispatch) => {
  dispatch(promoCodeLoading());
  promoCodeAPI(code)
    .then((res) =>  {
      dispatch(getUser());
      dispatch(promoSuccess(res.creditText));
      trackRedeemPromoEvent(res, code, clickSource, dispatch);
    })
    .catch((error) => error.json()
      .then((json) => {
        const fJson = toCamelCase(json);
        if (fJson.fullMessages && fJson.fullMessages.length > 0) {
          dispatch(promoError(fJson.fullMessages[0]));
          trackRedeemPromoFailedEvent(fJson.fullMessages[0], code, error, clickSource, dispatch);
          return;
        }
        if (fJson?.errors?.promoCodeId) {
          dispatch(promoError(fJson?.errors?.promoCodeId[0]));
          trackRedeemPromoFailedEvent(fJson?.errors?.promoCodeId, code, error, clickSource, dispatch);
        }
      })
      .catch((toJsonError) => log('toJsonError!', toJsonError)));
};
