import { connect } from 'react-redux';
import TopIcons from './top_icons';
import { showGeneralPopup } from '../../../actions/general_popup';
import { authPendingAction, displayRegister } from '../../../actions/auth';
import { favoriteAdvisor } from '../../../actions/favorite_advisors';
import { displayNotifyMe } from '../../../actions/notify_me';
import { showPopover } from '../../../actions/popover';
import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = (
  { favoriteAdvisors: { loading } }
) => ({
  favoriteLoading: loading
});

export default connect(
  mapStateToProps,
  {
    showGeneralPopup,
    favoriteAdvisor,
    authPendingAction,
    displayRegister,
    displayNotifyMe,
    showPopover
  }
)(withPGTranslation(TopIcons));
