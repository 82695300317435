import Const from '../config/const';

export const showPopover = (kind, payload) => (dispatch) => {
  dispatch({
    type: Const.popover.show,
    kind,
    payload
  });
};

export const dissmissPopover = () => (dispatch) => {
  dispatch({
    type: Const.popover.hide
  });
};
