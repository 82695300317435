import { connect } from 'react-redux';
import PayPalProcessing from './pay_pal_processing';
import { getUser } from '../../../actions/user';
import { cancelChat } from '../../../actions/chat';
import { cancelChatOnPP } from '../../../actions/connected_chat';
import { cancelVoipChatOnPP, cancelVoipChat } from '../../../actions/voip_chat';
import { hidePaymentDetails } from '../../../actions/funds';
import { loadAdvisor } from '../../../actions/advisors';
import { hideAffiliatePromoPopup } from '../../../actions/affiliate_promo_popup';
import Const from '../../../config/const';

import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = ({
  user: { user, loading },
  purchaseDetails: { advisorId, fees }
}) => ({
  ...user,
  userLoading: loading,
  advisorId,
  fees
});

const defaultFunctions = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  hidePaymentDetails: () => dispatch(hidePaymentDetails()),
  loadAdvisor: (advisorId) => dispatch(loadAdvisor(advisorId)),
  dispatch: (func) => dispatch(func)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(cancelChat()),
        cancelChatOnPP: () => dispatch(cancelChatOnPP(ownProps.isContinue)),
        hideAffiliatePromoPopup: () => dispatch(hideAffiliatePromoPopup()),
        ...defaultFunctions(dispatch)
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(cancelVoipChat()),
        cancelChatOnPP: () => dispatch(cancelVoipChatOnPP(ownProps.isContinue)),
        ...defaultFunctions(dispatch)
      });
    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withPGTranslation(PayPalProcessing));
