import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectChatCredit from '../chat_overlay/select_chat_credit';
import NotInLiveMode from '../chat_overlay/not_in_live_mode';
import CantCreate from '../chat_overlay/cant_create';
import CreatedVoipChat from '../created_voip_chat';
import GeneralError from '../chat_overlay/general_error';
import Constants from '../../config/const';
import IntroComponent from '../intro';
import PurchaseDetails from '../chat_overlay/purchase_details';
import NoAnswer from '../chat_overlay/no_answer';
import AppGeneralPopup from '../../../common/app_general_popup';
import classes from './classes.module.scss';
import { log } from '../../config/app_logger';
import Spiner from '../../../common/helpers/spiner';
import { changeAppStateAPI } from '../../config/api';

const { shared } = window;

function VoipChatOverlay({
  voipState, purchaseOnBack, cancelChat, voipType, continuedSession, orderId
}) {
  let content = null;

  useEffect(() => {
    const { onbeforeunload, onunload } = window;
    window.onbeforeunload = () => {
      log('VOIP Chat', 'Page is refreshed');
      return 'Test message';
    };

    window.onunload = () => {
      changeAppStateAPI(orderId, 'closed_window');
    };

    return () => {
      window.onbeforeunload = onbeforeunload;
      window.onunload = onunload;
    };
  }, [orderId]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  switch (voipState) {
    case Constants.voipChatStates.showSpin:
      log('VOIP CHAT', 'state showSpin');
      content = (
        <div className={ classes.callingScreen }>
          <Spiner loaderColor={ shared.appColor } />
        </div>
      );
      break;
    case Constants.voipChatStates.paymentRequired:
      log('VOIP CHAT', 'state paymentRequired');
      content = (
        <AppGeneralPopup>
          <PurchaseDetails
            chatType={ voipType }
            backArrowAction={ purchaseOnBack }
          />
        </AppGeneralPopup>
      );
      break;
    case Constants.voipChatStates.initial:
      log('VOIP CHAT', 'state appInitial');
      content = (
        <AppGeneralPopup>
          <SelectChatCredit
            chatType={ voipType }
            isContinue={ continuedSession > 0 }
            selectorOpenedTime={ new Date() }
          />
        </AppGeneralPopup>
      );
      break;
    case Constants.voipChatStates.introduce:
      log('VOIP CHAT', 'state introduce');
      content = (
        <div className={ classes.callingScreen }>
          <IntroComponent chatType={ voipType } closeButtonAction={ cancelChat } />
        </div>
      );
      break;
    case Constants.voipChatStates.noAnswer:
      log('VOIP CHAT', 'state noAnswer');
      content = (
        <div className={ classes.callingScreen }>
          <NoAnswer chatType={ voipType } />
        </div>
      );
      break;
    case Constants.voipChatStates.notInLiveMode:
      log('VOIP CHAT', 'state appNotInLiveMode');
      content = (
        <div className={ classes.callingScreen }>
          <NotInLiveMode chatType={ voipType } />
        </div>
      );
      break;
    case Constants.voipChatStates.appCantCreate:
      log('VOIP CHAT', 'state appCantCreate');
      content = (
        <div className={ classes.callingScreen }>
          <CantCreate chatType={ voipType } />
        </div>
      );
      break;
    case Constants.voipChatStates.unknownError:
      log('VOIP CHAT', 'state generalError || unknownError');
      content = (
        <div className={ classes.callingScreen }>
          <GeneralError chatType={ voipType } />
        </div>
      );
      break;
    default:
      // once we have an id, it means that CreatedChat will take care of the rendering
      log('VOIP CHAT', 'created');
      content = <CreatedVoipChat />;
      break;
  }

  return <div className={ classes.layout }>{content}</div>;
}

VoipChatOverlay.propTypes = {
  voipState: PropTypes.string.isRequired,
  error: PropTypes.object,
  purchaseOnBack: PropTypes.func.isRequired,
  cancelChat: PropTypes.func.isRequired,
  voipType: PropTypes.string,
  continuedSession: PropTypes.number.isRequired,
  orderId: PropTypes.number
};

VoipChatOverlay.defaultProps = {
  error: null,
  voipType: null,
  orderId: null
};

export default VoipChatOverlay;
