import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import PurchaseDetails from '../chat_overlay/purchase_details';
import Const from '../../config/const';
import { isPrerenderMode } from '../../config/util';

const noNeedRenderAffiliatePromoItem = ({ showItem, noThanksLinkClicked }) => {
  const noNeed = !showItem || noThanksLinkClicked || isPrerenderMode();
  return noNeed;
};

const affiliatePromoPopupCloseButtonAction = (noNeedCloseIcon, hideAffiliatePromoPopup) => {
  if (noNeedCloseIcon) return null;
  return hideAffiliatePromoPopup;
};

function AffiliatePromoPopup({
  showAffiliatePromoPopup, hideAffiliatePromoPopup, noNeedCloseIcon,
  chatType, needUpdatePricingOptions, noThanksLinkClicked
}) {
  const [needShowAffiliatePromoPopup, setNeedShowAffiliatePromoPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => setNeedShowAffiliatePromoPopup(true), 1000);
  }, []);

  const renderAffiliatePromoPopup = () => {
    const noNeed = noNeedRenderAffiliatePromoItem({
      showItem: showAffiliatePromoPopup, noThanksLinkClicked
    });
    return (
      <AppGeneralPopup
        visible={ !noNeed }
        closeButtonAction={
            affiliatePromoPopupCloseButtonAction(noNeedCloseIcon, hideAffiliatePromoPopup)
          }
        theme={ appGeneralPopupTheme.AFFILIATE_PROMO }
      >
        <PurchaseDetails
          chatType={ chatType }
          hidePaymentDetails = {
            affiliatePromoPopupCloseButtonAction(noNeedCloseIcon, hideAffiliatePromoPopup)
          }
          needUpdatePricingOptions={ needUpdatePricingOptions }
        />
      </AppGeneralPopup>
    );
  };

  const renderAffiliatePromoPopupContent = () => {
    if (!needShowAffiliatePromoPopup && isPrerenderMode()) return null;
    return (
      <>
        { renderAffiliatePromoPopup() }
      </>
    );
  };

  return renderAffiliatePromoPopupContent();
}

AffiliatePromoPopup.propTypes = {
  showAffiliatePromoPopup: PropTypes.bool.isRequired,
  hideAffiliatePromoPopup: PropTypes.func.isRequired,
  noNeedCloseIcon: PropTypes.bool,
  chatType: PropTypes.string,
  needUpdatePricingOptions: PropTypes.bool,
  noThanksLinkClicked: PropTypes.bool.isRequired
};

AffiliatePromoPopup.defaultProps = {
  noNeedCloseIcon: false,
  chatType: Const.chatType.text,
  needUpdatePricingOptions: false
};

export default AffiliatePromoPopup;
