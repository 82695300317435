/* eslint-disable import/no-cycle */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../menu_item';
import { renderSideMenuIcon, requireContext, isMenuItemActive } from '../side_menu';
import { specialitiesClickSource } from '../../../config/const';
import { localizedCategories } from '../../../config/app_routes';
import classes from './classes.module.scss';
import { isKA, getIcon } from '../../../../common/config/utils';

const sideMenuArrow = (iconToUse) => (
  <img
    src={ getIcon({ iconName: iconToUse, requireContext }) }
    alt=""
    className={ isKA() ? classes.specialitiesArrowKa : classes.specialitiesArrow }
  />
);

const renderSideMenuSpecialitiesArrow = (specialitiesShow) => {
  const iconName = isKA() ? 'specialities_ka_arrow' : 'specialities_arrow';
  const iconToUse = specialitiesShow ? `${ iconName }_top` : `${ iconName }_down`;
  return (
    <div className={ isKA() ? classes.specialitiesArrowBtn : null }>
      {sideMenuArrow(iconToUse)}
    </div>
  );
};

const getSpecialitiesStyle = () => {
  if (isKA()) return { width: '24px', height: '24px' };
  return {};
};

const getNeedChekActive = () => {
  if (isKA()) return false;
  return true;
};

const renderSpecialities = (specialities) => {
  if (!specialities) return null;
  return (
    specialities.map((s) => (
      <MenuItem
        key={ s.id + s.name }
        title={ { text: s.name, active: isMenuItemActive(s.slug) } }
        icon={ renderSideMenuIcon({
          iconName: s.iconName, path: s.slug, needChekActive: getNeedChekActive(), style: getSpecialitiesStyle()
        }) }
        linkTo={ {
          pathname: s.slug,
          state: {
            needRefresh: true,
            trackCategoryEvent: true,
            clickSource: specialitiesClickSource.menu,
            params: s.params
          }
        } }
      />
    ))
  );
};

function SpecialitiesMenuItem({ specialities, t }) {
  const [specialitiesShow, setSpecialitiesShow] = useState(
    localizedCategories.includes(window.location.pathname)
  );

  const userLoggedIn = window.currentUserId;

  const showSpecialities = () => {
    setSpecialitiesShow(!specialitiesShow);
  };

  const renderSpecialitiesMenuItem = () => {
    if (userLoggedIn) {
      return (
        <>
          <MenuItem
            title={ { text: t('sideMenu.specialities') } }
            icon={ renderSideMenuIcon({ iconName: 'specialities_menu_icon', path: ' ' }) }
            onClick={ showSpecialities }
            extraNode={ renderSideMenuSpecialitiesArrow(specialitiesShow) }
          />
          { specialitiesShow ? (
            <div className={ classes.specialitiesContainer }>
              {renderSpecialities(specialities)}
            </div>
          ) : null }
        </>
      );
    }
    return renderSpecialities(specialities);
  };

  return renderSpecialitiesMenuItem();
}

SpecialitiesMenuItem.propTypes = {
  t: PropTypes.func.isRequired,
  specialities: PropTypes.array
};

SpecialitiesMenuItem.defaultProps = {
  specialities: null
};

export default SpecialitiesMenuItem;
