import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  authPendingAction: null,
  authPendingCloseAction: null,
  authComplete: false,
  visible: false,
  signUpFlow: 'menu',
  currentScreen: Const.authScreens.signIn,
  screenStack: [],
  displayGDPRPopup: false,
  consentedToGDPR: false,
  GDPRConsernPopupVisible: {},
  socialOriginOnClick: null
};

const updateScreenStack = (screenStack, currentScreen) => {
  if (screenStack.includes(currentScreen)) {
    const stateStack = [...screenStack];
    stateStack.splice(Math.abs(screenStack.indexOf(currentScreen)), 1);
    return [...stateStack, currentScreen];
  }
  return [...screenStack, currentScreen];
};
const removeScreenFromStack = (screenStack) => {
  const newStateStack = [...screenStack];
  newStateStack.pop();
  return [...newStateStack];
};
const handler = {
  [Const.auth.loading]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [Const.auth.error]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error
  }),
  [Const.auth.success]: (state)   => ({
    ...state,
    loading: false,
    error: null,
    // currentScreen: justSignedUp ? Const.authScreens.promoRead : Const.authScreens.signIn,
    // visible: justSignedUp,
    visible: false,
    // authComplete: !justSignedUp
    authComplete: true
  }),
  [Const.auth.clearError]: state => ({
    ...state,
    error: null
  }),
  [Const.auth.pendingAction]: (state, { payload: { pendingAction } }) => ({
    ...state,
    authPendingAction: pendingAction
  }),
  [Const.auth.clearPendingAction]: state => ({
    ...state,
    authPendingAction: null,
    authComplete: false
  }),
  [Const.auth.pendingCloseAction]: (state, { payload: { pendingCloseAction } }) => ({
    ...state,
    authPendingCloseAction: pendingCloseAction
  }),
  [Const.auth.clearPendingCloseAction]: state => ({
    ...state,
    authPendingCloseAction: null
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState,
    visible: true,
    signUpFlow: 'after sign out',
    currentScreen: Const.authScreens.signIn
  }),
  [Const.auth.displayRegister]: (state, { shouldDisplay, signUpFlow, currentScreen }) => ({
    ...state,
    authComplete: state.currentScreen === Const.authScreens.promoRead,
    visible: shouldDisplay,
    signUpFlow,
    currentScreen
  }),
  [Const.auth.screenChange]: (state, { nextScreen }) => ({
    ...state,
    currentScreen:nextScreen,
    screenStack:updateScreenStack(state.screenStack, state.currentScreen)
  }),
  [Const.auth.updateCurrentScreen]: (state, { newScreen }) => ({
    ...state,
    currentScreen: newScreen
  }),
  [Const.auth.navigationPop]: (state) => ({
    ...state,
    currentScreen:state.screenStack[state.screenStack.length - 1],
    screenStack: removeScreenFromStack(state.screenStack)
  }),
  [Const.auth.showGDPRConsernPopup]: (state, { display, socialParams }) => ({
    ...state,
    GDPRConsernPopupVisible: display,
    socialParams,
    loading: false
  }),
  [Const.auth.consentToGDPRChanged]: (state, { value }) => ({
    ...state,
    consentedToGDPR: value
  })
};

export default createReducer(defaultState, handler);
