import Const from '../config/const';
import { doneManagingPaymentSource } from './payment_sources';
import { changingPaymentSource, updatePurchaseDetails, clearPurchaseDetails } from './purchase_details';
import { getUser } from './user';
import { updatePricingOptions as chatUpdatePricingOptions } from './connected_chat';
import { updatePricingOptionsOnContinueIfNeed as voipChatUpdatePricingOptions } from './voip_chat';
import { getAddCreditUrl } from '../../common/config/utils';

export const showAffiliatePromoPopup = (show, additionParams) => ({
  type: Const.affiliatePromoPopup.displayDetails,
  display: show,
  additionParams
});

export const paymentSelected = (details, type) => (dispatch) => {
  dispatch(updatePurchaseDetails({
    details,
    clickSource: 'affiliate promo',
    purchaseType: type
  }));
};

export const hideAffiliatePromoPopup = () => (dispatch) => {
  dispatch(showAffiliatePromoPopup(false));
  dispatch(doneManagingPaymentSource());
  dispatch(changingPaymentSource(false));
  dispatch(clearPurchaseDetails());
};

export const purchaseSuccess = (chatType, needUpdatePricingOptions) => (dispatch, getState) => {
  dispatch(showAffiliatePromoPopup(false));
  dispatch(getUser());
  if (needUpdatePricingOptions) {
    switch (chatType) {
      case Const.chatType.text:
        chatUpdatePricingOptions(false, dispatch, getState);
        break;
      case Const.chatType.voice:
      case Const.chatType.video:
        dispatch(voipChatUpdatePricingOptions(false));
        break;
      default:
        break;
    }
  }
};

export const showAffiliatePromoPopupAfterPromo = () => (dispatch, getState) => {
  const { arg, purchaseType } = getState().affiliatePromoPopup;
  dispatch(showAffiliatePromoPopup(true));
  const {
    price, url, bonus, expiresAt
  } = arg;
  dispatch(paymentSelected({
    price, url, bonus, expiresAt
  }, purchaseType));
};

const displayAffiliatePromoPopup = (arg, type, additionParams) => (dispatch) => {
  const {
    price, url, bonus, expiresAt
  } = arg;
  dispatch(showAffiliatePromoPopup(true, additionParams));
  dispatch(paymentSelected({
    price, url, bonus, expiresAt
  }, type));
};

export const displayAffiliatePromoPopupAction = (creditMatchingInfo, type, additionParams) => (dispatch) => {
  const url = getAddCreditUrl({ credit: creditMatchingInfo.requiredPurchasesAmount });
  const arg = {
    price: parseFloat(creditMatchingInfo.requiredPurchasesAmount),
    url,
    bonus: parseFloat(creditMatchingInfo.bonus),
    expiresAt: creditMatchingInfo.expiresAt
  };
  dispatch(displayAffiliatePromoPopup(arg, type, additionParams));
};

export const clearAffiliatePromoPopup = () => (dispatch) => {
  dispatch({ type: Const.affiliatePromoPopup.clear });
};

export const noThanksLinkClickedAction = () => (dispatch) => {
  dispatch({
    type: Const.affiliatePromoPopup.noThanksLinkClicked,
    noThanksLinkClicked: true
  });
};
