import { connect } from 'react-redux';
import AffiliatePromoPopup from './affiliate_promo_popup';
import withPGTranslation from '../../config/withPGTranslation';
import { hideAffiliatePromoPopup } from '../../actions/affiliate_promo_popup';

const mapStateToProps = ({ affiliatePromoPopup }) => affiliatePromoPopup;
export default connect(
  mapStateToProps,
  {
    hideAffiliatePromoPopup
  }
)(withPGTranslation(AffiliatePromoPopup));
