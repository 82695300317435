import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './classes.module.scss';
import  withPGTranslation  from '../../config/withPGTranslation';
import { onHeaderSearchClick, renderItemPropMeta } from '../../config/util';

const whiteSearchIcon = require('./img/searh_icon_white.svg');
const searchIcon = require('./img/search_icon.svg');

function SearchBar({
  t, itemProp
}) {
  const history = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef();
  const itemPropSearch = (itemProp || {}).search || {};
  const onSearch = () => {
    onHeaderSearchClick({
      searchInputRef, history, location
    });
  };

  const onSearchKeyDown = (e) => {
    if (e.key === 'Enter') onSearch();
    document.getElementById('id');
  };

  return (
    <div
      className={ classes.searchContainer }
      itemProp={ itemPropSearch.itemProp }
      itemScope={ itemPropSearch.itemScope }
      itemType={ itemPropSearch.itemType }
    >
      <div className={ classes.searchInput }>
        <div className={ classes.inputSearchIconContainer }>
          <img className={ classes.inputSearchIcon } src ={ searchIcon } alt="" />
        </div>
        { renderItemPropMeta(itemPropSearch) }
        <input
          type="text"
          ref={ searchInputRef }
          className={ classes.searchField }
          onKeyDown={ onSearchKeyDown }
          placeholder={ t('search_placeholder') }
          itemProp={ (itemPropSearch.input || {}).itemProp }
          name={ (itemPropSearch.input || {}).name }
          required={ (itemPropSearch.input || {}).required }
        />
      </div>
      <button type="button" onClick={ onSearch } className={ classes.searchButton }>
        <div className={ classes.searchButtonTitle }>
          {t('search_button_title')}
        </div>
        <img className={ classes.searchButtonIcon } src={ whiteSearchIcon } alt="" />
      </button>
    </div>
  );
}

SearchBar.propTypes = {
  t:PropTypes.func.isRequired,
  itemProp: PropTypes.object
};

SearchBar.defaultProps = {
  itemProp: null
};

export default withPGTranslation(SearchBar);
