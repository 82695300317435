/* eslint-disable max-len */
import React from 'react';
import classes from '../classes.module.scss';
import { getModeColor } from '../../../../../config/util';

export const voiceOnline = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.modeIcon }>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3984 16.318C13.3175 16.318 13.2367 16.3146 13.1522 16.3112C12.1569 16.2465 11.1615 15.9691 9.91999 15.4108C7.72623 14.3947 5.81455 12.9768 4.23167 11.193C2.9668 9.75985 2.0415 8.29605 1.48273 6.84075C1.20425 6.11396 0.931151 5.24419 1.0156 4.2825C1.04434 3.67656 1.31744 3.11827 1.80434 2.68764L3.03867 1.51659C3.76273 0.830648 4.75451 0.827244 5.50553 1.50979C5.7373 1.71233 5.96368 1.93361 6.18287 2.14637C6.29247 2.2519 6.40207 2.35913 6.51526 2.46636L7.49266 3.3923C8.24188 4.13272 8.24188 5.04334 7.49985 5.77694C7.28424 5.98119 7.07404 6.17353 6.86562 6.36587C6.68775 6.52927 6.50987 6.69267 6.3338 6.85948C6.54581 7.31223 6.85664 7.76499 7.32917 8.31137C8.31734 9.45007 9.27318 10.2722 10.3368 10.8969L10.3602 10.9105C10.4931 10.999 10.6566 11.0756 10.8291 11.1573C10.8758 11.1794 10.9243 11.2015 10.9711 11.2254L12.1281 10.1292C12.4857 9.79049 12.9133 9.61177 13.366 9.61177C13.8188 9.61177 14.2464 9.79049 14.604 10.1292L16.6073 12.027C17.3547 12.7351 17.3547 13.6866 16.6073 14.3947C16.4761 14.5189 16.3432 14.6415 16.2138 14.7623C15.9533 15.004 15.7071 15.2321 15.47 15.4721C14.9543 16.0304 14.2554 16.318 13.3984 16.318Z" stroke={ getModeColor('voice') } strokeWidth="1.5" />
  </svg>
);
