import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { rateReviewTheme } from '../../config/const';

const sadImage = require('./img/chat_rate_sad_face.png');
const sadImagePressed = require('./img/chat_rate_sad_face_pressed.png');

const happyImage = require('./img/chat_rate_happy_face.png');
const happyImagePressed = require('./img/chat_rate_happy_face_pressed.png');

const convertValue = value => {
  if (value === true) {
    return 1;
  } if (value === false) {
    return 0;
  }
  return 2;
};

function FeedbackSwitcher({ value, onValueChanged, theme }) {
  return (
    <div className={ classes.feedbackSwitcherContainer }>
      <div>
        <button
          type="button"
          onClick={ () => {
            onValueChanged(false);
          } }
          className={ classes[`negativeFeedbackButton${ theme }`] }
          style={ { backgroundColor: convertValue(value) === 0 ? '#ff5050' : '#ffffff00' } }
        >
          <img
            alt=""
            src={ convertValue(value) === 0 ? sadImagePressed : sadImage }
            className={ classes[`smileyIcon${ theme }`] }
          />
        </button>
      </div>
      <div>
        <button
          type="button"
          onClick={ () => {
            onValueChanged(true);
          } }
          className={ classes[`positiveFeedbackButton${ theme }`] }
          style={ { backgroundColor: convertValue(value) === 1 ? '#58be00' : '#ffffff00' } }
        >
          <img
            alt=""
            src={ convertValue(value) === 1 ? happyImagePressed : happyImage }
            className={ classes[`smileyIcon${ theme }`] }
          />
        </button>
      </div>
    </div>
  );
}

FeedbackSwitcher.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  value: PropTypes.bool,
  theme: PropTypes.string
};

FeedbackSwitcher.defaultProps = {
  value: null,
  theme: rateReviewTheme.DEFAULT
};

export default FeedbackSwitcher;
