import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { LANGUAGE, SUPPORTED_LANGUAGE } from '../../config/i18n';
import { log } from '../../config/app_logger';
import { updateLastHit } from '../../actions/mixpanel';
import { getRedirectPath } from './routes_utils';
import { setAplicationError } from '../../actions/aplication_error';

const supportedLanguages = Object.keys(SUPPORTED_LANGUAGE).map((key) => SUPPORTED_LANGUAGE[key]);
const allowedLanguage = Object.keys(LANGUAGE).map((key) => LANGUAGE[key]);

const checkLanguage = ({ language }) => {
  if (!supportedLanguages.includes(language)) language = null;
  return language;
};

const redirectIfNeed = ({ search, language }) => {
  if (language) {
    const redirectPath = getRedirectPath({ newLanguage: language });
    if (redirectPath) {
      return <Navigate to={ { pathname: redirectPath, search } } />;
    }
  }
  return null;
};

const checkMissingLanguage = ({ language, pathname }) => {
  if (language
    && !allowedLanguage.includes(language)
    && pathname !== '/') {
    log('Navigation', `missing language ${ language }`);
    window.store.dispatch(setAplicationError({ errorCode: 404 }));
  }
  return null;
};

function LocalizedRouter({ children }) {
  const path = '/:language/*';
  const location = useLocation();
  const match = useMatch(`${ path }`, { end: true });
  const params = match ? match.params : {};
  let { language } = params;
  const {
    pathname, search
  } = location;
  updateLastHit();
  log('Navigation', `on route changed path: ${ pathname } params: ${ search }`);

  language = checkLanguage({ language });

  const redirect = redirectIfNeed({ search, language });
  if (redirect) return redirect;

  const missingLanguage = checkMissingLanguage({ language, pathname });
  if (missingLanguage) return missingLanguage;

  return children;
}

LocalizedRouter.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(LocalizedRouter);
