import { useState, useEffect } from 'react';

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (!window.IntersectionObserver) { return () => {}; }

    const observer = new IntersectionObserver(([entry]) => {
      setState(entry.isIntersecting);
    }, { rootMargin });

    if (element.current) { observer.observe(element.current); }

    return () => observer.disconnect(element.current);
  }, [element.current, rootMargin]);

  return isVisible;
};
