import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Const from '../../config/const';
import withPGTranslation from '../../config/withPGTranslation';
import classesModule from './classes.module.scss';
import TimerLabel from './timer_label';
import {
  hangupVoipChat, setEnableMic, setEnableVideo
} from '../../actions/voip_chat';
import { trackEvent } from '../../actions/mixpanel';
import Switch from '../../../common/helpers/switch';

const kMuteActive = require('./img/mic_active.png');
const kMuteNotActive = require('./img/mic_non_active.png');

const kHangupIc = require('./img/ic_phone.png');

const cameraNotActive = require('./img/camera_non_active.svg');
const cameraActive = require('./img/camera_active.svg');
const micNotActive = require('./img/mic_non_active.svg');
const micActive = require('./img/mic_active.svg');

const renderVoiceMuteButton = (micEnabled, setMicEnabled) => {
  const muteIcon = micEnabled ? kMuteNotActive : kMuteActive;
  const muteClassName = micEnabled
    ? classesModule.muteNotActiveButton : classesModule.muteActiveButton;
  return (
    <div>
      <button
        type="button"
        onClick={ () => setMicEnabled(!micEnabled) }
        className={ muteClassName }
      >
        <img
          className={ classesModule.muteIcon }
          src={ muteIcon }
          alt=""
        />
      </button>
    </div>
  );
};

const renderVideoSwitcher = (
  micEnabled,
  videoEnabled,
  setMicEnabled,
  setVideoEnabled,
  trackMPEvent,
  advisorId,
  orderId
) => (
  <div>
    <div style={ { marginBottom: 10, marginTop: 2 } }>
      <Switch
        id="voipCameraSwitcher"
        checked={ videoEnabled }
        onChange={ () => {
          setVideoEnabled(!videoEnabled);
          if (!videoEnabled) {
            trackMPEvent({
              'advisor id': advisorId,
              'order id': orderId
            });
          }
        } }
        offColor="rgb(255, 255, 255, 0.16)"
        onColor="rgb(255, 255, 255, 0.16)"
        offSliderImg={ cameraNotActive }
        onSliderImg={ cameraActive }
        offSliderColor="rgb(255, 255, 255, 0.16)"
        onSliderColor="#16C5F0"
        width={ 50 }
        height={ 25 }
        handleDiameter={ 22 }
      />
    </div>
    <div>
      <Switch
        id="voipMicSwitcher"
        checked={ micEnabled }
        onChange={ () => setMicEnabled(!micEnabled) }
        offColor="rgb(255, 255, 255, 0.16)"
        onColor="rgb(255, 255, 255, 0.16)"
        offSliderImg={ micNotActive }
        onSliderImg={ micActive }
        offSliderColor="rgb(255, 255, 255, 0.16)"
        onSliderColor="#16C5F0"
        width={ 50 }
        height={ 25 }
        handleDiameter={ 22 }
      />
    </div>
  </div>
);

const renderReconnectLabel = (reconnecting) => {
  if (!reconnecting) return null;
  return (
    <div className={ classesModule.reconnectLabel }>
      <div className={ classesModule.timerContainer }>
        <div className={ classesModule.setupLabel }>Trying to reconnect...</div>
      </div>
    </div>
  );
};

const renderHangupButton = (hangupCall) => (
  <div>
    <button
      type="button"
      onClick={ hangupCall }
      className={ classesModule.hangupButton }
    >
      <img
        className={ classesModule.hangupIcon }
        src={ kHangupIc }
        alt=""
      />
    </button>
  </div>
);

function VoipControls({
  voipType, paidDuration, blinkSetup,
  callDurationNow, t, hangupChat, trackMPEvent,
  advisorId, orderId, enableMic, enableVideo, toggleMic,
  toggleVideo, reconnecting, voipState, buyerDurationCounterDirection
}) {
  const switcherContent = voipType === Const.chatType.video
    ? renderVideoSwitcher(
      enableMic,
      enableVideo,
      toggleMic,
      toggleVideo,
      trackMPEvent,
      advisorId,
      orderId
    ) : renderVoiceMuteButton(enableMic, toggleMic);
  return (
    <div className={ classesModule.controlsContainer }>
      { renderReconnectLabel(reconnecting) }
      <div className={ classesModule.controlsHolder }>
        { renderHangupButton(hangupChat) }
        { switcherContent }
      </div>
      <TimerLabel
        callDurationNow={ callDurationNow }
        paidDuration={ paidDuration }
        blinkSetup={ blinkSetup }
        t={ t }
        voipState={ voipState }
        buyerDurationCounterDirection={ buyerDurationCounterDirection }
      />
    </div>
  );
}

VoipControls.propTypes = {
  voipType: PropTypes.string.isRequired,
  paidDuration: PropTypes.number,
  blinkSetup: PropTypes.bool.isRequired,
  callDurationNow: PropTypes.number,
  t: PropTypes.func.isRequired,
  hangupChat: PropTypes.func.isRequired,
  trackMPEvent: PropTypes.func.isRequired,
  advisorId: PropTypes.number.isRequired,
  orderId: PropTypes.number.isRequired,
  enableMic: PropTypes.bool.isRequired,
  enableVideo: PropTypes.bool.isRequired,
  toggleMic: PropTypes.func.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  reconnecting: PropTypes.bool.isRequired,
  voipState: PropTypes.string.isRequired,
  buyerDurationCounterDirection: PropTypes.string.isRequired,
  setupTimeLabels: PropTypes.array.isRequired,
  notifyDate: PropTypes.string
};

VoipControls.defaultProps = {
  paidDuration: null,
  callDurationNow: null,
  notifyDate: null
};

const mapStateToProps = ({ voipChat }) => ({ ...voipChat, ...voipChat.conversationOption });

const mapDispatchToProps = (dispatch) => ({
  hangupChat: () => dispatch(hangupVoipChat()),
  toggleMic: (enable) => dispatch(setEnableMic(enable)),
  toggleVideo: (enable) => dispatch(setEnableVideo(enable)),
  trackMPEvent: (eventProperties) => dispatch(trackEvent('vc mute video', eventProperties))
});

export default connect(mapStateToProps, mapDispatchToProps)(withPGTranslation(VoipControls));
