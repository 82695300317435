import { connect } from 'react-redux';
import Badge from '../../badge';
import classes from './classes.module.scss';

const mapStateToProps = ({ myOrders: { myOrders } }) => ({
  count: (myOrders && myOrders.needReview && myOrders.needReview.length) || 0,
  customClass: classes.badgeContainer
});

export default connect(mapStateToProps)(Badge);
