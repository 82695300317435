import { connect } from 'react-redux';
import RateReview from './rate_review';
import { sendOrderReview } from '../../actions/order_details';

const mapStateToProps = ({
  chat, voipChat
}, { orderId, advisorId }) => {
  const sessionData = chat?.orderId ? chat : { ...voipChat, ...voipChat?.order };
  return {
    ...sessionData,
    orderId,
    advisorId
  };
};

export default connect(
  mapStateToProps,
  { sendOrderReview }
)(RateReview);
