import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { appRoutes } from '../../config/app_routes';
import { getLocalizedPath } from '../routes/routes_utils';

const notFoundIcon = require('./img/not_found.svg');

const renderNotFound = (errorCode, notFoundAction, renderServiceUnavailablePage) => {
  if (errorCode === 404) return notFoundAction();
  if (errorCode >= 500) return renderServiceUnavailablePage();
  return null;
};

function NotFound({
  errorCode, t, clearAplicationError, statusText
}) {
  const onHomePress = () => {
    clearAplicationError();
    window.location.replace(getLocalizedPath({ path: appRoutes.home }));
  };

  const renderNotFoundPage = () => (
    <div className={ classes.container }>
      <div className={ classes.innerContainer }>
        <span className={ classes.title }>
          { t('error.oops') }
        </span>
        <img
          src={ notFoundIcon }
          alt=""
          className={ classes.img }
        />
        <span className={ classes.notFound }>
          { t('error.not_found') }
        </span>
        <span className={ classes.subtitle }>
          { t('error.404_info')}
        </span>
        <button
          type="button"
          className={ classes.link }
          onClick={ onHomePress }
        >
          { t('error.home_button')}
        </button>
      </div>
    </div>
  );

  const notFoundAction = () => {
    const isAdvisorPage = `${ window.location.pathname }`.includes('/psychics/');
    if (isAdvisorPage) return onHomePress();
    return renderNotFoundPage();
  };

  const renderServiceUnavailablePage = () => (
    <div className={ classes.container }>
      <div className={ classes.innerContainer }>
        <span className={ classes.title }>
          { t('error.oops') }
        </span>
        <span
          className={ classes.serviceUnavailableErrorCode }
        >
          { errorCode }
        </span>

        <span className={ classes.serviceUnavailableText }>
          { statusText }
        </span>
        <button
          type="button"
          className={ classes.link }
          onClick={ onHomePress }
        >
          { t('error.home_button')}
        </button>
      </div>
    </div>
  );

  return renderNotFound(errorCode, notFoundAction, renderServiceUnavailablePage);
}

NotFound.propTypes = {
  errorCode: PropTypes.number,
  t: PropTypes.func.isRequired,
  clearAplicationError: PropTypes.func.isRequired,
  statusText: PropTypes.string
};

NotFound.defaultProps = {
  errorCode: null,
  statusText: null
};

export default NotFound;
