import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../common/helpers/alert';

function VoipAlert({ error, clearError }) {
  if (!error) {
    return null;
  }
  return (
    <Alert
      errorString={ error }
      onClose={ clearError }
    />
  );
}

VoipAlert.propTypes = {
  error: PropTypes.object,
  clearError: PropTypes.func.isRequired
};

VoipAlert.defaultProps = {
  error: null
};

export default VoipAlert;
