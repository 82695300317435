import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  success: false,
  error: ''
});

const passwordForgotReducer = createReducer(getDefaultState(), {
  [Const.passwordForgot.loading]: (state) => ({
    ...state,
    loading: true,
    error: ''
  }),
  [Const.passwordForgot.success]: (state) => ({
    ...state,
    loading: false,
    success: true
  }),
  [Const.passwordForgot.error]: (state, { error }) => ({
    ...state,
    loading: false,
    success: false,
    error
  }),
  [Const.passwordForgot.clearError]: (state) => ({
    ...state,
    error: ''
  }),
  [Const.passwordForgot.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  })
});

export default passwordForgotReducer;
