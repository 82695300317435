import React from 'react';
import PropTypes from 'prop-types';
import {
  Slider, Handles, Tracks, Rail
} from 'react-compound-slider';
import classes from './classes.module.scss';

function CustomSlider({
  min, max, step, value, onUpdate
}) {
  const onSliderUpdate = (v) => {
    onUpdate(v[0]);
  };

  const renderCustomSlider = () => (
    <Slider
      className={ classes.sliderRoot }
      domain={ [min, max] }
      step={ step }
      values={ [value] }
      onUpdate={ onSliderUpdate }
    >
      <Rail>
        {({ getRailProps }) => (
          <div className={ classes.rail } { ...getRailProps() } />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index) => (
              <div
                key={ index }
                className={ classes.antSliderHandle }
                style={ {
                  left: `${ handle.percent }%`
                } }
                { ...getHandleProps(handle.id) }
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={ false }>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <div
                key={ id }
                className={ classes.sliderTrack }
                style={ {
                  left: `${ source.percent }%`,
                  width: `${ target.percent - source.percent }%`
                } }
                { ...getTrackProps()  }
              />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );

  return renderCustomSlider();
}

CustomSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired
};

CustomSlider.defaultProps = {
  className: null
};

export default CustomSlider;
