import { connect } from 'react-redux';
import Popover from './popover';
import { dissmissPopover } from '../../actions/popover';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateProps = ({ popover }) => ({ ...popover });

export default connect(mapStateProps, {
  dissmissPopover
})(withPGTranslation(Popover));
