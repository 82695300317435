import { connect } from 'react-redux';
import StaffPickSection from './staff_pick_section';
import withPGTranslation from '../../../../config/withPGTranslation';
import { showGeneralPopup } from '../../../../actions/general_popup';
import { authPendingAction, displayRegister } from '../../../../actions/auth';
import { displayNotifyMe } from '../../../../actions/notify_me';
import { updateNavigationStack } from '../../../../actions/application';

const mapStateToProps = (state, props) => {
  const advisorID = props.content?.advisor?.id;
  if (!props?.content?.advisor === undefined) return { ...props };
  return (
    {
      ...state.advisors[advisorID],
      ...props
    }
  );
};

export default connect(
  mapStateToProps,
  {
    displayNotifyMe,
    showGeneralPopup,
    authPendingAction,
    displayRegister,
    updateNavigationStack
  }
)(withPGTranslation(StaffPickSection));
