import { connect } from 'react-redux';
import { clearAplicationError } from '../../actions/aplication_error';
import { logOutClear, displayRegister } from '../../actions/auth';

import RoutesComponent from './routes';

const mapStateToProps = ({
  chat, aplicationError, voipChat, popover
}) => ({
  ...chat, ...aplicationError, voipState: voipChat.voipState, popoverVisibility: !!popover.kind
});

export default connect(
  mapStateToProps,
  { clearAplicationError, logOutClear, displayRegister }
)(RoutesComponent);
