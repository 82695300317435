import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Rating from '../advisor_avatar/rating/rating';
import TopIcons from './top_icons';
import { AdvisorPropType, AdvisorDefaultProps } from '../../reducers/advisor_prop_types';
import classes from './classes.module.scss';
import AdvisorVideoPlayer from '../advisor_video_player';
import { advisorProfileAvatarTheme } from '../../config/themes';
import ExpandedText from '../../../common/helpers/expanded_text';
import { getReviewCount, isPrerenderMode } from '../../config/util';

const importAll = (r) => r.keys().map(r);

const defaultAvatar = require('../header/img/home_placeholder.png');

const badgeImages = importAll(require.context('./img', false, /\.png$/));

const findIconSource = (id) => badgeImages.find(item => item.includes(`${ id }`));

const renderBadge = (type) => {
  const backgroundColor = type.color;
  return (
    <div className= { classes.badgeContainer } style={ { backgroundColor } } suppressHydrationWarning>
      <img
        className = { classes.ratedImg }
        src= { findIconSource(type.id) }
        alt=""
        suppressHydrationWarning
      />
      <span className={ classes.badgeText } suppressHydrationWarning>{ type.name }</span>
    </div>
  );
};

const renderNameSectionContent = ({ nickname, serviceTitle }) => (
  <div className={ classes.nameSectionContainer } itemProp="author" itemScope itemType="http://schema.org/Person">
    <div id="product-name-for-ref-to" itemProp="name" className={ classes.nickNameContainer }>
      <ExpandedText
        customClasses={ { text: classes.nickNameText } }
        skeleton={ <Skeleton width={ 170 } /> }
        loading={ !nickname }
        ellipsis
      >
        { nickname }
      </ExpandedText>
    </div>
    <div id="product-name-for-ref-to" itemProp="name" className={ classes.serviceTitleContainer }>
      <ExpandedText
        customClasses={ { text: classes.serviceTitleText } }
        skeleton={ <Skeleton width={ 170 } /> }
        loading={ !serviceTitle }
        ellipsis
      >
        { serviceTitle }
      </ExpandedText>
    </div>
  </div>
);

const renderNameSection = ({
  nickname, serviceTitle, likesCount, dislikesCount, rating
}) => {
  const reviewCount = getReviewCount({ likesCount, dislikesCount });
  if (reviewCount > 0 && rating > 0) {
    return (
      <div itemProp="review" itemScope itemType="https://schema.org/Review">
        { renderNameSectionContent({ nickname, serviceTitle }) }
      </div>
    );
  }
  return renderNameSectionContent({ nickname, serviceTitle });
};

const renderBadges = (badges, windowBadges) => {
  if (!badges || !badges.length) return null;
  const advisorBadge = badges.find(item => item !== 'tryout');
  if (!windowBadges.badges || !windowBadges.badges.length) return null;
  if (!advisorBadge) return null;
  const bagdeSettings = windowBadges.badges.find((item) => advisorBadge === item.id);
  return renderBadge(bagdeSettings);
};

const getAdvisorStatusClassName = ({ loading, status }) => {
  if (isPrerenderMode() || loading || !status) return classes.indicatorSkeleton;
  return classes[`${ status }Indicator`];
};

const renderAdvisorStatus = ({ status, t, loading }) => (
  <div className={ classes.indicatorContainer }>
    <div className={ getAdvisorStatusClassName({ loading, status }) }>
      <span className={ classes.statusTitle } suppressHydrationWarning>{ t(`advisor_profile.status_label_${ status }`).toUpperCase()}</span>
    </div>
  </div>
);

const renderTopIcons = (
  id,
  favoriteAdvisor,
  favorite,
  loading,
  theme,
  availabilityNotification,
  status
) => (
  <TopIcons
    id = { id }
    favoriteAdvisor = { favoriteAdvisor }
    favorite = { favorite }
    loader = { loading }
    theme={ theme }
    availabilityNotification={ availabilityNotification }
    advisorStatus={ status }
  />
);

const renderRating = (loading, rating, isNewAdvisor) => {
  if (loading) return null;
  return (
    <Rating
      rating={ rating }
      isNewAdvisor={ isNewAdvisor }
    />
  );
};

const renderTopIconsContainer = (
  loading,
  rating,
  isNewAdvisor,
  id,
  favoriteAdvisor,
  favorite,
  theme,
  availabilityNotification,
  status
) => (
  <div className={ classes.topItemsContainer }>
    { renderRating(loading, rating, isNewAdvisor) }
    { renderTopIcons(
      id,
      favoriteAdvisor,
      favorite,
      loading,
      theme,
      availabilityNotification,
      status
    ) }
  </div>
);

function AdvisorProfileAvatar({
  profilePictureUrl, profileVideoUrl, nickname, serviceTitle, rating, id, loading,
  favoriteAdvisor, favorite, isNewAdvisor,
  profileVideoThumbUrl, badges, status, theme, t,
  likesCount, dislikesCount, availabilityNotification
}) {
  const windowBadges = {
    badges: window.config.uiSettings.badges
  };

  const renderVideoPlayer = () => {
    if (theme !== advisorProfileAvatarTheme.default) return null;
    return (
      <AdvisorVideoPlayer
        profileVideoUrl={ profileVideoUrl }
        profilePictureUrl={ profileVideoThumbUrl }
        profileVideoThumbUrl={ profileVideoThumbUrl }
        needTrackProfileVideoEvent
        advisorId={ id }
      />
    );
  };

  const advisorInfo = () => (
    <div className={ classes.advisorInfo }>
      { renderBadges(badges, windowBadges) }
      { renderNameSection({
        nickname, serviceTitle, likesCount, dislikesCount, rating
      }) }
    </div>
  );

  const renderProfileAvatar = () => (
    <div className = { classes.profileContainer }>
      <div className={ classes.avatarContainer }>
        { renderTopIconsContainer(
          loading,
          rating,
          isNewAdvisor,
          id,
          favoriteAdvisor,
          favorite,
          theme,
          availabilityNotification,
          status
        ) }
        <div className = { classes.profileImageContainer }>
          <div className={ classes.transparentOverlay } />
          <img
            suppressHydrationWarning
            src={ profilePictureUrl || defaultAvatar }
            itemScope
            itemProp="image"
            className={ classes.profileImg }
            alt={ nickname }
            style={ !profilePictureUrl ? { objectFit: 'scale-down' } : null }
          />
          { renderVideoPlayer() }
          { advisorInfo() }
        </div>
        <div className={ classes.statusContainer }>
          { renderAdvisorStatus({ status, t, loading }) }
        </div>
      </div>
    </div>
  );

  return (
    <div className={ classes[theme] }>
      {renderProfileAvatar()}
    </div>
  );
}

AdvisorProfileAvatar.propTypes = {
  ...AdvisorPropType,
  showBagde: PropTypes.bool,
  needTrackProfileVideoEvent: PropTypes.bool,
  favoriteAdvisor: PropTypes.func.isRequired,
  onNotifyClick: PropTypes.func,
  loading: PropTypes.bool,
  badges:PropTypes.array,
  theme: PropTypes.string,
  t: PropTypes.func.isRequired
};

AdvisorProfileAvatar.defaultProps = {
  ...AdvisorDefaultProps,
  showBagde: false,
  needTrackProfileVideoEvent: false,
  loading: null,
  badges: [],
  onNotifyClick: null,
  theme: advisorProfileAvatarTheme.default
};

export default AdvisorProfileAvatar;
