import queryString from 'query-string';
import Const from '../config/const';
import { loadAdvisorAPI, loadAdvisorFeedbacksAPI, getDirectMessagesApi } from '../config/api';
import { setAplicationError } from './aplication_error';
import { log } from '../config/app_logger';
import { updateNavigationStack, navigationStackLoadingAction } from './application';
import { toCamelCase } from '../../common/config/utils';
import { authPendingAction, authPendingCloseAction, displayRegister } from './auth';
import { trackEvent } from './mixpanel';
import { isPrerenderMode, getAdvisorDataIfSsrMode } from '../config/util';

export const reviewsLoaded = ({
  reviews, id, initial
}) => (dispatch) => {
  dispatch({
    type: Const.advisors.reviewsLoaded,
    id,
    reviews,
    initial
  });
};

export const advisorsReviewsReset = (id) => (dispatch) => {
  dispatch({
    type: Const.advisors.reviewsReset,
    id
  });
};

export const loadAdvisorReviews = (id) => (dispatch, getState) => {
  const { advisors } = getState();
  const params = advisors[id].reviews?.paginationParams;
  dispatch({ type: Const.advisors.reviewsLoading, id });
  loadAdvisorFeedbacksAPI(id, params).then((res) => {
    dispatch(reviewsLoaded({
      id,
      initial: params === undefined,
      reviews:{
        items: res.feedbacks,
        paginationParams: res.paginationParams
      }
    }));
  }).catch((e) => log('Error load feedback API', e));
};

export const updateAdvisorNavigationStack = ({ advisor, dispatch }) => {
  dispatch(updateNavigationStack(
    {
      itemData: { slug: advisor.slug, name: advisor.nickname },
      navigationStackLoading: false
    }
  ));
};

const removeDmIdFromSearch = ({ directMessageAnalytics, history, location }) => {
  const search = toCamelCase(queryString.parse(location.search));
  delete search.dmId;
  const updatedSearch = queryString.stringify(search);
  const analitycsPrams = directMessageAnalytics
    ? { ...directMessageAnalytics, startChatClickSource: directMessageAnalytics.clickSource } : {};
  history(`${ location.pathname }?${ updatedSearch }`, {
    search: updatedSearch,
    state: { ...location.state, ...analitycsPrams }
  });
};

const trackProfileViewEvents = ({
  dmId, dispatch, analyticsActions, conversionEventId, history, location, advisor
}) => {
  const {
    nickname, serviceTitle, profilePictureUrl, rating, readingsCount, yearJoined, profileVideoUrl
  } = advisor;
  const extraParams = {
    nickname,
    serviceTitle,
    profilePictureUrl,
    rating,
    readingsCount,
    yearJoined,
    profileVideoUrl,
    conversionEventId,
    history,
    location,
    advisorId: advisor.id
  };
  if (!dmId) {
    analyticsActions.forEach((a) => a(extraParams));
    return;
  }
  getDirectMessagesApi(dmId).then(({ directMessage }) => {
    dispatch(trackEvent('dm user connected', {
      'dm id': dmId,
      'advisor id': directMessage.advisor.id,
      'dm channel': 'email'
    }));
    removeDmIdFromSearch({ directMessageAnalytics: directMessage.analytics, history, location });
    analyticsActions.forEach((a) => a(extraParams));
  }).catch(() => {
    removeDmIdFromSearch({ history, location });
    analyticsActions.forEach((a) => a(extraParams));
  });
};

const handleAnalyticsAction = ({
  analyticsparams, conversionEventId, advisor, dispatch
}) => {
  const {
    fromAdvisorProfile, analyticsActions, history, location
  } = analyticsparams;
  if (!fromAdvisorProfile) return;
  const search = toCamelCase(queryString.parse(window.location.search));
  const { dmId } = search;
  if (window.currentUserId || !dmId) {
    trackProfileViewEvents({
      dmId, dispatch, analyticsActions, conversionEventId, history, location, advisor
    });
    return;
  }
  dispatch(authPendingAction(() => {
    trackProfileViewEvents({
      dmId, dispatch, analyticsActions, conversionEventId, history, location, advisor
    });
  }));
  dispatch(authPendingCloseAction(() => {
    trackProfileViewEvents({
      dmId, dispatch, analyticsActions, conversionEventId, history, location, advisor
    });
  }));
  dispatch(displayRegister({ shouldDisplay: true }));
};

export const loadAdvisor = (
  slug,
  analyticsparams = { fromAdvisorProfile : false }
) => (dispatch, getState) => {
  if (isPrerenderMode()) return;
  dispatch({ type: Const.advisors.loading });
  if (!getAdvisorDataIfSsrMode()) dispatch(navigationStackLoadingAction());
  const { fromAdvisorProfile } = analyticsparams;
  const additionalParams = fromAdvisorProfile ? { context: 'profile_page' } : {};
  loadAdvisorAPI(slug, additionalParams).then(({
    advisor, reviews, error, conversionEventId
  }) => {
    if (error) {
      dispatch(setAplicationError({ errorCode: error.errorStatus, errorUrl: error.errorUrl }));
      if (error.errorStatus !== 410) return;
    }
    handleAnalyticsAction({
      advisorId: advisor.id, analyticsparams: { ...analyticsparams, advisorId: advisor.id }, conversionEventId, dispatch, advisor
    });
    dispatch(reviewsLoaded({
      reviews, id: advisor.id, initial: true
    }));
    updateAdvisorNavigationStack({ advisor, dispatch, getState });
  }).catch((error) => {
    log('Error loadAdvisorAPI', error);
    dispatch(setAplicationError({ errorCode: error.status, errorUrl: error.url }));
  });
};
