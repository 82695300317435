import { isKA, matchPaths } from '../../common/config/utils';

export const appSpecificRoute = isKA() ? {
  psychic: '/psychic/:slug',
  psychicList: '/psychic',
  orderList: '/activity_list',
  advisorList: '/activity_list/:id',
  orderDetails: '/activity_list/:advisorId/session/:orderId'
} : {
  psychic: '/psychics/:slug',
  psychicList: '/psychics',
  orderList: '/order_list',
  advisorList: '/order_list/:id',
  orderDetails: '/order_list/:advisorId/order/:orderId'
};

export const getPathnameWithLanguageRoute = ({ pathname }) => {
  const pathnameWithLanguageRoute = [`/:language${ pathname }`, `${ pathname }`];
  return pathnameWithLanguageRoute;
};

export const getAdvisorSlug = ({ advisorId }) => {
  const { advisors } = window.store.getState();
  const { knownPaths } = advisors;
  const knownPath = Object.keys(knownPaths).find((kp) => knownPaths[kp] === advisorId);
  const slugMatch = matchPaths({ pathname: getPathnameWithLanguageRoute({ pathname: appSpecificRoute.psychic }), path: knownPath });
  const advisorSlug = slugMatch.params.slug;
  return advisorSlug;
};
