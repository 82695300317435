import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { appLinks } from '../../../config/const';
import Switch from '../../../../common/helpers/switch';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import { GDPRConsernPopupThemes } from '../../../config/themes';

const { shared } = window;

function GDPRConsernPopup({
  consentedToGDPR, GDPRConsernPopupVisible, gdprSwitcherValueChanged, shouldShowGDPRConsent, theme,
  socialParams, authSocial, clearError
}) {
  const { t } = useTranslation();

  const hideGDPRConsernPopup = () => {
    shouldShowGDPRConsent({ [theme]: false });
  };

  const renderRegularContent = () => (
    <div className = { classes.ppTofModalSignUpContent }>
      <div>
        { t('ppTof.you_must_consent_to_the') }
      </div>
      <div>
        <button
          type="button"
          onClick={ hideGDPRConsernPopup }
          className={ classes.ppTofModalSignUpButton }
        >
          { t('general.ok') }
        </button>
      </div>
    </div>
  );

  const onChangeSwitch = (value) => {
    gdprSwitcherValueChanged(value);
  };

  const onSocialGDPRCCancelClick = () => {
    shouldShowGDPRConsent({ [theme]: false });
    gdprSwitcherValueChanged(false);
  };

  const socialContinueOnClick = () => {
    clearError();
    authSocial(socialParams.socRequest, socialParams.signupFlow);
  };

  const renderSocialContent = () => (
    <div className={ classes.socialPpTofModalContent }>
      <div className={ classes.confirmPpTofSwitcherRowsignUp }>
        <div className={ classes.socialPpTofModalText }>
          { t('ppTof.i_consent_to_the') }
          <a href= { appLinks.privacyPolicy } className = { classes.confirmPpTofTextLink }>
            <b>{ t('settings.privacy_policy') }</b>
          </a>
          <br />
          { t('ppTof.and') }
          <a href= { appLinks.tos } className = { classes.confirmPpTofTextLink }>
            <b>{ t('settings.terms_of_service') }</b>
          </a>
        </div>
        <Switch
          id="socialPpTofSwitcher"
          checked={ consentedToGDPR }
          onChange= { onChangeSwitch }
          onColor={ shared.appColor }
        />
      </div>
      <div className={ classes.socialPpTofModalButtonsRow }>
        <div>
          <button
            type="button"
            onClick= { onSocialGDPRCCancelClick }
            className={ classes.socialPpTofModalCancelButton }
          >
            { t('general.cancel') }
          </button>
        </div>
        <div>
          <button
            type="button"
            disabled={ !consentedToGDPR }
            onClick={ socialContinueOnClick }
            className={ classes.socialPpTofModalOkButton }
          >
            { t('general.continue') }
          </button>
        </div>
      </div>
    </div>
  );

  const renderModalContent = () => {
    if (theme === GDPRConsernPopupThemes.social) return renderSocialContent();
    return renderRegularContent();
  };

  const renderGDPRConsernPopup = () => (
    <AppGeneralPopup
      visible={ GDPRConsernPopupVisible[theme] || false }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      { renderModalContent() }
    </AppGeneralPopup>
  );

  return renderGDPRConsernPopup();
}

GDPRConsernPopup.propTypes = {
  consentedToGDPR: PropTypes.bool,
  GDPRConsernPopupVisible: PropTypes.object,
  gdprSwitcherValueChanged: PropTypes.func.isRequired,
  shouldShowGDPRConsent: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  socialOriginOnClick: PropTypes.func
};

GDPRConsernPopup.defaultProps = {
  consentedToGDPR: null,
  GDPRConsernPopupVisible: null,
  socialOriginOnClick: null
};
export default GDPRConsernPopup;
