import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

const MessageIcon = require('./yellow_post_IMG.png');

const renderMessages = count => (
  <div className={ classes.messageContainer }>
    <img className={ classes.messageImage } src={ MessageIcon } alt="" />
    { count }
  </div>
);

function MessageCountBadge({ count }) {
  return <div>{count > 0 ? renderMessages(count) : null}</div>;
}

MessageCountBadge.propTypes = {
  count: PropTypes.number
};

MessageCountBadge.defaultProps = {
  count: 0
};

export default MessageCountBadge;
