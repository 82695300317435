import { connect } from 'react-redux';
import Header from './header';
import withPGTranslation from '../../config/withPGTranslation';
import { displayRegister, logOutClear } from '../../actions/auth';

const mapStateProps = ({
  user: { user }, myOrders: { myOrders }, application, logOut
}) => ({
  ...user,
  badgeCount: (myOrders && myOrders.needReview && myOrders.needReview.length) || 0,
  ...application,
  logOutSuccess: logOut.success,
  xfmProgram: user && user.xfm_program
});

export default connect(mapStateProps, {
  displayRegister,
  logOutClear
})(withPGTranslation(Header));
