import Bugsnag from '@bugsnag/js';
import { getCurrentUserAPI, updateUserAPI, getMyAccountCreditsApi } from '../config/api';
import Const from '../config/const';
import { log } from '../config/app_logger';
import { userUpdated } from './shared_actions';

const userLoadingCreator = () => ({
  type: Const.user.loading
});

const userErrorCreator = error => ({
  type: Const.user.error,
  payload: {
    error
  }
});

const getUserPromise = (dispatch) => (getCurrentUserAPI()
  .then(user => {
    const { user: { id, nickname, email } } = user;
    Bugsnag.setUser(id, nickname, email);
    dispatch(userUpdated(user.user));
  })
);

export const getUser = (currentUserId = window.currentUserId) => (dispatch, getState) => {
  // empty page needed for mobile preload
  // no need to load user in this case
  if (window.location.pathname.includes('/empty')) return;
  if (!currentUserId) return;
  const { loading } = getState().user;
  if (loading) return;
  dispatch(userLoadingCreator());
  getUserPromise(dispatch)
    .catch(error => {
      error
        .json()
        .then(jsonError => {
          dispatch(userErrorCreator(jsonError));
        })
        .catch(() => {
          dispatch(userErrorCreator(error));
        });
    })
    .catch((error) => {
      log('Error getUserPromise', error);
      dispatch(userErrorCreator(error));
    });
};

export const updateUser = (payload) => (dispatch) => {
  dispatch(userLoadingCreator());
  updateUserAPI(payload).then((res) => {
    dispatch(userUpdated(res));
  }).catch((error) => {
    dispatch(userErrorCreator(error));
  });
};

export const userMPIdentify = () => (dispatch) => {
  dispatch({ type: Const.user.MPIdentify });
};

export const getMyAccountCredits = () => (dispatch, getState) => {
  const { paymentProcessingSequence } = getState().user;
  getMyAccountCreditsApi(paymentProcessingSequence).then(() => {
    dispatch({
      type: Const.user.updatePaymentProcessingSequence
    });
  });
};
