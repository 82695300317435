import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import classes from './classes.module.scss';
import { fromMobileApp } from '../config/utils';
import { isPrerenderMode } from '../../app/config/util';

const cookies = new Cookies();
const cookiesIcon = require('./img/cookies_icon.svg');

const cookieNames = [
  'acceptGDPRPopover', // react
  'gdpr_popover_accept_click' // ruby
];

export const cookieValue = (valueName) => {
  const b = document.cookie.match(`(^|;)\\s*${ valueName }\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

const acceptClick = () => {
  const wpccContainer = document.getElementById('wpccContainerId');
  wpccContainer.style.display = 'none';
  const date = new Date();
  date.setTime(date.getTime() + (15 * 24 * 60 * 60 * 1000));
  cookieNames.forEach(cookieName => cookies.set(cookieName, true, { path: '/', expires: date }));
};

const shouldDisplayPopover = () => cookieNames.every(cookieName => !cookieValue(cookieName));

function CookiesPopover({
  wpccMessageText, wpccPrivacyText, wpccPrivacyHref, wpccBtnText
}) {
  const [showCookiesPopover, setshowCookiesPopover] = useState(false);

  useEffect(() => {
    setTimeout(() => setshowCookiesPopover(true), 1000);
  }, []);

  const renderCoockiesPopover = () => {
    if (fromMobileApp) return null;
    if (!shouldDisplayPopover() || isPrerenderMode() || !showCookiesPopover) return null;
    return (
      <div className = { classes.wpccContainer } id="wpccContainerId" suppressHydrationWarning>
        <div className={ classes.wpccContent } suppressHydrationWarning>
          <img className={ classes.wpccIcon } src={ cookiesIcon } alt="" suppressHydrationWarning />
          <span className={ classes.wpccMessage } suppressHydrationWarning>
            { wpccMessageText || '' }
            <span suppressHydrationWarning>&nbsp;</span>
            <a className={ classes.wpccPrivacy } href={ wpccPrivacyHref } rel="noopener noreferrer" target="_blank" suppressHydrationWarning>
              { wpccPrivacyText || '' }
            </a>
          </span>
          <div className={ classes.wpccCompliance }>
            <button className={ classes.wpccBtn } type="button" onClick={ acceptClick }>
              { wpccBtnText }
            </button>
          </div>
        </div>
      </div>
    );
  };

  return renderCoockiesPopover();
}

CookiesPopover.propTypes = {
  wpccMessageText: PropTypes.string.isRequired,
  wpccPrivacyText: PropTypes.string.isRequired,
  wpccPrivacyHref: PropTypes.string.isRequired,
  wpccBtnText: PropTypes.string.isRequired
};

CookiesPopover.defaultProps = {};

export default CookiesPopover;
