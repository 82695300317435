import { connect } from 'react-redux';
import Const from '../../../config/const';
import { cancelChat } from '../../../actions/chat';
import { cancelVoipChat } from '../../../actions/voip_chat';
import NoAnswer from './no_answer';
import withTranslation from '../../../config/withPGTranslation';

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        onCancelClicked: () => dispatch(cancelChat())
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        onCancelClicked: () => dispatch(cancelVoipChat())
      });
    default:
      return null;
  }
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(NoAnswer));
