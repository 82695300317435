import { connect } from 'react-redux';
import ChatOverlay from './chat_overlay';
import { purchaseOnBack, cancelChat } from '../../actions/chat';

const mapStateToProps = ({ chat }) => chat;

export default connect(
  mapStateToProps,
  {
    purchaseOnBack,
    cancelChat
  }
)(ChatOverlay);
