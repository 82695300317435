import React from 'react';
import PropTypes from 'prop-types';
import FeedbackSwitcher from './feeback_switcher';
import withPGTranslation from '../../config/withPGTranslation';
import { rateReviewTheme } from '../../config/const';
import classes from './classes.module.scss';

function RateSection({
  value,
  onValueChanged,
  t,
  onSmallModal,
  theme
}) {
  const styleMarging = (marginTopSmallModal, marginBottom, marginBottomSmallModal, display) => (
    onSmallModal ? {
      marginTop: marginTopSmallModal,
      marginBottom: marginBottomSmallModal,
      display
    } : {
      marginBottom,
      display
    }
  );
  const renderRateSectionDEFAULT = () => (
    <div className={ classes.rateSectionDEFAULT  }>
      <div className={ classes.flexDiv } style={ styleMarging(25, 12) }>
        <b>{ t('rate_review.title') }</b>
      </div>
      <div className={ classes.flexDiv } style={ styleMarging(5, 40) }>
        { t('rate_review.subTitle') }
      </div>
      <div style={ styleMarging(5, 20, 10, 'block') }>
        <FeedbackSwitcher value={ value } onValueChanged={ onValueChanged } />
      </div>
    </div>
  );

  const renderRateSectionCHATENDED = () => (
    <div className={ classes.rateSectionChatEndedContainer }>
      <div className={ classes.flexDiv } style={ styleMarging(25, 12) }>
        <div className={ classes.chatEndedTitle }>
          { t('rate_review.chat_ended_title') }
        </div>
      </div>
      <div className={ classes.flexDiv } style={ styleMarging(5, 40) }>
        <div className={ classes.chatEndedSubTitle }>
          { t('rate_review.chat_ended_subTitle1') }
          <br />
          { t('rate_review.chat_ended_subTitle2') }
          <br />
          { t('rate_review.chat_ended_subTitle3') }
        </div>
      </div>
      <div style={ styleMarging(5, 20, 10, 'block') }>
        <FeedbackSwitcher
          value={ value }
          onValueChanged={ onValueChanged }
          theme={ rateReviewTheme.CHAT_ENDED }
        />
      </div>
    </div>
  );
  return { renderRateSectionDEFAULT, renderRateSectionCHATENDED }[`renderRateSection${ theme }`]();
}

RateSection.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  value: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSmallModal: PropTypes.bool,
  theme: PropTypes.string
};

RateSection.defaultProps = {
  value: null,
  onSmallModal: false,
  theme: rateReviewTheme.DEFAULT
};

export default withPGTranslation(RateSection);
