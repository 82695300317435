import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { Speciality } from '@barges/react';
import '@barges/scss/lib/speciality.css';
import '@barges/scss/lib/title.css';
import withPGTranslation from '../../../../config/withPGTranslation';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../../reducers/advisor_prop_types';
import classes from './classes.module.scss';
import AppLink from '../../../routes/app_link';
import { specialitiesClickSource } from '../../../../config/const';
import { advisorSpecialitiesTheme } from '../../../../config/themes';
import { isKA } from '../../../../../common/config/utils';

const renderSpecialitiesContent = (specialities, loading, specialitiesContent) => (
  !(specialities || []).length && !loading ? null : specialitiesContent()
);

function AdvisorSpecialities({
  specialities, advisorId, t, loading
}) {
  const speciality = (spec) => {
    const  {
      iconUrl, name, slug, params, iconName
    } = spec;
    return (
      <AppLink to={ {
        pathname: slug,
        state: {
          needRefresh: true,
          trackCategoryEvent: true,
          clickSource: specialitiesClickSource.advisorProfile,
          advisorId,
          params
        }
      } }
      >
        <Speciality
          type="advisor-profile"
          speciality={ { iconName: !isKA() ? iconName : null, name, iconUrl } }
        />
      </AppLink>
    );
  };

  const specialitiesContent = () => (
    <div className={ classes.descriptionHolder } suppressHydrationWarning>
      {t('advisor_profile.specialities_title')}
      <div className={ classes.specialitiesContainer } suppressHydrationWarning>
        { loading
          ? (
            <Skeleton
              count={ 3 }
              containerClassName={ classes.specialitySkeletonContainer }
              className={ classes.specialitySkeleton }
            />
          ) : specialities.map((s) => speciality(s))}
      </div>
    </div>
  );

  return renderSpecialitiesContent(specialities, loading, specialitiesContent);
}

AdvisorSpecialities.propTypes = {
  advisorId: AdvisorPropType.id,
  specialities: AdvisorPropType.specialities,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

AdvisorSpecialities.defaultProps = {
  ...AdvisorDefaultProps,
  theme: advisorSpecialitiesTheme.default,
  loading: false
};

export default withPGTranslation(AdvisorSpecialities);
