import Const from '../config/const';

export const linkType = {
  videoNotSupported: 'video_not_supported',
  voiceNotSupported: 'voice_not_supported',
  notificationNotSupported: 'notification_not_supported',
  none: 'none'
};

export const dissmissPopup = () => dispatch => {
  dispatch({
    type: Const.generalPopup.hide
  });
};

export const showGeneralPopup = (title, link = linkType.none) => dispatch => {
  dispatch({
    type: Const.generalPopup.show,
    title,
    link
  });
};

export const setChatPopupRightItem = (item) => dispatch => {
  dispatch({
    type: Const.generalPopup.setRightCustomItem,
    item
  });
};

export const clearChatPopupRightItem = () => dispatch => {
  dispatch({
    type: Const.generalPopup.clearRightCustomItem
  });
};

export const updateChatPopupBackAction = (action) => dispatch => {
  dispatch({
    type: Const.generalPopup.updateBackAction,
    action
  });
};

export const cleanUpdatedBackAction = () => dispatch => {
  dispatch({
    type: Const.generalPopup.clearBackAction
  });
};
