import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { linkType } from '../../actions/general_popup';
import { downloadAppLink } from '../../../common/config/utils';
import classes from './classes.module.scss';
import { appGeneralPopupTheme } from '../../../common/config/const';
import AppGeneralPopup from '../../../common/app_general_popup';

function GeneralPopup({
  t, show, title, dissmissPopup, link
}) {
  const onClicked = useCallback(() => {
    dissmissPopup();
  }, []);

  const appLinkAFChanel = () => {
    switch (link) {
      case linkType.videoNotSupported:
        return 'video_button';
      case linkType.voiceNotSupported:
        return 'voice_button';
      case linkType.notificationNotSupported:
        return 'no_notification_support';
      default:
        return null;
    }
  };
  const appLink = (
    <a href={ downloadAppLink(appLinkAFChanel()) } target="_blank" rel="noopener noreferrer" className={ classes.link }>
      { t('app_general.download_link') }
    </a>
  );

  const renderLink = () => {
    if (!link) return null;
    switch (link) {
      case linkType.videoNotSupported:
      case linkType.voiceNotSupported:
        return (
          <div>
            { t('general.this_feature_is_not_supported_yet') }
            { appLink }
            { `for ${ link === linkType.videoNotSupported ? 'video' : 'voice' } call.`}
          </div>
        );
      case linkType.notificationNotSupported:
        return (
          <div>
            { t('general.this_feature_is_not_supported_in_this_browser') }
            { appLink }
          </div>
        );
      default:
        return null;
    }
  };

  const renderGeneralPopupContent = () => (
    <div className={ classes.generalPopupContentContainer }>
      <div className={ classes.title }>
        { title }
        { renderLink() }
      </div>
      <div className={ classes.okBtnContainer }>
        <button type="button" className={ classes.okBtn } onClick={ onClicked } key="ok_button">
          {t('general.ok')}
        </button>
      </div>
    </div>
  );

  const renderGeneralPopup = () => (
    <AppGeneralPopup
      visible={ show }
      theme={ appGeneralPopupTheme.CENTERED }
      closable={ onClicked }
    >
      { renderGeneralPopupContent() }
    </AppGeneralPopup>
  );

  return  renderGeneralPopup();
}

GeneralPopup.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  dissmissPopup: PropTypes.func.isRequired,
  title: PropTypes.string,
  link: PropTypes.string
};

GeneralPopup.defaultProps = {
  show: false,
  title: '',
  link: null
};

export default GeneralPopup;
