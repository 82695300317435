import Const from '../config/const';

const setError = (errorCode, errorText, errorUrl, statusText) => ({
  type: Const.aplicationError.error,
  errorCode,
  errorText,
  errorUrl,
  statusText
});

export const setAplicationError = ({
  errorCode, errorText, errorUrl, statusText
}) => (dispatch) => {
  dispatch(setError(errorCode, errorText, errorUrl, statusText));
};

const clearError = () => ({
  type: Const.aplicationError.clearError
});

export const clearAplicationError = () => (dispatch) => {
  dispatch(clearError());
};
