import createReducer from '../config/create_reducer';
import Const from '../config/const';
import { getLocalizedPath } from '../components/routes/routes_utils';
import { appRoutes } from '../config/app_routes';

const getDefaultState = () => ({});

const cleanAdvisorsArray = (advisors) => (
  Array.from(new Set(advisors.map(a => a.id))).map(id => (
    advisors.find(a => a.id === id)
  ))
);

const generateAdvisorsArray = ({
  payload, path, searchPage, state, needRefresh
}) => {
  if (searchPage || payload.page === 1 || needRefresh) return payload.advisors;
  return cleanAdvisorsArray([...state[path].advisors, ...payload.advisors]);
};

const updateSeenPages = ({ seenPages, page, needRefresh }) => {
  if (seenPages) {
    if (needRefresh) return [page];
    if (seenPages.includes(page)) return seenPages;
    return [...seenPages, page];
  }
  return [page];
};

const generateAdvisorsArrayForLoading = ({  state, path, searchPage }) => {
  if (searchPage) return { advisors: [] };
  const { advisors } = state[path] || {};
  return typeof advisors !== 'undefined' ? { advisors } : {};
};

const psychicsReducer = createReducer(getDefaultState(), {
  [Const.searchResults.loading]: (state, { path, searchPage }) => ({
    ...state,
    [path]: {
      ...state[path] || {},
      ...generateAdvisorsArrayForLoading({ state, path, searchPage }),
      loading: true,
      noResults: null
    }
  }),
  [Const.searchResults.error]: (state, { error, path }) => ({
    ...state,
    [path]: {
      ...state[path] || {},
      error
    }
  }),
  [Const.searchResults.loaded]: (state, {
    payload, path, searchPage, needRefresh
  }) => ({
    ...state,
    [path]: {
      ...state[path] || {},
      ...payload,
      advisors: generateAdvisorsArray({
        payload, path, searchPage, state, needRefresh
      }),
      analytics: payload.page === 1
        ? payload.analytics
        : state[path].analytics,
      loading: false,
      seenPages: updateSeenPages({ seenPages: state[path].seenPages, page: payload.page, needRefresh }),
      noResults: payload.noResults ? payload.noResults : null
    }
  }),

  [Const.favoriteAdvisors.markUnFavorite]: (state, { payload }) => {
    const favoriteAdvisorsPath = getLocalizedPath({ path: appRoutes.favoriteAdvisors });
    if (!state[favoriteAdvisorsPath]) return ({  ...state });
    return ({
      ...state,
      [favoriteAdvisorsPath]: {
        advisors: [...state[favoriteAdvisorsPath].advisors.filter(({ id }) => id !== payload.id)]
      }
    });
  },
  [Const.favoriteAdvisors.markFavorite]: (state, { payload: { advisor } }) => {
    const favoriteAdvisorsPath = getLocalizedPath({ path: appRoutes.favoriteAdvisors });
    if (!state[favoriteAdvisorsPath]) return ({ ...state });
    return ({
      ...state,
      [getLocalizedPath({ path: appRoutes.favoriteAdvisors })]: {
        ...state[favoriteAdvisorsPath],
        advisors: [...state[favoriteAdvisorsPath].advisors, advisor]
      }
    });
  },

  [Const.searchResults.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  })
});

export default psychicsReducer;
