/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menu_item';
import MyOrdersBadge from '../header/my_orders_badge';
import MessageCountBadge from '../header/message_count_badge';
import { pathNameWithoutLocalization } from '../../config/util';
import SpecialitiesMenuItem from './specialities_menu_item';
import classes from './classes.module.scss';
import { appLinks } from '../../config/const';
import HeaderLogo from '../header/header_logo';
import { getCurrentLocalization } from '../../config/i18n';
import { appRoutes } from '../../config/app_routes';
import { getIcon, matchPaths, isKA } from '../../../common/config/utils';

const sideMenuIconNames = {
  home: 'home',
  promo: 'promo',
  blog: 'blog',
  laguage: 'laguage',
  cashback: 'cashback',
  support: 'support',
  forAdvisors: 'for_advisors',
  settings: 'settings',
  myOrders: 'my_orders',
  favAdvisors: 'fav_advisors',
  credit: 'credit',
  inviteFriends: 'invite_friends',
  paymentSources: 'payment_sources',
  sideMenuClosed: 'side_menu_closed'
};

const language = getCurrentLocalization();

export const isMenuItemActive = (path) => matchPaths(
  {
    pathname: `${ pathNameWithoutLocalization(path) }`,
    path: `${ pathNameWithoutLocalization(window.location.pathname) }`
  }
);

export const requireContext = require.context('./img', true, /\.(svg|png)$/);

const getIconToUse = ({ iconName, path, needChekActive }) => {
  let iconToUse = iconName;
  if (needChekActive) {
    const pathActive = isMenuItemActive(path);
    iconToUse = pathActive ? `${ iconName }_active` : iconName;
  }
  return iconToUse;
};

export const renderSideMenuIcon = ({
  iconName, path, needChekActive = true,  style = {}
}) => {
  if (isKA()) return null;
  const iconToUse = getIconToUse({ iconName, path, needChekActive });
  return (
    <img src={ getIcon({ iconName: iconToUse, requireContext, forAppContext: true }) } alt="" className={ classes.menuItemIcon } style={ { ...style } } suppressHydrationWarning />
  );
};

export const renderChangeLanguageMenuItem = ({ t, languageShow, showLanguageSwitcher }) => {
  if (isKA()) return null;
  return (
    <MenuItem
      title={ { text: t('sideMenu.language'), active: languageShow } }
      icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.laguage, path: '/language' }) }
      onClick={ () => { showLanguageSwitcher(true); } }
      extraNode={ <div className={ classes.languageSign }>{ language }</div> }
      rightPos
    />
  );
};

const renderBlogMenuItem = (t) => (isKA() ? (
  <MenuItem
    title={ { text: t('sideMenu.articles') } }
    icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.blog, path: appLinks.articles }) }
    asHref
    linkTo={ appLinks.articles }
    openInNewTab
  />
) : (
  <MenuItem
    title={ { text: t('sideMenu.blog') } }
    icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.blog, path: appLinks.blog }) }
    asHref
    linkTo={ language === 'en' ? appLinks.blog : `${ appLinks.blog }/${ language }` }
    openInNewTab
  />
));

const renderMyHoroscopeMenuItem = (t, userLoggedIn) => (isKA() ? (
  <>
    {!userLoggedIn ? <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div> : null}
    <MenuItem title={ { text: t('sideMenu.myHoroscope') } } linkTo={ appLinks.myHoroscope } asHref />
  </>
) : null);

function SideMenu({
  t, isSufficientForCashback, cashbackAmount, userInviteCode, showLanguageSwitcher, languageShow, shouldShowPayoutMethodMenuItem, toggleDrawer,  visible
}) {
  const userLoggedIn = window.currentUserId;

  const inviteFriendsItem = () => {
    if (isKA()) return null;
    return (
      <MenuItem
        title={ { text: userInviteCode ? t('sideMenu.free_readings') : t('sideMenu.invite_friends'), active: isMenuItemActive(appRoutes.inviteFriends) } }
        icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.inviteFriends, path: appRoutes.inviteFriends }) }
        linkTo={ appRoutes.inviteFriends }
      />
    );
  };

  const cashbackItem = () => {
    if (isKA()) return null;
    return (
      <MenuItem
        title={ { text: t('sideMenu.cashback'), active: isMenuItemActive(appRoutes.cashback) } }
        icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.cashback, path: appRoutes.cashback }) }
        linkTo={ appRoutes.cashback }
        extraNode={ isSufficientForCashback ? (
          <div className={
      isSufficientForCashback ? classes.cashbackActive : classes.cashbackInactive
      }
          >
            { `$${ parseFloat(cashbackAmount).toFixed(2) }` }
          </div>
        ) : null }
      />
    );
  };

  function renderLoggedOutSideMenu() {
    return (
      <div className={ classes.sideMenu }>
        <MenuItem
          title={ { text: t('sideMenu.home'), active: isMenuItemActive('/') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.home, path: language === 'en' ? appRoutes.home : appRoutes.esHome }) }
          linkTo={ appRoutes.home }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <SpecialitiesMenuItem />
        {renderMyHoroscopeMenuItem(t, userLoggedIn)}
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <MenuItem
          title={ { text: t('sideMenu.apply_promo_code'), active: isMenuItemActive(appRoutes.promoCode) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.promo, path: appRoutes.promoCode }) }
          linkTo={ appRoutes.promoCode }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderBlogMenuItem(t)}
        { renderChangeLanguageMenuItem({ t, languageShow, showLanguageSwitcher }) }
        <MenuItem
          title={ { text: t('sideMenu.customer_support') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.support, path: appLinks.customerSupport }) }
          asHref
          openInNewTab
          linkTo= { appLinks.customerSupport }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <MenuItem
          title={ { text: t('sideMenu.menu_apply_advisor') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.forAdvisors, path: appLinks.forAdvisors }) }
          linkTo={ appLinks.forAdvisors }
          asHref
          openInNewTab
        />
      </div>
    );
  }

  function renderLoggedInSideMenu() {
    return (
      <div className={ classes.sideMenu }>
        <MenuItem
          title={ { text: t('sideMenu.home'), active: isMenuItemActive('/') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.home, path: language === 'en' ? appRoutes.home : appRoutes.esHome }) }
          linkTo={ appRoutes.home }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        <SpecialitiesMenuItem />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderMyHoroscopeMenuItem(t, userLoggedIn)}
        <MenuItem
          title={ { text: t('activity.my_activity'), active: isMenuItemActive(appRoutes.myOrders) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.myOrders, path: appRoutes.myOrders }) }
          linkTo={ appRoutes.myOrders }
          extraNode={ (
            <div className={ classes.badgeContainer }>
              <MyOrdersBadge />
              <MessageCountBadge />
            </div>
      ) }
        />
        <MenuItem
          title={ { text: t('sideMenu.favorite_advisors'), active: isMenuItemActive(appRoutes.favoriteAdvisors) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.favAdvisors, path: appRoutes.favoriteAdvisors }) }
          linkTo={ { pathname: appRoutes.favoriteAdvisors, state: { needRefresh: true } } }
        />
        <MenuItem
          title={ { text: t('sideMenu.add_funds'), active: isMenuItemActive(appRoutes.addFunds) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.credit, path: appRoutes.addFunds }) }
          linkTo={ appRoutes.addFunds }
        />
        { shouldShowPayoutMethodMenuItem ? (
          <MenuItem
            title={ { text:t('sideMenu.payment_methods'), active: isMenuItemActive(appRoutes.paymentSources) } }
            icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.paymentSources, path: appRoutes.paymentSources }) }
            linkTo={ appRoutes.paymentSources }
          />
        ) : null}
        {cashbackItem()}
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {inviteFriendsItem()}
        <MenuItem
          title={ { text: t('sideMenu.apply_promo_code'), active: isMenuItemActive(appRoutes.promoCode) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.promo, path: appRoutes.promoCode }) }
          linkTo={ appRoutes.promoCode }
        />
        <div className={ classes.sideMenuItem }><div className={ classes.menuDividers } /></div>
        {renderBlogMenuItem(t)}
        { renderChangeLanguageMenuItem({ t, languageShow, showLanguageSwitcher }) }
        <MenuItem
          title={ { text: t('sideMenu.customer_support'), active: languageShow } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.support, path: appLinks.customerSupport }) }
          asHref
          openInNewTab
          linkTo= { appLinks.customerSupport }
        />
        <MenuItem
          title={ { text: t('sideMenu.menu_apply_advisor') } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.forAdvisors, path: appLinks.forAdvisors }) }
          linkTo={ appLinks.forAdvisors }
          asHref
          openInNewTab
        />
        <MenuItem
          title={ { text: t('sideMenu.settings'), active: isMenuItemActive(appRoutes.settings) } }
          icon={ renderSideMenuIcon({ iconName: sideMenuIconNames.settings, path: appRoutes.settings }) }
          linkTo={ appRoutes.settings }
        />
      </div>
    );
  }

  const renderContent = () => (
    <div>
      <div suppressHydrationWarning className={ classes.drawer } style={ visible ? { '--display': 'flex' } : { '--display': 'none' } }>
        <div className={ classes.sideMenuHeaderLogoContainer } suppressHydrationWarning>
          <HeaderLogo toggleDrawer={ toggleDrawer } sideMenuIcon={ getIcon({ iconName: sideMenuIconNames.sideMenuClosed, requireContext, forAppContext: true }) } onSideMenu />
        </div>
        { userLoggedIn ? renderLoggedInSideMenu() : renderLoggedOutSideMenu() }
      </div>
      <div suppressHydrationWarning className={ classes.drawerMask } onClick={ toggleDrawer } onKeyDown={ toggleDrawer } style={ visible ? { '--display': 'flex' } : { '--display': 'none' } } />
    </div>
  );

  return renderContent();
}

SideMenu.propTypes = {
  t: PropTypes.func.isRequired,
  isSufficientForCashback: PropTypes.bool,
  cashbackAmount: PropTypes.string,
  userInviteCode: PropTypes.string,
  showLanguageSwitcher: PropTypes.func.isRequired,
  languageShow: PropTypes.bool.isRequired,
  shouldShowPayoutMethodMenuItem: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

SideMenu.defaultProps = {
  isSufficientForCashback: false,
  cashbackAmount: '0.0',
  userInviteCode: null
};

export default SideMenu;
