import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';
import env from '../../../config/variables';

const { GOOGLE_CLIENT_ID } = env;

export const gogleLoginScript = () => <script src="https://accounts.google.com/gsi/client" async defer />;

function GoogleLoginBtn({ googleCallback }) {
  const [googleConfig, setGoogleConfig] = useState(window.google);
  const googleLoginCallback = (response) => {
    googleCallback(response.credential);
  };

  const listenToPopstate = () => {
    const windowGoogle = window.google;
    setGoogleConfig(windowGoogle);
  };

  useEffect(() => {
    window.addEventListener('load', listenToPopstate, false);
    return () => {
      window.removeEventListener('load', listenToPopstate, false);
    };
  }, []);

  useEffect(() => {
    if (googleConfig) {
      googleConfig.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: googleLoginCallback
      });
      googleConfig.accounts.id.renderButton(
        document.getElementById('google_button_holder'),
        {
          theme: 'outline',
          size: 'large',
          text: 'signin',
          width: 150
        }
      );
    }
  }, [googleConfig]);

  return (
    <div
      className={ classes.googleButton }
      id="google_button_holder"
    />
  );
}
GoogleLoginBtn.propTypes = {
  googleCallback: PropTypes.func.isRequired
};

GoogleLoginBtn.defaultProps = {
};

export default GoogleLoginBtn;
