import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null
};

const handler = {
  [Const.creditCardProcessing.loading]: state => ({
    ...state,
    loading: true
  }),
  [Const.creditCardProcessing.dataLoaded]: (state, { payload }) => ({
    ...state,
    purchaseParams: payload
  }),
  [Const.creditCardProcessing.purchaseLoaded]: (state, { payload }) => ({
    ...state,
    purchaseData: payload
  }),
  [Const.creditCardProcessing.purchaseResponceLoaded]: (state, { payload }) => ({
    ...state,
    purchaseResponce: payload,
    loading: !(payload.okMessage || payload.errorMessage)
  }),
  [Const.creditCardProcessing.clearCreditCardProcessing]: () => ({
    ...defaultState
  }),
  [Const.creditCardProcessing.error]: (state, { error }) => ({
    ...state,
    error: { ...error, text: error.error || error.statusText },
    loading: false
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, handler);
