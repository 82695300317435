/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import SignOut from './sign_out';
import { logOutAction, logOutClear } from '../../../actions/auth';
import withPGTranslation from '../../../config/withPGTranslation';

const mapStateProps = ({ logOut }) => ({ ...logOut });

export default connect(mapStateProps, {
  logOutAction,
  logOutClear
})(withPGTranslation(SignOut));
