import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input, { LOGIN_EMAIL, LOGIN_PASSWORD } from './input';
import classes from '../classes.module.scss';
import { passwordIsValid } from '../../../config/util';
import { emailIsValid } from '../../../../common/config/utils';
import Const from '../../../config/const';
import { trackPageVisit } from '../../../actions/analytics';

const allFieldsValid = (email, password) => emailIsValid(email) && passwordIsValid(password, true);

const onLoginClick = (email, password, checkInputErrors, onLogin) => {
  checkInputErrors();
  if (allFieldsValid(email, password)) {
    onLogin();
  }
};

function LogIn({
  email,
  password,
  loading,
  error,
  t,
  onFieldChanged,
  rememberMe,
  social,
  changeScreen,
  onLogin,
  checkInputErrors,
  clearError
}) {
  useEffect(() => {
    trackPageVisit('log in with email');
  }, []);

  const navigate = (e) => {
    changeScreen(e.target.id);
    clearError();
  };

  const onLoginClickAction = () => {
    onLoginClick(email, password, checkInputErrors, onLogin);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onLoginClick(email, password, checkInputErrors, onLogin);
    }
  };

  return (
    <div>
      <div>
        <Input
          inputType={ LOGIN_EMAIL }
          onKeyDown={ handleKeyPress }
          onChange={ onFieldChanged }
          value={ email }
          id="email"
          disabled={ loading }
          error={ error && error.email && error.email[0] }
        />
      </div>
      <div>
        <Input
          inputType={ LOGIN_PASSWORD }
          onKeyDown={ handleKeyPress }
          onChange={ onFieldChanged }
          value={ password }
          id="password"
          disabled={ loading }
          error={ error && error.password && error.password[0] }
        />
      </div>
      { rememberMe }
      <div>
        <button
          className={ classNames(
            classes.actionButton,
            !loading ? null : classes.disableActionBtn
          ) }
          type="button"
          onClick={ onLoginClickAction }
        >
          <b>{t('login')}</b>
        </button>
      </div>
      <div className={ classes.navigationButtonContainer }>
        <button
          id={ Const.authScreens.forgotPassword }
          className={ classes.navigationButton }
          type="button"
          onClick={ navigate }
        >
          {t('forgot_password')}
        </button>
      </div>
      <div>{social}</div>
      <div className={ classes.navigationButtonContainer }>
        <button
          id={ Const.authScreens.signUp }
          className={ classes.navigationButton }
          type="button"
          onClick={ navigate }
        >
          {t('not_have_account')}
        </button>
      </div>
    </div>
  );
}

LogIn.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
  onFieldChanged: PropTypes.func.isRequired,
  rememberMe: PropTypes.node.isRequired,
  social: PropTypes.node.isRequired,
  changeScreen: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  checkInputErrors: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired
};

LogIn.defaultProps = {
  error: null
};

export default LogIn;
