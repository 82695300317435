import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import { chatTypeToOrderType, purchaseTypeToOrderType } from '../../../config/const';
import { toCamelCase } from '../../../../common/config/utils';

const errorIcon = require('./img/error_icon.svg');

const getBuyBtnClassName = ({ loading, disableBuyButton }) => {
  if (loading) return classnames(classes.creditCardValidateBuyBtnContainer, classes.skeleton);
  if (disableBuyButton) {
    return classnames(classes.creditCardValidateBuyBtnContainer, classes.creditCardValidateDisableBuyButton);
  }
  return classes.creditCardValidateBuyBtnContainer;
};

const renderError = ({ t, error }) => {
  if (!error) return null;
  return (
    <div className={ classes.creditCardValidateErrorContainer }>
      <div className={ classes.creditCardValidateErrorImgContainer }>
        <img src={ errorIcon } alt="" className={ classes.creditCardValidateErrorImg } />
      </div>
      <div className={ classes.creditCardValidateErrorTitle }>
        { t('credit_card_validate_processing.card_validation_failed') }
      </div>
      <div className={ classes.creditCardValidateErrorText }>
        { t('credit_card_validate_processing.there_was_a_failure_validating_your_card') }
      </div>
    </div>
  );
};

const getValidateCreditCardRequestParams = ({ advisorId, chatType }) => {
  const {
    intendedAdvisorId, purchaseType
  } = toCamelCase(queryString.parse(window.location.search));
  const requestParams = {
    intended_advisor_id: parseFloat(intendedAdvisorId) || advisorId,
    order_type: purchaseTypeToOrderType[purchaseType] || chatTypeToOrderType[chatType]
  };
  return requestParams;
};

function CreditCardValidateProcessing({
  disableBuyButton, loading, onCreditCardValidated, validateCreditCardAction, validatedData,
  error, validateCreditCardClearAction, getValidateCreditCardStatus, creditCardValidateProcessingValidating, requiresPayAsYouGoValidation, advisorId, chatType
}) {
  const { t } = useTranslation();

  const [getValidateCreditCardStatusInterval, setGetValidateCreditCardStatusInterval] = useState(null);

  useEffect(() => {
    if (validatedData) {
      const { id, state } = validatedData || {};
      if (state === 'pending' && !getValidateCreditCardStatusInterval) {
        const int = setInterval(() => {
          getValidateCreditCardStatus({ id });
        }, 1500);
        setGetValidateCreditCardStatusInterval(int);
        return () => {
          clearInterval(int);
          setGetValidateCreditCardStatusInterval(null);
        };
      }
      if (state !== 'pending' || error) {
        clearInterval(getValidateCreditCardStatusInterval);
        setGetValidateCreditCardStatusInterval(null);
        creditCardValidateProcessingValidating({ validating: false });
      }
      if (state === 'validated') {
        onCreditCardValidated();
      }
    }
    return () => {};
  }, [validatedData]);

  useEffect(() => () => {
    clearInterval(getValidateCreditCardStatusInterval);
    setGetValidateCreditCardStatusInterval(null);
    validateCreditCardClearAction();
  }, []);

  const validateCreditCardActionHandler = () => {
    const requestParams = getValidateCreditCardRequestParams({ advisorId, chatType });
    validateCreditCardAction({ requestParams });
  };

  const renderCreditCardValidateBtn = () => {
    let onClickBtn = onCreditCardValidated;
    let text = t('purchase.purchase_start_session');
    if (requiresPayAsYouGoValidation) {
      onClickBtn = validateCreditCardActionHandler;
    }
    if (error) {
      text = t('general.ok');
      onClickBtn = validateCreditCardClearAction;
    }
    return (
      <button type="button" onClick={ onClickBtn } className={ getBuyBtnClassName({ loading, disableBuyButton, error  }) }>
        { text }
      </button>
    );
  };

  const renderCreditCardValidateProcessing = () => (
    <div className={ classes.creditCardValidateContainer }>
      { renderError({ t, error }) }
      { renderCreditCardValidateBtn() }
    </div>
  );

  return renderCreditCardValidateProcessing();
}

CreditCardValidateProcessing.propTypes = {
  onCreditCardValidated: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disableBuyButton: PropTypes.bool,
  validateCreditCardAction: PropTypes.func.isRequired,
  validateCreditCardClearAction: PropTypes.func.isRequired,
  requiresPayAsYouGoValidation: PropTypes.bool,
  advisorId: PropTypes.number,
  chatType: PropTypes.string
};

CreditCardValidateProcessing.defaultProps = {
  loading: null,
  disableBuyButton: null,
  validatedData: null,
  requiresPayAsYouGoValidation: null,
  advisorId: null,
  chatType: null
};

export default CreditCardValidateProcessing;
