import {
  passwordForgotAPI
} from '../config/api';
import Const from '../config/const';
import { toCamelCase } from '../../common/config/utils';

const passwordForgotLoading = () => ({
  type: Const.passwordForgot.loading
});

const passwordForgotSent = () => ({
  type: Const.passwordForgot.success
});

const passwordForgotError = (error) => ({
  type: Const.passwordForgot.error,
  error
});

export const passwordForgotClear = () => ({
  type: Const.passwordForgot.clear
});

export const clearError = () => ({
  type: Const.passwordForgot.clearError
});

export const forgot = (email) => (dispatch) => {
  dispatch(passwordForgotLoading());
  passwordForgotAPI(email)
    .then(() => dispatch(passwordForgotSent()))
    .catch((error) => error.json()
      .then((json) => {
        const fJson = toCamelCase(json);
        if (fJson.fullMessages && fJson.fullMessages.length > 0) {
          dispatch(passwordForgotError(fJson.fullMessages[0]));
        }
      }));
};
