import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  noAnswer
} from '../../actions/voip_chat';

function AutoHangupTimer({ noAnswerCall, automaticHangupIn, timerRef }) {
  if (!timerRef) {
    return null;
  }
  const timer = timerRef;
  useEffect(() => {
    timer.current = setTimeout(() => {
      noAnswerCall();
    }, automaticHangupIn * 1000);
    return () => clearTimeout(timer.current);
  }, [automaticHangupIn]);
  return <div />;
}

AutoHangupTimer.propTypes = {
  automaticHangupIn: PropTypes.number.isRequired,
  noAnswerCall: PropTypes.func.isRequired,
  timerRef: PropTypes.object
};

AutoHangupTimer.defaultProps = {
  timerRef: null
};

const mapStateToProps = ({ voipChat }) => ({ ...voipChat, ...voipChat.conversationOption });

const mapDispatchToProps = (dispatch) => ({
  noAnswerCall: () => dispatch(noAnswer())
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoHangupTimer);
