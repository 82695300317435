import React from 'react';
import PropTypes from 'prop-types';
import withPGTranslation from '../../../config/withPGTranslation';
import classes from '../classes.module.scss';
import { getReviewCount } from '../../../config/util';

const starIcon = require('../img/starIcon.svg');
const starGreyIcon = require('../img/new_advisor.svg');

const getRatingText = (isNewAdvisor, rating, t) => {
  if (isNewAdvisor) return t('home_page.rating_new_advisor');
  if (!rating && rating !== 0) return <div className={ classes.ratingTextSkeleton } suppressHydrationWarning />;
  return parseFloat(rating).toFixed(1);
};

const getRatingTextClass = (isNewAdvisor) => {
  if (isNewAdvisor) return classes.newAdvisorText;
  return classes.ratingText;
};

const getRatingContaineritemProp = (reviewCount, rating, needAddItemProp) => {
  if (reviewCount > 0 && rating > 0 && needAddItemProp) {
    return { itemProp:'aggregateRating', itemScope:'itemScope', itemType:'http://schema.org/AggregateRating' };
  }
  return null;
};

const addMetaTag = (reviewCount, rating, needAddItemProp) => {
  if (reviewCount > 0 && rating > 0 && needAddItemProp) {
    return (
      <>
        <meta id="metaTagItemPropRatingCount" content={ `${ reviewCount }` } itemProp="ratingCount" suppressHydrationWarning />
        <meta id="metaTagItemPropRatingValue" content={ `${ (parseFloat(rating)) }` } itemProp="ratingValue" suppressHydrationWarning />
      </>
    );
  }
  return null;
};

function Rating({
  rating, isNewAdvisor, t, likesCount, dislikesCount, needAddItemProp, color
}) {
  if (!rating && !isNewAdvisor) {
    return null;
  }

  const reviewCount = getReviewCount({ likesCount, dislikesCount });

  const renderRating = () => (
    <div
      className={ classes.ratingContainer }
      style={ { backgroundColor: color || (isNewAdvisor ? '#7FD14A' : '#FFECBC') } }
      { ...getRatingContaineritemProp(reviewCount, rating, needAddItemProp) }
      suppressHydrationWarning
    >
      <img src={ isNewAdvisor ? starGreyIcon : starIcon } alt = "" className={ classes.starImg } suppressHydrationWarning />
      <div className={ getRatingTextClass(isNewAdvisor) } suppressHydrationWarning>
        { getRatingText(isNewAdvisor, rating, t) }
      </div>
      { addMetaTag(reviewCount, rating, needAddItemProp) }
    </div>
  );

  return renderRating();
}

Rating.propTypes = {
  rating: PropTypes.number,
  isNewAdvisor: PropTypes.bool,
  t: PropTypes.func.isRequired,
  needAddItemProp: PropTypes.bool,
  likesCount: PropTypes.number,
  dislikesCount: PropTypes.number,
  color: PropTypes.string
};

Rating.defaultProps = {
  isNewAdvisor: null,
  rating: null,
  needAddItemProp: null,
  likesCount: null,
  dislikesCount: null,
  color: null
};

export default withPGTranslation(Rating);
