import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  orderDetails: null,
  error: null
});

const orderDetailsReducer = createReducer(getDefaultState(), {
  [Const.orderDetails.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.orderDetails.loaded]: (state, { orderDetails }) => ({
    ...state,
    loading: false,
    orderDetails: orderDetails.order
  }),
  [Const.orderDetails.sendedOrderReview]: (state, { orderDetails }) => ({
    ...state,
    loading: false,
    orderDetails: orderDetails.order
  }),
  [Const.orderDetails.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.orderDetails.messagesRead]: (state) => ({
    ...state,
    orderDetails: {
      ...state.orderDetails,
      buyerUnreadMessagesCount: 0
    }
  }),
  [Const.orderDetails.sendedOrderReviewError]: (state, { error }) => ({
    ...state,
    error,
    loading: false
  }),
  [Const.orderDetails.sendedOrderReviewClearError]: (state) => ({
    ...state,
    error: null
  }),
  [Const.aplicationError.clearError]: (state) => ({
    ...state,
    loading: false
  })
});

export default orderDetailsReducer;
