import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  categories: [],
  loading: false
};

const handler = {
  [Const.categories.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.categories.loaded]: (state, { categories }) => ({
    ...state,
    categories,
    loading: false
  })
};

export default createReducer(defaultState, handler);
