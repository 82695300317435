import React, {  useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './classes.module.scss';
import  withPGTranslation  from '../../config/withPGTranslation';
import { onHeaderSearchClick, isHomePage } from '../../config/util';

const headerSearch = require('./img/header_search_icon.svg');
const headerSearchCloseIcon = require('./img/header_search_close_icon.svg');
const headerSearchGreyIcon = require('./img/header_search_icon_grey.svg');

const innerSearchKinds = {
  button: 'button',
  input:'input'
};
const getInnerSearchItemDisplay = (showHeaderSearchLine, kind) => {
  if (kind === innerSearchKinds.button) {
    return { display: showHeaderSearchLine ? 'none' : 'flex' };
  }
  return { display: showHeaderSearchLine ? 'flex' : 'none' };
};

function HeaderSearch({ t }) {
  const history = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef();
  const [showHeaderSearchLine, setShowHeaderSearchLine] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(
    queryString.parse(window.location.search).query
  );

  useEffect(() => {
    setSearchInputValue(queryString.parse(window.location.search).query);
  }, [window.location.search]);

  useEffect(() => {
    if ((searchInputRef || {}).current) searchInputRef.current.focus();
  }, [showHeaderSearchLine]);

  const onHeaderSearchLineButtonClick = () => {
    onHeaderSearchClick({
      searchInputRef, setSearchInputValue, history, location
    });
  };

  const onHeaderSearchLineButtonKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onHeaderSearchLineButtonClick();
    }
  };

  const searchInputValueChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const headerSearchButtonClick = () => {
    setShowHeaderSearchLine(true);
  };

  const headerSearchLineCloseButtonClick = () => {
    onHeaderSearchClick({
      searchInputRef,
      setSearchInputValue,
      history,
      location,
      isSearchLineCloseButtonClick: true
    });
    setShowHeaderSearchLine(false);
  };

  const closeButton = () => (
    <div className={ classes.headerSearchLineSearchCloseButtonContainer }>
      <button
        type="button"
        className={ classes.headerSearchLineButton }
        onClick={ onHeaderSearchLineButtonClick }
      >
        { t('search_button_title') }
      </button>
      <button
        type="button"
        className={ classes.headerSearchLineCloseButton }
        onClick={ headerSearchLineCloseButtonClick }
      >
        <img src={ headerSearchCloseIcon } className={ classes.headerSearchLineCloseButtonImg } alt="" />
      </button>
    </div>
  );

  const searchInput = () => (
    <div
      suppressHydrationWarning
      className={ classes.headerSearchLineContainer }
      style={ getInnerSearchItemDisplay(showHeaderSearchLine, innerSearchKinds.input) }
    >
      <div className={ classes.headerSearchLineInputContainer }>
        <div className={ classes.headerSearchLineSearchIconAndInputContainer }>
          <img src={ headerSearchGreyIcon } className={ classes.headerSearchGreyIcon } alt="" />
          <form className={ classes.headerSearchLineForm }>
            <input
              type="text"
              ref={ searchInputRef }
              value={ searchInputValue || '' }
              onChange={ searchInputValueChange }
              className={ classes.headerSearchLineInput }
              placeholder={ t('search_placeholder') }
              onKeyDown={ onHeaderSearchLineButtonKeyDown }
              autoComplete="off"
            />
          </form>
        </div>
        <div className={ classes.headerSearchLineDivider } />
      </div>
      {closeButton()}
    </div>
  );

  const renderSearch = () => {
    if (isHomePage()) return null;
    return (
      <div className={ classes.headerSearchMainContainer } suppressHydrationWarning>
        <button
          suppressHydrationWarning
          type="button"
          className={ classes.headerSearchButton }
          onClick={ headerSearchButtonClick }
          style={ getInnerSearchItemDisplay(showHeaderSearchLine, innerSearchKinds.button) }
        >
          <img src={ headerSearch } className={ classes.headerSearchButtonImg } alt="" />
        </button>
        {searchInput()}
      </div>
    );
  };

  return renderSearch();
}

HeaderSearch.propTypes = {
  t:PropTypes.func.isRequired
};

export default withPGTranslation(HeaderSearch);
