import React from 'react';
import NotFound from '../../not_found';
import ChangeLanguage from '../../change_language';
import PushNotification from '../../push_notifcation';
import GeneralError from '../../general_error';
import SmartBanner from '../../shared/smart_banner';

function Overlays() {
  return (
    <>
      <NotFound />
      <ChangeLanguage />
      <PushNotification />
      <GeneralError />
      <SmartBanner />
    </>
  );
}

export default Overlays;
