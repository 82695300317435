import React from 'react';
import PropTypes from 'prop-types';
import { withLastLocation } from 'react-router-dom-last-location';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../../reducers/advisor_prop_types';
import classes from './classes.module.scss';
import ReviewsSection from '../reviews_section';
import { makeMoreReadable } from '../../../../config/util';
import SomeOtherAdvisors from '../../some_other_advisors';
import { psychicsListTypes } from '../../../../config/const';
import AdvisorSpecialities from './advisor_specialities';

const descriptionContent = (title, description, needAddItemProp) => (
  <div className={ classes.descriptionHolder }>
    {title}
    <span className={ classes.description } itemProp={ needAddItemProp ? 'description' : '' }>
      {makeMoreReadable(description) || <Skeleton count={ 4 } height={ 17 } />}
    </span>
  </div>
);

const reviewsContent = (id) => (!id  ? null : (
  <ReviewsSection id={ id } />
));

const specialitiesContent = (specialities, advisorId, loading) => (
  <AdvisorSpecialities specialities={ specialities } advisorId={ advisorId } loading={ loading } />
);

const renderNoAavailableForPG = (liveModes, t, loading, location) => {
  const advisorAvalibalForPG = liveModes.find((item) => item.modeState !== 'disabled');
  if (liveModes.length > 0 && !advisorAvalibalForPG && typeof (loading) !== 'undefined' && !loading) {
    return (
      <SomeOtherAdvisors
        theme={ psychicsListTypes.NOTAVAILABLEFORPG }
        title={ t('notAvailableForPg.sorry_this_advisor_is_not_available_for_live_readings_now') }
        subTitle={ t('notAvailableForPg.here_are_some_other_advisors_who_may_interest_you') }
        location={ location }
      />
    );
  }
  return null;
};

function AdvisorMainContent({
  serviceDescription, aboutMe, specialities, id, t, liveModes, loading
}) {
  const location = useLocation();
  return (
    <div className={ classes.container }>
      {descriptionContent(t('advisorProfile.about_my_services'), serviceDescription, true)}
      {descriptionContent(t('advisorProfile.about_me'), aboutMe)}
      {specialitiesContent(specialities, id, loading)}
      {renderNoAavailableForPG(liveModes, t, loading, location)}
      {reviewsContent(id)}
    </div>
  );
}

AdvisorMainContent.propTypes = {
  ...AdvisorPropType,
  t: PropTypes.func.isRequired
};

AdvisorMainContent.defaultProps = {
  ...AdvisorDefaultProps
};

export default withLastLocation(AdvisorMainContent);
