import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import withPGTranslation from '../../../config/withPGTranslation';
import { clearAplicationError } from '../../../actions/aplication_error';
import SomeOtherAdvisors from './some_other_advisors';
import { loadAdvisors } from '../../../actions/search_results';

const mapStateToProps = ({
  aplicationError,
  searchResults
}, {
  location: { pathname }
}) => ({
  ...aplicationError,
  pathname,
  ...searchResults[pathname]
});

export default  withLastLocation(withPGTranslation(connect(mapStateToProps, {
  clearAplicationError,
  loadAdvisors
})(SomeOtherAdvisors)));
