import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { matchPaths, isKA } from '../../common/config/utils';
import en from './translations/en.json';
import es from './translations/es.json';

export const NAME_SPACE = 'pg';

export const DEFAULT_LANGUAGE = 'en';

export const SUPPORTED_LANGUAGE = {
  en: 'en',
  es: 'es'
};

const getLANGUAGE = () => {
  let language = {
    en: 'en',
    es: 'es'
  };

  if (isKA()) {
    language = {
      en: 'en'
    };
  }
  return language;
};

export const LANGUAGE = getLANGUAGE();

let extraES = null;
let extraEN = null;
if (isKA()) {
  extraES = require('./translations/es_ks.json');
  extraEN = require('./translations/en_ks.json');
} else {
  extraES = require('./translations/es_pg.json');
  extraEN = require('./translations/en_pg.json');
}

export const getCurrentLocalization = () => {
  const match = matchPaths({ pathname: ['/:locale', '/:locale/*'], path: window.location.pathname });
  if (!match) return DEFAULT_LANGUAGE;
  const { params: { locale } } = match || {};
  if (locale === 'my') return DEFAULT_LANGUAGE;
  if (Object.keys(LANGUAGE).includes(locale)) {
    return locale;
  }
  return DEFAULT_LANGUAGE;
};

i18n.use(initReactI18next).init({
  resources: {
    [LANGUAGE.en]: {
      translation: { ...en, ...extraEN }
    },
    [LANGUAGE.es]: {
      translation: { ...es, ...extraES }
    }
  },
  fallbackLng: DEFAULT_LANGUAGE
});
i18n.changeLanguage(getCurrentLocalization());

export default i18n;
