import { connect } from 'react-redux';
import withPGTranslation from '../../../config/withPGTranslation';
import Footer from './footer';

const mapStateToProps = ({
  homePage
}) => ({
  specialities: homePage.specialities || []
});

export default connect(mapStateToProps, {})(withPGTranslation(Footer));
