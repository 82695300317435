import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import classnames from 'classnames';
import classes from '../classes.module.scss';
import env from '../../../config/variables';

const { FACEBOOK_ID, FACEBOOK_REDIRECT, FACEBOOK_VERSION } = env;

const fbLogo = require('../img/fb_login.svg');

function FacebookBtn({ facebookCallback }) {
  const renderFBBtn = renderProps => {
    const {
      onClick, isDisabled, isProcessing, isSdkLoaded
    } = renderProps;
    const disabled = isDisabled || !isSdkLoaded;
    return (
      <button
        type="button"
        className={ classnames(
          classes.fbButton,
          isProcessing ? classes.fbProcessing : null,
          disabled ? classes.socialDisabled : null
        ) }
        onClick={ onClick }
      >
        <img className={ classes.socialImg } src={ fbLogo } alt="" width={ 21 } />
        Facebook
        <div />
      </button>
    );
  };

  return (
    <FacebookLogin
      appId={ FACEBOOK_ID }
      fields="email,picture"
      callback={ facebookCallback }
      cookie
      xfbml
      version={ FACEBOOK_VERSION }
      disableMobileRedirect
      redirectUri={ FACEBOOK_REDIRECT }
      render={ renderFBBtn }
    />
  );
}

FacebookBtn.propTypes = {
  facebookCallback: PropTypes.func.isRequired
};

FacebookBtn.defaultProps = {
};

export default FacebookBtn;
