import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from '../reducers';

export const buildStore = () => {
  const logger = createLogger({ diff: false });
  let middleWares = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleWares = [...middleWares, logger];
  }
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(middleWares)
  });
  if (module.hot) {
    module.hot.accept(() => {
      /* eslint-disable global-require */
      const nextRootReducer = require('../reducers/index').default;
      /* eslint-enable global-require */
      store.replaceReducer(nextRootReducer);
    });
  }
  window.store = store;
  return store;
};
