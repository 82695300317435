import PropTypes from 'prop-types';
import queryString from 'query-string';
import createReducer from '../config/create_reducer';
import Const from '../config/const';

export const UserVerificationPropType = PropTypes.shape({
  kind: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  id: PropTypes.number,
  uploadUrl: PropTypes.string
});

const utilityBillStatus = (queryString.parse(window.location.search) || {}).idScanStatus;
const utilityBillErrorCode = (queryString.parse(window.location.search) || {}).errorCode;

const getDefaultState = () => ({
  userVerifications: [],
  selectedVerification: null,
  loading: false,
  error: null,
  jumioProcessing: false,
  verificationProcessing: false
});

const updateVerificationsArray = (
  currentVerifications,
  newVerification
) =>   currentVerifications.map((verification) => {
  if (verification.kind === newVerification.kind) {
    return newVerification;
  }
  return verification;
});

const purchaseDetailsReducer = createReducer(getDefaultState(), {
  [Const.spendingLimits.loadingVerifications]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.spendingLimits.verificationsLoaded]: (state, { userVerifications }) => ({
    ...state,
    userVerifications,
    loading: false,
    verificationProcessing : !!utilityBillStatus && utilityBillStatus === 'SUCCESS',
    selectedVerification: utilityBillStatus ? userVerifications.find((ver) => ver.kind === 'utility_bill') : state.selectedVerification,
    error: utilityBillStatus && utilityBillStatus === 'ERROR' ? utilityBillErrorCode : state.error
  }),
  [Const.spendingLimits.loadVerificationsError]: (state, { error }) => ({
    ...state,
    error,
    loading: false
  }),
  [Const.spendingLimits.verificationSelected] : (state, { verification }) => ({
    ...state,
    selectedVerification: verification,
    userVerifications: !verification
      ? state.userVerifications :  updateVerificationsArray(state.userVerifications, verification),
    jumioProcessing: !!verification,
    loading: false
  }),
  [Const.spendingLimits.jumioEnded]: (state) => ({
    ...state,
    jumioProcessing: false,
    verificationProcessing: true
  }),
  [Const.spendingLimits.verificationProcessed]: (state) => ({
    ...state,
    verificationProcessing: false
  }),
  [Const.spendingLimits.verificationStatusUpdated]: (state, { verification, onError }) => ({
    ...state,
    userVerifications: updateVerificationsArray(state.userVerifications, verification),
    verificationProcessing: !onError
  && (verification.state !== 'processing_completed' && verification.state !== 'approved' && verification.state !== 'rejected')
  }),
  [Const.spendingLimits.cleanError]: (state) => ({
    ...state,
    error: null
  }),
  [Const.spendingLimits.jumioError]: (state, { error }) => ({
    ...state,
    jumioProcessing: false,
    error
  })
});

export default purchaseDetailsReducer;
