import { connect } from 'react-redux';
import SmartBanner from './smart_banner';
import { updateSmartBannerVisibility } from '../../../actions/application';
import { trackSmartBannerEvent } from '../../../actions/analytics';
import { getUserMatchUpBonusInfo } from '../../../config/util';

const mapStateToProps = ({
  user: { user }, homePage, application
}) => ({
  hasOrders: !!((user || {}).numberOfChatOrders || (user || {}).numberOfVcOrders
  || (user || {}).numberOfVoiceCallOrders),
  promoBannerIsOn: !!((homePage.salesBanner || {}).content),
  smartBannerVisible: application.smartBannerVisible,
  userMatchUpBonusInfo: getUserMatchUpBonusInfo({ bonusInfos: user && user.bonusInfos }),
  userShowMatchUpBonusInfo: user && user.showMatchUpBonusInfo,
  userLoading: false
});

export default connect(
  mapStateToProps,
  {
    updateSmartBannerVisibility,
    trackSmartBannerEvent
  }
)(SmartBanner);
