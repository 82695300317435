import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { differenceInDays } from 'date-fns';
import classes from './classes.module.scss';
import RateSection from './rate_section';
import ReviewSection from './review_section';
import withPGTranslation from '../../config/withPGTranslation';
import Const, { rateReviewTheme } from '../../config/const';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import Alert from '../../../common/helpers/alert';
import { paramOrNul } from '../../../common/config/utils';
import { getSessionType } from '../../actions/analytics';

const checkCanPostLikeComment = (like, comment, t) => {
  let canPost = true;
  const errors = [];
  if (like === null) {
    errors.push(t('rate_review.please_rate_the_advisor'));
    canPost = false;
  }
  if (!like && comment.length < 10) {
    errors.push(t('rate_review.please_provide_some_details_about_your_experience'));
    canPost = false;
  }
  if (like && comment.length < 3) {
    errors.push(t('rate_review.please_provide_some_details_about_your_experience'));
    canPost = false;
  }
  return { canPost, errors };
};

const checkCanPost = (like, comment, setAlerts, t) => {
  const { canPost, errors } = checkCanPostLikeComment(like, comment, t);
  if (!canPost) {
    const uniqErrors = [...new Set(errors)];
    setAlerts([...uniqErrors]);
  }
  return canPost;
};

const getFeedbackData = (props) => {
  const {
    like, comment, xfm, orderId, ppm, undiscountedPpm, advisorName,
    clickSource,  advisorId, profileLanguage, createdAt, totalCharged, chatType, discountValue
  } = props;
  const totalChargedNumber = totalCharged ? Number(totalCharged) : undefined;
  const feedbackData = {
    ...paramOrNul('advisor name', advisorName),
    ...paramOrNul('ppm', ppm),
    ...paramOrNul('undiscounted ppm', undiscountedPpm),
    ...paramOrNul('session amount', totalChargedNumber),
    ...paramOrNul(`${ getSessionType(chatType) } charged`, totalChargedNumber || totalCharged),
    ...paramOrNul('xfm', xfm),
    ...paramOrNul('discount value', discountValue),
    ...paramOrNul('click source', clickSource),
    ...paramOrNul('advisor id', advisorId),
    ...paramOrNul('order id', orderId),
    ...paramOrNul('sentiment', like),
    ...paramOrNul('review length', comment.length),
    ...paramOrNul('chat call', chatType === Const.chatType.text),
    ...paramOrNul('voice call', chatType === Const.chatType.voice),
    ...paramOrNul('video call', chatType === Const.chatType.video),
    ...paramOrNul('profile language', profileLanguage),
    ...paramOrNul('days after order', differenceInDays(new Date(), new Date(createdAt)))
  };
  return feedbackData;
};

const checkSendFeedback = (props) => {
  const {
    like, comment, sendOrderReview, orderId, setAlerts, t, onCancel, redirectTo
  } = props;
  if (!checkCanPost(like, comment, setAlerts, t)) return;
  const feedback = { feedbackVote: like, feedbackComment: comment };
  const feedbackData = getFeedbackData({ ...props });
  sendOrderReview(orderId, feedback, feedbackData);
  onCancel();
  if (redirectTo) redirectTo();
};

const alertsContent = (alerts) => {
  if (alerts.length > 0) {
    return alerts.map((alert, index) => <Alert key={ index } message="" errorString={ alert } banner />);
  }
  return null;
};

const redirectIfNeed = ({ redirectTo }) => {
  if (redirectTo) redirectTo();
};

function RateReview({
  visible, onCancel, orderId, advisorName, sendOrderReview, totalCharged,
  existingComment, existingLike, t, clickSource, advisorId, chatType,
  profileLanguage, createdAt, redirectTo,
  theme, analytics
}) {
  const [like, setLike] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setLike(existingLike);
    setComment(existingComment);
  }, [existingLike, existingComment]);

  useEffect(() => {
    if (alerts.length === 0 && shouldValidate) {
      checkSendFeedback({
        like, comment, sendOrderReview, orderId, advisorName, setAlerts, t, chatType, clickSource,
        advisorId, profileLanguage, createdAt, onCancel, redirectTo, totalCharged, ...analytics
      });
      setShouldValidate(false);
    }
  }, [alerts, shouldValidate]);

  const onSkipClick = () => {
    setLike(existingLike);
    setComment(existingComment);
    setAlerts([]);
    onCancel();
    redirectIfNeed({ redirectTo });
  };

  const onSubmit = (c) => {
    setComment(c);
    if (alerts.length > 0) {
      setAlerts([]);
      setShouldValidate(true);
      return;
    }
    checkSendFeedback({
      like, comment: c, sendOrderReview, orderId, advisorName, chatType, setAlerts, t, clickSource,
      advisorId,  profileLanguage, createdAt, onCancel, redirectTo, totalCharged, ...analytics
    });
  };

  const renderRateReviewDEFAULT = () => (
    <AppGeneralPopup
      visible={ visible }
      closable={ onCancel }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      <div className={ classes.rateReviewContainer }>
        {alertsContent(alerts)}
        <RateSection value={ like } onValueChanged={ (liked) => setLike(liked) } />
        <ReviewSection
          comment={ comment }
          advisorName={ advisorName }
          onSubmit={ onSubmit }
        />
        <div className={ classes.skipButtonContainer }>
          <button type="button" className={ classes.skipButton } onClick={ onSkipClick }>
            {t('rate_review.skip')}
          </button>
        </div>
      </div>
    </AppGeneralPopup>

  );

  const renderRateReviewCHATENDED = () => (
    <div className={ classes.rateReviewCHATENDED }>
      {alertsContent(alerts)}
      <RateSection
        value={ like }
        onValueChanged={ (liked) => setLike(liked) }
        theme={ rateReviewTheme.CHAT_ENDED }
      />
      <ReviewSection
        comment={ comment }
        advisorName={ advisorName }
        onSubmit={ onSubmit }
      />
    </div>
  );

  return (
    <div>
      { { renderRateReviewDEFAULT, renderRateReviewCHATENDED }[`renderRateReview${ theme }`]() }
    </div>
  );
}

RateReview.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  existingComment: PropTypes.string,
  existingLike: PropTypes.bool,
  orderId: PropTypes.number,
  advisorName: PropTypes.string.isRequired,
  sendOrderReview: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  clickSource: PropTypes.string,
  advisorId: PropTypes.number,
  profileLanguage: PropTypes.string,
  createdAt: PropTypes.string,
  redirectTo: PropTypes.func,
  theme: PropTypes.string,
  analytics: PropTypes.object,
  totalCharged: PropTypes.string,
  chatType: PropTypes.string
};

RateReview.defaultProps = {
  existingComment: '',
  existingLike: null,
  clickSource: null,
  advisorId: null,
  profileLanguage: null,
  createdAt: null,
  redirectTo: null,
  theme: rateReviewTheme.DEFAULT,
  analytics: null,
  orderId: null,
  totalCharged: null,
  chatType: null
};

export default withPGTranslation(RateReview);
