import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../config/app_routes';
import withPGTranslation from '../../config/withPGTranslation';
import { updateLink } from '../../config/util';

const getUpdatedLink = (to) => {
  const updatedLink = updateLink(to);
  let link = updatedLink;
  let state = {};
  let search = {};
  if (typeof updatedLink === 'object') {
    link = updatedLink.pathname;
    state = updatedLink.state;
    search = updatedLink.search;
  }
  return { link, state, search };
};

function AppLink({
  to, className, children, replace, onClick, alt, style, itemProp, blank, rel, reloadDocument
}) {
  const history = useNavigate();

  const onLinkClick = (e) => {
    if (blank) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }

    const { link, search, state } = getUpdatedLink(to);
    if (window.location.pathname === link) {
      e.preventDefault();
      history(`${ link }?${ search }`, { state, replace: true });
      return;
    }
    if (link === appRoutes.home || link === appRoutes.esHome) {
      window.scrollTo(0, 0);
    }
  };

  const renderAppLink = () => {
    const { link, search, state } = getUpdatedLink(to);
    return (
      <Link
        to={ `${ link }${ queryString.stringify(queryString.parse(search)) }` }
        state={ state }
        rel={ rel }
        className={ className }
        replace={ replace }
        onClick={ onLinkClick }
        alt={ alt }
        style={ style }
        itemProp={ itemProp }
        suppressHydrationWarning
        reloadDocument={ reloadDocument }
      >
        { children }
      </Link>
    );
  };

  return renderAppLink();
}

AppLink.propTypes = {
  to: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  replace: PropTypes.bool,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  style: PropTypes.object,
  itemProp: PropTypes.string,
  blank: PropTypes.bool,
  reloadDocument: PropTypes.bool
};

AppLink.defaultProps = {
  to: null,
  className: '',
  children: null,
  replace: false,
  onClick: null,
  alt: null,
  style: null,
  itemProp: null,
  blank: null,
  reloadDocument: false
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withPGTranslation(AppLink));
