import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './classes.module.scss';
import { LANGUAGE, getCurrentLocalization } from '../../config/i18n';
import { log } from '../../config/app_logger';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { getRedirectPath } from '../routes/routes_utils';
import { trackPageVisit } from '../../actions/analytics';

const closeIcon = require('./img/close_icon.svg');

function ChangeLanguage({
  t, show, showLanguage
}) {
  const history = useNavigate();
  const location = useLocation();
  const language = getCurrentLocalization();

  useEffect(() => {
    if (show) trackPageVisit('language setting');
  }, [show]);

  const onCloseCliked = useCallback(() => {
    showLanguage(false);
  }, []);

  const onLanguageChanged = useCallback(e => {
    if (e.target.id !== language) {
      e.target.disabled = true;
      const redirecPath = getRedirectPath({ newLanguage: e.target.id });
      log('General', `On popup language changed from ${ language } to ${ e.target.id }`);
      log('General', `Redirect from  ${ window.location.pathname } to ${ redirecPath }`);
      history(`${ redirecPath }?${ location.search }`, {
        state: location.state,
        replace: true
      });
      history(0);
    }
  }, []);

  const renderCloseBtn = () => (
    <div className={ classes.closeBtnContainer }>
      <button type="button" onClick={ onCloseCliked } className={ classes.closeBtn }>
        <img src={ closeIcon } alt="" />
      </button>
    </div>
  );

  const renderContent = () => (
    <div className={ classes.container }>
      <div className={ classes.titleContainer }>
        <b>{t('language.title')}</b>
      </div>
      <div className={ classes.radioBtnContainer }>
        <button
          type="button"
          id={ LANGUAGE.en }
          onClick={ onLanguageChanged }
          className={ classnames(
            classes.radioBtnLeft,
            LANGUAGE.en === language ? classes.radioBtnSelected : null
          ) }
        >
          {t('language.english')}
        </button>
        <button
          type="button"
          id={ LANGUAGE.es }
          onClick={ onLanguageChanged }
          className={ classnames(
            classes.radioBtnRight,
            LANGUAGE.es === language ? classes.radioBtnSelected : null
          ) }
        >
          {t('language.espanol')}
        </button>
      </div>
    </div>
  );
  return (
    <AppGeneralPopup
      visible={ show }
      customTopHeader={ renderCloseBtn() }
      closable={ onCloseCliked }
      theme={ appGeneralPopupTheme.CENTERED }
    >
      {renderContent()}
    </AppGeneralPopup>
  );
}

ChangeLanguage.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showLanguage: PropTypes.func.isRequired
};

export default ChangeLanguage;
