import { connect } from 'react-redux';
import { uploadUserAvatar } from '../../actions/aws';
import UserAvatarPicker from './user_avatar_picker';

const mapStateToProps = ({ aws }) => ({ ...aws });

export default connect(
  mapStateToProps,
  { uploadUserAvatar }
)(UserAvatarPicker);
