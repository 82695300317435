import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatISO, differenceInSeconds, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';

const callStatusDot = require('./img/call_status_dot.svg');

const getTimerSize = (callType) => (callType === 'chat' ? '35px' : '65px');

const renderDurationText = (secondsCount, secondsPast,  countdownSecondsToShow, callType, t) => (
  <div className={ callType === 'chat' ? classes.timerTextColumnContainer : classes.timerTextRowContainer }>
    { countdownSecondsToShow ? (
      <>
        <span className={ classes.timerSecDuration }>{Math.floor(secondsCount)}</span>
        <span className={ classes.timerSec }>{t('chat.sec')}</span>
      </>
    ) : (
      <>
        <span className={ classes.timerMinDuration }>{Math.ceil(secondsPast / 60) }</span>
        <span className={ classes.timerMin }>{t('chat.min')}</span>
      </>
    )}
  </div>
);

const renderTimerPath = (callType) => (callType === 'chat' ? (
  <g id="gg">
    <path
      id="rect"
      d="M17,3 h10 a5,5 0 0 1 5,5 v26 a0,0 0 0 1 -5,0 h-20 a5,5 0 0 1 -5,-5 v-21 a5,5 0 0 1 5,-5 z"
    />
  </g>
) : (
  <g id="gg">
    <path
      id="rect"
      d="M32,5 h26 a5,5 0 0 1 5,5 v20 a0,0 0 0 1 -5,0 h-50 a5,5 0 0 1 -5,-5 v-15 a5,5 0 0 1 5,-5 z"
    />
  </g>
));

const renderFreeTime = (setupTimeLabels, callType, t) => {
  let durationText = t('chat.free');
  let setupTimeLabelsText = t('chat.setup_time');
  let setupTimeLabelsVoipText = t('chat.free_setup_time');
  if (setupTimeLabels.length) {
    const [text1, ...remainingText] = setupTimeLabels;
    durationText = text1;
    setupTimeLabelsText = remainingText.join(' ');
    setupTimeLabelsVoipText = setupTimeLabels.join(' ');
  }
  if (callType === 'chat') {
    return (
      <div className={ classes.freeTimeContainer }>
        <span className={ classes.freeTimeDuration }>{durationText}</span>
        <span className={ classes.freeTimeText }>{setupTimeLabelsText}</span>
      </div>
    );
  }
  return (
    <div className={ classes.freeTimeVoipContainer }>
      <img src={ callStatusDot } className={ classes.callStatusDotImg } alt="" />
      <span className={ classes.freeTimeVoipText }>{setupTimeLabelsVoipText}</span>
    </div>
  );
};

const getTimerAnimationStrokeValue = (callType) => (callType === 'chat' ? '117' : '167');

const getStopwatchStyle = (secondsCount, sessionEndingHighlightedCountdownSeconds) => (
  secondsCount <= sessionEndingHighlightedCountdownSeconds ? classes.timerHighlightedContainer : classes.timerContainer
);

const Stopwatch = ({
  secondsCount, callType, sessionEndingCountdownSeconds, sessionEndingHighlightedCountdownSeconds, setupTimeLabels, paidDuration
}) => {
  const { t } = useTranslation();
  const [notifyDate, setNotifyDate] = useState(formatISO(new Date()));
  const countdownSecondsToShow = secondsCount < sessionEndingCountdownSeconds;

  useEffect(() => {
    setNotifyDate(formatISO(new Date()));
  }, [secondsCount <= paidDuration]);

  const renderTimer = () => {
    const dateNow = formatISO(new Date());
    const secondsPast = differenceInSeconds(parseISO(dateNow), parseISO(notifyDate)) + 1;
    return (
      <div className={ getStopwatchStyle(secondsCount, sessionEndingHighlightedCountdownSeconds) }>
        {renderDurationText(secondsCount, secondsPast, countdownSecondsToShow, callType, t)}
        <svg width={ getTimerSize(callType) } height="35px" fillOpacity="0%">
          <defs>{renderTimerPath(callType)}</defs>
          <g fill="none" stroke="#EEE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><use xlinkHref="#gg" /></g>
          <g
            className={ classes.timerAnimation }
            style={ { '--strokeValue': getTimerAnimationStrokeValue(callType) } }
            fill="none"
            stroke={ countdownSecondsToShow ? '#FF993C' : '#7BD137' }
            strokeWidth="2"
            strokeLinecap="butt"
            strokeLinejoin="round"
          >
            <use xlinkHref="#rect" />
          </g>
        </svg>
      </div>
    );
  };

  const renderStopwatch = () => {
    if (secondsCount <= 0) return null;
    if (secondsCount <= paidDuration) return renderTimer();
    return renderFreeTime(setupTimeLabels, callType, t);
  };

  return renderStopwatch();
};

Stopwatch.propTypes = {
  secondsCount: PropTypes.number,
  callType: PropTypes.string.isRequired,
  setupTimeLabels: PropTypes.array,
  sessionEndingCountdownSeconds: PropTypes.number,
  sessionEndingHighlightedCountdownSeconds: PropTypes.number,
  paidDuration: PropTypes.number
};

Stopwatch.defaultProps = {
  secondsCount: null,
  sessionEndingCountdownSeconds: null,
  sessionEndingHighlightedCountdownSeconds: null,
  setupTimeLabels: [],
  paidDuration: null
};

export default Stopwatch;
