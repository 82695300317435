import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function DisconnectSessionTimer({
  onTimeOut, stream, timers, timerRef
}) {
  useEffect(() => {
    const timer = timerRef;
    if (timer && timers) {
      timer.current = setTimeout(() => {
        if (stream) {
          onTimeOut();
        }
      }, timers.diconnectTimer * 1000);
      return () => clearInterval(timer.current);
    }
    return () => {};
  }, [timers]);

  return <div />;
}

DisconnectSessionTimer.propTypes = {
  timers: PropTypes.object,
  onTimeOut: PropTypes.func.isRequired,
  stream: PropTypes.object,
  timerRef: PropTypes.object
};

DisconnectSessionTimer.defaultProps = {
  timers: null,
  stream: null,
  timerRef: null
};

const mapStateToProps = ({ voipChat }) => ({ ...voipChat, ...voipChat.conversationOption });

export default connect(mapStateToProps, {})(DisconnectSessionTimer);
