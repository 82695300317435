import { connect } from 'react-redux';
import CreditCardProcessing from './credit_card_processing';
import { getUser } from '../../../actions/user';
import { cancelChat } from '../../../actions/chat';
import { cancelChatOnPP } from '../../../actions/connected_chat';
import { cancelVoipChatOnPP, cancelVoipChat } from '../../../actions/voip_chat';
import { hidePaymentDetails } from '../../../actions/funds';
import { loadAdvisor } from '../../../actions/advisors';
import { hideAffiliatePromoPopup } from '../../../actions/affiliate_promo_popup';
import {
  getCreditCardProcessingData, createCreditCardProcessingPurchase,
  getCreditCardProcessingPurchaseResponce, clearCreditCardProcessing
} from '../../../actions/credit_card_processing';
import { loadPaymentOptions } from '../../../actions/payment_options';
import Const from '../../../config/const';
import { purchase3DSRequiredEvent, purchase3DSFailedEvent } from '../../../actions/analytics';

import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = ({
  user: { user, loading },
  purchaseDetails: { advisorId, fees },
  creditCardProcessing
}) => ({
  ...user,
  userLoading: loading || creditCardProcessing.loading,
  advisorId,
  ...creditCardProcessing,
  fees
});

const defaultFunctions = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  hidePaymentDetails: () => dispatch(hidePaymentDetails()),
  loadAdvisor: (advisorId) => dispatch(loadAdvisor(advisorId)),
  getCreditCardProcessingData: ({ url, params }) => dispatch(
    getCreditCardProcessingData({ url, params })
  ),
  createCreditCardProcessingPurchase: (params) => dispatch(
    createCreditCardProcessingPurchase(params)
  ),
  getCreditCardProcessingPurchaseResponce: (id) => dispatch(
    getCreditCardProcessingPurchaseResponce(id)
  ),
  clearCreditCardProcessing: () => dispatch(clearCreditCardProcessing()),
  dispatch: (func) => dispatch(func),
  loadPaymentOptions: (params) => dispatch(loadPaymentOptions(params)),
  purchase3DSRequiredEvent: (params) => dispatch(purchase3DSRequiredEvent(params)),
  purchase3DSFailedEvent: (params) => dispatch(purchase3DSFailedEvent(params))
});

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(cancelChat()),
        cancelChatOnPP: () => dispatch(cancelChatOnPP(ownProps.isContinue)),
        hideAffiliatePromoPopup: () => dispatch(hideAffiliatePromoPopup()),
        ...defaultFunctions(dispatch)
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(cancelVoipChat()),
        cancelChatOnPP: () => dispatch(cancelVoipChatOnPP(ownProps.isContinue)),
        ...defaultFunctions(dispatch)
      });
    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withPGTranslation(CreditCardProcessing));
