import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chat from './chat';
import ChatEnded from './chat_ended';
import SelectChatCredit from '../chat_overlay/select_chat_credit';
import PurchaseDetails from '../chat_overlay/purchase_details';
import Constants from '../../config/const';
import Ringing from '../chat_overlay/outgoin_call';
import AppGeneralPopup from '../../../common/app_general_popup';
import WaitingComponent from './waiting';
import classes from './classes.module.scss';
import { trackEventSpentCredit } from '../../actions/analytics';
import { trackChatEndedEventGTM } from '../../actions/google_tag_mahager_events';
import AdvisorEnded from '../voip_chat/advisor_ended';
import Spiner from '../../../common/helpers/spiner';

const { shared } = window;

export default class CreatedChat extends PureComponent {
  static propTypes = {
    connectChatProvider: PropTypes.func.isRequired,
    disconnectChatProvider: PropTypes.func.isRequired,
    hangupChat: PropTypes.func.isRequired,
    hangingUp: PropTypes.bool,
    state: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    purchaseOnBack: PropTypes.func,
    totalCharged: PropTypes.string,
    uuid: PropTypes.string,
    orderId: PropTypes.number,
    userId: PropTypes.number.isRequired,
    advisorId: PropTypes.number.isRequired,
    totalDuration: PropTypes.number,
    profilePictureUrl: PropTypes.string.isRequired,
    isNewUser: PropTypes.bool,
    analyticEvents: PropTypes.object
  };

  static defaultProps = {
    hangingUp: false,
    purchaseOnBack: null,
    totalCharged: null,
    uuid: null,
    orderId: null,
    totalDuration: null,
    isNewUser: false,
    analyticEvents: null
  };

  componentDidUpdate(prevProps) {
    const {
      state, totalCharged, connectChatProvider, uuid, userId, orderId, advisorId, totalDuration
    } = this.props;
    if (prevProps.state !== Constants.chatStates.ended
      && state === Constants.chatStates.ended) {
      trackEventSpentCredit(parseFloat(totalCharged).toFixed(2), 'USD', userId, orderId);
      const chatEndedEventGTMParams = {
        'advisor id': advisorId,
        'user id': userId,
        'order id': orderId,
        'total charged': parseFloat(totalCharged).toFixed(2),
        'total duration': parseFloat(totalDuration / 60).toFixed(2)
      };
      trackChatEndedEventGTM(Constants.chatType.text, chatEndedEventGTMParams);
    }

    if (!prevProps.uuid && uuid) {
      connectChatProvider();
    }
  }

  componentWillUnmount() {
    const { disconnectChatProvider } = this.props;
    disconnectChatProvider();
  }

  render() {
    const {
      state, purchaseOnBack, profilePictureUrl, isNewUser
    } = this.props;
    const renderTopImg = () => (isNewUser ? (
      <img
        className={ classes.advisorImage }
        src={ profilePictureUrl }
        alt=""
      />
    ) : null);
    let content = null;
    let overlay = null;
    const shouldShowChatContent = state === Constants.chatStates.started
    || state === Constants.chatStates.paused
    || state === Constants.chatStates.paymentRequiredOnContinue;
    const ringingArray = [Constants.chatStates.appStarting,
      Constants.chatStates.appConnecting,
      Constants.chatStates.ringing,
      Constants.chatStates.appConnectProvider];

    if (ringingArray.includes(state)) {
      content = <Ringing chatType={ Constants.chatType.text } />;
    } else if (state === Constants.chatStates.waiting) {
      content = <WaitingComponent />;
    } else if (shouldShowChatContent) {
      content = <Chat { ...this.props } disabled={ state !== Constants.chatStates.started } />;
      if (state === Constants.chatStates.paused) {
        overlay = (
          <div className={ classes.addCreditContainer }>
            <AppGeneralPopup logoImg={ renderTopImg() }>
              <SelectChatCredit
                chatType={ Constants.chatType.text }
                isContinue
                selectorOpenedTime={ new Date() }
              />
            </AppGeneralPopup>
          </div>
        );
      } else if (state === Constants.chatStates.paymentRequiredOnContinue) {
        overlay = (
          <div className={ classes.addCreditContainer }>
            <AppGeneralPopup>
              <PurchaseDetails
                chatType={ Constants.chatType.text }
                backArrowAction={ purchaseOnBack }
                isContinue
              />
            </AppGeneralPopup>
          </div>
        );
      }
    } else if (state === Constants.chatStates.ended) {
      content = (
        <>
          <ChatEnded chatType={ Constants.chatType.text } />
          <AdvisorEnded chatType={ Constants.chatType.text } />
        </>
      );
    } else if (state === Constants.chatStates.continuingChat) {
      overlay = <Spiner loaderColor={ shared.appColor } />;
    } else {
      content = (
        <div>
          TODO:
          {state}
        </div>
      );
    }

    // overlay over the content

    return (
      <div className={ classes.callingScreen }>
        {content}
        {overlay}
      </div>
    );
  }
}
