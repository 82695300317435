import Const from '../config/const';
import { validateCreditCardApi, getValidateCreditCardStatusApi } from '../config/api';

export const creditCardValidateProcessingValidating = ({ validating }) => ({
  type: Const.creditCardValidateProcessing.validating,
  validating
});

const creditCardValidateProcessingValidated = ({ validatedData }) => ({
  type: Const.creditCardValidateProcessing.setValidatedData,
  validatedData
});

const creditCardValidateProcessingError = (error) => ({
  type: Const.creditCardValidateProcessing.error,
  error
});

export const validateCreditCardAction = ({ requestParams }) => (dispatch) => {
  dispatch(creditCardValidateProcessingValidating({ validating: true }));
  validateCreditCardApi({ requestParams })
    .then((validatedData) => {
      dispatch(creditCardValidateProcessingValidated({ validatedData }));
    })
    .catch((error) => {
      error.json()
        .then((jsonError) => { dispatch(creditCardValidateProcessingError(jsonError)); })
        .catch(() => { dispatch(creditCardValidateProcessingError(error)); });
    });
};

export const getValidateCreditCardStatus = ({ id }) => (dispatch) => {
  getValidateCreditCardStatusApi({ id })
    .then((validatedData) => {
      dispatch(creditCardValidateProcessingValidated({ validatedData }));
    })
    .catch((error) => {
      error.json()
        .then((jsonError) => { dispatch(creditCardValidateProcessingError(jsonError)); })
        .catch(() => { dispatch(creditCardValidateProcessingError(error)); });
    });
};

export const validateCreditCardClearAction = () => ({
  type: Const.creditCardValidateProcessing.clear
});
