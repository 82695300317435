import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChatEnded from '../created_chat/chat_ended';
import Constants from '../../config/const';
import VoipChat from '../voip_chat';
import Starting from '../chat_overlay/starting';
import SelectChatCredit from '../chat_overlay/select_chat_credit';
import AppGeneralPopup from '../../../common/app_general_popup';
import PurchaseDetails from '../chat_overlay/purchase_details';
import classes from '../created_chat/classes.module.scss';
import AdvisorEnded from '../voip_chat/advisor_ended';
import { trackEventSpentCredit } from '../../actions/analytics';
import { trackChatEndedEventGTM } from '../../actions/google_tag_mahager_events';

const renderTopImg = (isNewUser, profilePictureUrl) => (isNewUser ? (
  <img
    className={ classes.advisorImage }
    src={ profilePictureUrl }
    alt=""
  />
) : null);

const renderSelectCredit = (voipType, isNewUser, profilePictureUrl) => (
  <div className={ classes.addCreditContainer }>
    <AppGeneralPopup logoImg={ renderTopImg(isNewUser, profilePictureUrl) }>
      <SelectChatCredit chatType={ voipType } isContinue selectorOpenedTime={ new Date() } />
    </AppGeneralPopup>
    <AdvisorEnded chatType={ voipType } />
  </div>
);

const renderPurchaseDetails = (voipType, voipPurchaseOnBack) => (
  <div className={ classes.addCreditContainer }>
    <AppGeneralPopup>
      <PurchaseDetails
        chatType={ voipType }
        backArrowAction={ voipPurchaseOnBack }
        isContinue
      />
    </AppGeneralPopup>
  </div>
);

const voipActionList = (voipType, voipPurchaseOnBack, isNewUser, profilePictureUrl) => ({
  [Constants.voipChatStates.connectProvider]: {
    voipChat: <VoipChat />, content: <Starting chatType={ voipType } />
  },
  [Constants.voipChatStates.connecting]: {
    voipChat: <VoipChat />, content: <Starting chatType={ voipType } />
  },
  [Constants.voipChatStates.starting]: {
    voipChat: <VoipChat />, content: <Starting chatType={ voipType } />
  },
  [Constants.voipChatStates.endedConversation]: {
    voipChat: null, content: <ChatEnded chatType={ voipType } />
  },
  [Constants.voipChatStates.inConversation]: {
    voipChat: <VoipChat />, content: null
  },
  [Constants.voipChatStates.finishing]: {
    voipChat: <VoipChat />, content: null
  },
  [Constants.voipChatStates.subscribed]: {
    voipChat: <VoipChat />, content: null
  },
  [Constants.voipChatStates.paused]: {
    voipChat: null,
    content: renderSelectCredit(voipType, isNewUser, profilePictureUrl)
  },
  [Constants.voipChatStates.paymentRequiredOnContinue]: {
    voipChat: null,
    content: renderPurchaseDetails(voipType, voipPurchaseOnBack)
  },
  [Constants.voipChatStates.continue]: {
    voipChat: <VoipChat />, content: null
  }
});

export default class CreatedVoipChat extends PureComponent {
  static propTypes = {
    voipState: PropTypes.string.isRequired,
    voipType: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    voipPurchaseOnBack: PropTypes.func.isRequired,
    totalCharged: PropTypes.string,
    orderId: PropTypes.number,
    userId: PropTypes.number.isRequired,
    advisorId: PropTypes.number.isRequired,
    totalDuration: PropTypes.number,
    profilePictureUrl: PropTypes.string.isRequired,
    isNewUser: PropTypes.bool
  };

  static defaultProps = {
    totalCharged: null,
    orderId: null,
    totalDuration: null,
    isNewUser: false
  };

  componentDidUpdate(prevProps) {
    const {
      voipState, totalCharged, orderId, userId, advisorId, voipType, totalDuration
    } = this.props;
    if (prevProps.voipState !== Constants.voipChatStates.endedConversation
      && voipState === Constants.voipChatStates.endedConversation) {
      trackEventSpentCredit(parseFloat(totalCharged).toFixed(2), 'USD', userId, orderId);
      const voipEndedEventGTMParams = {
        'advisor id': advisorId,
        'user id': userId,
        'order id': orderId,
        'total charged': parseFloat(totalCharged).toFixed(2),
        'total duration': parseFloat(totalDuration / 60).toFixed(2)
      };
      trackChatEndedEventGTM(voipType, voipEndedEventGTMParams);
    }
  }

  render() {
    const {
      voipState, voipType, voipPurchaseOnBack, isNewUser, profilePictureUrl
    } = this.props;
    const { voipChat, content } = voipActionList(voipType, voipPurchaseOnBack, isNewUser, profilePictureUrl)[voipState];

    return (
      <div className={ classes.callingScreen }>
        { voipChat }
        { content }
        <AdvisorEnded chatType={ voipType } />
      </div>
    );
  }
}
