/* eslint-disable object-property-newline */
import queryString from 'query-string';
import { payPalProcessingFailReason, updateLink } from '../../config/util';
import {
  paramOrNul, toLowerCaseSpaced, paymentKindToAnalyticsName, toCamelCase
} from '../../../common/config/utils';
import Const from '../../config/const';
import { purchaseDetailsTypes } from '../../../common/config/const';
import {
  trackPurchaseStartedEvent, trackPurchaseEvent, trackPurchaseFailedEvent, getDestination
} from '../../actions/analytics';
import { log } from '../../config/app_logger';
import { appRoutes } from '../../config/app_routes';
// import { updateLink } from '../routes/routes_utils';
import { getAdvisorSlug } from '../../project_items/helper_functions';

export const endPurchaseErrorTypes = [
  'advisor_busy',
  'advisor_offline',
  'spending_limits'
];

export const isSideMenu = ({ type }) => (type === purchaseDetailsTypes.SIDE_MENU);

export const isAffiliatePromoPopup = ({ type }) => (
  type === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP
);

export const sign = ({ URL }) => (URL.indexOf('?') === -1 ? '?' : '&');

export const getFollowupParam = ({ isContinue, type }) => {
  const { context } = toCamelCase(queryString.parse(window.location.search));
  if (context) return { context };
  if (isContinue) return { context: 'followup' };
  let followupParam;
  switch (type) {
    case purchaseDetailsTypes.CHAT: {
      followupParam = { context: 'live_chat' };
      break;
    }
    case purchaseDetailsTypes.VIDEO_CHAT: {
      followupParam = { context: 'video_call' };
      break;
    }
    case purchaseDetailsTypes.VOICE_CHAT: {
      followupParam = { context: 'voice_call' };
      break;
    }
    default: {
      followupParam = { context: 'credit_screen' };
      break;
    }
  }
  return followupParam;
};

export const renderButtonTitle = ({ isContinue, type, t }) => {
  if (isContinue) {
    return t('general.continue');
  }
  switch (type) {
    case purchaseDetailsTypes.CHAT:
      return t('chat.start_chat');
    case purchaseDetailsTypes.VIDEO_CHAT:
      return t('chat.start_video_call');
    case purchaseDetailsTypes.VOICE_CHAT:
      return t('chat.start_voice_call');
    case purchaseDetailsTypes.AFFILIATE_PROMO_POPUP:
      return t('purchase.affiliate_promo_popup_confirm_button');
    default:
      return 'OK';
  }
};

export const selectCloseButtonActionIfNeed = ({
  type, cancelChatOnPP, hideAffiliatePromoPopup
}) => {
  if (type !== purchaseDetailsTypes.SIDE_MENU
    && type !== purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
    if (cancelChatOnPP) cancelChatOnPP();
  }
  if (type === purchaseDetailsTypes.AFFILIATE_PROMO_POPUP) {
    if (hideAffiliatePromoPopup) hideAffiliatePromoPopup();
  }
};

const paymentOptionEventData = ({ currentPaymentOption }) => {
  if (!currentPaymentOption) return null;
  const { context, id, gatewayName } = currentPaymentOption;
  return ({
    ...paramOrNul('payment id', id),
    ...paramOrNul('cc type', (context || {}).type),
    ...paramOrNul('payment gateway', gatewayName)
  });
};

const getChatPurchaseScreenName = ({ chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return 'live chat';
    case Const.chatType.voice:
      return 'voice call';
    case Const.chatType.video:
      return 'video call';
    default:
      return null;
  }
};

const hidePaymentDetailsAndCancelChatIfNeed = ({
  errorType, advisorId, loadAdvisor, hidePaymentDetails, cancelChat
}) => {
  if (endPurchaseErrorTypes.includes(errorType)) {
    if (advisorId) loadAdvisor(getAdvisorSlug({ advisorId }));
    hidePaymentDetails();
    cancelChat();
  }
};

export const onRetryButtonClick =  ({
  purchaseResponce, hidePaymentDetails, cancelChat, history, loadAdvisor, advisorId,
  purchaseURL, startPurchaseAction, clearPurchaseAction, clearAdditionalAction
}) => {
  const {
    errorOrigin, errorPathname, recoverable, retriable, errorType
  } = purchaseResponce;
  log('Purchase', 'retry Button clicl');
  if (recoverable) {
    const recoverableParams = { recover: true };
    const recoverableURL = `${ errorOrigin }${ errorPathname }?${ queryString.stringify(recoverableParams) }`;
    startPurchaseAction(recoverableURL);
  } else if (retriable) {
    startPurchaseAction(purchaseURL);
  } else {
    clearPurchaseAction();
    clearAdditionalAction(false);
  }
  hidePaymentDetailsAndCancelChatIfNeed({
    errorType, advisorId, loadAdvisor, hidePaymentDetails, cancelChat
  });
  if (errorType === 'spending_limits') {
    window.onbeforeunload = null;
    history(updateLink(appRoutes.spendLimits), { state: { clickSource: 'popup' } });
    window.location.reload();
  }
  log('Purchase', `retry Button click recoverable: ${ recoverable }, retriable: ${ retriable }`);
};

export const onConfirmButton = ({
  clearPurchaseAction, setRetryCount, onPurchaseComplete
}) => {
  clearPurchaseAction();
  setRetryCount(0);
  if (onPurchaseComplete) {
    onPurchaseComplete();
  }
};

export const payingWithCreditCard = ({ currentPaymentOption }) => {
  if (!currentPaymentOption) { return false; }
  return currentPaymentOption.kind === 'cc';
};

const getPurchaseBillingAgreementProperty = ({
  currentPaymentOption, hasPaypalBillingAgreement
}) => {
  if (currentPaymentOption?.kind !== 'pp') return null;
  return { 'purchase billing agreement': hasPaypalBillingAgreement };
};

const set3DSAuthResult = (hash, threeDAuthResult) => {
  if (!threeDAuthResult) { return hash; }
  return {
    ...hash,
    'ThreeDS Auth Result': threeDAuthResult
  };
};

const extraEventParamsData = ({ extraEventParams }) => {
  if (extraEventParams && extraEventParams.length === 0) return {};
  return { ...toLowerCaseSpaced(extraEventParams) };
};

export const buildEventData = ({
  retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, threeDAuthResult,
  currentPaymentOption, chatType, purchaseFees, extraEventParams
}) => {
  const purchaseAmountFloat = parseFloat(purchaseAmount);
  return set3DSAuthResult({
    'purchase screen': type === purchaseDetailsTypes.SIDE_MENU ? 'credit screen' : getChatPurchaseScreenName({ chatType }),
    credit: (purchaseAmountFloat + parseFloat(bonus) ?? 0).toFixed(2),
    'base price': purchaseAmountFloat.toFixed(2),
    'purchase amount': purchaseAmountFloat.toFixed(2),
    'payment method': paymentKindToAnalyticsName(currentPaymentOption?.kind),
    ...getPurchaseBillingAgreementProperty({ currentPaymentOption, hasPaypalBillingAgreement }),
    'purchase source': 'web',
    'retry count': retryCount === undefined ? 0 : retryCount,
    ...paymentOptionEventData({ currentPaymentOption }),
    ...paramOrNul('purchase fees', purchaseFees || purchaseFees === 0 ? purchaseFees.toFixed(2) : null),
    ...extraEventParamsData({ extraEventParams })
  }, threeDAuthResult);
};

export const mpTrackPurchaseStartedEvent = ({
  retryCount, conversionEventId, type, purchaseAmount, bonus, hasPaypalBillingAgreement,
  threeDAuthResult, currentPaymentOption, chatType, dispatch, purchaseFees
}) => {
  dispatch(trackPurchaseStartedEvent(
    buildEventData({
      retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, threeDAuthResult,
      currentPaymentOption, chatType, purchaseFees
    }),
    conversionEventId
  ));
};

export const mpTrackPurchaseEvent = ({
  retryCount, conversionEventId, type, purchaseAmount, bonus, hasPaypalBillingAgreement,
  threeDAuthResult, currentPaymentOption, chatType, dispatch, pathname, getUser, extraEventParams
}) => {
  dispatch(trackPurchaseEvent({
    ...buildEventData({
      retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, threeDAuthResult,
      currentPaymentOption, chatType, extraEventParams
    }),
    'local currency': 'USD'
  }, pathname, getUser, conversionEventId));
};

const getFailReason = ({ errorType, currentPaymentOption }) => {
  if (errorType) {
    return payPalProcessingFailReason(errorType);
  }
  if (currentPaymentOption?.kind === 'ap') {
    return 'apple pay failed';
  }
  if (currentPaymentOption?.kind === 'pp') {
    return 'paypal failed';
  }
  if (currentPaymentOption?.kind === 'cc') {
    return 'credit card failed';
  }
  return 'error';
};

export const getPurchaseFailedPropsForMobileApp = ({
  type, purchaseAmount, bonus, currentPaymentOption, chatType, purchaseFees, extraEventParams, reason, errorType, errorMessage,
  errorName, errorCode, gatewayName, spendingLimits
}) => ({
  ...buildEventData({
    type, purchaseAmount, bonus,
    currentPaymentOption, chatType, purchaseFees, extraEventParams
  }),
  'failed reason': reason || getFailReason({ errorType, currentPaymentOption }),
  'payment error message': errorMessage !== undefined ? errorMessage : null,
  isCreditCard: payingWithCreditCard({ currentPaymentOption }),
  ...paramOrNul('failed reason description', errorName),
  ...paramOrNul('failed reason error code', errorCode),
  ...paramOrNul('payment gateway', gatewayName || currentPaymentOption?.gatewayName),
  ...paramOrNul('spending limits exceeded', spendingLimits),
  ...getDestination({ spendingLimits })
});

export const mpTrackPurchaseFailedEvent = ({
  errorType, errorMessage, errorName, errorCode, gatewayName, errorPathname, threeDAuthResult,
  retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, currentPaymentOption,
  chatType, dispatch, reason, purchaseFees, extraEventParams, spendingLimits
}) => dispatch(trackPurchaseFailedEvent(
  {
    ...buildEventData({
      retryCount, type, purchaseAmount, bonus, hasPaypalBillingAgreement, threeDAuthResult,
      currentPaymentOption, chatType, purchaseFees, extraEventParams
    }),
    'failed reason': reason || getFailReason({ errorType, currentPaymentOption }),
    'payment error message': errorMessage !== undefined ? errorMessage : null,
    isCreditCard: payingWithCreditCard({ currentPaymentOption }),
    ...paramOrNul('failed reason description', errorName),
    ...paramOrNul('failed reason error code', errorCode),
    ...paramOrNul('payment gateway', gatewayName || currentPaymentOption?.gatewayName),
    ...paramOrNul('spending limits exceeded', spendingLimits),
    ...getDestination({ spendingLimits })
  },
  errorPathname
));
