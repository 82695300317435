import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectChatCredit from './select_chat_credit';
import NotInLiveMode from './not_in_live_mode';
import CantCreate from './cant_create';
import CreatedChat from '../created_chat';
import GeneralError from './general_error';
import Constants from '../../config/const';
import IntroComponent from '../intro';
import PurchaseDetails from './purchase_details';
import NoAnswer from './no_answer';
import AppGeneralPopup from '../../../common/app_general_popup';
import classes from './classes.module.scss';
import { log } from '../../config/app_logger';
import { hideBodyScroll, unlockBodyScroll } from '../../../common/config/utils';
import { changeAppStateAPI } from '../../config/api';

function ChatOverlay({
  state, purchaseOnBack, cancelChat, orderId
}) {
  let content = null;

  useEffect(() => {
    const { onbeforeunload, onunload } = window;
    window.onbeforeunload = () => {
      log('Chat', 'Page is refreshed');
      return 'Test message';
    };

    window.onunload = () => {
      changeAppStateAPI(orderId, 'closed_window');
    };

    return () => {
      window.onbeforeunload = onbeforeunload;
      window.onunload = onunload;
    };
  }, [orderId]);

  useEffect(() => {
    hideBodyScroll();
    return () => {
      unlockBodyScroll();
    };
  }, [state]);

  switch (state) {
    case Constants.chatStates.paymentRequired:
      log('Chat', 'state paymentRequired');
      content = (
        <AppGeneralPopup>
          <PurchaseDetails
            chatType={ Constants.chatType.text }
            backArrowAction={ purchaseOnBack }
          />
        </AppGeneralPopup>
      );
      break;
    case Constants.chatStates.appInitial:
      log('Chat', 'state appInitial');
      content = (
        <AppGeneralPopup>
          <SelectChatCredit
            chatType={ Constants.chatType.text }
            selectorOpenedTime={ new Date() }
          />
        </AppGeneralPopup>
      );
      break;
    case Constants.chatStates.introduce:
      log('Chat', 'state introduce');
      content = (
        <IntroComponent chatType={ Constants.chatType.text } closeButtonAction={ cancelChat } />
      );
      break;
    case Constants.chatStates.appNotInLiveMode:
      log('Chat', 'state appNotInLiveMode');
      content = (
        <div className={ classes.callingScreen }>
          <NotInLiveMode chatType={ Constants.chatType.text } />
        </div>
      );
      break;
    case Constants.chatStates.appCantCreate:
      log('Chat', 'state appCantCreate');
      content = (
        <div className={ classes.callingScreen }>
          <CantCreate chatType={ Constants.chatType.text } />
        </div>
      );
      break;
    case Constants.chatStates.generalError:
      log('Chat', 'state generalError');
      content = (
        <div className={ classes.callingScreen }>
          <GeneralError chatType={ Constants.chatType.text } />
        </div>
      );
      break;
    case Constants.chatStates.noAnswer:
      log('Chat', 'state noAnswer');
      content = (
        <div className={ classes.callingScreen }>
          <NoAnswer chatType={ Constants.chatType.text } />
        </div>
      );
      break;
    default:
      // once we have an id, it means that CreatedChat will take care of the rendering
      log('Chat', 'created');
      content = <CreatedChat />;
      break;
  }

  return <div className={ classes.layout }>{content}</div>;
}

ChatOverlay.propTypes = {
  state: PropTypes.string.isRequired,
  error: PropTypes.object,
  purchaseOnBack: PropTypes.func.isRequired,
  cancelChat: PropTypes.func.isRequired,
  orderId: PropTypes.number
};

ChatOverlay.defaultProps = {
  error: null,
  orderId: null
};

export default ChatOverlay;
