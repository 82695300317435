import { matchPaths } from '../../../common/config/utils';
import { LANGUAGE, DEFAULT_LANGUAGE, getCurrentLocalization } from '../../config/i18n';
import { applicationRoutes } from './routes_settings';
import { pathNameWithoutLocalization } from '../../config/util';

const currentLocalization = getCurrentLocalization();

const routeMatch = ({ path, pathnames }) => {
  const match = matchPaths({ pathname: pathnames, path });
  return match || {};
};

const replaceLocalizedPathWithUrlParams = ({ localizedPath, urlParams }) => {
  let localizedPathWithUrlParams = `${ localizedPath }`;
  Object.keys(urlParams).forEach((key) => {
    localizedPathWithUrlParams = localizedPathWithUrlParams.replace(`:${ key }`, urlParams[key]);
  });
  return localizedPathWithUrlParams;
};

const getPathnames = () => {
  const pathnames = Object.keys(applicationRoutes);
  Object.keys(applicationRoutes).forEach((key) => {
    const { localizedPaths } = applicationRoutes[key];
    if (localizedPaths) Object.keys(localizedPaths).map((k) => pathnames.push(localizedPaths[k]));
  });
  return pathnames;
};

const getRedirectPathForCurrentPath = ({ language, currentPath }) => {
  let redirecPath;
  const { localizedPaths } = applicationRoutes[currentPath];
  const routeLocalizedPath = localizedPaths && localizedPaths[language];
  if (routeLocalizedPath) {
    redirecPath = `/${ language }${ routeLocalizedPath }`;
    return redirecPath;
  }
  redirecPath = currentPath;
  if (language !== DEFAULT_LANGUAGE) {
    redirecPath = `/${ language }${ redirecPath }`;
    if (currentPath === '/') redirecPath = `/${ language }`;
  }
  return redirecPath;
};

const getRedirectPathForCurrentLocalizedPaths = ({ language, currentPath }) => {
  let redirecPath;
  const routeKeyWithCurrentLocalizedPaths = Object.keys(applicationRoutes)
    .find((key) => {
      const { localizedPaths } = applicationRoutes[key];
      if (localizedPaths) {
        return Object.keys(localizedPaths).find((k) => localizedPaths[k] === currentPath);
      }
      return false;
    });
  redirecPath = applicationRoutes[routeKeyWithCurrentLocalizedPaths].localizedPaths[language]
  || routeKeyWithCurrentLocalizedPaths;
  if (language !== DEFAULT_LANGUAGE) redirecPath = `/${ language }${ redirecPath }`;
  return redirecPath;
};

export const getLocalizedPath = ({ path, language = currentLocalization }) => {
  const match = routeMatch({
    path,
    pathnames: getPathnames()
  });
  let localizedPath;
  if (Object.keys(match).length > 0) {
    const currentPath = match.pattern.path;
    if (applicationRoutes[currentPath]) {
      localizedPath = getRedirectPathForCurrentPath({ language, currentPath });
    } else {
      localizedPath = getRedirectPathForCurrentLocalizedPaths({ language, currentPath });
    }
  }
  localizedPath = replaceLocalizedPathWithUrlParams({ localizedPath, urlParams: match.params });
  return localizedPath;
};

export const getRedirectPath = ({ newLanguage = DEFAULT_LANGUAGE }) => {
  const redirecPath = getLocalizedPath({
    path: pathNameWithoutLocalization(window.location.pathname),
    language: newLanguage
  });
  const redirecPathMatchWithCurrentPath = routeMatch({
    path: window.location.pathname,
    pathnames: [redirecPath]
  });
  if (Object.keys(redirecPathMatchWithCurrentPath).length > 0) return null;
  return redirecPath;
};

export const generateLocalizedPaths = ({ path }) => {
  const localizedPaths = [];
  Object.keys(LANGUAGE).forEach((key) => {
    if (LANGUAGE[key] !== DEFAULT_LANGUAGE) {
      localizedPaths.push(getLocalizedPath({ path, language: LANGUAGE[key] }));
    }
  });
  return localizedPaths;
};
