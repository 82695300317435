import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../common/app_general_popup';
import { appGeneralPopupTheme } from '../../../common/config/const';
import { trackPageVisit } from '../../actions/analytics';

const onCodeSent = (code, applyCode, onOkClickSignUpPromoReadAction) => {
  if (code && code.length !== 0) {
    applyCode(code, 'signup');
  } else {
    onOkClickSignUpPromoReadAction();
  }
};

const onShowModal = (modalText) => {
  if (modalText) return true;
  return false;
};

const renderModalButtons = (success, onOkClickSignUpPromoReadAction, clearShowModal, t) => {
  if (!success) {
    return (
      <div className={ classes.promoCodeModalButtonContainer }>
        <button type="button" value="Promo Code" className={ classes.promoCodeModalButton } onClick={ onOkClickSignUpPromoReadAction }>
          <b>{ t('promoCode.continue_without_promo') }</b>
        </button>
        <button type="button" value="Promo Code" className={ classes.promoCodeModalButton } onClick={ clearShowModal }>
          <b>{ t('promoCode.reenter_promo') }</b>
        </button>
      </div>
    );
  }
  return (
    <div className={ classes.promoCodeModalButtonContainer }>
      <button type="button" value="Promo Code" className={ classes.promoCodeModalButton } onClick={ clearShowModal }>
        <b>{ t('promoCode.ok') }</b>
      </button>
    </div>
  );
};

function PromoCodeSignUp({
  loading, applyCode, clearModalText, modalText, onOkClickSignUpPromoRead, success
}) {
  const { t } = useTranslation();
  const codeInput = useRef();

  useEffect(() => {
    trackPageVisit('promo code sign up complete', true);
  }, []);

  const clearShowModal = () => {
    if (success) onOkClickSignUpPromoRead();
    clearModalText();
  };

  const renderPromoText = () => (
    <div className={ classes.text }>
      <br />
      <b>{ t('promoCode.sign_up_complete') }</b>
      <br />
      <br />
      { t('promoCode.if_you_have_a_promo_code') }
      <br />
      { t('promoCode.please_enter_it_below') }
    </div>
  );

  const onOkClickSignUpPromoReadAction = () => {
    onOkClickSignUpPromoRead();
    clearModalText();
  };

  const codeSent = () => {
    const code = codeInput.current.value;
    onCodeSent(code, applyCode, onOkClickSignUpPromoReadAction);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      codeSent();
    }
  };

  const showModal = () => onShowModal(modalText);

  const renderModalContent = () => (
    <div className={ classes.promoCodeModalContainer }>
      <div className={ classes.promoCodeModalHeader }>
        <b>{ t('app_name_title') }</b>
      </div>
      <div className={ classes.promoCodeModalBody }>
        { modalText }
      </div>
      <div className={ classes.modalFooter }>
        { renderModalButtons(success, onOkClickSignUpPromoReadAction, clearShowModal, t) }
      </div>
    </div>
  );

  return (
    <div>
      { renderPromoText() }
      <div>
        <input
          placeholder = { t('promoCode.enter_code_optional') }
          ref={ codeInput }
          className = { classes.inputContent }
          onKeyDown={ handleKeyPress }
          defaultValue={ window.attribution.promo }
        />
      </div>
      <div>
        <button type="button" value="Promo Code" disabled={ loading } onClick={ codeSent } className={ classes.submitButton }>
          { t('promoCode.continue') }
        </button>
      </div>
      <AppGeneralPopup visible={ showModal() } theme={ appGeneralPopupTheme.CENTERED }>
        { renderModalContent()}
      </AppGeneralPopup>
    </div>
  );
}

PromoCodeSignUp.propTypes = {
  promoCode: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  applyCode: PropTypes.func.isRequired,
  clearModalText: PropTypes.func.isRequired,
  modalText: PropTypes.string,
  t: PropTypes.func.isRequired,
  onOkClickSignUpPromoRead: PropTypes.func,
  success: PropTypes.bool.isRequired
};

PromoCodeSignUp.defaultProps = {
  promoCode: null,
  modalText: null,
  onOkClickSignUpPromoRead: null
};

export default PromoCodeSignUp;
