import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import withPGTranslation from '../../../config/withPGTranslation';

function CallAnimation({
  pictureSrc, borderColor
}) {
  return (
    <div className= { classes.frameOut }>
      <div className={ classes.frameMid }>
        <div className={ classes.frameIn }>
          <img
            className={ classes.profilePicture }
            style={ { '--color': borderColor } }
            src={ pictureSrc }
            alt="Profile"
          />
        </div>
      </div>
    </div>
  );
}

CallAnimation.propTypes = {
  pictureSrc: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired
};

export default withPGTranslation(CallAnimation);
