import React from 'react';
import PropTypes from 'prop-types';
import AppLink from '../routes/app_link';
import classes from './classes.module.scss';
import { isKA } from '../../../common/config/utils';

const { shared } = window;

const getMenuItemClass = (title) => {
  if (isKA()) {
    return title.active ? classes.menuItemTitleBoldActive : classes.menuItemTitleBold;
  }
  return title.active ? classes.menuItemTitleActive : classes.menuItemTitle;
};

const renderContent = (icon, title, extraNode, rightPos, onClick) => (
  <div
    className={ classes.sideMenuItemContainer }
    onClick={ onClick }
    onKeyDown={ onClick }
    suppressHydrationWarning
  >

    <div className={ classes.sideMenuItemTitleContainer } suppressHydrationWarning>
      { icon }
      <div className={ getMenuItemClass(title) } suppressHydrationWarning>
        { title.text || title }
      </div>
    </div>
    { extraNode || null }
  </div>
);

const renderInnerLink = (linkTo, icon, title, extraNode, rightPos) => (
  <AppLink
    to={ linkTo }
    className={ classes.actionLink }
    activeStyle={ { color: shared.appcolor } } // activeClassName - doesn't work
  >
    { renderContent(icon, title, extraNode, rightPos) }
  </AppLink>
);

const renderRemoteLink = (linkTo, icon, title, extraNode, rightPos, openInNewTab) => (
  openInNewTab ? (
    <a href={ linkTo } target="_blank" rel="noopener noreferrer" className={ classes.actionLink }>
      { renderContent(icon, title, extraNode, rightPos) }
    </a>
  ) : (
    <a href={ linkTo } className={ classes.actionLink }>
      { renderContent(icon, title, extraNode, rightPos) }
    </a>
  )
);

const renderActionLink = (icon, title, extraNode, rightPos, onClick) => (
  <div className={ classes.actionLink } suppressHydrationWarning>
    { renderContent(icon, title, extraNode, rightPos, onClick) }
  </div>
);

function MenuItem({
  title, icon, linkTo, extraNode, rightPos, asHref, onClick, openInNewTab
}) {
  const renderItem = () => {
    if (onClick) {
      return renderActionLink(icon, title, extraNode, rightPos, onClick);
    }
    return asHref
      ? renderRemoteLink(linkTo, icon, title, extraNode, rightPos, openInNewTab)
      : renderInnerLink(linkTo, icon, title, extraNode, rightPos);
  };
  return (<div className={ classes.sideMenuItem }>{ renderItem() }</div>);
}

MenuItem.propTypes = {
  title: PropTypes.object.isRequired,
  icon: PropTypes.node,
  linkTo: PropTypes.any,
  extraNode: PropTypes.node,
  rightPos: PropTypes.bool,
  asHref: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  onClick: PropTypes.func
};

MenuItem.defaultProps = {
  icon: null,
  extraNode: null,
  linkTo: null,
  rightPos: false,
  asHref: false,
  openInNewTab: false,
  onClick: null
};

export default MenuItem;
