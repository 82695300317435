import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import withPGTranslation from '../../config/withPGTranslation';

class Form extends PureComponent {
  static propTypes = {
    sendMessage: PropTypes.func.isRequired,
    sendTyping: PropTypes.func.isRequired,
    sendStopTyping: PropTypes.func.isRequired,
    typingTimeout: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    textAreaRef: PropTypes.object.isRequired
  };

  state = {
    body: ''
  };

  onStopTyping = () => {
    clearTimeout(this.typingTimer);
    this.typingTimer = null;
    const { sendStopTyping } = this.props;
    sendStopTyping();
  };

  onChange = event => {
    this.setState({ body: event.target.value });
    const { typingTimeout, sendTyping } = this.props;
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    } else {
      sendTyping();
    }
    this.typingTimer = setTimeout(this.onStopTyping * 1000, typingTimeout);
  };

  onSend = e => {
    e.preventDefault();
    const { sendStopTyping, sendMessage, textAreaRef } = this.props;
    const { body } = this.state;

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
      this.typingTimer = null;
      sendStopTyping();
    }

    sendMessage(body);
    this.setState({ body: '' });
    textAreaRef.current.focus();
  };

  isSendAllowed = () => {
    const { body } = this.state;

    return body.trim() !== '';
  };

  onKeyPressed = e => {
    if (!this.isSendAllowed()) return;

    if (e.keyCode === 13 && e.shiftKey === false) {
      this.onSend(e);
    }
  };

  render() {
    const { body } = this.state;
    const { disabled, t, textAreaRef } = this.props;
    const sendButton = disabled ? null : (
      <button
        className={ classes.sendBtn }
        type="button"
        onClick={ this.onSend }
        disabled={ !this.isSendAllowed() }
      >
        { t('chat.send') }
      </button>
    );

    return (
      <div className={ classes.chatSendContainer }>
        <textarea
          className={ classes.chatSendArea }
          placeholder={ t('chat.your_message') }
          value={ body }
          onChange={ this.onChange }
          onKeyDown={ this.onKeyPressed }
          rows="3"
          disabled={ disabled }
          ref={ textAreaRef }
        />
        {sendButton}
      </div>
    );
  }
}
export default withPGTranslation(Form);
