import React from 'react';
import PropTypes from 'prop-types';
import classes from '../../classes.module.scss';
import AppLink from '../../../routes/app_link';
import { appRoutes } from '../../../../config/app_routes';
import withPGTranslation from '../../../../config/withPGTranslation';

const seeMoreBtn = require('../../../advisors_list/img/see_more_btn.svg');

function ButtonSection({
  id, title, t, params
}) {
  if (id === 'all_advisors') {
    return (
      <div className={ classes.allAdvisorsButtonContainer }>
        <div className={ classes.allAdvisorsButtonTextBrowser }>
          { t('home_page.all_advisors_button_text_browser') }
        </div>
        <div className={ classes.allAdvisorsButtonTextMobile }>
          { t('home_page.all_advisors_button_text_mobile') }
        </div>
        <AppLink
          to={ {
            pathname: appRoutes.psychics,
            state: {
              needRefresh: true,
              params
            }
          } }
          className={ classes.allAdvisorsButton }
        >
          <img src={ seeMoreBtn } className={ classes.seeMoreBtnImg } alt="" />
          <span className={ classes.seeMoreBtnText }>
            {title}
          </span>
        </AppLink>
      </div>
    );
  }
  return null;
}

ButtonSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
};

ButtonSection.defaultProps = {
  id: null,
  title: ''
};

export default withPGTranslation(ButtonSection);
