/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import withPGTranslation from '../../../config/withPGTranslation';
import PaginationPages from './pagination_pages';

const mapStateToProps = ({ searchResult }) => ({
  ...searchResult
});

export default connect(
  mapStateToProps,
  { }
)(withPGTranslation(PaginationPages));
