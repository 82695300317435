import { connect } from 'react-redux';
import Const from '../../../config/const';
import Outgoing from './outgoing';
import { hangupChat, createChat } from '../../../actions/chat';
import { hangupVoipChat } from '../../../actions/voip_chat';
import withPGTranslation from '../../../config/withPGTranslation';

const mapStateToProps = ({ chat, voipChat }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({ ...chat, soundUrl: 'https://dpam6lhev4yyd.cloudfront.net/assets/outgoing_ring_live_chat_pg.mp4' });
    case Const.chatType.voice:
      return ({ ...voipChat, soundUrl: 'https://dpam6lhev4yyd.cloudfront.net/assets/outgoing_voice_call.wav' });
    case Const.chatType.video:
      return ({ ...voipChat, soundUrl: 'https://dpam6lhev4yyd.cloudfront.net/assets/outgoing_video_call.mp3' });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(hangupChat()),
        createChat: () => dispatch(createChat())
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(hangupVoipChat(true))
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPGTranslation(Outgoing));
