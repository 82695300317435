import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import FilterDropdown from './filter_dropdown';
import FilterSlider from './filter_slider/filter_slider';
import FilterSwitcher from './filter_switcher';
import {
  popoverKinds, readingViaFilters, numberOfReviewsFilters
} from '../../../../config/const';
import withPGTranslation from '../../../../config/withPGTranslation';
import UsePreviousValue from '../../../../../common/config/utils';
import {
  getInitialState, buildQueryString, renderViewForNewAffiliateUser,
  renderCancelBtn, searchSection, renderFiltersMobileView, getFilterSwitcherId
} from './filter_utils';
import { trackPageVisit } from '../../../../actions/analytics';

function FiltersContainer({
  showPopover, popoverView, autofocus, dissmissPopover,
  history, newToPG, lastPurchaseDate, location
}) {
  const { t } = useTranslation();
  const [readingVia, setReadingVia] = useState(getInitialState('readingVia'));
  const [numberOfReviews, setNumberOfReviews] = useState(getInitialState('numberOfReviews'));
  const [ppmRange, setPPMRange] = useState(getInitialState('ppmRange'));
  const [includeOffline, setIncludeOffline] = useState(getInitialState('includeOffline'));
  const [searchQuery, setSearchQuery] = useState(getInitialState('searchQuery'));
  const [historySearch, setHistorySearch] = useState(location.search);
  const [showFilters, setShowFilters] = useState(!newToPG);
  const eligibleForNewAffiliateUserView = !lastPurchaseDate && newToPG;

  const closeFilters = () => {
    setShowFilters(false);
  };

  const openFilters = () => {
    setShowFilters(true);
  };

  useEffect(() => {
    if (popoverView) trackPageVisit('search and filter');
  }, []);

  useEffect(() => {
    setReadingVia(getInitialState('readingVia'));
    setNumberOfReviews(getInitialState('numberOfReviews'));
    setPPMRange(getInitialState('ppmRange'));
    setIncludeOffline(getInitialState('includeOffline'));
    setSearchQuery(queryString.parse(window.location.search).query);
    setHistorySearch(location.search.replace('?', ''));
  }, [window.location.search]);

  useEffect(() => {
    if (window.currentUserId) setShowFilters(true);
    else if (eligibleForNewAffiliateUserView) setShowFilters(false);
  }, [window.currentUserId, newToPG]);

  const onReadingViaChanged = (id) => { setReadingVia(id); };
  const onNumverOfReviewsChanged = (id) => { setNumberOfReviews(id); };
  const onSliderChanged = (values) => { setPPMRange(values); };
  const onIncludeOfflineChanged = (value) => { setIncludeOffline(value); };

  const searchIconTap = () => {
    showPopover(popoverKinds.filter, {
      autoFocus:true, popoverId: 'searchPopover', history, location
    });
  };
  const showFilterPopover = () => {
    showPopover(popoverKinds.filter, { popoverId: 'filterPopover', history, location });
  };
  const onSearchChanged = (e) => { setSearchQuery(e.target.value); };

  const onFilterApply = () => {
    const prevHistorySearch = <UsePreviousValue value={ historySearch } />;
    const search = buildQueryString({
      readingVia, numberOfReviews, ppmRange, includeOffline, query: (searchQuery || '').trim(), historySearch
    });
    if (search === prevHistorySearch.props.value) return;
    history(`${ location.pathname }?${ search }`, {
      state: { needRefresh: true, params: (location.state || {}).params },
      replace: true
    });
    if (popoverView) dissmissPopover();
  };

  const onPopoverSearchInputKeyDown = (e) => { if (e.key === 'Enter') onFilterApply(); };

  const renderClickHereBtn = () => (
    <div className={ classes.clickHereText }>
      {t('search_results.need_help')}
      &nbsp;
      <button type="button" className={ classes.clickHereBtn } onClick={ openFilters }>{t('search_results.click_here')}</button>
    </div>
  );

  const showNewToPGSection = () => (eligibleForNewAffiliateUserView && !popoverView ? (
    <div className={ classes.newToPGSectionContainer } style={ showFilters ? { borderBottom: '1px solid #EADDED' } : { borderBottom: 'none' } }>
      {renderViewForNewAffiliateUser({
        showFilterPopover, title: t('app_search_results.this_is_how_it_works'), t
      })}
      { showFilters ? null : renderClickHereBtn() }
    </div>
  ) : null);

  const renderFiltersSection = () => (
    <div className={ classes.filterFieldsContainer } style={ eligibleForNewAffiliateUserView ? { paddingTop: '0px' } : { paddingTop: '30px' } }>
      <FilterDropdown
        title={ t('home_page.show_only') }
        options={ readingViaFilters }
        selectedOptionId={ readingVia }
        onChange={ onReadingViaChanged }
        dropdownId="readingViaDropdown"
      />
      <FilterDropdown
        title={ t('home_page.number_of_reviews') }
        options={ numberOfReviewsFilters }
        selectedOptionId={ numberOfReviews }
        onChange={ onNumverOfReviewsChanged }
        dropdownId="numberOfReviewsDropdown"
      />
      <FilterSlider currentPPMRange={ ppmRange } onRangeChange={ onSliderChanged } />
      <FilterSwitcher
        filterSwitcherId={ getFilterSwitcherId({ popoverView }) }
        checked={ includeOffline }
        onChange={ onIncludeOfflineChanged }
      />
      <button type="button" className={ classes.applyButton } onClick={ onFilterApply }>
        { t('home_page.apply') }
      </button>
    </div>
  );

  return [
    <div
      key="expanded_view"
      className={ popoverView ? classes.popoverFilters : classes.filterContainer }
    >
      {showNewToPGSection()}
      {renderCancelBtn(showFilters, eligibleForNewAffiliateUserView, popoverView, closeFilters)}
      {searchSection({
        popoverView, t, searchQuery,  onSearchChanged, autofocus, onPopoverSearchInputKeyDown
      })}
      { popoverView || showFilters ? renderFiltersSection() : null}
    </div>,
    renderFiltersMobileView({
      popoverView, showFilterPopover, searchIconTap, t, eligibleForNewAffiliateUserView
    })
  ];
}

FiltersContainer.propTypes = {
  showPopover:PropTypes.func.isRequired,
  popoverView:PropTypes.bool,
  loadAdvisors: PropTypes.func,
  autofocus: PropTypes.bool,
  dissmissPopover: PropTypes.func,
  history: PropTypes.func.isRequired,
  newToPG: PropTypes.bool.isRequired,
  lastPurchaseDate: PropTypes.string,
  location: PropTypes.object.isRequired
};

FiltersContainer.defaultProps = {
  popoverView: false,
  autofocus: false,
  dissmissPopover: null,
  lastPurchaseDate: null
};

export default withPGTranslation(FiltersContainer);
