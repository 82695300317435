import { connect } from 'react-redux';
import VoipChatOverlay from './voip_chat_overlay';
import withPGTranslation from '../../config/withPGTranslation';
import { voipPurchaseOnBack, cancelVoipChat } from '../../actions/voip_chat';

const mapStateToProps = ({ voipChat }) => voipChat;

export default connect(mapStateToProps, {
  purchaseOnBack: voipPurchaseOnBack,
  cancelChat: cancelVoipChat
})(withPGTranslation(VoipChatOverlay));
