/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import React from 'react';
import Application from 'app/index';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { advisorUpdated, userUpdated } from './app/actions/shared_actions';
import { reviewsLoaded, updateAdvisorNavigationStack } from './app/actions/advisors';
import { getAdvisorDataIfSsrMode, getHomePageDataIfSsrMode, isPrerenderMode } from './app/config/util';
import { loadedSpecialitiesAction, homeLoadedActions } from './app/actions/home_page';
import { updateSEOData } from './app/actions/application';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app-root');

  // hydrate advisor page if need
  const advisorData = getAdvisorDataIfSsrMode();
  if (advisorData) {
    const {
      advisor, reviews, user, specialities
    } = advisorData;
    if (advisor) {
      window.store.dispatch(advisorUpdated(advisor));
      updateAdvisorNavigationStack({ advisor, dispatch: window.store.dispatch });
    }
    if (reviews) {
      window.store.dispatch(reviewsLoaded({
        reviews, id: advisor?.id, initial: true
      }));
    }
    if (user) {
      window.store.dispatch(userUpdated(user));
    }
    if (specialities) {
      window.store.dispatch(loadedSpecialitiesAction(specialities));
    }

    if (!isPrerenderMode()) {
      hydrateRoot(container, <Application />);
      return;
    }
  }

  // hydrate home page if need
  const homePageData = getHomePageDataIfSsrMode();
  if (homePageData) {
    const { sections, specialities, seoData } = homePageData;
    if (sections) {
      window.store.dispatch(homeLoadedActions(sections));
    }
    if (specialities) {
      window.store.dispatch(loadedSpecialitiesAction(specialities));
    }
    if (seoData) {
      window.store.dispatch(updateSEOData(seoData.slug, { seoData }));
    }

    if (!isPrerenderMode()) {
      hydrateRoot(container, <Application />);
      return;
    }
  }

  const root = createRoot(container);
  root.render(<Application />);
});
