import { connect } from 'react-redux';
import withPGTranslation from '../../config/withPGTranslation';
import { clearAplicationError } from '../../actions/aplication_error';
import NotFound from './not_found';

const mapStateToProps = ({ aplicationError }) => ({
  ...aplicationError
});

export default  connect(mapStateToProps, {
  clearAplicationError
})(withPGTranslation(NotFound));
