import { json2csv } from 'json-2-csv';
import Const from '../config/const';
import { updateUser } from './user';
import { getAvatarPresignedURL } from '../config/api';
import { getDataFromStorage } from '../config/util';
import { log } from '../config/app_logger';

const csvContentType = 'application/csv';
const jpegContentType = 'image/jpeg';

const uploadingAWS = () => ({
  type: Const.aws.uploading
});

const uploadingAWSSuccess = () => ({
  type: Const.aws.success
});

const uploadingAWSFailed = error => ({
  type: Const.aws.failed,
  payload: error
});

export const uploadClear = () => ({
  type: Const.aws.reset
});

const upload = (file, presignedUrl, contentType) => {
  const headers = new Headers({
    'Content-Type': contentType
  });
  const request = new Request(
    presignedUrl,
    {
      method: 'PUT',
      headers,
      body: file
    }
  );
  return fetch(request);
};

export const uploadUserAvatar = file => (dispatch) => {
  dispatch(uploadingAWS());
  getAvatarPresignedURL().then((res) => {
    const { presignedUrlKey, presignedUrl } = res;
    log('Amazon', 'uplaoding user avatar');
    upload(file, presignedUrl, jpegContentType).then((amazonRes) => {
      if (amazonRes.ok) {
        dispatch(uploadingAWSSuccess());
        dispatch(updateUser({ avatarUid : presignedUrlKey }));
        dispatch(uploadClear());
      }
      dispatch(uploadingAWSFailed());
      log('Amazon', 'avatar responce error');
    }).catch((error) => {
      dispatch(uploadingAWSFailed(error));
      log('Amazon', `uploading user avatar error ${ error }`);
    });
  }).catch((error) => {
    dispatch(uploadingAWSFailed(error));
  });
};

const uploadUserLogs = (file, key, presignedUrl) => (dispatch) => {
  log('Amazon', 'uploading user logs');
  upload(file, presignedUrl, csvContentType).then((res) => {
    if (res.ok) {
      log('Amazon', 'uploading user logs success');
      dispatch(uploadingAWSSuccess());
      dispatch(updateUser({ logUploadedTo: key }));
      dispatch(uploadClear());
    }
  }).catch((error) => {
    dispatch(uploadingAWSFailed(error));
    log('Amazon', `uploading user logs error ${ error }`);
  });
};

export const parseAndUploadLogs = (url, key) => (dispatch) => {
  const currentLogs = getDataFromStorage('logs_batch');
  json2csv(JSON.parse(currentLogs), (err, csv) => {
    if (err) {
      log('Amazon', `JSON to csv parse error ${ err }`);
    }
    dispatch(uploadUserLogs(csv, key, url));
  });
};
