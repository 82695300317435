import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@barges/react';
import '@barges/scss/lib/button.css';
import '@barges/scss/lib/title.css';
import Const from '../../../../config/const';
import classes from './classes.module.scss';
import withPGTranslation from '../../../../config/withPGTranslation';
import { log } from '../../../../config/app_logger';

function ContinueCallFooter({
  selectCredit, hangupChat, option, t, hangingUp, disabledStartChatButton, selectedDuration,
  chatType, clearAffiliatePromoPopup, trackDurationPickExit,
  defaultDurationChanged, selectorOpenedTime
}) {
  const onAddTimeClicked = () => {
    const exitSelectorTime = new Date();
    const time = exitSelectorTime - selectorOpenedTime;
    log('Chat', `on start chat isContinue: ${ true }`);
    const arg = chatType === Const.chatType.text ? option : selectedDuration;
    selectCredit(arg, time, defaultDurationChanged);
  };

  const hangupChatAction = () => {
    const exitSelectorTime = new Date();
    const time = exitSelectorTime - selectorOpenedTime;
    const arg = chatType === Const.chatType.text ? option : selectedDuration;
    trackDurationPickExit(arg, time, defaultDurationChanged);
    clearAffiliatePromoPopup();
    hangupChat();
  };

  return (
    <div className={ classes.continueContainer }>
      <div className={ classes.endChatBtn }>
        <Button
          type="secondary"
          title={ t('chat.end_chat') }
          disabled={ hangingUp }
          onClick={ hangupChatAction }
        />
      </div>
      <div className={ classes.addTimeBtn }>
        <Button
          title={ t('chat.add_time') }
          disabled={ chatType === Const.chatType.text && disabledStartChatButton }
          onClick={ onAddTimeClicked }
        />
      </div>
    </div>
  );
}

ContinueCallFooter.propTypes = {
  selectCredit: PropTypes.func.isRequired,
  hangupChat: PropTypes.func.isRequired,
  option: PropTypes.object,
  t: PropTypes.func.isRequired,
  hangingUp: PropTypes.bool.isRequired,
  disabledStartChatButton: PropTypes.bool.isRequired,
  chatType: PropTypes.string.isRequired,
  selectedDuration: PropTypes.number,
  clearAffiliatePromoPopup: PropTypes.func.isRequired,
  trackDurationPickExit: PropTypes.func.isRequired,
  defaultDurationChanged: PropTypes.bool.isRequired,
  selectorOpenedTime: PropTypes.instanceOf(Date).isRequired
};

ContinueCallFooter.defaultProps = {
  option: {},
  selectedDuration: null
};

export default withPGTranslation(ContinueCallFooter);
