/* eslint-disable @typescript-eslint/no-var-requires */
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import classes from './classes.module.scss';
import { isKA } from '../../../common/config/utils';

const clockIcon = require('./img/clock.svg');
const rightArrowIcon = require('./img/right_arrow.svg');
const liveChatIcon = require('./img/live_chat.svg');
const videoCallIcon = require('./img/video_call.svg');
const voiceCallIcon = require('./img/voice_call.svg');
const kaLiveChatIcon = require('./img/ka_live_chat.svg');
const kaVoiceCallIcon = require('./img/ka_voice_call.svg');

const OrderTypes = {
  'live-chat' : 'live-chat',
  'video-call': 'video-call',
  'voice-call': 'voice-call'
};

const renderOrderIcon = (type: string) => {
  switch (type) {
    case 'live-chat':
      return <img src={ isKA() ? kaLiveChatIcon : liveChatIcon } className={ classes.orderCellIcon } alt="" />;
    case 'video-call':
      return <img src={ videoCallIcon } className={ classes.orderCellIcon } alt="" />;
    case 'voice-call':
      return <img src={ isKA() ? kaVoiceCallIcon : voiceCallIcon } className={ classes.orderCellIcon } alt="" />;
    default:
      return null;
  }
};

const getCellContainerClass = (type: string) => {
  if (!isKA()) return classNames(classes.orderCellContainer, classes.borderDefault);
  switch (type) {
    case 'live-chat':
      return classNames(classes.orderCellContainer, classes.kaBorderChat);
    case 'voice-call':
      return classNames(classes.orderCellContainer, classes.kaBorderVoice);
    default:
      return null;
  }
};

interface MyOrdersOrderCellProps {
  type: keyof typeof OrderTypes,
  orderSubject?: string,
  orderDescription?: string,
  labels?: Array<ReactElement>,
  advisorId?: string,
  activityId?: number,
}

const MyOrdersOrderCell: React.FC<MyOrdersOrderCellProps> = ({
  type = 'live-chat',
  orderSubject,
  orderDescription = '',
  labels = []
}) => {
  const renderLabel = () => (
    <div className={ classes.labelsHolderContainer }>
      { labels.map((label, index) => (
        <div className={ classes.orderCellLabelContainer } key={ index }>
          { label }
        </div>
      )) }
    </div>
  );

  const renderOrderSubject = () => (
    <div className={ classes.orderCellSubjectContainer }>
      <div className={ classes.orderSubjectName }>
        { orderSubject }
      </div>
    </div>
  );

  const renderOrderTitle = () => (
    <div className={ classes.orderCellTitleContainer }>
      { renderOrderIcon(type) }
      { renderOrderSubject() }
    </div>
  );

  const renderOrderDescription = () => (
    <div className={ classes.orderDescriptionContainer }>
      { orderDescription.length > 0 ? <img src={ clockIcon } className={ classes.orderDescriptionIcon } alt="" /> : null }
      <div className={ classes.orderDescription }>
        { orderDescription }
      </div>
    </div>
  );

  const renderMyOrdersAdvisorCell = () => (
    <div className={ getCellContainerClass(type) }>
      <div className={ classes.orderCellContentContainer }>
        <div className={ classes.orderTitleLabelsContainer }>
          { renderOrderTitle() }
          { renderLabel() }
        </div>
      </div>
      <div className={ classes.orderRightDataContainer }>
        <div className={ classes.orderDataContainer }>
          { renderOrderDescription() }
        </div>
        <div className={ classes.rightArrowContainer }>
          <img src={ rightArrowIcon } alt="" className={ classes.rightArrowIcon } />
        </div>
      </div>
    </div>
  );

  return renderMyOrdersAdvisorCell();
};

export default MyOrdersOrderCell;
