import { getCreditsAPI } from '../config/api';
import Const from '../config/const';
import { purchaseDetailsTypes } from '../../common/config/const';
import { doneManagingPaymentSource } from './payment_sources';
import { changingPaymentSource, updatePurchaseDetails, clearPurchaseDetails } from './purchase_details';
import { log } from '../config/app_logger';

const fundsLoading = () => ({
  type: Const.funds.loading
});

const fundsLoadingError = error => ({
  type: Const.funds.error,
  payload: {
    error
  }
});

const fundsSuccess = credits => ({
  type: Const.funds.credits,
  payload: {
    credits
  }
});

export const getFunds = () => (dispatch) => {
  dispatch(fundsLoading());
  getCreditsAPI()
    .then(({ products }) => {
      dispatch(fundsSuccess(products));
    })
    .catch(error => {
      error
        .json()
        .then(errorModel => {
          dispatch(fundsLoadingError(errorModel));
        })
        .catch(() => {
          dispatch(fundsLoadingError(error));
        });
    })
    .catch((error) => {
      log('Error getFunds', error);
      dispatch(fundsLoadingError(error));
    });
};

const showPaymentDetails = (show) => ({
  type: Const.funds.displayDetails,
  display: show
});

export const paymentSelected = (details) => (dispatch) => {
  dispatch(showPaymentDetails(true));
  dispatch(updatePurchaseDetails({
    details,
    clickSource: 'credit screen',
    purchaseType: purchaseDetailsTypes.SIDE_MENU
  }));
};

export const hidePaymentDetails = () => (dispatch) => {
  dispatch(showPaymentDetails(false));
  // dispatch(doneEditingPaymentSource());
  dispatch(doneManagingPaymentSource());
  dispatch(changingPaymentSource(false));
  dispatch(clearPurchaseDetails());
};

export const purchaseSuccess = () => (dispatch) => {
  dispatch(showPaymentDetails(false));
  dispatch(getFunds());
};
