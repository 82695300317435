import { connect } from 'react-redux';
import AdvisorHeader from './advisor_header';
import withPGTranslation from '../../../../config/withPGTranslation';

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {}
)(withPGTranslation(AdvisorHeader));
