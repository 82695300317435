import React from 'react';
import PropTypes from 'prop-types';
import CallAnimation from '../chat_overlay/outgoin_call/call_animation';
import classesModule from './classes.module.scss';
import VoipControls from './voip_controls';

function VoiceOverlay({
  profilePictureUrl, blinkSetup, callDurationNow
}) {
  return (
    <div className={ classesModule.voiceContainer }>
      <img
        className={ classesModule.backgroundImage }
        src={ profilePictureUrl }
        alt=""
      />
      <div className={ classesModule.voiceOverlay } />
      <div className={ classesModule.callAnimation }>
        <CallAnimation
          pictureSrc={ profilePictureUrl }
          borderColor="#8F63EC"
        />
      </div>
      <VoipControls
        blinkSetup={ blinkSetup }
        callDurationNow={ callDurationNow }
      />
    </div>
  );
}

VoiceOverlay.propTypes = {
  profilePictureUrl: PropTypes.string.isRequired,
  blinkSetup: PropTypes.bool.isRequired,
  callDurationNow: PropTypes.number
};

VoiceOverlay.defaultProps = {
  callDurationNow: null
};

export default VoiceOverlay;
