import { connect } from 'react-redux';
import AdvisorModes from './advisor_modes';

const mapStateToProps = () => (
  {}
);

export default connect(
  mapStateToProps,
  {}
)((AdvisorModes));
