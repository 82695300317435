import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import withPGTranslation from '../../config/withPGTranslation';

function ReviewSection({
  advisorName, onSubmit, comment, t
}) {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(comment);
  }, [comment]);

  const onValueChanged = useCallback((e) => {
    setText(e.target.value);
  }, [comment]);

  return [
    <div className={ classes.divider } key="divider" />,
    <div className={ classes.reviewSectionContainer } key="main">
      <div className={ classes.flexDiv }>
        <div className={ classes.rateReviewSectionTitle }>{ t('rate_review.section_title') }</div>
      </div>
      <div className={ classes.flexDiv } style={ { marginBottom: 12 } }>
        <div className={ classes.advisorNameText }>{ advisorName }</div>
      </div>
      <div className={ classes.flexDiv } style={ { marginBottom: 12 } }>
        <textarea
          value={ text }
          onChange={ onValueChanged }
          placeholder={ t('rate_review.section_placeholder') }
          className={ classes.inputArea }
          rows="7"
        />
      </div>
      <div className={ classes.flexDiv } style={ { marginBottom: 12 } }>
        <button
          type="button"
          className={ classes.submitButton }
          onClick={ () => onSubmit(text || '') }
        >
          { t('rate_review.submit') }
        </button>
      </div>
    </div>
  ];
}

ReviewSection.propTypes = {
  advisorName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  comment: PropTypes.string,
  t: PropTypes.func.isRequired
};

ReviewSection.defaultProps = {
  comment: null
};

export default withPGTranslation(ReviewSection);
