import React, { PureComponent, createRef, memo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { isMobile, isBrowser } from 'react-device-detect';
import Form from './form';
import classes from './classes.module.scss';
import TimerComponent from './timer';
import NetworkStateComponent from './chat_network_state';
import Spiner from '../../../common/helpers/spiner';
import { isKA } from '../../../common/config/utils';

const hangupIconPG = require('./img/ic_chat_hangup_pg.svg');
const hangupIconKA = require('./img/ic_chat_hangup_ka.svg');

let pendingUpdate = false;
export default class Chat extends PureComponent {
  lastMessageRef = createRef();

  textAreaRef = createRef();

  static propTypes = {
    peerTyping: PropTypes.bool,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string.isRequired,
        uuid: PropTypes.number.isRequired,
        timetoken: PropTypes.string
      })
    ),
    systemMessages: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string.isRequired,
        timetoken: PropTypes.string
      })
    ),
    peers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        nickname: PropTypes.string.isRequired,
        uuid: PropTypes.number
      })
    ).isRequired,
    disabled: PropTypes.bool.isRequired,

    typingTimeout: PropTypes.number.isRequired,
    sendMessage: PropTypes.func.isRequired,
    sendTyping: PropTypes.func.isRequired,
    sendStopTyping: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    profilePictureUrl: PropTypes.string.isRequired,
    hangupChat: PropTypes.func.isRequired,
    ownUuid: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    hangingUp: PropTypes.bool.isRequired
  };

  static defaultProps = {
    peerTyping: false,
    messages: [],
    systemMessages: []
  };

  state = {
    faviconIconHref: null
  };

  constructor() {
    super();
    this.liveIndecatorInterval = null;
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('onpopstate', this.disableBackButton);
    window.visualViewport.addEventListener('scroll', this.viewportHandler);
    window.visualViewport.addEventListener('resize', this.viewportHandler);
    if (isBrowser) {
      const faviconLinkElement = document.getElementById('favicon_link');
      this.setState({ faviconIconHref: faviconLinkElement.href });
      const canvas = document.createElement('canvas');
      canvas.id = 'canvas';
      canvas.width =  32;
      canvas.height = 32;
      canvas.style.display = 'none';
      this.liveIndicatorAnimation(canvas);
    }
    this.textAreaRef.current.focus();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    window.removeEventListener('onpopstate', this.disableBackButton);
    window.visualViewport.removeEventListener('scroll', this.viewportHandler);
    window.visualViewport.removeEventListener('resize', this.viewportHandler);
    const { faviconIconHref } = this.state;
    if (isBrowser) {
      if (this.liveIndecatorInterval) clearInterval(this.liveIndecatorInterval);
      document.getElementById('favicon_link').href = faviconIconHref;
    }
  }

  getAvatarFromUuuId(uuid) {
    const { peers } = this.props;
    const peer = peers.find(p => parseInt(p.uuid, 10) === parseInt(uuid, 10));
    const avatar = <img className={ classes.avatar } src={ peer.imageUrl } alt="" style={ { '--size': '32px' } } />;

    return avatar;
  }

  disableBackButton = () => {
    window.history.go(1);
  };

  renderHeader = () => {
    const {
      name,
      profilePictureUrl,
      hangupChat,
      t,
      hangingUp
    } = this.props;
    return (
      <div
        className={ isMobile ? classes.chatHeaderContainer : classes.chatHeaderContainerBrowser }
      >
        <div className={ classes.chatHeader } style={ { justifyContent: 'start' } }>
          <button className={ classes.hangupBtn } type="button" onClick={ hangupChat } disabled={ hangingUp }>
            <img className={ classes.hangupImg } src={ isKA() ? hangupIconKA : hangupIconPG } alt="" />
            { t('chat.hang_up') }
          </button>
        </div>
        <div className={ classes.chatHeader } style={ { justifyContent: 'center' } }>
          <img className={ classes.avatar } src={ profilePictureUrl } alt="" style={ { '--size': '40px' } } />
          <b className={ classes.advisorNameText }>{name}</b>
        </div>
        <div className={ classes.chatHeader } style={ { justifyContent: 'flex-end' } }>
          <TimerComponent />
        </div>
      </div>
    );
  };

  liveIndicatorAnimation = (canvas) => {
    let pulseFactor = 1;
    const minPulse = 0.6;
    const maxPulse = 0.9;

    this.liveIndecatorInterval = setInterval(() => {
      // Update the pulse factor with easing
      const range = maxPulse - minPulse;
      const halfRange = range / 2;
      const center = minPulse + halfRange;
      const easing = Math.sin(Date.now() / 300) * halfRange;
      pulseFactor = center + easing;

      const newFavicon = this.createFavicon(canvas, pulseFactor);
      this.setFavicon(newFavicon);
    }, 300);
  };

  createFavicon = (canvas, pulseFactor) => {
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, 32, 32);

    // Draw the pulsing circle
    const radius = (32 / 2) * pulseFactor;
    ctx.beginPath();
    ctx.arc(32 / 2, 32 / 2, radius, 0, Math.PI * 2);
    ctx.fillStyle = '#73C940';
    ctx.fill();

    // Create a data URL from the canvas
    return canvas.toDataURL('image/png');
  };

  setFavicon = (dataURL) => {
    let favicon = document.getElementById('favicon_link');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      document.head.appendChild(favicon);
    }
    favicon.href = dataURL;
  };

  viewportHandler = () => {
    if (pendingUpdate) return;
    pendingUpdate = true;

    const chatContainer = document.getElementById('chatContainer');
    const chatMessagesContainer = document.getElementById('chatMessagesContainer');
    chatContainer.ontouchmove = (e) => {
      if (chatMessagesContainer.contains(e.target)) return;
      e.preventDefault();
    };
  };

  scrollToBottom() {
    this.lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  renderMessage = (message, index) => {
    const { ownUuid } = this.props;
    const { uuid } = message;
    if (parseInt(ownUuid, 10) === uuid) {
      return this.renderMyMessage(message, index);
    }
    return this.renderAdvisorMessage(message);
  };

  renderSystemMessage = ({
    body, timetoken
  }, index) => (
    <SystemMessage key={ index } body={ body } timetoken={ timetoken || '' } />
  );

  renderMyMessage({
    body, uuid, timetoken, offline
  }, index) {
    const { peers } = this.props;
    const peer = peers.find(p => parseInt(p.uuid, 10) === parseInt(uuid, 10));
    return (
      <ClientMessage
        key={ index }
        body={ body }
        timetoken={ timetoken || '' }
        imageUrl={ peer.imageUrl }
        offline= { offline }
      />
    );
  }

  renderAdvisorMessage({ body, uuid, timetoken }) {
    const { peers } = this.props;
    const peer = peers.find(p => parseInt(p.uuid, 10) === parseInt(uuid, 10));
    return (
      <AdvisorMessage
        key={ timetoken }
        body={ body }
        timetoken={ timetoken }
        imageUrl={ peer.imageUrl }
      />
    );
  }

  renderPeerTyping() {
    const {
      peerTyping, ownUuid, peers, t
    } = this.props;
    const { uuid, nickname } = peers.find(p => parseInt(p.uuid, 10) !== parseInt(ownUuid, 10));

    return peerTyping ? (
      <div className={ classes.typingContainer }>
        {this.getAvatarFromUuuId(uuid)}
        <span className={ classes.nickname }>{` ${ nickname } ${ t('chat.is_typing') }`}</span>
      </div>
    ) : (
      <div className={ classes.typingContainer } />
    );
  }

  render() {
    const {
      messages, systemMessages, sendMessage, sendTyping, sendStopTyping,
      typingTimeout, disabled
    } = this.props;
    const formProps = {
      sendMessage, sendTyping, sendStopTyping, typingTimeout, disabled
    };
    return (
      <div id="chatContainer" className={ isMobile ? classes.chatContainerMobile : classes.chatContainerBrowser }>
        {this.renderHeader()}
        <NetworkStateComponent />
        <div id="chatMessagesContainer" className={ classes.chatMessagesContainer }>
          {systemMessages.map(this.renderSystemMessage) }
          {messages.map(this.renderMessage) }
          <div ref={ this.lastMessageRef } />
        </div>
        {this.renderPeerTyping()}
        <Form { ...formProps } textAreaRef={ this.textAreaRef } />
      </div>
    );
  }
}

export const SystemMessage = memo(({ body, timetoken }) => (
  <div key={ timetoken } className={ classes.chatSystemMessageContainer } data-hj-suppress>
    <div className={
      classNames(classes.chatSystemBubbleContainer, classes.chatBaseBubbleContainer)
}
    >
      <span className={ classes.bubbdleText }>
        {parse(body)}
      </span>
    </div>
  </div>
));

SystemMessage.propTypes = {
  body: PropTypes.string.isRequired,
  timetoken: PropTypes.string.isRequired
};

export const ClientMessage = memo(({ body, timetoken, offline }) => (
  <div key={ timetoken } className={ classes.chatOwnMessageContainer } data-hj-suppress>
    <div className={ classNames(classes.chatOwnBubbleContainer, classes.chatBaseBubbleContainer) }>
      <span className={ classes.bubbdleText }>
        {body}
        {
         offline
           ? <LoadIndicator classname={ classes.clientTimeToken } />
           : <TimeToken timetoken={ timetoken } classname={ classes.clientTimeToken } />
        }
      </span>
    </div>
  </div>
));

ClientMessage.propTypes = {
  body: PropTypes.string.isRequired,
  timetoken: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  offline: PropTypes.bool
};

ClientMessage.defaultProps = {
  imageUrl: null,
  offline: false
};

export const AdvisorMessage = memo(({ body, timetoken, imageUrl }) => (
  <div key={ timetoken } className={ classes.chatAdvisorMessageContainer }>
    <img className={ classes.avatar } src={ imageUrl } alt="" style={ { '--size': '32px' } } />
    <div
      className={ classNames(classes.chatAdvisorBubbleContainer, classes.chatBaseBubbleContainer) }
      data-hj-suppress
    >
      <span className={ classes.bubbdleText }>
        {body}
        <TimeToken timetoken={ timetoken } classname={ classes.advisorTimeToken } />
      </span>
    </div>
  </div>
));

AdvisorMessage.propTypes = {
  body: PropTypes.string.isRequired,
  timetoken: PropTypes.string.isRequired,
  imageUrl: PropTypes.string
};

AdvisorMessage.defaultProps = {
  imageUrl: null
};

function TimeToken({ timetoken, classname }) {
  return <span className={ classname }>{format(new Date(timetoken), 'HH:mm')}</span>;
}

TimeToken.propTypes = {
  timetoken: PropTypes.string.isRequired,
  classname: PropTypes.string.isRequired
};

function LoadIndicator({ classname }) {
  return <span className={ classname }><Spiner /></span>;
}

LoadIndicator.propTypes = {
  classname: PropTypes.string.isRequired
};
