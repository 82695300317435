import React, { PureComponent } from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Const from '../../../config/const';
import classes from './classes.module.scss';
import CallAnimation from './call_animation';

const cancelBtn = require('./img/cross_icn.png');
const voipCancelButton = require('./img/ic_phone.png');

const videoConstraints = {
  width: 100,
  height: 150,
  facingMode: 'user'
};

export default class Outgoing extends PureComponent {
  static propTypes = {
    cancelChat: PropTypes.func.isRequired,
    profilePictureUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hangingUp: PropTypes.bool,
    t: PropTypes.func.isRequired,
    chatType: PropTypes.string.isRequired,
    soundUrl: PropTypes.string.isRequired,
    createChat: PropTypes.func,
    orderId: PropTypes.number
  };

  static defaultProps = {
    hangingUp: false,
    createChat: null,
    orderId: null
  };

  constructor(props) {
    super(props);

    this.renderWebcam = this.renderWebcam.bind(this);
  }

  componentDidMount() {
    const { soundUrl } = this.props;
    this.audio = new Audio(soundUrl);
    this.audio.play();
    this.audio.loop = true;
    const { createChat } = this.props;
    if (createChat) createChat();
  }

  componentWillUnmount() {
    this.audio.pause();
  }

  onHangUP = () => {
    const { cancelChat, hangingUp, orderId } = this.props;
    if (hangingUp || !orderId) return;
    cancelChat();
  };

  renderWebcam() {
    const { chatType } = this.props;
    if (chatType !== Const.chatType.video) {
      return null;
    }
    return (
      <div className={ classes.publisherContainer }>
        <Webcam
          audio={ false }
          height={ 150 }
          width={ 100 }
          videoConstraints={ videoConstraints }
          mirrored
        />
      </div>
    );
  }

  render() {
    const {
      profilePictureUrl, name,
      hangingUp, t, chatType
    } = this.props;
    let borderColor;
    switch (chatType) {
      case Const.chatType.text:
        borderColor = '#6eda0f';
        break;
      case Const.chatType.video:
        borderColor = '#67B9ED';
        break;
      case Const.chatType.voice:
        borderColor = '#8F63EC';
        break;
      default:
        borderColor = '#000000';
        break;
    }
    return (
      <div className={ classes.container }>
        <img className={ classes.blurImage } src={ profilePictureUrl } alt="Blur" />
        <div className={ classes.overlay } />
        <CallAnimation
          pictureSrc={ profilePictureUrl }
          borderColor={ borderColor }
        />
        <div className={ classes.contactingContainer }>
          <span className={ classes.contacting }>
            {hangingUp ? t('chat.hanging_up')  : t('chat.contacting') }
          </span>
          <br />
          {name}
        </div>
        <div className={ classes.buttonContainer }>
          <div
            className={ classNames(classes.button, hangingUp ? classes.hangingUp : null) }
            onClick={ this.onHangUP }
            onKeyDown={ this.onHangUP }
          >
            <img
              className={
                chatType === Const.chatType.text ? classes.buttonImage : classes.voipButtonImage
              }
              src={ chatType === Const.chatType.text ? cancelBtn : voipCancelButton }
              alt="Cancel"
            />
          </div>
        </div>
        { this.renderWebcam() }
      </div>
    );
  }
}
