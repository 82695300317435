import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  show: false,
  link: null,
  title: null,
  rightCustomItem: null,
  backCustomAction: null
});

const handler = {
  [Const.generalPopup.show]: (state, { title, link }) => ({
    ...state,
    show: true,
    title,
    link
  }),
  [Const.generalPopup.hide]: (state) => ({
    ...state,
    show: false,
    title: null,
    link: null
  }),
  [Const.generalPopup.setRightCustomItem]: (state, { item }) => ({
    ...state,
    rightCustomItem: item
  }),
  [Const.generalPopup.clearRightCustomItem]: (state) => ({
    ...state,
    rightCustomItem: null
  }),
  [Const.generalPopup.updateBackAction]: (state, { action }) => ({
    ...state,
    backCustomAction: action
  }),
  [Const.generalPopup.clearBackAction]: (state) => ({
    ...state,
    backCustomAction: null
  })
};

export default createReducer(getDefaultState(), handler);
