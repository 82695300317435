import React from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';

function Track({ source, target, getTrackProps }) {
  return (
    <div
      className={ classes.sliderTrack }
      style={ {
        left: `${ source.percent }%`,
        width: `${ target.percent - source.percent }%`
      } }
      { ...getTrackProps()  }
    />
  );
}

Track.propTypes = {
  source: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  getTrackProps: PropTypes.func.isRequired
};
export default Track;
