import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  credits: [],
  showPaymentDetails: false
};

const handler = {
  [Const.funds.loading]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [Const.funds.error]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error
  }),
  [Const.funds.credits]: (state, { payload: { credits } }) => ({
    ...state,
    loading: false,
    credits,
    error: null
  }),
  [Const.funds.displayDetails]: (state, { display }) => ({
    ...state,
    showPaymentDetails: display
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  }),
  [Const.aplicationError.clearError]: (state) => ({
    ...state,
    showPaymentDetails: false
  })
};

export default createReducer(defaultState, handler);
