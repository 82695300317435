import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  validating: false,
  validatedData: null,
  error: null
};

const validateState = ({ state }) => {
  if (state !== 'pending' && state !== 'validated') {
    return { error: { text: 'error' } };
  }
  return {};
};

const handler = {
  [Const.creditCardValidateProcessing.validating]: (state, { validating }) => ({
    ...state,
    validating
  }),
  [Const.creditCardValidateProcessing.setValidatedData]: (state, { validatedData }) => ({
    ...state,
    validatedData,
    ...validateState({ state: validatedData.state })
  }),
  [Const.creditCardValidateProcessing.error]: (state, { error }) => ({
    ...state,
    error: { ...error, text: error.error || error.statusText },
    validating: false
  }),
  [Const.creditCardValidateProcessing.clear]: () => ({
    ...defaultState
  }),
  [Const.chat.cancel]: () => ({
    ...defaultState
  }),
  [Const.voip.cancel]: () => ({
    ...defaultState
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, handler);
