import { format } from 'date-fns';
import { checkNickname, introduceYourselfUpdate } from '../config/api';
import Const from '../config/const';

const inProgressAction = () => ({
  type: Const.introduce.inProgress
});

const errorAction = error => ({
  type: Const.introduce.error,
  payload: {
    error
  }
});

export const clearErrorAction = () => ({
  type: Const.introduce.clearError
});

const successAction = user => ({
  type: Const.introduce.user,
  payload: {
    user
  }
});

export const updateUserBaseInfo = ({ nickname, gender, dateOfBirth }) => (dispatch) => {
  dispatch(inProgressAction());
  checkNickname(nickname)
    .then(() => introduceYourselfUpdate({ nickname, gender, date: format(dateOfBirth, 'yyyy-MM-dd') }))
    .then(user => {
      dispatch(successAction(user));
    })
    .catch(error => {
      error.json()
        .then(errorJson => {
          dispatch(errorAction(errorJson));
        });
    });
};
