/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import withPGTranslation from '../../../config/withPGTranslation';
import SeeMoreBtn from './see_more_btn';

export const seeMoreBtnThemes = {
  searchResult: 'searchResult',
  someOtherAdvisor: 'someOtherAdvisor',
  homePageAllAdvisors: 'homePageAllAdvisors'
};

const mapStateToProps = ({ searchResult }) => ({
  ...searchResult
});

export default connect(
  mapStateToProps,
  { }
)(withPGTranslation(SeeMoreBtn));
