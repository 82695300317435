import React from 'react';
import {
  Slider, Handles, Tracks, Rail
} from 'react-compound-slider';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';
import Handle from './filter_handle';
import Track from './slider_track';
import withPGTranslation from '../../../../../config/withPGTranslation';

function FilterSlider({
  currentPPMRange, onRangeChange, t
}) {
  const { min, max, step } = window.config.filterPricePerMinute;
  const priceValues = () => (
    <div className={ classes.sliderPrices }>
      <span>{`$${ min }`}</span>
      <span className={ classes.selectedPriceContainer }>{`$${ currentPPMRange[0] }-$${ currentPPMRange[1] }/min`}</span>
      <span>{`$${ max }`}</span>
    </div>
  );

  return (
    <div className={ classes.itemContainer }>
      <div className={ classes.itemHolder }>
        <span className={ classes.itemTitle }>
          { t('home_page.price_per_minute') }
        </span>
        <div className={ classes.sliderContainer }>
          <Slider
            className={ classes.sliderRoot }
            domain={ [min, max] }
            step={ step }
            values={ currentPPMRange }
            mode={ 2 }
            onUpdate={ onRangeChange }
          >
            <Rail>
              {({ getRailProps }) => (
                <div className={ classes.rail } { ...getRailProps() } />
              )}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div>
                  {handles.map((handle, index) => (
                    <Handle
                      key={ handle.id }
                      handle={ handle }
                      getHandleProps={ getHandleProps }
                      isLeft={ index === 0 }
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks right={ false } left={ false }>
              {({ tracks, getTrackProps }) => (
                <div>
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={ id }
                      source={ source }
                      target={ target }
                      getTrackProps={ getTrackProps }
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
          {priceValues()}
        </div>
      </div>
    </div>
  );
}

FilterSlider.propTypes = {
  currentPPMRange: PropTypes.array.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
export default withPGTranslation(FilterSlider);
