import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import env from './variables';

const onErrorFunc = () => env.BUGSNAG_TRACKING_EVENTS || !window.store.getState().user.impersonated;
Bugsnag.start({
  apiKey: env.BUGSNAG_KEY,
  version: env.CLIENT_APP_VERSION,
  // <<<<<<< HEAD
  //   beforeSend: (report) => {
  //     if (!env.BUGSNAG_TRACKING_EVENTS) report.ignore();
  //     if (window.store.getState().user.impersonated) report.ignore();
  //   }
  // =======
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ['error', 'manual', 'navigation', 'process', 'request', 'state', 'user'],
  onError: onErrorFunc
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default ErrorBoundary;
