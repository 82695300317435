import React from 'react';
import PropTypes from 'prop-types';
import MessageToAppHandler from '../../../common/message_to_app_handler';

function MessageToApp({
  messageToAppData, setMessageToAppData
}) {
  return (
    <MessageToAppHandler
      messageToAppData={ messageToAppData }
      setMessageToAppData={ setMessageToAppData }
    />
  );
}

MessageToApp.propTypes = {
  messageToAppData: PropTypes.object.isRequired,
  setMessageToAppData: PropTypes.func.isRequired
};

MessageToApp.defaultProps = {
};

export default MessageToApp;
