import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  success: false
});

const logOutReducer = createReducer(getDefaultState(), {
  [Const.logOut.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.logOut.success]: (state) => ({
    ...state,
    loading: false,
    success: true
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  })
});

export default logOutReducer;
