import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { displayRegister, authPendingAction } from '../../actions/auth';
import { needRegisterRoutes } from '../../config/app_routes';

function UnregisteredLinks({
  children, showRegister, addAuthPendingAction
}) {
  const checkLinks = () => {
    const needRegisterRoutesArray = Object.keys(needRegisterRoutes).map(
      (key) => needRegisterRoutes[key]
    );
    const pathNameWithoutLocalization = window.location.pathname.replace(/(en[/])|(es[/])/, '');
    needRegisterRoutesArray.find((item) => {
      const matchNeedRegisterUrl = matchPath(
        {
          path: `${ item }`,
          end: true
        },
        `${ pathNameWithoutLocalization }`
      );
      if (matchNeedRegisterUrl && Object.keys(matchNeedRegisterUrl).length > 0 && !window.currentUserId) {
        showRegister(true);
        addAuthPendingAction(() => {
          window.location.replace(`${ window.location.pathname }`);
        });
        return true;
      }
      return false;
    });
    return children;
  };
  return checkLinks();
}

UnregisteredLinks.propTypes = {
  showRegister: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  addAuthPendingAction: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  showRegister: (visible) => dispatch(displayRegister({ shouldDisplay: visible })),
  addAuthPendingAction: (visible) => dispatch(authPendingAction(visible))
});
export default connect(() => ({}), mapDispatchToProps)(UnregisteredLinks);
