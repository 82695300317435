import React, { useEffect, useRef } from 'react';
import { isMobile, isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import env from '../../../config/variables';
import 'node_modules/react-smartbanner/dist/main.css';
import classes from '../classes.module.scss';
import { getDataFromStorage, shouldShowAffiliateBanner } from '../../../config/util';
import { fromMobileApp, downloadAppLink } from '../../../../common/config/utils';

const {
  SMART_BANNER_SHOW,
  SMART_BANNER_DAYS_HIDDEN,
  SMART_BANNER_DAYS_REMINDEER,
  PGWEB_HIDE_SMART_BANNER_SECONDS
} = env;

const cookies = new Cookies();

const link = downloadAppLink('smart_banner');

const androidIcon = require('./img/android_icon.svg');
const iosIcon = require('./img/ios_icon.svg');

const getIconStyles = () => {
  let iconStyle = `${ classes.androidIcon }`;
  let iconImg = `${ androidIcon }`;

  if (isIOS) {
    iconStyle = `${ classes.iosIcon }`;
    iconImg = `${ iosIcon }`;
  }
  return {
    iconStyle, iconImg
  };
};

const cookieValue = (valueName) => {
  const valueString = document.cookie.split('; ').find(row => row.startsWith(`${ valueName }=`));
  const value = valueString ? valueString.split('=')[1] : null;
  return value;
};

const smartBannerDisplayValidation = (hasOrders, promoBannerIsOn, showAffiliatePromoBanner) => (
  (SMART_BANNER_SHOW && !fromMobileApp)
  && hasOrders
  && isMobile
  && !promoBannerIsOn
  && !window.attribution.promo
  && !showAffiliatePromoBanner
  && !cookieValue('cancelSmartBanner')
  && !cookieValue('smartBannerImg')
);

const addEventListenerForRef = (smartBannerRef, cancelButtonRef, bannerImgClick, cancelClick) => {
  const smartBanner = smartBannerRef.current;
  const cancelButton = cancelButtonRef.current;
  if (smartBanner && cancelButton) {
    smartBanner.addEventListener('click', bannerImgClick);
    cancelButton.addEventListener('click', cancelClick);
    return () => {
      smartBanner.removeEventListener('click', bannerImgClick);
      cancelButton.removeEventListener('click', cancelClick);
    };
  }
  return null;
};

function SmartBanner({
  hasOrders,
  promoBannerIsOn,
  updateSmartBannerVisibility,
  smartBannerVisible,
  trackSmartBannerEvent,
  userMatchUpBonusInfo,
  userShowMatchUpBonusInfo,
  userLoading
}) {
  const title = 'download the app';

  let startDate;

  const mpTrackSmartBannerEvent = () => {
    const params = {
      sessionsCount: (parseInt(getDataFromStorage('BROWSER_SESSIONS'), 10) || 0),
      secondsFromSessionBegan: ((new Date() - startDate) / 1000).toFixed(2)
    };
    trackSmartBannerEvent(params);
  };

  const cancelClick = (e) => {
    const smartBannerContainer = document.getElementById('bannerContainerID');
    smartBannerContainer.style.display = 'none';
    const date = new Date();
    date.setTime(date.getTime() + (SMART_BANNER_DAYS_HIDDEN * 24 * 60 * 60 * 1000));
    cookies.set('cancelSmartBanner', true, {
      path: '/',
      expires: date
    });
    e.stopPropagation();
  };

  const bannerImgClick = () => {
    const smartBannerContainer = document.getElementById('bannerContainerID');
    smartBannerContainer.style.display = 'none';
    mpTrackSmartBannerEvent();
    const date = new Date();
    date.setTime(date.getTime() + (SMART_BANNER_DAYS_REMINDEER * 24 * 60 * 60 * 1000));
    cookies.set('smartBannerImg', true, {
      path: '/',
      expires: date
    });
    window.open(link, '_blank');
  };

  const smartBannerRef = useRef();
  const cancelButtonRef = useRef();

  useEffect(() => {
    startDate = new Date();
    addEventListenerForRef(smartBannerRef, cancelButtonRef, bannerImgClick, cancelClick);
  });

  setTimeout(() => {
    updateSmartBannerVisibility({ url: {}, visible: false });
  }, PGWEB_HIDE_SMART_BANNER_SECONDS * 1000);

  const { MATCH_UP_BONUS_INFO } = env;
  const creditMatchingInfo = userMatchUpBonusInfo || MATCH_UP_BONUS_INFO;

  if (!smartBannerVisible || !smartBannerDisplayValidation(hasOrders, promoBannerIsOn, shouldShowAffiliateBanner(creditMatchingInfo, userShowMatchUpBonusInfo, userLoading))) return null;

  return (
    <div id= "bannerContainerID" className = { classes.smartBannerContainer }>
      <div className = { classes.smartBanner } ref= { smartBannerRef }>
        <div className = { classes.cancelBtnContainer } ref= { cancelButtonRef }>
          <div className= { classes.cancelButton } />
        </div>
        <svg id= "bannerImgID" display= "flex" viewBox="-2 0 100 100" width="100" height="100">
          <defs>
            <path id="circle" d="M 53.2,39 m -41 0 2,1 -0,1 a 35,36 2 1,1 56,37 a 32,65 3 1,1 -75,7" />
          </defs>
          <text className = { classes.smartBannerTitle }>
            <textPath xlinkHref="#circle">
              {title}
            </textPath>
          </text>
        </svg>
        <img className= { getIconStyles().iconStyle } src={ getIconStyles().iconImg } alt="" />
      </div>
    </div>
  );
}

SmartBanner.propTypes = {
  hasOrders: PropTypes.bool,
  promoBannerIsOn: PropTypes.bool,
  updateSmartBannerVisibility: PropTypes.func.isRequired,
  smartBannerVisible: PropTypes.bool,
  trackSmartBannerEvent: PropTypes.func.isRequired,
  userShowMatchUpBonusInfo: PropTypes.object,
  userMatchUpBonusInfo: PropTypes.object,
  userLoading: PropTypes.bool
};
SmartBanner.defaultProps = {
  hasOrders: false,
  promoBannerIsOn: false,
  smartBannerVisible: false,
  userMatchUpBonusInfo: {},
  userShowMatchUpBonusInfo: {},
  userLoading: false
};

export default SmartBanner;
