import React from 'react';
import classes from './classes.module.scss';
import { roundCredits } from '../../../../config/util';
import Const from '../../../../config/const';
import { rightArrow } from './img/right_arrow';
import { leftArrow } from './img/left_arrow';

const newUserPaygFreeMinIcon = require('./img/new_user_payg_free_min_icon.svg');
const newUserPaygFreeCreditsIcon = require('./img/new_user_payg_free_credits_icon.svg');
const newUserPaygDiscountAppliedIcon = require('./img/new_user_payg_discount_applied_icon.svg');

const shouldScrollRight = (carouselRef, pricingOptions) => {
  if (!carouselRef || !carouselRef.current) return false;
  const { current: { state: { selectedItem } } } = carouselRef;
  return selectedItem !== pricingOptions.length - 1;
};

const shouldScrollLeft = (carouselRef) => {
  if (!carouselRef || !carouselRef.current) return false;
  const { current: { state: { selectedItem } } } = carouselRef;
  return selectedItem !== 0;
};

export const carouselHandleKeyPress = (
  e,
  carouselOnclickArrowNext,
  carouselOnclickArrowPrev,
  carouselRef,
  pricingOptions
) => {
  if (e.code === 'ArrowRight' && shouldScrollRight(carouselRef, pricingOptions)) {
    carouselOnclickArrowNext();
  }
  if (e.code === 'ArrowLeft' && shouldScrollLeft(carouselRef)) {
    carouselOnclickArrowPrev();
  }
};

const getDelta = ({ carouselSwipeCurrentPosition, swipeChange }) => {
  let delta = 0;
  if (carouselSwipeCurrentPosition > swipeChange) {
    delta = (carouselSwipeCurrentPosition - swipeChange) * (-1);
  }
  if (carouselSwipeCurrentPosition < swipeChange) {
    delta = swipeChange - carouselSwipeCurrentPosition;
  }
  return delta;
};

const carouselSwipeMoveLeftActionHandler = ({
  setCarouselSwipeCurrentPosition, e, carouselRef, carouselSetSelected
}) => {
  setCarouselSwipeCurrentPosition(e.changedTouches[0].pageX);
  if (!shouldScrollLeft(carouselRef)) return;
  carouselRef.current.onSwipeBackwards();
  carouselSetSelected();
};

const carouselSwipeMoveRightActionHandler = ({
  setCarouselSwipeCurrentPosition, e, carouselRef, pricingOptions,
  carouselSetSelected
}) => {
  setCarouselSwipeCurrentPosition(e.changedTouches[0].pageX);
  if (!shouldScrollRight(carouselRef, pricingOptions)) return;
  carouselRef.current.onSwipeForward();
  carouselSetSelected();
};

export const carouselSwipeMoveAction = ({
  e,
  carouselRef,
  carouselSwipeCurrentPosition,
  setCarouselSwipeCurrentPosition,
  carouselSetSelected,
  pricingOptions,
  getCarouselPosition
}) => {
  const swipeChange = e.changedTouches[0].pageX;
  const currentPosition = getCarouselPosition(carouselRef.current.state.selectedItem, carouselRef.current.props);
  const delta = getDelta({ carouselSwipeCurrentPosition, swipeChange });
  carouselRef.current.listRef.setAttribute('style', `transform: translate3d(${ currentPosition + delta }%, 0px, 0px)`);
  if (carouselSwipeCurrentPosition && swipeChange > carouselSwipeCurrentPosition + 64) {
    carouselSwipeMoveLeftActionHandler({
      setCarouselSwipeCurrentPosition, e, carouselRef, carouselSetSelected
    });
  }
  if (carouselSwipeCurrentPosition && swipeChange < carouselSwipeCurrentPosition - 64) {
    carouselSwipeMoveRightActionHandler({
      setCarouselSwipeCurrentPosition,
      e,
      carouselRef,
      pricingOptions,
      carouselSetSelected
    });
  }
};

export const getDurationOptionsClass = (option, selected, index, selectedCarouselIndex, isNewUser) => {
  if (isNewUser && index === selectedCarouselIndex) return classes.carouselSelectedItem;
  if (option.duration === selected.duration) return classes.carouselSelectedItem;
  return classes.carouselItem;
};

export const onCarouselSetSelected = (carouselRef, pricingOptions, onSelect) => {
  if (!carouselRef || !carouselRef.current) return null;
  const selectedIndex = carouselRef.current.state.selectedItem;
  const option = pricingOptions[selectedIndex];
  const defaultDuration = pricingOptions[selectedIndex].default;
  onSelect({
    defaultDuration, ...option, selectedCarouselIndex: selectedIndex
  });
  return () => {};
};

export const carouselArrowPrevHandler = ({
  carouselRef, carouselOnclickArrowPrev, onKeyDown, carouselArrowPrevRef
}) => {
  if (!carouselRef || !carouselRef.current) return null;
  const disableArrow = carouselRef.current.state.selectedItem === 0;
  return (
    <div
      className={ classes.carouselArrowPrevContainer }
      onClick={ disableArrow ? null : carouselOnclickArrowPrev }
      onKeyDown={ onKeyDown }
      ref={ carouselArrowPrevRef }
      style={ disableArrow ? { opacity: 0, cursor:'auto' } : null }
    >
      <div className={ classes.carouselArrowPrevContent }>
        <div className={ classes.arrowImgContainer }>
          {leftArrow()}
        </div>
      </div>
    </div>
  );
};

export const carouselArrowNextHandler = ({
  carouselRef, pricingOptions, carouselOnclickArrowNext, onKeyDown,
  carouselArrowNextRef
}) => {
  if (!carouselRef || !carouselRef.current) return null;
  const disableArrow = carouselRef.current.state.selectedItem
     === pricingOptions.length - 1;
  return (
    <div
      className={ classes.carouselArrowNextContainer }
      onClick={ disableArrow ? null : carouselOnclickArrowNext }
      onKeyDown={ onKeyDown }
      ref={ carouselArrowNextRef }
      style={ disableArrow ? { opacity: 0, cursor:'auto' } : null }
    >
      <div className={ classes.carouselArrowNextContent }>
        <div className={ classes.arrowImgContainer }>
          {rightArrow()}
        </div>
      </div>
    </div>
  );
};

export const renderNewUserPaygPrice = ({ price, availableSalesPrice }) => {
  let content = (
    <div className={ classes.newUserPaygPriceContainer }>
      <div className={ classes.newUserPaygPrice }>
        {`$${ roundCredits(price) }`}
      </div>
      <div className={ classes.newUserPaygMinText }>
        /min
      </div>
    </div>
  );

  if (availableSalesPrice) {
    content = (
      <div className={ classes.newUserPaygPriceContainer }>
        <div className={ classes.newUserPaygSalesPrice }>
          {`$${ roundCredits(availableSalesPrice) }`}
        </div>
        <div className={ classes.newUserPaygSaleCrosPrice }>
          {`$${ roundCredits(price) }`}
        </div>
        <div className={ classes.newUserPaygMinText }>
          /min
        </div>
      </div>
    );
  }
  return content;
};

export const renderNewUserPaygDiscountApplied = ({ discountApplied }) => {
  if (!discountApplied) return null;
  return (
    <div className={ classes.newUserPaygDiscountAppliedContainer }>
      <div className={ classes.newUserPaygDiscountAppliedTitle }>
        Discount applied:
      </div>
      <div className={ classes.newUserPaygDiscountAppliedImgTextContainer }>
        <img src={ newUserPaygDiscountAppliedIcon } alt="" className={ classes.newUserPaygDiscountAppliedImg } />
        <span className={ classes.newUserPaygDiscountAppliedText }>
          { discountApplied }
        </span>
      </div>
    </div>
  );
};

const renderNewUserPaygLabelContent = ({
  kind, icon, title, subtitle, color
}) => (
  <div key={ `newUserPaygBonusItem_${ kind }` } className={ classes.newUserPaygBonusLabelContainer }>
    <img src={ icon } alt="" className={ classes.newUserPaygBonusLabelImg } />
    <div className={ classes.newUserPaygBonusLabelTextContainer }>
      <div className={ classes.newUserPaygBonusLabelText } style={ { color } }>
        { title }
      </div>
      <div className={ classes.newUserPaygBonusLabelSubText }>
        { subtitle }
      </div>
    </div>
  </div>
);

export const renderNewUserPaygLabel = ({ bonusItem }) => {
  const { kind, title, subtitle } = bonusItem;
  let content;
  let icon;
  switch (kind) {
    case Const.newUserPaygBonusItemKind.xfm:
      icon = newUserPaygFreeMinIcon;
      content = renderNewUserPaygLabelContent({
        kind, icon, title, subtitle, color: '#7BD137'
      });
      break;
    case Const.newUserPaygBonusItemKind.cardValidationBonus:
      icon = newUserPaygFreeCreditsIcon;
      content = renderNewUserPaygLabelContent({
        kind, icon, title, subtitle
      });
      break;
    default:
      content = null;
      break;
  }
  return content;
};
