import { connect } from 'react-redux';
import Const from '../../../config/const';
import Error from '../error';
import { hangupVoipChat } from '../../../actions/voip_chat';
import { hangupChat } from '../../../actions/chat';

const mapStateToProps = ({ chat: { errors }, voipChat: { errors: voipErrors, permissionDeniedError } }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({ error: { errors }, chatType });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({ error: { voipErrors }, chatType, permissionDeniedError });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        onCancelClicked: () => dispatch(hangupChat())
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        onCancelClicked: () => dispatch(hangupVoipChat())
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error);
