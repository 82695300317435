import { connect } from 'react-redux';
import CreatedChat from './created_chat';
import Const from '../../config/const';
import {
  connectChatProvider,
  disconnectChatProvider,
  sendMessage,
  sendTyping,
  sendStopTyping
} from '../../actions/connected_chat';
import {
  cancelChat, hangupChat, purchaseOnBack
} from '../../actions/chat';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateToProps = ({
  chat, pubnub: { ownUuid }, user: { user }
}) => ({
  ...chat,
  ownUuid,
  uuid: (chat.pubnub || {}).uuid,
  userId: user && user.id,
  isNewUser: chat && chat.selectDurationKind === Const.selectDurationKind.newUser
});

export default connect(
  mapStateToProps,
  {
    connectChatProvider,
    disconnectChatProvider,
    cancelChat,
    sendMessage,
    sendTyping,
    sendStopTyping,
    hangupChat,
    purchaseOnBack
  }
)(withPGTranslation(CreatedChat));
