import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function BlinkTimer({ onTick }) {
  useEffect(() => {
    const timer = setInterval(() => {
      onTick();
    },  500);
    return () => clearInterval(timer);
  }, []);
  return <div />;
}

BlinkTimer.propTypes = {
  onTick: PropTypes.func.isRequired
};

export default BlinkTimer;
