import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './classes.module.scss';
import AdvisorCta from './advisor_cta';
import { AdvisorPropType, AdvisorDefaultProps } from '../../../reducers/advisor_prop_types';
import AdvisorAvatar, { advisorThemes } from '../../advisor_avatar';
import TopIcons from '../../advisor_profile_avatar/top_icons';
import ExpandedText from '../../../../common/helpers/expanded_text';
import { isPrerenderMode, getHeaderHeight } from '../../../config/util';
import { isKA } from '../../../../common/config/utils';
import { advisorCtaThemes } from './advisor_cta/advisor_cta_themes';

function AdvisorStaticContainer({
  modesContainerVisible, profilePictureUrl, nickname, serviceTitle, loading, liveModes, id,
  favoriteAdvisor, loader, favorite, availabilityNotification, status
}) {
  const renderAvatarSection = () => (
    <div className={ classes.fixedContainerAvatarContainer }>
      <div className={ classes.fixedContainerAvatarHolder } suppressHydrationWarning>
        <AdvisorAvatar
          profilePictureUrl={ profilePictureUrl }
          loading={ loading }
          theme={ advisorThemes.profileSmallAvatar }
        />
      </div>
      <div className={ classes.advisorStaticTitleContainer }>
        <div className={ classes.advisorNameContainer } suppressHydrationWarning>
          <ExpandedText
            customClasses={ { text: classes.advisorNameText } }
            ellipsis
          >
            { nickname }
          </ExpandedText>
        </div>

        <div className={ classes.advisorServiceTitleContainer } suppressHydrationWarning>
          <ExpandedText
            customClasses={ { text: classes.advisorServiceTitleText } }
            ellipsis
          >
            { serviceTitle }
          </ExpandedText>
        </div>
      </div>
    </div>
  );

  const renderCtaSection = () => {
    if (loading || isPrerenderMode() || liveModes.length === 0) {
      const skeletonCount = isKA() ? 2 : 3;
      const skeletonClassName = isKA() ? classNames(classes.ctaSkeleton, classes.ctaSkeletonFullWidth) : classes.ctaSkeleton;
      return (
        <div className={ classes.ctaSkeletonContainer }>
          {
            [...Array(skeletonCount)].map((s, index) => (
              <div id={ `ctaSkeleton${ index }` } key={ `ctaSkeleton${ index }` } className={ skeletonClassName } />
            ))
          }
        </div>
      );
    }
    return (
      <div className={ classes.ctaHolder }>
        {liveModes.map((mode) => (
          <AdvisorCta
            key={ mode.mode }
            { ...mode }
            advisorId={ id }
            advisorCallProps={ {
              id,
              name: nickname,
              profilePictureUrl
            } }
            theme={ advisorCtaThemes.advisorFixContainer }
            liveModes={ liveModes }
          />
        )) }
      </div>
    );
  };

  const renderTopIconContainer = () => (
    <div className={ classes.topIconsContaner }>
      <TopIcons
        id = { id }
        favoriteAdvisor = { favoriteAdvisor }
        favorite = { favorite }
        loader = { loader }
        theme = { advisorThemes.sticky_icons }
        availabilityNotification={ availabilityNotification }
        advisorStatus={ status }
      />
    </div>
  );

  const modesContainerStyle = () => (modesContainerVisible ? { '--display':'none' } : { '--display':'flex', '--top': getHeaderHeight() });
  const renderContentBrowser = () => (
    <div
      className={ classes.fixedContainerBrowser }
      style={ modesContainerStyle() }
      suppressHydrationWarning
    >
      <div className={ classes.fixedContentBrowser }>
        { renderAvatarSection() }
        <div className={ classes.optionsContainer }>
          { renderCtaSection() }
          { renderTopIconContainer() }
        </div>
      </div>
    </div>
  );

  const renderContentMobile = () => (
    <>
      <div
        className={ classes.fixedTopContainerMobile }
        style={ modesContainerStyle() }
        suppressHydrationWarning
      >
        <div className={ classes.fixedTopContentMobile }>
          { renderAvatarSection() }
          <div className={ classes.optionsContainer }>
            { renderTopIconContainer() }
          </div>
        </div>
      </div>
      <div
        className={ classes.fixedBottomContainerMobile }
        style={ modesContainerStyle() }
        suppressHydrationWarning
      >
        { renderCtaSection() }
      </div>
    </>
  );

  return (
    <>
      {renderContentBrowser()}
      {renderContentMobile()}
    </>
  );
}

AdvisorStaticContainer.propTypes = {
  ...AdvisorPropType,
  modesContainerVisible: PropTypes.bool.isRequired
};

AdvisorStaticContainer.defaultProps = {
  ...AdvisorDefaultProps
};

export default AdvisorStaticContainer;
