import { connect } from 'react-redux';
import Starting from './starting';
import { createChat } from '../../../actions/chat';
import { createVoipChat } from '../../../actions/voip_chat';
import Const from '../../../config/const';

const mapStateToProps = ({ chat, voipChat }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return chat;
    case Const.chatType.voice:
    case Const.chatType.video:
      return voipChat;
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        createChat: () => dispatch(createChat())
      });
    case Const.chatType.video:
      return ({
        createChat: () => dispatch(createVoipChat('video', false))
      });
    case Const.chatType.voice:
      return ({
        createChat: () => dispatch(createVoipChat('voice_call', false))
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Starting);
