import { connect } from 'react-redux';
import GeneralPopup from './general_popup';
import { dissmissPopup } from '../../actions/general_popup';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateToProps = ({ generalPopup }) => generalPopup;

export default connect(
  mapStateToProps,
  { dissmissPopup }
)(withPGTranslation(GeneralPopup));
