import React from 'react';
import { getHomePage } from '../config/api';
import Const, { socialLinks, downloadLink } from '../config/const';
import { sectionIds as allSectionIds } from '../components/home_page/prop_types';
import { refreshReducersFromResponse } from '../config/api_functions';
import { getAppName } from '../../common/config/utils';
import { appRoutes } from '../config/app_routes';
import { isPrerenderMode } from '../config/util';

export const itemProp = {
  itemScope: 'itemscope',
  itemType: 'https://schema.org/WebSite',
  meta: {
    name: <meta key="name" itemProp="name" content={ getAppName() } />,
    url: <meta key="url" itemProp="url" content={ `${ window.location.href }` } suppressHydrationWarning />,
    sameAs1: <meta key="sameAs1" itemProp="sameAs" content={ downloadLink.appleItune } />,
    sameAs2: <meta key="sameAs2" itemProp="sameAs" content={ downloadLink.googlePlay } />,
    sameAs3: <meta key="sameAs3" itemProp="sameAs" content={ socialLinks.ig } />,
    sameAs4: <meta key="sameAs4" itemProp="sameAs" content={ socialLinks.fb } />,
    description: <meta key="description" itemProp="description" content="Live Psychic readings on love, romance, money, and more! Unlock life secrets by connecting with some of the highest rated advisors in the world!" />,
    link: <link key="link" itemProp="mainEntityOfPage" href={ window.location.href } suppressHydrationWarning />
  },
  image: {
    itemScope: 'itemscope',
    itemType: 'https://schema.org/ImageObject',
    imageItemProp: 'image',
    imageContentUrl: 'url',
    meta: {
      width: <meta key="width" itemProp="width" content="60" />,
      height: <meta key="height" itemProp="height" content="600" />
    }
  },
  search: {
    itemProp: 'potentialAction',
    itemScope: 'itemscope',
    itemType: 'https://schema.org/SearchAction',
    input: { itemProp: 'query-input', name: 'search_term_string', required: true },
    meta: {
      target: <meta key="target" itemProp="target" content={ `${ window.location.origin }${ appRoutes.psychics }?q={search_term_string}` } suppressHydrationWarning />
    }
  }
};

const homeLoading = () => ({
  type: Const.homePage.loading
});

const sectionsloadingHomeLoading = (sectionsIds) => ({
  type: Const.homePage.sectionsloading,
  sectionsIds
});

const homeError = (error) => ({
  type: Const.homePage.error,
  error
});

const homeLoaded = (sections, fullReload) => ({
  type: Const.homePage.loaded,
  fullReload,
  sections
});

const getUpdatedSections = (sections, param) => {
  const sectionIds = [];
  if (!sections) return sectionIds;
  sections.forEach((s) => {
    if ((s || {}).refresh) {
      sectionIds.push(s.id);
    }
    if (param && param.section_ids.includes(s.id)) {
      sectionIds.push(s.id);
    }
    if (s.id === 'sales_banner' && !!s.content) {
      sectionIds.push(s.id);
    }
  });
  return sectionIds;
};

const buildQueryString = (sections, param) => {
  const sectionIds = getUpdatedSections(sections, param);
  return sectionIds.length === 0 ? null : { section_ids:sectionIds.join() };
};

const getHomePageParams = (fullReload, homePage, param) => {
  if (fullReload) return null;
  return buildQueryString((homePage || {}).sections, param);
};

const homePageLoadingAction = (fullReload, homePage, param, dispatch) => {
  if (fullReload) {
    dispatch(homeLoading());
  } else {
    const sectionsIds = getUpdatedSections((homePage || {}).sections, param);
    dispatch(sectionsloadingHomeLoading(sectionsIds));
  }
};

export const loadedSpecialitiesAction = specialities => ({
  type: Const.homePage.specialities,
  payload: {
    specialities
  }
});

const loadedSalesBannerAction = salesBanner => ({
  type: Const.homePage.salesBanner,
  payload: {
    salesBanner
  }
});

const saveAdvisorsFromSections = (sections) => {
  const sectionsWithAdvisors = sections.filter((s) => s?.content?.advisors);
  let advisors = [];
  sectionsWithAdvisors.forEach((s) => {
    advisors = [...advisors, ...s.content.advisors];
  });
  refreshReducersFromResponse({ advisors });
};

const cleanAdvisorsArray = (advisors) => (
  Array.from(new Set(advisors.map(a => a.id))).map(id => (
    advisors.find(a => a.id === id)
  ))
);

export const homeLoadedActions = (sections, fullReload) => (dispatch) => {
  dispatch(homeLoaded(sections, fullReload));
  saveAdvisorsFromSections(sections);
};

const updateSalesBannerIfNeed = (sections, dispatch) => {
  const salesBanner = sections.find(
    (section) => section && section.id === allSectionIds.salesBanner
  );
  if (salesBanner) {
    dispatch(loadedSalesBannerAction(salesBanner));
  }
};

const saveAllAdvisorsListSection = (res, fullReload, param, dispatch, getState) => {
  if (param.section_ids && param.page) {
    let allAdvisorsListSection = res.sections.find((section) => section.id === allSectionIds.allAdvisorsList);
    if (!allAdvisorsListSection) return;
    let lastPageNum = param.page + 2;
    const newAdvisors = allAdvisorsListSection?.content?.advisors;
    if (newAdvisors.length < 16) lastPageNum = param.page;
    const { homePage } = getState();
    const prevAdvisors = homePage?.sections.find((section) => section.id === allSectionIds.allAdvisorsList)?.content?.advisors;
    const advisors = cleanAdvisorsArray([...prevAdvisors, ...newAdvisors]);
    const advisorsSliceCounter = (4 + (param.page - 1) * 16);
    allAdvisorsListSection = [{
      ...allAdvisorsListSection, content: { advisors: advisors.slice(0, advisorsSliceCounter) }, page: param.page, lastPageNum
    }];
    dispatch(homeLoadedActions(allAdvisorsListSection, fullReload));
  }
};

export const loadHomePage = (fullReload, param) => (dispatch, getState) => {
  if (isPrerenderMode()) return;
  const { homePage } = getState();
  const sectionsLoaded = ((homePage || {}).sections || {}).length > 0;
  const params = getHomePageParams(fullReload, homePage, param);
  if (sectionsLoaded && !params && !fullReload) return;
  homePageLoadingAction(fullReload, homePage, param, dispatch);
  getHomePage(param || params).then((res) => {
    if (param) {
      const { sections } = res;
      const specialities = sections.find((s) => s.id === allSectionIds.specialities)?.content?.specialities;
      if (specialities) {
        dispatch(loadedSpecialitiesAction(specialities));
      }
      updateSalesBannerIfNeed(sections, dispatch);
      saveAllAdvisorsListSection(res, fullReload, param, dispatch, getState);
    } else {
      const sections = res.sections.filter((section) => section !== null);
      dispatch(homeLoadedActions(sections, fullReload));
      updateSalesBannerIfNeed(sections, dispatch);
    }
  }).catch((e) => {
    dispatch(homeError(e));
  });
};
