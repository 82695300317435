import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import AdvisorCTA from './advisor_cta';
import {
  trackEventAddToCardAction,
  trackProfileChatButton
} from '../../../../actions/analytics';
import { makeCallOrShowPopup } from '../../../../actions/voip_chat';
import { startChat, chatInit } from '../../../../actions/chat';
import { authPendingAction, displayRegister } from '../../../../actions/auth';
import withPGTranslation from '../../../../config/withPGTranslation';
import { showGeneralPopup } from '../../../../actions/general_popup';
import { advisorCtaThemes } from './advisor_cta_themes';
import { getUserFirstActivePromotion } from  '../../../../config/util';

const mapStateToProps = (
  { advisors, user, application },
  { advisorId, theme }
) => ({
  ...((advisors || {})[parseInt(advisorId, 10)] || {}),
  theme: theme || advisorCtaThemes.default,
  userXfmProgram: user && user.user && user.user.xfmProgram,
  userAdvisorModeSalePrice: getUserFirstActivePromotion({ user, application })?.advisorModeSalePrice
});

export default connect(
  mapStateToProps,
  {
    startChat,
    authPendingAction,
    trackEventAddToCardAction,
    displayRegister,
    trackProfileChatButton,
    showGeneralPopup,
    makeCallOrShowPopup,
    chatInit
  }
)(withLastLocation(withPGTranslation(AdvisorCTA)));
