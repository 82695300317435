import React from 'react';
import PropTypes from 'prop-types';
import { Footer as FooterOrganism } from '@barges/react';
import { useTranslation } from 'react-i18next';
import classes from './classes.module.scss';
import {
  appLinks, socialLinks
} from '../../../config/const';
import { fromMobileApp, isKA } from '../../../../common/config/utils';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/footer.css';
import { appRoutes } from '../../../config/app_routes';
import { getCurrentLocalization } from '../../../config/i18n';

const fbIcon = require('./img/fb_footer.svg');
const igIcon = require('./img/ig_footer.svg');
const tickTokIcon = require('./img/tiktok_footer.svg');
const twitterIcon = require('./img/twitter_footer.svg');
const ytIcon = require('./img/yt_footer.svg');
const kaFooterLogo = require('./img/ka_footer_logo.svg');
const pgFooterLogo = require('./img/pg_footer_logo.svg');

const socialLinksArray = [{
  href: socialLinks.x,
  key: 1,
  icon: twitterIcon
}, {
  href: socialLinks.fb,
  key: 2,
  icon: fbIcon
}, {
  href: socialLinks.ig,
  key: 3,
  icon: igIcon
}, {
  href: socialLinks.tt,
  key: 4,
  icon: tickTokIcon
}, {
  href: socialLinks.yt,
  key: 5,
  icon: ytIcon
}];

const getInc = () => {
  if (isKA()) return 'Kasamba Inc.';
  return 'Barges Technologies, Inc.';
};

const onIconTap = () => {
  window.scrollTo({ left: 0, top:0, behavior: 'smooth' });
};

const companyInfo = `${ new Date().getFullYear() } `;

const language = getCurrentLocalization();

const logoSrc = () => <img alt="logo" src={ isKA() ? kaFooterLogo : pgFooterLogo } />;

const Footer = ({ specialities }) => {
  const { t } = useTranslation();

  if (fromMobileApp) return null;

  return (
    <div className={ classes.footerHolder }>
      <FooterOrganism
        companyInfo={ `© ${  companyInfo  }${ getInc() }` }
        logo={ logoSrc()  }
        termsLinks={ [
          { name: t('privacy_policy'), path: appLinks.privacyPolicy },
          { name: t('terms_of_use'), path: appLinks.tos },
          { name: t('affiliation'), path: appLinks.affiliation }
        ] }
        logoAction={ onIconTap }
        socialLinks={ socialLinksArray  }
        links={ [
          {
            title: t('footerLinks.explore'),
            children:  isKA() ? [
              { name: t('footerLinks.advisors'), path: appRoutes.psychics },
              { name: t('footerLinks.specialities'), path: appLinks.categories },
              { name: t('footerLinks.horoscope'), path: language === 'en' ? appLinks.myHoroscope : `${ appLinks.myHoroscope }/${ language }` },
              { name: t('footerLinks.articles'), path: language === 'en' ? appLinks.articles : `${ appLinks.articles }/${ language }` }
            ] : [
              { name: t('footerLinks.advisors'), path: appRoutes.psychics },
              { name: t('footerLinks.our_blog'), path: language === 'en' ? appLinks.blog : `${ appLinks.blog }/${ language }` }
            ]
          },
          {
            title: t('footerLinks.specialities'),
            children: specialities?.map(({ name, slug }) => ({ name, path: slug })) || []
          },
          {
            title: t('footerLinks.more'),
            children: [
              { name: t('footerLinks.apply_promo_code'), path: appRoutes.promoCode },
              { name: t('footerLinks.customer_support'), path: appLinks.customerSupport },
              { name: t('footerLinks.psychic_apply_here'), path: appLinks.forAdvisors }
            ]
          }
        ] }
      />
    </div>
  );
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  specialities: PropTypes.array
};
Footer.defaultProps = {
  specialities: []
};

export default Footer;
