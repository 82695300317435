import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({
  loading: false,
  page: 0,
  complete: [],
  advisor: null,
  activityItemsList: [],
  paginationParams: null,
  activitiesLoading: false,
  errorStatus: false
});

const updateComplete = (complete, orderDetails) => {
  const { order } = orderDetails;
  if (complete.length === 0) return complete;
  const updatedOrder = complete.find((item) => item.id === order.id);
  if (!updatedOrder) return complete;
  const newCompleteArray = complete;
  newCompleteArray[complete.indexOf(updatedOrder)] = order;
  return newCompleteArray;
};

const advisorOrdersReducer = createReducer(getDefaultState(), {
  [Const.advisorOrders.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.advisorOrders.activitiesLoading]: (state) => ({
    ...state,
    activitiesLoading: true
  }),
  [Const.advisorOrders.reset] : () => (
    getDefaultState()
  ),
  [Const.advisorOrders.resetActivities] : (state) => ({
    ...state,
    activityItemsList: [],
    paginationParams: null,
    activitiesLoading: false,
    errorStatus: false
  }
  ),
  [Const.advisorOrders.setErrorStatus] : (state, { errorStatus }) => ({
    ...state,
    errorStatus
  }),
  [Const.advisorOrders.activitiesLoaded]: (state, { payload }) => {
    const { activityItems, paginationParams } = payload;
    return ({
      ...state,
      ...payload,
      paginationParams: paginationParams || null,
      activityItemsList: [...state.activityItemsList, ...activityItems],
      loading: false,
      activitiesLoading: false
    });
  },
  [Const.advisorOrders.activitiesResetLoaded]: (state, { payload }) => {
    const { activityItems, paginationParams } = payload;
    return ({
      ...state,
      ...payload,
      paginationParams: paginationParams || null,
      activityItemsList: [...activityItems],
      loading: false,
      activitiesLoading: false
    });
  },
  [Const.advisorOrders.activityLoaded]: (state, { payload }) => {
    const { activityItems } = payload;
    return ({
      ...state,
      ...payload,
      advisor: {
        ...payload.advisor,
        slug: state.advisor.slug,
        rating: state.advisor.rating
      },
      activityItemsList: [...activityItems, ...state.activityItemsList],
      loading: false,
      activitiesLoading: false
    });
  },
  [Const.advisorOrders.advisorOrdersDelete]: (state, { orderId }) => ({
    ...state,
    complete: [...state.complete.filter(order => order.id !== orderId)]
  }),
  [Const.logOut.clear]: () => ({
    ...getDefaultState()
  }),
  [Const.orderDetails.sendedOrderReview]: (state, { orderDetails }) => ({
    ...state,
    complete: updateComplete(state.complete, orderDetails)
  })
});

export default advisorOrdersReducer;
