import { connect } from 'react-redux';
import ContinueCallFooter from './continue';
import { selectCredit, hangupChat, trackDurationPickExit } from '../../../../actions/chat';
import { continueVoipChat, hangupVoipChat, trackVoipDurationPickExit } from '../../../../actions/voip_chat';
import { clearAffiliatePromoPopup } from '../../../../actions/affiliate_promo_popup';
import Const from '../../../../config/const';
import withPGTranslation from '../../../../config/withPGTranslation';

const mapStateToProps = ({ chat: { hangingUp, state }, voipChat: { voipState } }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({
        hangingUp: hangingUp || state === Const.chatStates.ended,
        disabledStartChatButton: state !== Const.chatStates.paused
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        hangingUp: voipState === Const.voipChatStates.endedConversation,
        disabledStartChatButton: voipState !== Const.voipChatStates.paused
      });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        selectCredit: (option, time, defaultDurationChanged) => dispatch(selectCredit(option, time, defaultDurationChanged, true)),
        hangupChat: () => dispatch(hangupChat()),
        clearAffiliatePromoPopup: () => dispatch(clearAffiliatePromoPopup()),
        trackDurationPickExit: (option, time, defaultDurationChanged) => dispatch(
          trackDurationPickExit(option, time, defaultDurationChanged)
        )
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        selectCredit: (duration, time, defaultDurationChanged) => dispatch(continueVoipChat(duration, time, defaultDurationChanged, ownProps.option.selectedCarouselIndex)),
        hangupChat: () => dispatch(hangupVoipChat()),
        clearAffiliatePromoPopup: () => dispatch(clearAffiliatePromoPopup()),
        trackDurationPickExit: (duration, time, defaultDurationChanged) => dispatch(
          trackVoipDurationPickExit(duration, time, defaultDurationChanged)
        )
      });
    default:
      return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPGTranslation(ContinueCallFooter));
