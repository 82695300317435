import { connect } from 'react-redux';
import AdvisorPreview from './advisor_preview';
import withPGTranslation from '../../../../config/withPGTranslation';
import { showGeneralPopup } from '../../../../actions/general_popup';
import { authPendingAction, displayRegister } from '../../../../actions/auth';
import { displayNotifyMe } from '../../../../actions/notify_me';
import { updateNavigationStack } from '../../../../actions/application';
import { trackBEvent } from '../../../../actions/b_events';

export const AdvisorPreviewThemes = {
  staff_pick: 'staff_pick',
  personal: 'personal',
  badge: 'badge',
  badge_end_to_end: 'badge_end_to_end',
  searchResult: 'searchResult',
  someOtherAdvisor: 'someOtherAdvisor'
};

const mapStateToProps = ({
  user: { user }
}) => (
  {
    userXfmProgram: user && user.xfmProgram
  }
);

export default connect(
  mapStateToProps,
  {
    updateNavigationStack,
    trackBEvent,
    showGeneralPopup,
    authPendingAction,
    displayRegister,
    displayNotifyMe
  }
)(withPGTranslation(AdvisorPreview));
