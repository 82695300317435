import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AdvisorsList, { advisorsListThemes } from '../../advisors_list';
import classes from './classes.module.scss';
import AppLink from '../../routes/app_link';

function SomeOtherAdvisors({
  t, theme, title, subTitle, loading, clickSource, loadAdvisors, advisors, pathname
}) {
  const startChatClickSource = (window.location.state && window.location.state.startChatClickSource) ? window.location.state.startChatClickSource : 'direct';

  useEffect(() => {
    loadAdvisors({ page: 1 }, pathname);
  }, []);

  const generateData = () => {
    const advisorsArray = advisors.slice(0, 4);
    return advisorsArray;
  };

  const generateAnalytics = () => {
    const analyticsData = {
      clickSource,
      startChatClickSource
    };
    return analyticsData;
  };

  const renderTitle = () => (
    <div className={ classes.titleContainer }>
      <div className={ classes.title }>
        { title }
      </div>
      <div className={ classes.subTitle }>
        { subTitle }
      </div>
    </div>
  );

  const renderHomeButton = () => (
    <div className={ classes.homeButtonContainer }>
      <AppLink
        to="/"
        className={ classes.homeButton }
        replace
      >
        {  t('terminatedAdvisor.back_to_homepage')}
      </AppLink>
    </div>
  );

  const renderSomeOtherAdvisors = () => (
    <div className={ classes[theme] }>
      { renderTitle() }
      <AdvisorsList
        advisors={ generateData() }
        theme={ advisorsListThemes.someOtherAdvisor }
        loading={ loading }
        analytics={ generateAnalytics() }
      />
      { renderHomeButton() }
    </div>
  );

  return renderSomeOtherAdvisors();
}

SomeOtherAdvisors.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  loadAdvisors: PropTypes.func.isRequired,
  advisors: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired
};

SomeOtherAdvisors.defaultProps = {
  advisors: []
};

export default SomeOtherAdvisors;
