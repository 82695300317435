import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  notification: null
};

const defaultHandler = {
  [Const.notification.received] : (state, { notification }) => ({
    ...state,
    notification
  }),
  [Const.notification.clear] : (state) => ({
    ...state,
    notification: null
  })
};

export default createReducer(defaultState, defaultHandler);
