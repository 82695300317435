import Const from '../config/const';

export const userSetImpersonate = (impersonated) => ({
  type: Const.user.impersonated,
  impersonated
});

export const setImpersonated = (impersonated) => dispatch => {
  const { user } = window.store.getState();
  if (user && user.impersonated !== impersonated) {
    dispatch(userSetImpersonate(impersonated));
  }
};
