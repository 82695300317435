/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from '../classes.module.scss';
import { appGeneralPopupTheme } from '../../../../common/config/const';
import AppGeneralPopup from '../../../../common/app_general_popup';
import { trackPageVisit } from '../../../actions/analytics';
import { isKA } from '../../../../common/config/utils';

const signOutKA = require('../img/kasamba_sign_out.svg');
const signOutPG = require('../img/pg_sign_out.svg');
const popoverSignOut = require('../img/popover_logout.svg');

export const MODAL = 'modal';
export const SETTINGS = 'settings';
export default class SignOut extends PureComponent {
  static propTypes = {
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    logOutAction: PropTypes.func.isRequired,
    logOutClear: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    theme: PropTypes.oneOf([MODAL, SETTINGS])
  };

  static defaultProps = {
    theme: SETTINGS
  };

  state = {
    showSignOutModal: false
  };

  componentDidUpdate(prevProps) {
    const { success, logOutClear } = this.props;
    if (!prevProps.success && success) {
      logOutClear();
    }
  }

  signOutClick = () => {
    this.setState({ showSignOutModal: true });
    trackPageVisit('sign out? popup');
  };

  signOutCancel = () => {
    this.setState({ showSignOutModal: false });
  };

  signOutOk = () => {
    this.setState({ showSignOutModal: false });
    const { logOutAction } = this.props;
    logOutAction();
  };

  renderSingOutContent = () => {
    const { t } = this.props;
    return (
      <div className={ classes.signOutContentContainer }>
        <div className={ classes.signOutHeader }>
          <b>{ t('app_name_title') }</b>
        </div>
        <div className={ classes.signOutBody }>
          { t('settings.are_you_sure_you_want_sign_out') }
        </div>
        <div className={ classes.footer }>
          <div className={ classes.signOutYesBtnContainer }>
            <button type="button" onClick={ this.signOutCancel } className={ classes.signOutCancelButton }>{ t('settings.cancel') }</button>
          </div>
          <div className={ classes.signOutCancelBtnContainer }>
            <button type="button" onClick={ this.signOutOk } className={ classes.signOutYesButton }>{ t('settings.yes') }</button>
          </div>
        </div>
      </div>
    );
  };

  renderBoldText = (text) => (
    <div>
      <b>{ text }</b>
    </div>
  );

  renderImgSrc = (isModal) => {
    if (isModal) {
      return popoverSignOut;
    }
    return isKA() ? signOutKA : signOutPG;
  };

  render() {
    const { showSignOutModal } = this.state;
    const { t, theme } = this.props;
    const isModal = theme === MODAL;
    return (
      <div className={ classes.signOutContainer }>
        <button
          type="button"
          className = { isModal ? classes.signOutButtonModal : classes.signOutButton }
          onClick = { this.signOutClick }
        >
          <img
            src={ this.renderImgSrc(isModal) }
            className = { classes.signOutImage }
            alt=""
          />
          <div className={ isModal ? classes.modalSignOutText : '' }>{ t('settings.sign_out') }</div>
        </button>
        <AppGeneralPopup
          visible={ showSignOutModal }
          closable={ this.signOutCancel }
          theme={ appGeneralPopupTheme.CENTERED }
        >
          { this.renderSingOutContent()}
        </AppGeneralPopup>
      </div>
    );
  }
}
