import React from 'react';
import classes from './classes.module.scss';

function WaitingComponent() {
  return (
    <div className={ classes.waitingContainer }>
      <div>Waiting...</div>
    </div>
  );
}

export default WaitingComponent;
