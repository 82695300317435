import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';

function FilePicker({ onFilePicked, children }) {
  const inputFile = useRef(null);

  const onAvatartClicked = () => {
    inputFile.current.click();
  };

  const inputOnPick = (e) => {
    if (e.target.files.length === 0) return;
    onFilePicked({ file: e.target.files[0] });
    e.target.value = null;
  };

  return (
    <button type="button" onClick={ onAvatartClicked } className={ classes.button }>
      {children}
      <input
        ref={ inputFile }
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        style={ { display: 'none' } }
        onChange={ inputOnPick }
        suppressHydrationWarning
      />
    </button>
  );
}

FilePicker.propTypes = {
  onFilePicked: PropTypes.func,
  children: PropTypes.node.isRequired
};

FilePicker.defaultProps = {
  onFilePicked: null
};

export default FilePicker;
