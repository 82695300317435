import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import ReviewsSection from './reviews_section';
import {
  loadAdvisorReviews, advisorsReviewsReset
} from '../../../../actions/advisors';
import withPGTranslation from '../../../../config/withPGTranslation';

const mapStateToProps = (
  { advisors },
  { id }
) => ({
  ...(advisors || {})[id] || {}
});

export default connect(
  mapStateToProps,
  {
    loadAdvisorReviews,
    advisorsReviewsReset
  }
)(withLastLocation(withPGTranslation(ReviewsSection)));
