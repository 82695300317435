import { connect } from 'react-redux';
import IntroComponent from './intro';
import { updateUserBaseInfo, clearErrorAction } from '../../actions/introduce_yourself';
import withPGTranslation from '../../config/withPGTranslation';

const mapStateToProps = ({ intro }) => ({
  ...intro
});

export default connect(
  mapStateToProps,
  {
    updateUserBaseInfo,
    clearErrorAction
  }
)(withPGTranslation(IntroComponent));
