import { connect } from 'react-redux';
import NotifyMe from './notify_me';
import withPGTransaltion from '../../config/withPGTranslation';
import { notifyMeAction, clearLocalState, displayNotifyMe } from '../../actions/notify_me';

const mapStateToProps = (
  { advisors, notifyMe }
) => {
  const advisor = (advisors || {})[parseInt(notifyMe.advisorId, 10)] || {};
  const { availabilityNotification } = advisor;

  return {
    // If the state doesn't contain the loading property then it means the advisor should
    // be being downloaded, so the loading property should be set to true, if the state
    // conatins it then we should pass it as the loading prop
    loading: advisors.loading,
    ...notifyMe,
    availabilityNotification
  };
};

export default connect(
  mapStateToProps,
  {
    notifyMeAction,
    clearLocalState,
    displayNotifyMe
  }
)(withPGTransaltion(NotifyMe));
