/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from './use_intersection';
import { cleanupAdvisorFieldsForAnalytics } from '../../../../config/util';

export const withAnalytics = (WrappedComponent) => function ({
  advisor,
  theme,
  sectionSlug,
  analytics,
  trackBEvent,
  ...rest
}) {
  const ref = useRef(null);
  const [renderedAt] = useState((new Date()).getTime());
  const [visibleSince, setVisibleSince] = useState(null);

  const onAnalyticsClick = () => {
    if (advisor && advisor.id) {
      const opts = {
        ...cleanupAdvisorFieldsForAnalytics(advisor),
        ...analytics,
        theme,
        sectionSlug,
        pathname: window.location.pathname,
        timeSinceFirstRender: (new Date()).getTime() - renderedAt
      };
      if (visibleSince) {
        opts.timeVisible = (new Date()).getTime() - visibleSince;
      }
      trackBEvent('advisor box - click', opts);
    }
  };

  const isVisible = useIntersection(ref, '-10px');

  useEffect(() => {
    if (advisor && advisor.id) {
      let sendEvent = false;
      const wasVisibleSince = visibleSince;
      if (isVisible && !visibleSince) {
        setVisibleSince((new Date()).getTime());
        sendEvent = true;
      } else if (!isVisible && visibleSince) {
        setVisibleSince(null);
        sendEvent = true;
      }
      const action = isVisible ? 'appears' : 'hides';
      if (!sendEvent) { return; }
      const opts = {
        ...cleanupAdvisorFieldsForAnalytics(advisor),
        ...analytics,
        theme,
        sectionSlug,
        pathname: window.location.pathname,
        timeSinceFirstRender: (new Date()).getTime() - renderedAt
      };
      if (action === 'hides' && wasVisibleSince) {
        opts.timeVisible = (new Date()).getTime() - wasVisibleSince;
      }
      trackBEvent(`advisor box - ${ action }`, opts);
    }
  }, [isVisible]);

  const props = {
    advisor,
    theme,
    sectionSlug,
    analytics,
    onAnalyticsClick,
    ...rest
  };

  return (
    <WrappedComponent { ...props } innerRef={ ref } />
  );
};
