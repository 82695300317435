import { connect } from 'react-redux';
import HomePage from './home_page';
import { loadHomePage } from '../../actions/home_page';
import withPGTranslation from '../../config/withPGTranslation';
import { parseAdvisorsFromAdvisorsState, getHomePageDataIfSsrMode } from '../../config/util';

const updateSectionsContent = (sections) => {
  const newSections =  sections?.map((s) => {
    if (s?.content?.advisors === undefined) return s;
    return ({
      ...s,
      content: {
        ...s.content,
        advisors: parseAdvisorsFromAdvisorsState(s?.content?.advisors)
      }
    });
  });
  return newSections;
};

const mapStateToProps = ({
  homePage
}) => ({
  ...homePage,
  sections: updateSectionsContent(homePage.sections),
  loading: homePage?.loading && !getHomePageDataIfSsrMode()
});

export default connect(
  mapStateToProps,
  {
    loadHomePage
  }
)(withPGTranslation(HomePage));
