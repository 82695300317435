import createReducer from '../config/create_reducer';
import Const from '../config/const';

const getDefaultState = () => ({});

const advisorUpdated = (state, { payload: { advisor } }) => ({
  ...state,
  [advisor.id]: {
    ...(state[advisor.id] || {}),
    ...advisor
  }
});

const advisorsListUpdated = (state, advisors) => {
  let newState = { ...state };
  advisors.forEach((a) => {
    newState = {
      ...newState,
      knownPaths:{
        ...newState.knownPaths,
        [a.slug]: a.id
      },
      ...{
        [a.id]:{
          ...state[a.id],
          ...a
        }
      }
    };
  });
  return newState;
};

const advisorsReducer = createReducer(getDefaultState(), {
  [Const.advisors.loading]: (state) => ({
    ...state,
    loading: true
  }),
  [Const.advisors.loaded]: (state, { advisor }) => ({
    ...state,
    loading: false,
    knownPaths: {
      ...state.knownPaths,
      [advisor.slug]: advisor.id
    },
    [advisor.id]: {
      ...state[advisor.id] || {},
      ...advisor,
      reviews: state[advisor.id]?.reviews || {}
    }
  }),
  [Const.advisors.listLoaded]: (state, { advisors }) => advisorsListUpdated(state, advisors),
  [Const.advisors.reviewsLoading]: (state, { id }) => ({
    ...state,
    [id]: {
      ...state[id] || {},
      reviews: {
        ...(state[id] || {}).reviews,
        loading: true
      }
    }
  }),
  [Const.advisors.reviewsLoaded]: (state, {
    reviews, id, initial
  }) => ({
    ...state,
    [id]: {
      ...state[id] || {},
      reviews: {
        ...state[id].reviews,
        ...reviews,
        items: initial ? reviews.items : [...state[id].reviews.items || [], ...reviews.items],
        showMore: reviews.paginationParams || reviews.showMore,
        loading: false
      }
    }
  }),
  [Const.advisors.reviewsReset]: (state, { id }) => ({
    ...state,
    [id]: {
      ...state[id] || {},
      reviews: {
        items: [],
        showMore: null,
        paginationParams: null
      }
    }
  }),
  [Const.favoriteAdvisors.markUnFavorite]: (state, { payload: { id } }) => ({
    ...state,
    [id]: {
      ...state[id] || {},
      favorite: false
    }
  }),
  [Const.favoriteAdvisors.markFavorite]: advisorUpdated,
  [Const.notifyMe.success]: advisorUpdated
});

export default advisorsReducer;
