import { connect } from 'react-redux';
import MessageToApp from './message_to_app';
import { setMessageToAppData } from '../../actions/message_to_app';

const mapStateToProps = ({ messageToApp }) => messageToApp;

export default connect(
  mapStateToProps,
  {
    setMessageToAppData
  }
)(MessageToApp);
