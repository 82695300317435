import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import classes from './classes.module.scss';

const renderBadge = (count, overflowCount, visible, customClass) => (count > 0 && visible ? (
  <div className={ classnames(classes.badgeContainer, customClass) } />

) : null);

const renderBadgeAroundAComponent = (count, overflowCount, children, visible, customClass) => (
  <div className={ classes.container }>
    <div>{children}</div>
    {renderBadge(
      count,
      overflowCount,
      visible,
      classnames(classes.badgeContainerAsChild, customClass)
    )}
  </div>
);

function Badge({
  count, overflowCount, children, visible, customClass
}) {
  return children
    ? renderBadgeAroundAComponent(count, overflowCount, children, visible, customClass)
    : renderBadge(count, overflowCount, visible, customClass);
}

Badge.propTypes = {
  count: PropTypes.number.isRequired,
  overflowCount: PropTypes.number,
  children: PropTypes.node,
  visible: PropTypes.bool,
  customClass: PropTypes.string
};

Badge.defaultProps = {
  overflowCount: 99,
  visible: true,
  children: null,
  customClass: null
};

export default Badge;
