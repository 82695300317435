import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { emailIsValid } from '../../../common/config/utils';
import Input, {
  LOGIN_EMAIL
} from '../registration/inner_components/input';
import classes from './classes.module.scss';
import AppGeneralPopup from '../../../common/app_general_popup';
import { trackPageVisit } from '../../actions/analytics';

class ForgotPassword extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
    forgot: PropTypes.func.isRequired,
    passwordForgotClear: PropTypes.func.isRequired,
    clearError: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    email: '',
    showForgotModal: false
  };

  componentDidMount() {
    trackPageVisit('forgot password');
  }

  componentDidUpdate() {
    const { success, error } = this.props;
    if (success || error) {
      this.showForgotPasswordModal();
    }
  }

  onFieldChanged = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onForgotPasswordClicked = () => {
    const { forgot } = this.props;
    const { email } = this.state;
    forgot(email);
  };

  showForgotPasswordModal = () => {
    this.setState({ showForgotModal: true });
  };

  clearForgotPassword = () => {
    const { passwordForgotClear } = this.props;
    this.setState({ showForgotModal: false });
    passwordForgotClear();
  };

  handleKeyPress = (e) => {
    const { email } = this.state;
    if (e.key === 'Enter' && emailIsValid(email)) {
      this.onForgotPasswordClicked();
    }
  };

  renderForgotModalTitle = () => {
    const { t } = this.props;
    return <div className={ classes.forgotModalTitle }>{ t('app_name_title') }</div>;
  };

  renderForgotModalContent() {
    const { t } = this.props;
    return (
      <div className={ classes.forgotModalContentContainer }>
        <div>
          {t('forgot_password_modal_text')}
        </div>
        <div className={ classes.modalContentOkBtnContainer }>
          <button className={ classes.modalContentOkBtn } type="button" value="Promo Code" onClick={ this.clearForgotPassword }>
            { t('general.ok') }
          </button>
        </div>
      </div>
    );
  }

  renderForgotPassword = () => {
    const { email, showForgotModal } = this.state;
    const { loading, t } = this.props;
    const allFieldsValid = emailIsValid(email);
    return (
      <div>
        <div className={ classes.text }>
          <b>
            {t('reset_your_password')}
            <br />
          </b>
        </div>
        <div className={ classes.text }>
          {t('submit_your_email_address')}
        </div>
        <div>
          <Input
            inputType={ LOGIN_EMAIL }
            onChange={ this.onFieldChanged }
            value={ email }
            id="email"
            disabled={ loading }
            onKeyDown={ this.handleKeyPress }
          />
        </div>
        <div>
          <button
            className={ classNames(
              classes.actionButton,
              allFieldsValid && !loading ? null : classes.disableActionBtn
            ) }
            type="button"
            onClick={ this.onForgotPasswordClicked }
            disabled={ !allFieldsValid }
          >
            <b>{t('submit')}</b>
          </button>
        </div>
        <AppGeneralPopup
          visible = { showForgotModal }
          customTopHeader={ this.renderForgotModalTitle() }
        >
          { this.renderForgotModalContent()}
        </AppGeneralPopup>
      </div>
    );
  };

  render() {
    return (
      <div className={ classes.container }>
        { this.renderForgotPassword() }
      </div>
    );
  }
}

export default ForgotPassword;
