import { connect } from 'react-redux';
import CantCreate from './cant_create';
import Const from '../../../config/const';
import { cancelChat } from '../../../actions/chat';
import { cancelVoipChat } from '../../../actions/voip_chat';
import { loadAdvisor } from '../../../actions/advisors';

const mapStateToProps = ({ chat, voipChat }, { chatType }) => {
  switch (chatType) {
    case Const.chatType.text:
      return ({ ...chat });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({ ...voipChat });
    default:
      return null;
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  switch (ownProps.chatType) {
    case Const.chatType.text:
      return ({
        cancelChat: () => dispatch(cancelChat()),
        loadAdvisor: (advisorId) => dispatch(loadAdvisor(advisorId))
      });
    case Const.chatType.voice:
    case Const.chatType.video:
      return ({
        cancelChat: () => dispatch(cancelVoipChat()),
        loadAdvisor: (advisorId) => dispatch(loadAdvisor(advisorId))
      });
    default:
      return null;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CantCreate);
