import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import classes from './classes.module.scss';
import {
  generateDate, validateDate, addDividerToDateString, validateDateString, getDatePickerPlaceholder,
  cleanupDateValue
} from './date_picker_utils';

function DatePicker({
  className, t, setError, clearError, setDate, datePickerInputStyle, datePickerHolderStyle,
  setedDate
}) {
  const [datePickerValue, setDatePickerValue] = useState('');
  const divider = '/';

  useEffect(() => {
    if (setedDate) {
      const formatedDateString = format(new Date(setedDate), 'MM-dd-yyyy');
      const formatedDateStringSplit = formatedDateString.split('-');
      const month = formatedDateStringSplit[0];
      const day = formatedDateStringSplit[1];
      const year = formatedDateStringSplit[2];
      const dateArray = [month, day, year];
      const dateArrayJoined = dateArray.join(divider);
      setDatePickerValue(addDividerToDateString({ dataString: dateArrayJoined, divider }));
    }
  }, []);

  const onKeyDownDatePicker = (e) => {
    if (e.key === '/') {
      e.preventDefault();
    }
  };

  const onChangeDatePicker  = (e) => {
    const updatedValue = cleanupDateValue(e.target.value);
    let dataStringWithDivider = addDividerToDateString({ dataString: updatedValue, divider });
    dataStringWithDivider = validateDateString({ dateString: dataStringWithDivider, divider });
    setDatePickerValue(dataStringWithDivider);
  };

  const onClickDatePicker = () => {
    clearError();
  };

  const onBlurDatePicker = () => {
    const date = generateDate({ datePickerValue });
    const error = validateDate({ t, date });
    if (error) setError(error);
    setDate(date);
  };

  const renderDatePicker = () => (
    <div
      className={ classNames(className, classes.datePickerHolder) }
      data-placeholder={ `${ getDatePickerPlaceholder({ datePickerValue, divider }) }` }
      style={ datePickerHolderStyle }
    >
      <input
        inputMode="numeric"
        value={ datePickerValue }
        className={ classes.datePickerInput }
        onChange={ onChangeDatePicker }
        maxLength={ 10 }
        onBlur={ onBlurDatePicker }
        onClick={ onClickDatePicker }
        style={ datePickerInputStyle }
        onKeyDown={ onKeyDownDatePicker }
      />
    </div>
  );

  return renderDatePicker();
}

DatePicker.defaultProps = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  datePickerInputStyle: PropTypes.object,
  datePickerHolderStyle: PropTypes.object,
  date: PropTypes.string
};

DatePicker.defaultProps = {
  className: null,
  style: null,
  datePickerHolderStyle: null,
  date: null
};

export default DatePicker;
