import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AdvisorPreview from '../advisor_preview';
import { SectionProps, SectionDefaultProps, sectionSubTypes } from '../../prop_types';
import { advisorsEmptyList, isPrerenderMode } from '../../../../config/util';
import classes from './classes.module.scss';
import AppLink from '../../../routes/app_link';
import AdvisorSectionTitle from './advisor_section_title';

const leftArrow = require('../../img/left_arrow.svg');
const rightArrow = require('../../img/right_arrow.svg');

const scrollElementTypes = {
  left: 'Left',
  right: 'Right'
};

const getJustifyContentStyle = ({ subtype, scrollElementsContentWidth, windowInnerWidth }) => {
  if (scrollElementsContentWidth <= windowInnerWidth && subtype === sectionSubTypes.endToEnd) return { justifyContent: 'center' };
  return { justifyContent: 'unset' };
};

const getPersonalContentStyle = (personalSection) => {
  if (personalSection) return { '--displayRulePersonalBrowser': 'flex', '--displayRulePersonalMobile': 'none' };
  return { '--displayRulePersonalBrowser': 'none', '--displayRulePersonalMobile': 'flex' };
};

const cantBePresented = (content) => (content || {}).advisors;

const calcScrollElementContantWidth = ({ id, setScrollElementsContentWidth }) => {
  const contentContainer = document.getElementById(`${ id }advisorsContainerScroller`);
  if (!contentContainer) return;
  const contentContainerChildrenArray = Array.from(contentContainer.children);
  let contentWidth = 0;
  contentContainerChildrenArray.forEach((el) => {
    const style = window.getComputedStyle(el);
    contentWidth += parseFloat(style.marginLeft) + parseFloat(style.width);
  });
  if (setScrollElementsContentWidth) setScrollElementsContentWidth(contentWidth);
  return { contentWidth, contentContainer }; //eslint-disable-line
};

const needShowScrollElement = ({
  id, setShowLeftScrollElement, setShowRightScrollElement
}) => {
  const data = calcScrollElementContantWidth({ id });
  if (!data) return;
  const { contentWidth } = data;
  const mainScrollContainer = document.getElementById(`${ id }advisorsContainerfalse`);
  if (mainScrollContainer) {
    if (contentWidth <= mainScrollContainer.offsetWidth) {
      setShowLeftScrollElement(false);
      setShowRightScrollElement(false);
      return;
    }

    if (mainScrollContainer.scrollLeft === 0 && contentWidth > mainScrollContainer.offsetWidth) {
      setShowLeftScrollElement(false);
      setShowRightScrollElement(true);
      return;
    }

    if (mainScrollContainer.scrollLeft > 0 && contentWidth > mainScrollContainer.offsetWidth) {
      setShowLeftScrollElement(true);
      setShowRightScrollElement(true);
    }

    if (mainScrollContainer.scrollLeft > 0 && contentWidth > mainScrollContainer.offsetWidth
      && mainScrollContainer.scrollLeft + mainScrollContainer.offsetWidth
      >= mainScrollContainer.scrollWidth) {
      setShowLeftScrollElement(true);
      setShowRightScrollElement(false);
    }
  }
};

const onScrollBtnClickAction = ({ e, id }) => {
  const scrollContainer = document.getElementById(`${ id }advisorsContainerfalse`);
  if (e.currentTarget.id === scrollElementTypes.left) {
    scrollContainer.scrollTo({ left: scrollContainer.scrollLeft - scrollContainer.clientWidth, behavior: 'smooth' });
    return;
  }
  scrollContainer.scrollTo({ left: scrollContainer.scrollLeft + scrollContainer.offsetWidth, behavior: 'smooth' });
};

const renderSeeAllItmeMobile = ({
  showMore, slug, theme, windowInnerWidth, params
}) => {
  if (!showMore || !slug) return null;
  const seeAllBtnText = showMore.title ? (
    <div className={ classes.seeAllBtnTextContainer }>
      { showMore.subtitle }
      {` (${ showMore.title })`}
    </div>
  ) : (
    showMore.subtitle
  );
  const seeAll = (
    <AppLink
      to={ {
        pathname: slug,
        state: {
          needRefresh: true,
          params
        }
      } }
      className={ classes.seeAllItmeMobile }
    >
      { seeAllBtnText }
    </AppLink>
  );
  if (windowInnerWidth >= window.shared.phoneOnlyUpSize) return null;
  return (
    <AdvisorPreview
      key="seeAllItmeMobileItem"
      theme={ theme }
      content={ seeAll }
      style={ { height: '100%' } }
      className={ classes.seeAllItmeMobileContainer }
    />
  );
};

function AdvisorSection({
  iconUrl, title, description, content, shortDescription,
  theme, id, personalSection, loading, analytics, slug, showMore, subtype, sectionLoading, params
}) {
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [scrollElementsContentWidth, setScrollElementsContentWidth] = useState();
  const [showLeftScrollElement, setShowLeftScrollElement] = useState(true);
  const [showRightScrollElement, setShowRightScrollElement] = useState(true);
  const advisorsContainerRef = useRef(null);
  const advisorsContainerScrollerRef = useRef();
  const onScrollBtnClick = (e) => onScrollBtnClickAction({
    e, id, setShowLeftScrollElement, setShowRightScrollElement
  });

  const handleResize = () => {
    setWindowInnerWidth(window.innerWidth);
    needShowScrollElement({ id, setShowLeftScrollElement, setShowRightScrollElement });
  };

  const handleScroll = () => {
    needShowScrollElement({ id, setShowLeftScrollElement, setShowRightScrollElement });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    calcScrollElementContantWidth({ id, setScrollElementsContentWidth });
    needShowScrollElement({ id, setShowLeftScrollElement, setShowRightScrollElement });
  }, [advisorsContainerScrollerRef]);

  useEffect(() => {
    if (!loading && windowInnerWidth >= window.shared.phoneOnlyUpSize && advisorsContainerRef.current) {
      const advisorsContainer = advisorsContainerRef.current;
      advisorsContainer.addEventListener('scroll', handleScroll);
      return () => {
        advisorsContainer.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, [windowInnerWidth, advisorsContainerRef.current]);

  const renderScrollLeftElement = () => {
    if (!showLeftScrollElement && !isPrerenderMode()) return null;
    return (
      <div className={ classes.scrollLeftElementContainer }>
        <button
          id={ scrollElementTypes.left }
          type="button"
          className={ classes.scrollElementImgContainer }
          style={ { border: '1px solid #ededed', borderTopRightRadius:'8px', borderBottomRightRadius: '8px' } }
          onClick={ onScrollBtnClick }
          suppressHydrationWarning
        >
          <img src={ leftArrow } alt="" />
        </button>
      </div>
    );
  };

  const renderScrollRightElement = () => {
    if (!showRightScrollElement && !isPrerenderMode()) return null;
    return (
      <div className={ classes.scrollRightElementContainer }>
        <button
          id={ scrollElementTypes.right }
          type="button"
          className={ classes.scrollElementImgContainer }
          style={ { border: '1px solid #ededed', borderTopLeftRadius:'8px', borderBottomLeftRadius: '8px' } }
          onClick={ onScrollBtnClick }
          suppressHydrationWarning
        >
          <img src={ rightArrow } alt="" />
        </button>
      </div>
    );
  };

  const renderScrollElements = () => (
    <>
      { renderScrollLeftElement() }
      { renderScrollRightElement() }
    </>
  );

  const renderAdvisorLoading = () => {
    const emptyList = advisorsEmptyList(4);
    return (
      emptyList.map((emptyAdvisor, index) => (
        <AdvisorPreview
          key={ `emptyAdvisorSection_${ index }` }
          advisor={ emptyAdvisor }
          theme={ theme }
          advisorCount={ 4 }
          loading={ loading }
        />
      ))
    );
  };

  const renderAdvisors = () => {
    if (loading) return renderAdvisorLoading();
    return (
      [
        content.advisors.map((advisor) => (
          <AdvisorPreview
            key={ advisor.id }
            advisor={ advisor }
            theme={ theme }
            advisorCount={ 4 }
            loading={ loading || sectionLoading }
            analytics={ analytics }
            sectionSlug={ slug }
          />
        )),
        renderSeeAllItmeMobile({
          showMore, slug, theme, windowInnerWidth, params
        })
      ]
    );
  };

  const renderSectionAdvisor = () => (
    <div
      className={ classes.sectionMainContainer }
    >
      { renderScrollElements() }
      <div
        id={ `${ id }advisorsContainer${ personalSection }` }
        className={ classes.advisorsContainer }
        ref= { advisorsContainerRef }
      >
        <div
          className={ classes.advisorsContainerScroller }
          id={ `${ id }advisorsContainerScroller` }
          ref={ advisorsContainerScrollerRef }
          style={ getJustifyContentStyle({
            subtype, scrollElementsContentWidth, windowInnerWidth
          }) }
          suppressHydrationWarning
        >
          { renderAdvisors() }
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    if (!cantBePresented(content)) return null;
    return (
      <div
        className={ classes[theme] }
        style={ getPersonalContentStyle(personalSection) }
        suppressHydrationWarning
      >
        <AdvisorSectionTitle
          title={ title }
          loading={ loading }
          iconUrl={ iconUrl }
          description={ description }
          shortDescription={ shortDescription }
          showMore={ showMore }
          slug={ slug }
          params={ params }
        />
        { renderSectionAdvisor() }
      </div>
    );
  };

  return renderContent();
}

AdvisorSection.propTypes = {
  ...SectionProps,
  theme: PropTypes.string,
  t: PropTypes.func.isRequired,
  personalSection: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  showMore: PropTypes.object,
  sectionLoading: PropTypes.bool
};

AdvisorSection.defaultProps = {
  ...SectionDefaultProps,
  theme: 'badge',
  iconUrl: null,
  title: null,
  personalSection: false,
  showMore:null,
  sectionLoading: null
};

export default AdvisorSection;
