import React from 'react';
import { lazyRetry } from '../../../../common/config/utils';

const SearchResults = React.lazy(() => lazyRetry(() => import('../../search_results'), 'SearchResults'));

// "/astrology",
// "/tarot-reading",
// "/psychic-reading",
// "/love-relationships",
// "/palm-reading",
// "/dream-analysis",
// "/numerology",
// "/fortune-telling",
// "/mediums-reaching-beyond",
// "/carrer-forecasts"

export const categoryRoutesKA = {
  '/astrology': {
    Component: SearchResults
  },
  '/tarot-reading': {
    Component: SearchResults
  },
  '/psychic-reading': {
    Component: SearchResults
  },
  '/love-relationships': {
    Component: SearchResults
  },
  '/palm-reading': {
    Component: SearchResults
  },
  '/dream-analysis': {
    Component: SearchResults
  },
  '/numerology': {
    Component: SearchResults
  },
  '/fortune-telling': {
    Component: SearchResults
  },
  '/mediums-reaching-beyond': {
    Component: SearchResults
  },
  '/career-forecasts': {
    Component: SearchResults
  }
};
