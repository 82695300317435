import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserContext } from '../config/utils';

function CreditCardPayment({
  paymentsParams, createCreditCardProcessingPurchase, getCreditCardProcessingPurchaseResponce,
  paymentsData, paymentsResponce, setCreditCardPaymentStatusHandler
}) {
  const [purchaseResponceInterval, setPurchaseResponceInterval] = useState(null);

  useEffect(() => {
    if (paymentsParams) {
      setCreditCardPaymentStatusHandler({ purchaseStarted: true });
      if (paymentsParams.threeDsConfig) {
        setCreditCardPaymentStatusHandler({ showThreeDSecure: true });
        return;
      }
      const params = {
        ...paymentsParams.requiredParams,
        ...browserContext
      };
      createCreditCardProcessingPurchase(params);
    }
  }, [paymentsParams]);

  useEffect(() => {
    if (paymentsData && !purchaseResponceInterval) {
      const int = setInterval(() => {
        getCreditCardProcessingPurchaseResponce(paymentsData.id);
      }, 1500);
      setPurchaseResponceInterval(int);
    }
  }, [paymentsData]);

  useEffect(() => {
    if (paymentsResponce) {
      const { okMessage, errorMessage } = paymentsResponce;
      if (okMessage) setCreditCardPaymentStatusHandler({ okMessage: paymentsResponce });
      if (errorMessage) setCreditCardPaymentStatusHandler({ errorMessage: paymentsResponce });
      if (purchaseResponceInterval && (okMessage || errorMessage)) {
        clearInterval(purchaseResponceInterval);
        setPurchaseResponceInterval(null);
      }
    }
  }, [paymentsResponce]);

  return <></>;
}

CreditCardPayment.propTypes = {
  createCreditCardProcessingPurchase: PropTypes.func.isRequired,
  getCreditCardProcessingPurchaseResponce: PropTypes.func.isRequired,
  paymentsParams: PropTypes.object,
  paymentsData: PropTypes.object,
  paymentsResponce: PropTypes.object,
  setCreditCardPaymentStatusHandler: PropTypes.func.isRequired
};

CreditCardPayment.defaultProps = {
  paymentsParams: null,
  paymentsData: null,
  paymentsResponce: null
};

export default CreditCardPayment;
