import React from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import AppLink from '../../../routes/app_link';
import { SectionProps, SectionDefaultProps } from '../../prop_types';

const seeAllRightArrow = require('../../img/see_all_right_arrow.svg');

const renderSeeAllBtnText = ({ showMore, slug, params }) => {
  if (!showMore || !slug) return null;
  const seeAllBtnText = showMore.title ? (
    <div className={ classes.seeAllBtnTextContainer }>
      { showMore.subtitle }
      {` (${ showMore.title })`}
      <img src={ seeAllRightArrow } className={ classes.seeAllBtnRightArrow } alt="" />
    </div>
  ) : (
    showMore.subtitle
  );
  return (
    <AppLink
      to={ {
        pathname: slug,
        state: {
          needRefresh: true,
          params
        }
      } }
      className={ classes.seeAllBtn }
    >
      { seeAllBtnText }
    </AppLink>
  );
};

const renderTitleTextSection = ({
  title, iconUrl, slug, params
}) => {
  if (slug) {
    return (
      <AppLink
        to={ {
          pathname: slug,
          state: {
            needRefresh: true,
            params
          }
        } }
        rel="nofollow"
      >
        <h2 className={ classes.advisorTitleText } style={ !iconUrl ? { marginLeft: '0px' } : {} }>
          { title ||  <div className={ classes.advisorTitleTextSkeleton } /> }
        </h2>
      </AppLink>
    );
  }
  return (
    <h2 className={ classes.advisorTitleText } style={ !iconUrl ? { marginLeft: '0px' } : {} }>
      { title || <div className={ classes.advisorTitleTextSkeleton } /> }
    </h2>
  );
};

const getIconUrlStyle = (iconUrl) => {
  if (!iconUrl) return { display: 'none' };
  return {};
};

function AdvisorSectionTitle({
  title, loading, iconUrl, description, shortDescription, showMore, slug, theme, params
}) {
  const renderSectionTitle = () => {
    if (!title && !loading) return null;
    return (
      <div className={ classes.advisorTitleContainer }>
        <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } } suppressHydrationWarning>
          <div className={ classes.advisorTitle }>
            <img src={ iconUrl } alt="" className={ classes.advisorTitleImg } style={ getIconUrlStyle(iconUrl) } />
            { renderTitleTextSection({
              title, iconUrl, slug, params
            }) }
          </div>
          { renderSeeAllBtnText({ showMore, slug, params }) }
        </div>
        <div className={ classes.advisorTitleDescription }>
          { description || <div className={ classes.advisorDescriptionSkeleton } /> }
        </div>
        <div className={ classes.advisorShortDescription }>
          { shortDescription || <div className={ classes.advisorShortDescriptionSkeleton } /> }
        </div>
      </div>
    );
  };
  return (
    <div className={ classes[theme] }>
      { renderSectionTitle() }
    </div>
  );
}

AdvisorSectionTitle.propTypes = {
  ...SectionProps,
  theme: PropTypes.string
};

AdvisorSectionTitle.defaultProps = {
  ...SectionDefaultProps,
  theme: null
};

export default AdvisorSectionTitle;
