import createReducer from '../config/create_reducer';
import Const from '../config/const';

const defaultState = {
  loading: false,
  error: null,
  status: null
};

const defaultHandler = {
  [Const.cashback.loading]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [Const.cashback.error]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error
  }),
  [Const.cashback.cashbackStatus]: (state, { payload }) => ({
    ...state,
    loading: false,
    ...payload
  }),
  [Const.cashback.clear]: () => ({
    ...defaultState
  }),
  [Const.logOut.clear]: () => ({
    ...defaultState
  })
};

export default createReducer(defaultState, defaultHandler);
